import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { Themes } from "../../../modules/cvx-ui-module";

export interface OdsRecentlyAddedColumnProps {
}

const OdsRecentlyAddedColumn: React.FC<OdsRecentlyAddedColumnProps> = ({
    ...props
}) => {
    return (
        <BsPatchCheckFill className={Themes.AccentLightColor} />
    )
};

export default OdsRecentlyAddedColumn;
