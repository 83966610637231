import { Permission } from "./permission";
import { State } from "./state";

export class RolePermission {
    public stateId: number = 0;
    public permission: Permission = { id: 'None', name: 'None' };

    public state?: State;

    public static map(data: any): RolePermission {
        const m = new RolePermission();

        m.stateId = parseInt(data.stateId);
        m.permission = Permission.map(data.permission);

        return m;
    };
};
