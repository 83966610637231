import { useEffect, useState } from "react";
import { Col, Form, FormLabel, FormSelect } from "react-bootstrap";
import OdsBaseTable from "../../../components/base-table/base-table";
import OdsBradenheadDashboardFilter, { BradenheadDashboardPreDefinedFilterValues } from "../../../components/bradenhead-dashboard-filter/bradenhead-dashboard-filter";
import OdsRecentlyAddedColumn from "../../../components/table-columns/recently-added-column/recently-added-column";
import OdsShouldBeDeletedColumn from "../../../components/table-columns/should-be-deleted-column/should-be-deleted-column";
import OdsAddBradenheadWellDialog from "../../../dialogs/add-bradenhead-well/add-bradenhead-well.dialog";
import OdsConfirmRolloverDialog from "../../../dialogs/confirm-rollover/confirm-rollover.dialog";
import OdsDispatchDialog from "../../../dialogs/dispatch-dialog/dispatch-dialog";
import OdsAutoDispatchDialog from "../../../dialogs/dispatch-dialog/auto-dispatch-dialog";
import OdsAutoDispatchAllDialog from "../../../dialogs/dispatch-dialog/auto-dispatch-all-dialog";
import OdsEditBradenheadWellDialog from "../../../dialogs/edit-bradenhead-well/edit-bradenhead-well.dialog";
import OdsMultiDeleteItemDialog from "../../../dialogs/multi-delete-item/multi-delete-item.dialog";
import OdsMultiEditColumnDialog from "../../../dialogs/multi-edit-column-dialog/multi-edit-column.dialog";
import OdsRemoveBradenheadWellDialog from "../../../dialogs/remove-bradenhead-well-dialog/remove-bradenhead-well.dialog";
import { BradenheadNewWell } from "../../../models/bradenhead-new-well";
import { BradenheadPressureData } from "../../../models/bradenhead-pressure-data";
import { BradenheadWellCategory } from "../../../models/bradenhead-well-category";
import { CreateBradenheadDispatch } from "../../../models/create-bradenhead-dispatch";
import { NamedFilter } from "../../../models/named-filter";
import { ReportMonth } from "../../../models/report-month";
import { ColumnDefinition, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import CvxContentBlock from "../../../modules/cvx-ui-module/components/content-block/content-block";
import { OdsPersonnelApiService } from "../../../services";
import { DataToCsvService } from "../../../services/data-to-csv-service";
import { DateHelper } from "../../../services/date-helper";
import { OdsApiService } from "../../../services/ods-api-service";
import OdsService from "../../../services/ods-service";


export interface OdsBradenheadDashboardViewProps {
    service: OdsApiService;
    odsService: OdsService | undefined;
    personnelService: OdsPersonnelApiService;
}


const columns: ColumnDefinition<BradenheadPressureData>[] = [
    {
        header: 'API No', exportHeader: 'API No', align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.api), sortFilterValue: ((i) => i.api),
        sortDirection: 'Asc'
    },
    {
        header: <span>Well&nbsp;Id</span>, exportHeader: 'Well Id', align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.wellId), sortFilterValue: ((i) => i.wellId)
    },
    {
        header: <span>Well&nbsp;Name</span>, exportHeader: 'Well Name', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.wellName), sortFilterValue: ((i) => i.wellName), tooltip: ((i) => i.wellName)
    },
    {
        header: <span>Route</span>, exportHeader: 'Route', align: 'left', canSort: true, canFilter: true, noWrap: true,
        template: ((i) => i.route), sortFilterValue: ((i) => i.route)
    },
    {
        header: <span>Record&nbsp;Date</span>, exportHeader: 'Record Date', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.recordDate?.toLocaleDateString()), sortFilterValue: ((i) => i.recordDate)
    },
    {
        header: <span>Bradenhead Pressure</span>, exportHeader: 'Bradenhead Pressure', align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.bradenheadPressure), sortFilterValue: ((i) => i.bradenheadPressure)
    },
    {
        header: <span>Production Casing Pressure</span>, exportHeader: 'Production Casing Pressure', align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.productionCasingPressure), sortFilterValue: ((i) => i.productionCasingPressure)
    },
    {
        header: <span>Intermediate Casing Pressure</span>, exportHeader: 'Intermediate Casing Pressure', align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.intermediateCasingPressure), sortFilterValue: ((i) => i.intermediateCasingPressure)
    },
    {
        header: <span>Datasource</span>, exportHeader: 'Datasource', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.datasourceName), sortFilterValue: ((i) => i.datasourceName)
    },
    {
        header: <span>Datasource Context</span>, exportHeader: 'Datasource Context', align: 'left', canSort: true, canFilter: true, noWrap: true,
        template: ((i) => i.datasourceContext), sortFilterValue: ((i) => i.datasourceContext)
    },
    {
        header: <span>Well Category</span>, exportHeader: 'Well Category', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.wellCategory), sortFilterValue: ((i) => i.wellCategory),
        canBulkEdit: true
    },
    {
        header: <span>User Name</span>, exportHeader: 'User Name', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.userName), sortFilterValue: ((i) => i.userName)
    },
    {
        header: <span>Area</span>, exportHeader: 'Area', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.areaName), sortFilterValue: ((i) => i.areaName), tooltip: ((i) => i.areaName)
    },
    {
        header: <span>Form Name</span>, exportHeader: 'Form Name', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.formName), sortFilterValue: ((i) => i.formName), tooltip: ((i) => i.formName)
    },
    {
        header: <span>Dispatch Form</span>, exportHeader: 'Dispatch Form', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.dispatchForm), sortFilterValue: ((i) => i.dispatchForm), tooltip: ((i) => i.dispatchForm)
    },
    {
        header: <span>Dispatch References</span>, exportHeader: 'Dispatch References', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.dispatchReferences), sortFilterValue: ((i) => i.dispatchReferences), tooltip: ((i) => i.dispatchReferences)
    },
    {
        header: <span>Dispatch Users</span>, exportHeader: 'Dispatch Users', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.dispatchUsers), sortFilterValue: ((i) => i.dispatchUsers), tooltip: ((i) => i.dispatchUsers)
    },
    {
        header: <span>Comments</span>, exportHeader: 'Comments', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.commentText), sortFilterValue: ((i) => i.commentText), tooltip: ((i) => i.commentText)
    },
    {
        header: <span>Status</span>, exportHeader: 'Status', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.status), sortFilterValue: ((i) => i.status)
    },
    {
        header: <span>LCT</span>, exportHeader: 'LCT', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.lct?.toLocaleDateString()), sortFilterValue: ((i) => i.lct?.toLocaleDateString())
    },
    {
        header: <span>AOC Fuild Type</span>, exportHeader: 'AOC Fuild Type', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.aocFluidType), sortFilterValue: ((i) => i.aocFluidType)
    },
    {
        header: <span>Automation Status</span>, exportHeader: 'Automation Status', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.automationStatus), sortFilterValue: ((i) => i.automationStatus)
    },
    {
        header: <span>Needs Dispatch</span>, exportHeader: 'Needs Dispatch', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.needsDispatch), sortFilterValue: ((i) => i.needsDispatch)
    },
    {
        header: <span>Available Dispatch</span>, exportHeader: 'Available To Dispatch', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.availableToDispatch), sortFilterValue: ((i) => i.availableToDispatch)
    },
    {
        header: <span>Recently Added</span>, exportHeader: 'Recently Added', align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.recentlyAdded === true ? <OdsRecentlyAddedColumn /> : ''), sortFilterValue: ((i) => i.recentlyAdded)
    },
    {
        header: <span>Should Be Deleted</span>, exportHeader: 'Should Be Deleted', align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.shouldBeDeleted === true ? <OdsShouldBeDeletedColumn /> : ''), sortFilterValue: ((i) => i.shouldBeDeleted)
    },
];


const dispatchColumns: ColumnDefinition<any>[] = [
    {
        header: <span>API No</span>, align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.api), sortFilterValue: ((i) => i.api)
    },
    {
        header: <span>Well Name</span>, align: 'left', noWrap: true, maxWidth: 200, canSort: true, canFilter: true,
        template: ((i) => i.wellName), sortFilterValue: ((i) => i.wellName)
    },
    {
        header: <span>Route Name</span>, align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.route), sortFilterValue: ((i) => i.route)
    },
    {
        header: <span>Well Category</span>, align: 'center', noWrap: true, canSort: true, canFilter: true,
        template: ((i) => i.wellCategory), sortFilterValue: ((i) => i.wellCategory)
    },
    {
        header: <span>Needs Dispatch</span>, exportHeader: 'Needs Dispatch', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.needsDispatch), sortFilterValue: ((i) => i.needsDispatch)
    },
    {
        header: <span>Available Dispatch</span>, exportHeader: 'Available To Dispatch', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.availableToDispatch), sortFilterValue: ((i) => i.availableToDispatch)
    },
    {
        header: <span>Dispatch Users</span>, align: 'left', noWrap: true, maxWidth: 250, canSort: true, canFilter: true,
        template: ((i) => i.dispatchUsers), sortFilterValue: ((i) => i.dispatchUsers), tooltip: ((i) => i.dispatchUsers)
    },
];

const autoDispatchColumns: ColumnDefinition<any>[] = [
    {
        header: <span>API No</span>, align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.api), sortFilterValue: ((i) => i.api)
    },
    {
        header: <span>Well Name</span>, align: 'left', noWrap: true, maxWidth: 200, canSort: true, canFilter: true,
        template: ((i) => i.wellName), sortFilterValue: ((i) => i.wellName)
    },
    {
        header: <span>Route Name</span>, align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.route), sortFilterValue: ((i) => i.route)
    },
    {
        header: <span>Well Category</span>, align: 'center', noWrap: true, canSort: true, canFilter: true,
        template: ((i) => i.wellCategory), sortFilterValue: ((i) => i.wellCategory)
    },
    {
        header: <span>Needs Dispatch</span>, exportHeader: 'Needs Dispatch', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.needsDispatch), sortFilterValue: ((i) => i.needsDispatch)
    },
    {
        header: <span>Available Dispatch</span>, exportHeader: 'Available To Dispatch', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.availableToDispatch), sortFilterValue: ((i) => i.availableToDispatch)
    },
    {
        header: <span>Dispatch Users</span>, align: 'left', noWrap: true, maxWidth: 250, canSort: true, canFilter: true,
        template: ((i) => i.dispatchUsers), sortFilterValue: ((i) => i.dispatchUsers), tooltip: ((i) => i.dispatchUsers)
    },
];

const autoDispatchAllColumns: ColumnDefinition<any>[] = [
    {
        header: <span>API No</span>, align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.api), sortFilterValue: ((i) => i.api)
    },
    {
        header: <span>Well Name</span>, align: 'left', noWrap: true, maxWidth: 200, canSort: true, canFilter: true,
        template: ((i) => i.wellName), sortFilterValue: ((i) => i.wellName)
    },
    {
        header: <span>Route Name</span>, align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.route), sortFilterValue: ((i) => i.route)
    },
    {
        header: <span>Well Category</span>, align: 'center', noWrap: true, canSort: true, canFilter: true,
        template: ((i) => i.wellCategory), sortFilterValue: ((i) => i.wellCategory)
    },
    {
        header: <span>Needs Dispatch</span>, exportHeader: 'Needs Dispatch', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.needsDispatch), sortFilterValue: ((i) => i.needsDispatch)
    },
    {
        header: <span>Available Dispatch</span>, exportHeader: 'Available To Dispatch', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.availableToDispatch), sortFilterValue: ((i) => i.availableToDispatch)
    },
    {
        header: <span>Dispatch Users</span>, align: 'left', noWrap: true, maxWidth: 250, canSort: true, canFilter: true,
        template: ((i) => i.dispatchUsers), sortFilterValue: ((i) => i.dispatchUsers), tooltip: ((i) => i.dispatchUsers)
    },
];

const addWellColumns: ColumnDefinition<BradenheadNewWell>[] = [
    {
        header: 'API No', align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.apiNo), sortFilterValue: ((i) => i.apiNo)
    },
    {
        header: <span>Well&nbsp;Id</span>, align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.wellId), sortFilterValue: ((i) => i.wellId)
    },
    {
        header: <span>Well&nbsp;Name</span>, align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.wellName), sortFilterValue: ((i) => i.wellName), tooltip: ((i) => i.wellName)
    },
    {
        header: <span>Route</span>, align: 'left', canSort: true, canFilter: true, noWrap: true,
        template: ((i) => i.routeName), sortFilterValue: ((i) => i.routeName)
    },
    {
        header: <span>Area</span>, align: 'left', canSort: true, canFilter: true, noWrap: true,
        template: ((i) => i.areaName), sortFilterValue: ((i) => i.areaName)
    },
    {
        header: <span>Well&nbsp;Category</span>, align: 'left', canSort: true, canFilter: true, noWrap: true,
        template: ((i) => i.wellCategory), sortFilterValue: ((i) => i.areaName)
    },
];

const multiCategoryColumns: ColumnDefinition<BradenheadPressureData>[] = [
    {
        header: 'API No', exportHeader: 'API No', align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.api), sortFilterValue: ((i) => i.api)
    },
    {
        header: <span>Well&nbsp;Id</span>, exportHeader: 'Well Id', align: 'center', canSort: true, canFilter: true,
        template: ((i) => i.wellId), sortFilterValue: ((i) => i.wellId)
    },
    {
        header: <span>Well&nbsp;Name</span>, exportHeader: 'Well Name', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.wellName), sortFilterValue: ((i) => i.wellName), tooltip: ((i) => i.wellName)
    },
    {
        header: <span>Route</span>, exportHeader: 'Route', align: 'left', canSort: true, canFilter: true, noWrap: true,
        template: ((i) => i.route), sortFilterValue: ((i) => i.route)
    },
    {
        header: <span>Well Category</span>, exportHeader: 'Well Category', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.wellCategory), sortFilterValue: ((i) => i.wellCategory)
    },
];


const OdsBradenheadDashboardView: React.FC<OdsBradenheadDashboardViewProps> = ({
    service,
    odsService,
    personnelService,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [reportMonths, setReportMonths] = useState<ReportMonth[]>([]);
    const [retrievedPressureData, setRetrievedPressureData] = useState<BradenheadPressureData[]>([]);
    const [pressureData, setPressureData] = useState<BradenheadPressureData[]>([]);
    const [wellCategories, setWellCategories] = useState<BradenheadWellCategory[]>([]);

    const [selectedReportMonthId, setSelectedReportMonthId] = useState<number>(0);
    const [isLatestMonth, setIsLatestMonth] = useState<boolean>(false);
    const [isCurrentMonth, setIsCurrentMonth] = useState<boolean>(false);

    const [showDispatchDialog, setShowDispatchDialog] = useState<boolean>(false);
    const [filteredBradenheadData, setFilteredBradenheadData] = useState<BradenheadPressureData[]>([]);
    const [selectedBradenheadData, setSelectedBradenheadData] = useState<BradenheadPressureData[]>([]);
    const [dispatchBradenheadData, setDispatchBradenheadData] = useState<BradenheadPressureData[]>([]);
    const [autoDispatchBradenheadData, setAutoDispatchBradenheadData] = useState<BradenheadPressureData[]>([]);
    const [autoDispatchFilteredBradenheadData, setAutoDispatchFilteredBradenheadData] = useState<BradenheadPressureData[]>([]);

    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [editWell, setEditWell] = useState<BradenheadPressureData | undefined>(undefined);

    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [deleteWell, setDeleteWell] = useState<BradenheadPressureData | undefined>(undefined);

    const [showAutoDispatchDialog, setShowAutoDispatchDialog] = useState<boolean>(false);
    const [showAutoDispatchAllDialog, setShowAutoDispatchAllDialog] = useState<boolean>(false);
    const [showRollover, setShowRollover] = useState<boolean>(false);
    const [rolloverDate, setRolloverDate] = useState<string>('');

    const [showMultiEditCategory, setShowMultiEditCategory] = useState<boolean>(false);
    const [multiEditCategory, setMultiEditCategory] = useState<number | undefined>();

    const [showMultiDelete, setShowMultiDelete] = useState<boolean>(false);

    const [tableColumns, setTableColumns] = useState<ColumnDefinition<BradenheadPressureData>[]>(columns);

    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const currentDate = () => {
        const date = new Date();
        return monthNames[date.getMonth()] + "-" + date.getFullYear();
    };


    const fetchReportMonths = async () => {
        if (odsService) {
            const rm = await odsService.RetrieveBradenheadReportMonthsAsync()
            setReportMonths(rm);
            setSelectedReportMonthId(rm[0]?.id ?? 0);
        }
    };

    const fetchPressureData = async () => {
        if (odsService && selectedReportMonthId > 0) {
            setIsLoading(true);

            const [categories, pressData] = await Promise.all([
                odsService.RetrieveBradenheadWellCategoriesAsync(),
                odsService.RetrieveBradenheadDashboardAsync(selectedReportMonthId),
            ])

            pressData.forEach((w) => {
                const cat = categories.find(c => c.name === w.wellCategory);
                w.wellCategoryId = cat?.id;
            });

            setWellCategories(categories);
            setPressureData(pressData);
            setRetrievedPressureData(pressData);
            setAutoDispatchBradenheadData(pressData.filter(p => p.needsDispatch === 'Y'));
            setIsLoading(false);
        }
    };


    const onSelectMonth = (reportMonth: number) => {
        setSelectedReportMonthId(reportMonth);
    }

    const onDispatch = () => {
        setShowDispatchDialog(true);
    };

    const onDispatchComplete = () => {
        setShowDispatchDialog(false);
    };

    const onAutoDispatchAll = () => {
        setAutoDispatchFilteredBradenheadData(
            autoDispatchBradenheadData.filter(p => (p.wellCategory?.includes('LO') || p.wellCategory?.includes('Problem Well') || p.wellCategory?.includes('ARO'))
                && (!p.wellCategory?.includes('AOC')))
        );
        setShowAutoDispatchAllDialog(true);  
    }

    const onAutoDispatchLO = () => {
        setAutoDispatchFilteredBradenheadData(autoDispatchBradenheadData.filter(p => p.wellCategory?.includes('LO') && !p.wellCategory.includes('Problem Well')
            && !p.wellCategory.includes('AOC')));
        setShowAutoDispatchDialog(true);  
    };

    const onAutoDispatchARO = () => {
        setAutoDispatchFilteredBradenheadData(autoDispatchBradenheadData.filter(p => p.wellCategory?.includes('ARO') && !p.wellCategory.includes('Problem Well')
            && !p.wellCategory.includes('AOC')));
        setShowAutoDispatchDialog(true);
    };

    const onAutoDispatchProblemWell = () => {
        setAutoDispatchFilteredBradenheadData(autoDispatchBradenheadData.filter(p => p.wellCategory?.includes('Problem Well')));
        setShowAutoDispatchDialog(true);
    };

    const onAutoDispatchComplete = (refreshNeeded: boolean) => {
        setAutoDispatchFilteredBradenheadData([]);
        setShowAutoDispatchDialog(false);

        if (refreshNeeded) {
            //refresh dashboard with updates
            fetchPressureData();
        }
        
    };

    const onAutoDispatchAllComplete = (refreshNeeded: boolean) => {
        setAutoDispatchFilteredBradenheadData([]);
        setShowAutoDispatchAllDialog(false);

        if (refreshNeeded) {
            //refresh dashboard with updates
            fetchPressureData();
        }

    }

    const onAutoDispatchItem = async (item: BradenheadPressureData, users: string[]) => {
        if (odsService) {
            const dispatch = new CreateBradenheadDispatch();
            dispatch.formId = 148914032;
            dispatch.apiNo = item.api;
            dispatch.wellCategory = item.wellCategory;
            dispatch.wellId = item.wellId;
            dispatch.wellName = item.wellName;
            dispatch.reportingMonth = reportMonths.find(i => i.id == selectedReportMonthId)?.name;
            dispatch.route = item.route;
            dispatch.dueDate = new Date();
            dispatch.recipients = users;

            await odsService.DispatchBradenheadPressureRequest(dispatch);
        }
    };

    const onDispatchItem = async (item: BradenheadPressureData, users: string[]) => {
        if (odsService) {
            const dispatch = new CreateBradenheadDispatch();
            dispatch.formId = 148914032;
            dispatch.apiNo = item.api;
            dispatch.wellCategory = item.wellCategory;
            dispatch.wellId = item.wellId;
            dispatch.wellName = item.wellName;
            dispatch.reportingMonth = reportMonths.find(i => i.id == selectedReportMonthId)?.name;
            dispatch.route = item.route;
            dispatch.dueDate = new Date();
            dispatch.recipients = users;

            await odsService.DispatchBradenheadPressureRequest(dispatch);
        }
    };

    const onFilteredItemsChanged = (filteredItems: BradenheadPressureData[]) => {
        setFilteredBradenheadData(filteredItems);
    };

    const onSelectedItemsChanged = (selectedItems: BradenheadPressureData[]) => {
        setSelectedBradenheadData(selectedItems);
    };

    const onNamedFilterChanged = (filter: NamedFilter | undefined) => {
        if (filter && filter.filterName === BradenheadDashboardPreDefinedFilterValues.List && filter.filterValues.length > 0) {
            const filtered: BradenheadPressureData[] = [];

            filter.filterValues.forEach(v => {
                const item = retrievedPressureData.find(i => i.api === v);
                if (item) {
                    filtered.push(item);
                }
            });
            setPressureData(filtered);
        } else if (filter && filter.filterName === BradenheadDashboardPreDefinedFilterValues.InComplete && filter.filterValues.length === 1) {
            setPressureData(retrievedPressureData.filter(i => i.status?.toLowerCase() === filter.filterValues[0].toLowerCase()));
        } else if (filter && filter.filterName === BradenheadDashboardPreDefinedFilterValues.InCompleteDispatched && filter.filterValues.length === 1) {
            setPressureData(retrievedPressureData.filter(i => i.status?.toLowerCase() === filter.filterValues[0].toLowerCase()
                && (i.dispatchUsers || i.dispatchReferences)));
        } else if (filter && filter.filterName === BradenheadDashboardPreDefinedFilterValues.InCompleteUndispatched && filter.filterValues.length === 1) {
            setPressureData(retrievedPressureData.filter(i => i.status?.toLowerCase() === filter.filterValues[0].toLowerCase()
                && !i.dispatchUsers && !i.dispatchReferences));
        } else {
            setPressureData(retrievedPressureData);
            clearFilters();
        }

        setFilteredBradenheadData([]);
        setSelectedBradenheadData([]);
        setDispatchBradenheadData([]);
    };

    const clearFilters = () => {
        const updatedColumns: ColumnDefinition<BradenheadPressureData>[] = [];
        columns.forEach(c => {
            c.filterValues = [];
            updatedColumns.push(c);
        });

        setTableColumns(updatedColumns);
    };

    const onShowAddEditWellDialog = (wellData: BradenheadPressureData | undefined): void => {
        if (wellData) {
            setEditWell(wellData);
            setShowEdit(true);
        } else {
            setShowAdd(true);
        }
    };

    const onAddCompleteDialog = async (dialogResult: boolean, newWells: BradenheadNewWell[]) => {
        if (dialogResult && odsService) {
            await odsService.AddBradenheadWellDataAsync(selectedReportMonthId, newWells);
            fetchPressureData();
        }
        setShowAdd(false);
    };

    const onEditCompleteDialog = async (dialogResult: boolean, wellData: BradenheadPressureData | undefined) => {
        if (dialogResult && odsService && wellData) {

            wellData.wellCategory = wellCategories.find(c => c.id === parseInt(wellData.wellCategoryId?.toString() ?? ''))?.name;

            await odsService.UpdateBradenheadWellDataAsync(selectedReportMonthId, wellData);
            fetchPressureData();
        }
        setShowEdit(false);
    };

    const onShowRemoveWell = (item: BradenheadPressureData) => {
        setDeleteWell(item);
        setShowDelete(true);
    };

    const onRemoveWell = async (dialogResult: boolean) => {
        if (odsService && dialogResult && deleteWell) {
            await odsService.RemoveBradenheadWellDataAsync(selectedReportMonthId, deleteWell);
            fetchPressureData();
        }

        setShowDelete(false);
    };

    const onRollover = () => {
        if (selectedReportMonthId > 0) {
            const currentMonth = reportMonths.find(r => r.id === selectedReportMonthId);

            if (currentMonth) {
                const date = DateHelper.addMonths(new Date(currentMonth.name), 1);
                const newMonth = monthNames[date.getMonth()] + "-" + date.getFullYear();

                setRolloverDate(newMonth);
                setShowRollover(true);
            }
        }
    }

    const onRolloverComplete = async (dialogResult: boolean) => {
        if (odsService && dialogResult) {
            await odsService.RolloverBradenheadDashboardAsync(selectedReportMonthId, rolloverDate, '');

            await fetchReportMonths();
            fetchPressureData();
        }
        setShowRollover(false);
    }

    const onExport = () => {
        const a = document.createElement('a');
        a.download = 'bradenhead.csv';
        a.href = URL.createObjectURL(DataToCsvService.generateCsvData(columns, dispatchBradenheadData));
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };

    const onMultiEditColumn = (column: ColumnDefinition<BradenheadPressureData>) => {
        setShowMultiEditCategory(true);
    }

    const onMultiEditCategoryComplete = async (dialogResult: boolean) => {
        if (dialogResult && odsService) {
            const category = wellCategories.find(c => c.id === multiEditCategory)?.name;

            for (const data of dispatchBradenheadData) {
                data.wellCategory = category;
                data.wellCategoryId = multiEditCategory;

                await odsService.UpdateBradenheadWellDataAsync(selectedReportMonthId, data);
            }

            fetchPressureData();
        }

        setShowMultiEditCategory(false);
    }

    const onMultiDeleteItem = () => {
        setShowMultiDelete(true);
    }

    const onMultiDeleteItemComplete = async (dialogResult: boolean) => {
        if (dialogResult && odsService) {
            for (const data of dispatchBradenheadData) {
                await odsService.RemoveBradenheadWellDataAsync(selectedReportMonthId, data);
            }

            fetchPressureData();
        }

        setShowMultiDelete(false);
    }

    useEffect(() => {
        fetchReportMonths();
    }, [odsService]);

    useEffect(() => {
        fetchPressureData();
    }, [selectedReportMonthId]);

    useEffect(() => {
        if (reportMonths && selectedReportMonthId) {
            setIsLatestMonth(reportMonths.findIndex(i => i.id === selectedReportMonthId) === 0);
            setIsCurrentMonth(reportMonths.find(i => i.id == selectedReportMonthId)?.name?.toUpperCase() === currentDate());
        } else {
            setIsLatestMonth(false);
            setIsCurrentMonth(false);
        }
    }, [reportMonths, selectedReportMonthId]);

    useEffect(() => {
        let dispatchData = pressureData;

        if (filteredBradenheadData && filteredBradenheadData.length > 0) {
            dispatchData = filteredBradenheadData;
        }

        if (selectedBradenheadData && selectedBradenheadData.length > 0) {
            dispatchData = selectedBradenheadData;
        }

        setDispatchBradenheadData(dispatchData);


    }, [pressureData, filteredBradenheadData, selectedBradenheadData]);

    return (
        <CvxPage desktop={
            <CvxLayoutCenterColumn>
                <OdsDispatchDialog
                    service={personnelService}
                    show={showDispatchDialog}
                    onComplete={onDispatchComplete}
                    inspections={dispatchBradenheadData}
                    columns={dispatchColumns}
                    onDispatchItem={onDispatchItem}
                />
                <OdsAutoDispatchAllDialog
                    service={personnelService}
                    show={showAutoDispatchAllDialog}
                    onComplete={onAutoDispatchAllComplete}
                    inspections={autoDispatchFilteredBradenheadData
                        }
                    columns={autoDispatchAllColumns}
                    onAutoDispatchItem={onAutoDispatchItem}
                />
                <OdsAutoDispatchDialog
                    service={personnelService}
                    show={showAutoDispatchDialog}
                    onComplete={onAutoDispatchComplete}
                    inspections={autoDispatchFilteredBradenheadData}
                    columns={autoDispatchColumns}
                    onAutoDispatchItem={onAutoDispatchItem}
                />
                <OdsAddBradenheadWellDialog
                    show={showAdd}
                    odsService={odsService}
                    columns={addWellColumns}
                    categories={wellCategories}
                    reportingMonthId={selectedReportMonthId}
                    onComplete={onAddCompleteDialog}
                />
                <OdsEditBradenheadWellDialog
                    show={showEdit}
                    well={editWell}
                    categories={wellCategories}
                    onComplete={onEditCompleteDialog}
                />
                <OdsRemoveBradenheadWellDialog
                    show={showDelete}
                    well={deleteWell}
                    onComplete={onRemoveWell}
                />
                <OdsConfirmRolloverDialog
                    show={showRollover}
                    rolloverMonth={rolloverDate}
                    onComplete={onRolloverComplete}
                />
                <OdsMultiEditColumnDialog
                    header="Well Category"
                    show={showMultiEditCategory}
                    data={dispatchBradenheadData}
                    columns={multiCategoryColumns}
                    onComplete={onMultiEditCategoryComplete}
                    editControl={
                        <Col>
                            <FormLabel>Well Category</FormLabel>
                            <FormSelect onChange={(e) => setMultiEditCategory(parseInt(e.target.value))}>
                                <option></option>
                                {
                                    wellCategories && wellCategories.map((item: any, index: number) => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                    })
                                }
                            </FormSelect>
                        </Col>
                    }
                />
                <OdsMultiDeleteItemDialog
                    header="Wells"
                    show={showMultiDelete}
                    data={dispatchBradenheadData}
                    columns={multiCategoryColumns}
                    onComplete={onMultiDeleteItemComplete}
                />
                <OdsBradenheadDashboardFilter
                    reportMonths={reportMonths}
                    selectedReportMonthId={selectedReportMonthId}
                    isLatestMonth={isLatestMonth}
                    isCurrentMonth={isCurrentMonth}
                    onSelectMonth={onSelectMonth}
                    onDispatch={onDispatch}
                    onAutoDispatchAll={onAutoDispatchAll}
                    onAutoDispatchLO={onAutoDispatchLO}
                    onAutoDispatchARO={onAutoDispatchARO}
                    onAutoDispatchProblemWell={onAutoDispatchProblemWell}
                    onRollover={onRollover}
                    onFilterChanged={onNamedFilterChanged}
                    onRefresh={() => fetchPressureData()}
                    onExport={onExport}
                />
                <CvxTableContentBlock>
                    <OdsBaseTable
                        heading={<span>Bradenhead Dashboard</span>}
                        columns={tableColumns}
                        dataSource={pressureData}
                        isLoading={isLoading}
                        canAdd={isCurrentMonth}
                        canEdit={isLatestMonth}
                        canRemove={isLatestMonth}
                        onAddEdit={onShowAddEditWellDialog}
                        onRemove={onShowRemoveWell}
                        onFilteredItemsChanged={onFilteredItemsChanged}
                        onSelectedItemsChanged={onSelectedItemsChanged}
                        onBulkEditColumn={onMultiEditColumn}
                        onBulkDeleteItem={onMultiDeleteItem}
                    />
                </CvxTableContentBlock>
            </CvxLayoutCenterColumn>
        } />
    );
};

export default OdsBradenheadDashboardView;
