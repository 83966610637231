export class Reg7HaulerDashboardView {
    public entityVersionId?: number;
    public entityId?: number;
    public versionId?: number;
    public versionName?: string;
    public lodId?: number;
    public lodName?: string;
    public facilityName?: string;
    public submissionEntityId?: number;
    public deviceSubmitDate?: Date;
    public pnfRequestId?: string;
    public userName?: string;
    public serverReceiveDate?: Date;
    public referenceNumber?: string;
    public tankId?: string;
    public tankName?: string;
    public haulerId?: string;
    public haulerName?: string;
    public driverName?: string;
    public preJob?: string;
    public preJobCorrectiveActions?: string;
    public connectVRS?: string;
    public vrsCorrectiveActions?: string;
    public loadEquipmentFunctioningCorrectly?: string;
    public properlyLoadProduct?: string;
    public loadProductCorrectiveActions?: string;
    public malfunctionEquipmentList?: string;
    public equipmentChevronOwned?: string;
    public chevronEquipmentMalfunction?: string;
    public haulerEquipmentMalfunction?: string;
    public haulerPositiveObservations?: string;
    public haulerCorrectiveActions?: string;
    public recordMonth?: string;
    public commentAttributeId?: number;
    public commentText?: string;

    public static mapDashboard(data: any): Reg7HaulerDashboardView {
        const d = new Reg7HaulerDashboardView;

        d.entityVersionId = data.entityVersionId;
        d.entityId = data.entityId;
        d.versionId = data.versionId;
        d.versionName = data.versionName;
        d.lodId = data.lodId;
        d.lodName = data.lodName;
        d.facilityName = data.facilityName;
        d.submissionEntityId = data.submissionEntityId;
        d.deviceSubmitDate = data.deviceSubmitDate ? new Date(data.deviceSubmitDate) : undefined;
        d.pnfRequestId = data.pnfRequestId;
        d.userName = data.userName;
        d.serverReceiveDate = data.serverReceiveDate ? new Date(data.serverReceiveDate) : undefined;
        d.referenceNumber = data.referenceNumber;
        d.tankId = data.tankId;
        d.tankName = data.tankName;
        d.haulerId = data.haulerId;
        d.haulerName = data.haulerName;
        d.driverName = data.driverName;
        d.preJob = data.preJob;
        d.preJobCorrectiveActions = data.preJobCorrectiveActions;
        d.connectVRS = data.connectVRS;
        d.vrsCorrectiveActions = data.vrsCorrectiveActions;
        d.loadEquipmentFunctioningCorrectly = data.loadEquipmentFunctioningCorrectly;
        d.properlyLoadProduct = data.properlyLoadProduct;
        d.loadProductCorrectiveActions = data.loadProductCorrectiveActions;
        d.malfunctionEquipmentList = data.malfunctionEquipmentList;
        d.equipmentChevronOwned = data.equipmentChevronOwned;
        d.chevronEquipmentMalfunction = data.chevronEquipmentMalfunction;
        d.haulerPositiveObservations = data.haulerPositiveObservations;
        d.haulerCorrectiveActions = data.haulerCorrectiveActions;
        d.recordMonth = data.recordMonth;
        d.commentAttributeId = data.commentAttributeId;
        d.commentText = data.commentText;

        return d;
    };
}
