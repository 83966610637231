import * as yup from 'yup';
import OdsDate from '../../components/form-editors/date/date';
import OdsInput from "../../components/form-editors/input/input";
import { LdarHoliday } from '../../models/ldar-holiday';
import { OdsLdarApiService } from '../../services';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditLdarHolidayProps {
    service: OdsLdarApiService;
    show: boolean;
    ldarHoliday: LdarHoliday | undefined;
    onComplete: (dialogResult: boolean, ldarHoliday: LdarHoliday | undefined) => Promise<any>;
}

export const OdsEditLdarHoliday: React.FC<OdsEditLdarHolidayProps> = ({
    service,
    show,
    ldarHoliday,
    onComplete,
    ...props
}) => {

    const schema = yup.object().shape({
        name: yup.string().required().max(250),
        date: yup.date().required().nullable(),
    });

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={ldarHoliday?.id ? 'Edit LDAR Holiday: ' + ldarHoliday.name : 'Add LDAR Holiday'}
            validationSchema={schema}
            initialValue={ldarHoliday ?? new LdarHoliday()}
            onComplete={onComplete}
        >
            <OdsInput name="name" label="Name" />
            <OdsDate name="date" label="Date" />
        </OdsBaseAddEditDialog>
    );
};
