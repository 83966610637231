import React, { HTMLAttributes } from "react";
import { BsFileRuled, BsFileRuledFill, BsFillPencilFill, BsPencil } from "react-icons/bs";
import { Themes } from "../../../modules/cvx-ui-module";

export interface OdsMultiEditColumnHeaderProps {
    selectedItems: any[];
    onClick: () => void;
}

export const OdsMultiEditColumnHeader: React.FC<OdsMultiEditColumnHeaderProps & HTMLAttributes<HTMLSpanElement>> = ({
    selectedItems,
    onClick,
    ...props
}) => {
    return (
        <th>
            {selectedItems !== undefined && selectedItems.length > 1 ?
                <span className={Themes.PrimaryDarkColor} onClick={onClick}><BsFileRuledFill /></span>
                : <span className="sort-inactive"><BsFileRuled /></span>
            }
        </th>
    )
};
