import { DispatchForm } from "./dispatch-form";
import { OrdInspectionRequestStatus } from "./ord-inspection-request-status";
import { OrdInspectionRequestTask } from "./ord-inspection-request-task";

export class OrdInspectionRequest {
    public id?: number;
    public assetNumber: string = '';
    public assetDescription: string = '';
    public formId: number = 0;
    public comments: string = '';
    public sourceSubmissionNumber: string = '';
    public userDefined1: string = '';
    public userDefined2: string = '';
    public statusId?: number;
    public taskId?: number;
    public routeId?: string;
    public masterId: string = '';
    public requestId: string = '';
    public startDate?: Date;
    public dueDate?: Date;
    public dispatchDate?: Date;
    public dispatchUserIds: number[] = [];
    public formSubmissionDate?: Date;
    public formSubmissionNumber?: string;

    public form?: DispatchForm;
    public task?: OrdInspectionRequestTask;
    public status?: OrdInspectionRequestStatus;

    public static map(data: any): OrdInspectionRequest {
        const m = new OrdInspectionRequest;

        m.id = data.id;
        m.assetNumber = data.assetNumber;
        m.assetDescription = data.assetDescription;
        m.formId = parseInt(data.formId);
        m.comments = data.comments;
        m.sourceSubmissionNumber = data.sourceSubmissionNumber;
        m.userDefined1 = data.userDefined1;
        m.userDefined2 = data.userDefined2;
        m.statusId = parseInt(data.statusId);
        m.taskId = parseInt(data.taskId);
        m.routeId = data.routeId;
        m.masterId = data.masterId;
        m.requestId = data.requestId;
        m.startDate = new Date(data.startDate);
        m.dueDate = new Date(data.dueDate);
        m.dispatchDate = new Date(data.dispatchDate);
        m.dispatchUserIds = data?.dispatchUserIds?.map((d: any) => parseInt(d)) ?? [];
        m.formSubmissionDate = new Date(data.formSubmissionDate);
        m.formSubmissionNumber = data.formSubmissionNumber;

        return m;
    };
};
