import { ListResult, LdarInspectionRequest, LdarInspectionRequestFilter, BooleanFilter, LdarHoliday, LdarHolidayFilter } from "../models";
import { LdarInspectionRequestDispatch } from "../models/ldar-inspection-request-dispatch";
import { LdarInspectionRequestDispatchFilter } from "../models/ldar-inspection-request-dispatch-filter";
import { LdarInspectionRequestStatus } from "../models/ldar-inspection-request-status";
import { LdarInspectionRequestStatusFilter } from "../models/ldar-inspection-request-status-filter";
import { LdarInspectionRequestType } from "../models/ldar-inspection-request-type";
import { LdarInspectionRequestTypeFilter } from "../models/ldar-inspection-request-type-filter";
import { Lod } from "../models/lod";
import { ApiService } from "./api-service";
import { ItemCache } from "./item-cache";
import { OdsApiServiceBase } from "./ods-api-service-base";

export class OdsLdarApiService extends OdsApiServiceBase {
    public constructor(apiService: ApiService | undefined) {
        super(apiService);
    }


    //*************************************************************************************************************************************
    //**************************************************   LDAR INSPECTION REQUESTS   *****************************************************
    //*************************************************************************************************************************************
    public async RetrieveLdarInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: LdarInspectionRequestFilter): Promise<ListResult<LdarInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<LdarInspectionRequest>>('/api/v1.0/ldar-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => LdarInspectionRequest.map(d)) };
    }

    public async RetrieveActiveLdarInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: LdarInspectionRequestFilter): Promise<ListResult<LdarInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = false;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<LdarInspectionRequest>>('/api/v1.0/ldar-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => LdarInspectionRequest.map(d)) };
    }

    public async RetrieveNewLdarInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: LdarInspectionRequestFilter): Promise<ListResult<LdarInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isDispatched = new BooleanFilter();
        filter.isDispatched.equal = false;

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = false;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<LdarInspectionRequest>>('/api/v1.0/ldar-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => LdarInspectionRequest.map(d)) };
    }

    public async RetrieveDispatchedLdarInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: LdarInspectionRequestFilter): Promise<ListResult<LdarInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isDispatched = new BooleanFilter();
        filter.isDispatched.equal = true;

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = false;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<LdarInspectionRequest>>('/api/v1.0/ldar-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => LdarInspectionRequest.map(d)) };
    }

    public async RetrieveCompletedLdarInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: LdarInspectionRequestFilter): Promise<ListResult<LdarInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = true;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<LdarInspectionRequest>>('/api/v1.0/ldar-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => LdarInspectionRequest.map(d)) };
    }

    public async RetrieveLdarInspectionRequestAsync(ldarInspectionRequestId: number): Promise<LdarInspectionRequest> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<LdarInspectionRequest>(`/api/v1.0/ldar-inspection-requests/${ldarInspectionRequestId}`);

        return LdarInspectionRequest.map(data);
    }

    public async CreateLdarInspectionRequestAsync(ldarInspectionRequest: LdarInspectionRequest): Promise<LdarInspectionRequest> {
        if (!this.apiService) {
            throw new Error();
        }

        ldarInspectionRequest.stateId = ldarInspectionRequest.state?.id ?? ldarInspectionRequest.stateId;
        ldarInspectionRequest.statusId = ldarInspectionRequest.status?.id ?? ldarInspectionRequest.statusId;

        const data = await this.apiService.post<LdarInspectionRequest>(`/api/v1.0/ldar-inspection-requests`, JSON.stringify(LdarInspectionRequest.map(ldarInspectionRequest)));

        return LdarInspectionRequest.map(data);
    }

    public async UpdateLdarInspectionRequestAsync(ldarInspectionRequest: LdarInspectionRequest): Promise<LdarInspectionRequest> {
        if (!this.apiService) {
            throw new Error();
        }

        ldarInspectionRequest.stateId = ldarInspectionRequest.state?.id ?? ldarInspectionRequest.stateId;
        ldarInspectionRequest.statusId = ldarInspectionRequest.status?.id ?? ldarInspectionRequest.statusId;

        const data = await this.apiService.put<LdarInspectionRequest>(`/api/v1.0/ldar-inspection-requests/${ldarInspectionRequest.id}`, JSON.stringify(LdarInspectionRequest.map(ldarInspectionRequest)));

        return LdarInspectionRequest.map(data);
    }

    public async DeleteLdarInspectionRequestAsync(ldarInspectionRequest: LdarInspectionRequest): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/ldar-inspection-requests/${ldarInspectionRequest.id}`);
    }


    public async RetrieveLdarInspectionRequestSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/ldar-inspection-requests/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportLdarInspectionRequestListAsync<T>(sort: string, sortDirection: string, filter: LdarInspectionRequestFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/ldar-inspection-requests/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }

    public async DispatchLdarInspectionRequestAsync(ldarInspectionRequest: LdarInspectionRequest, users: string[]): Promise<LdarInspectionRequest> {
        if (!this.apiService) {
            throw new Error();
        }

        const body = {
            emailAddresses: users,
        };

        const data = await this.apiService.post<LdarInspectionRequest>(`/api/v1.0/ldar-inspection-requests/${ldarInspectionRequest.id}/dispatch`, JSON.stringify(body));

        return LdarInspectionRequest.map(data);
    }


    //*************************************************************************************************************************************
    //***********************************************   LDAR INSPECTION REQUEST STATUSES   ************************************************
    //*************************************************************************************************************************************

    private statusCache = new ItemCache<number, LdarInspectionRequestStatus>();

    public async RetrieveLdarInspectionRequestStatusListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: LdarInspectionRequestStatusFilter): Promise<ListResult<LdarInspectionRequestStatus>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<LdarInspectionRequestStatus>>('/api/v1.0/ldar-inspection-request-statuses/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => this.statusCache.set(d.id, LdarInspectionRequestStatus.map(d))) };
    }

    public async RetrieveLdarInspectionRequestStatusAsync(statusId: number): Promise<LdarInspectionRequestStatus> {
        return this.statusCache.get(statusId, async (id: number) => {
            if (!this.apiService) {
                throw new Error();
            }

            const data = await this.apiService.get<LdarInspectionRequestStatus>(`/api/v1.0/ldar-inspection-request-statuses/${statusId}`);

            return LdarInspectionRequestStatus.map(data);
        });
    }


    //*************************************************************************************************************************************
    //*************************************************   LDAR INSPECTION REQUEST TYPES   *************************************************
    //*************************************************************************************************************************************

    private typeCache = new ItemCache<number, LdarInspectionRequestType>();

    public async RetrieveLdarInspectionRequestTypeListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: LdarInspectionRequestTypeFilter): Promise<ListResult<LdarInspectionRequestType>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<LdarInspectionRequestType>>('/api/v1.0/ldar-inspection-request-types/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => this.typeCache.set(d.id, LdarInspectionRequestType.map(d))) };
    }

    public async RetrieveLdarInspectionRequestTypeAsync(typeId: number): Promise<LdarInspectionRequestType> {
        return this.typeCache.get(typeId, async (id: number) => {
            if (!this.apiService) {
                throw new Error();
            }

            const data = await this.apiService.get<LdarInspectionRequestType>(`/api/v1.0/ldar-inspection-request-types/${typeId}`);

            return LdarInspectionRequestType.map(data);
        });
    }


    //*************************************************************************************************************************************
    //***********************************************   LDAR INSPECTION REQUEST DISPATCHES   **********************************************
    //*************************************************************************************************************************************

    public async RetrieveLdarInspectionRequestDispatchListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: LdarInspectionRequestDispatchFilter): Promise<ListResult<LdarInspectionRequestDispatch>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<LdarInspectionRequestDispatch>>('/api/v1.0/ldar-inspection-request-dispatches/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => LdarInspectionRequestDispatch.map(d)) };
    }

    public async RetrieveLdarInspectionRequestDispatchSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/ldar-inspection-request-dispatches/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async DeleteLdarInspectionRequestDispatchAsync(dispatch: LdarInspectionRequestDispatch): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/ldar-inspection-request-dispatches/${dispatch.id}/${dispatch.personnelId}`);
    }


    //*************************************************************************************************************************************
    //*******************************************************   LDAR HOLIDAYS  ************************************************************
    //*************************************************************************************************************************************

    public async RetrieveLdarHolidayListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: LdarHolidayFilter): Promise<ListResult<LdarHoliday>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<LdarHoliday>>('/api/v1.0/ldar-holidays/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => LdarHoliday.map(d)) };
    }

    public async RetrieveLdarHolidayAsync(ldarHolidayId: number): Promise<LdarHoliday> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<LdarHoliday>(`/api/v1.0/ldar-holidays/${ldarHolidayId}`);

        return LdarHoliday.map(data);
    }

    public async CreateLdarHolidayAsync(ldarHoliday: LdarHoliday): Promise<LdarHoliday> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.post<LdarHoliday>(`/api/v1.0/ldar-holidays`, JSON.stringify(ldarHoliday));

        return LdarHoliday.map(data);
    }

    public async UpdateLdarHolidayAsync(ldarHoliday: LdarHoliday): Promise<LdarHoliday> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.put<LdarHoliday>(`/api/v1.0/ldar-holidays/${ldarHoliday.id}`, JSON.stringify(ldarHoliday));

        return LdarHoliday.map(data);
    }

    public async DeleteLdarHolidayAsync(ldarHoliday: LdarHoliday): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/ldar-holidays/${ldarHoliday.id}`);
    }

    public async ValidateLdarHolidayNameAsync(name: string, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/ldar-holidays/validate?name=${name}&id=${id}`);

        return data;
    }

    public async RetrieveLdarHolidaySearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/ldar-holidays/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportLdarHolidayListAsync<T>(sort: string, sortDirection: string, filter: LdarHolidayFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/ldar-holidays/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }



    //*************************************************************************************************************************************
    //************************************************************   LODS   ***************************************************************
    //*************************************************************************************************************************************

    private lodCache = new ItemCache<string, Lod>();

    public async RetrieveLodListAsync(top: number, skip: number, sort: string, sortDirection: string): Promise<ListResult<Lod>> {
        if (!this.apiService) {
            throw new Error();
        }

        let data: ListResult<Lod>;
        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        data = await this.apiService.get<ListResult<Lod>>('/api/v1.0/lods' + params);

        return { totalCount: data.totalCount, items: data.items.map(d => this.lodCache.set(d.id, Lod.map(d))) };
    }

    public async RetrieveLodAsync(lodId: string): Promise<Lod> {
        return this.lodCache.get(lodId, async (id: string) => {
            if (!this.apiService) {
                throw new Error();
            }

            const data = await this.apiService.get<Lod>(`/api/v1.0/lods/${lodId}`);

            return Lod.map(data);
        });
    }
}
