import React from "react";

export interface CvxTableContentBlockProps {
    children?: React.ReactNode;
}

export const CvxTableContentBlock: React.FC<CvxTableContentBlockProps> = ({
    ...props
}) => {
    return (
        <div className="cvx-table-content-block">
            {props.children}
        </div>
    );
};

export default CvxTableContentBlock;
