import React from "react";
import { BsCheckCircle, BsCheckCircleFill, BsXCircle } from "react-icons/bs";

export interface OdsIsActiveColumnProps {
    isActive: boolean,
}

const OdsIsActiveColumn: React.FC<OdsIsActiveColumnProps> = ({
    isActive,
    ...props
}) => {
    return (
        isActive ? <BsCheckCircleFill /> : <BsXCircle />
    )
};

export default OdsIsActiveColumn;
