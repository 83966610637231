import { useEffect, useReducer, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import OdsTableFilterDialog from "../../dialogs/table-filter-dialog/table-filter.dialog";
import { FilterableData } from "../../models/filterable-data";
import { ColumnDefinition, CvxButton, CvxTable, PageChangedEvent } from "../../modules/cvx-ui-module";
import OdsDeleteColumn from "../table-columns/delete-column/delete-column";
import OdsEditColumn from "../table-columns/edit-column/edit-column";
import OdsIsSelectedColumn from "../table-columns/is-selected-column/is-selected-column";

export interface BaseTable2Props<T> {
    isLoading: boolean;
    overridePageSize?: number;
};


export interface OdsBaseTable2Props<T> {
    dataSource: FilterableData<T>;
    heading?: JSX.Element | string;
    columns: ColumnDefinition<T>[];
    footer?: JSX.Element | string;
    isLoading: boolean;
    canAdd?: boolean;
    canEdit?: boolean;
    canRemove?: boolean;
    canSelectItem?: boolean;
    onAddEdit?: (item: T | undefined) => void;
    onRemove?: (item: T) => void;
    onFilteredItemsChanged?: (filteredItems: T[]) => void;
    onSelectedItemsChanged?: (selectedItems: T[]) => void;
    onBulkEditColumn?: (column: ColumnDefinition<T>) => void;
    onBulkDeleteItem?: () => void;
}

interface ColumnFilter2Value<T> {
    column: ColumnDefinition<T>;
    filteredValues: any[];
}

interface Item {
}

const OdsBaseTable2 = <T extends Item>({
    dataSource,
    heading,
    columns,
    footer,
    isLoading,
    overridePageSize,
    canSelectItem,
    canAdd,
    canEdit,
    canRemove,
    onAddEdit,
    onRemove,
    onFilteredItemsChanged,
    onSelectedItemsChanged,
    onBulkEditColumn,
    onBulkDeleteItem,
    ...props
}: OdsBaseTable2Props<T> & BaseTable2Props<T>) => {
    const [updatedColumns, setUpdatedColumns] = useState<ColumnDefinition<T>[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const [sortColumn, setSortColumn] = useState<ColumnDefinition<T> | undefined>();
    const [sortColumnDirection, setSortColumnDirection] = useState<string>();
    const [filters, setFilters] = useState<ColumnFilter2Value<T>[]>([]);

    const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
    const [filterColumn, setFilterColumn] = useState<ColumnDefinition<T> | undefined>();
    const [filterValues, setFilterValues] = useState<any[]>([]);

    const [selectedItem, setSelectedItem] = useState<T | undefined>(undefined);

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const onPageChanged = (e: PageChangedEvent) => {
        setPageIndex(e.pageIndex);
        setPageSize(e.pageSize);
    };

    const onSortChanged = (column: ColumnDefinition<T>): void => {
        setSortColumn(undefined);

        if (column.sortDirection === 'Asc') {
            column.sortDirection = 'Desc';
        } else if (column.sortDirection === 'Desc') {
            column.sortDirection = 'Asc';
        } else {
            column.sortDirection = 'Asc';
        }

        columns.forEach(c => {
            if (c !== column) {
                c.sortDirection = undefined;
            }
        });

        setSortColumn(columns.find(c => c.sortDirection !== undefined));
        setSortColumnDirection(columns.find(c => c.sortDirection !== undefined)?.sortDirection);
    }

    const onFilterChanged = (column: ColumnDefinition<T>): void => {
        setFilterColumn(column);
        setFilterValues(filters.find(f => f.column === column)?.filteredValues ?? []);

        setShowFilterDialog(true);
    }

    const onFilterComplete = async (dialogResult: boolean, filterValues: any[]): Promise<any> => {
        const f = filters;

        let cf = f.find(f => f.column === filterColumn);
        if (cf) {
            cf.filteredValues = filterValues;
        } else if (filterColumn && filterValues?.length > 0) {
            f.push({ column: filterColumn, filteredValues: filterValues });
        }

        if (filterColumn) {
            filterColumn.filterValues = filterValues;
        }

        setSelectedItem(undefined);
        dataSource.selectedData = [];

        updateDispatchData();

        setPageIndex(0);
        setFilters(f);
        setShowFilterDialog(false);
    }

    const onAddItem = () => {
        if (onAddEdit) {
            onAddEdit(undefined);
        }
    };

    const onEditItem = (item: T) => {
        if (onAddEdit) {
            onAddEdit(item);
        }
    };

    const onRemoveItem = (item: T) => {
        if (onRemove) {
            onRemove(item);
        }
    };

    const onSelectionChanged = (selectedItem: T | undefined, selectedItems: T[]) => {
        setSelectedItem(selectedItem);
        dataSource.selectedData = selectedItems;

        updateDispatchData();

        if (onSelectedItemsChanged) {
            onSelectedItemsChanged(dataSource.selectedData);
        }
    }

    const updateDispatchData = () => {
        if(dataSource) {
            if(dataSource.selectedData.length > 0) {
                dataSource.dispatchData = dataSource.selectedData;
            } else if(dataSource.filteredData.length > 0) {
                dataSource.dispatchData = dataSource.filteredData;
            } else {
                dataSource.dispatchData = dataSource.data;
            }
        }
    }

    useEffect(() => {
        let filtered = dataSource.data ?? [];

        filters.forEach(f => {
            if (f.filteredValues.length > 0) {
                filtered = filtered.filter(i => f.filteredValues.findIndex(v => {
                    if (v === '-- Blanks --') {
                        return (f.column.sortFilterValue && f.column.sortFilterValue(i) === ''
                            || f.column.sortFilterValue && f.column.sortFilterValue(i) === undefined
                            || f.column.sortFilterValue && f.column.sortFilterValue(i) === null);
                    } else {
                        return f.column.sortFilterValue && f.column.sortFilterValue(i) === v;
                    }
                }) >= 0);
            }
        });

        if (sortColumn && sortColumn.sortFilterValue) {
            filtered = filtered.sort((l, r) => {
                if (sortColumn && sortColumn.sortFilterValue) {
                    if (sortColumn.sortFilterValue(l) > sortColumn.sortFilterValue(r)) {
                        return sortColumn.sortDirection === 'Asc' ? 1 : -1;
                    } else if (sortColumn.sortFilterValue(l) < sortColumn.sortFilterValue(r)) {
                        return sortColumn.sortDirection === 'Desc' ? 1 : -1;
                    } else {
                        return 0;
                    }
                }
                return 0;
            });
        }

        dataSource.filteredData = filtered;

        if (onFilteredItemsChanged) {
            onFilteredItemsChanged(filtered);
        }

        dataSource.pagedData = dataSource.filteredData.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);

        updateDispatchData();
        forceUpdate();

    }, [dataSource, pageIndex, pageSize, sortColumn, sortColumnDirection, showFilterDialog, filters]);

    useEffect(() => {
        const updatedColumns: ColumnDefinition<T>[] = [];

        if (canEdit && onAddEdit) {
            updatedColumns.push({ header: '', align: 'center', template: ((i) => <OdsEditColumn item={i} onEdit={(i) => onEditItem(i)} />), sortFilterValue: () => undefined })
        }

        if (canSelectItem) {
            updatedColumns.push({
                header: '', align: 'center', template: ((i) => <OdsIsSelectedColumn isSelected={dataSource.selectedData.find(s => s === i) !== undefined} onSelectedChanged={s => {
                    if (s) {
                        dataSource.selectedData.push(i)
                    } else {
                        const index = dataSource.selectedData.indexOf(i, 0);
                        if (index > -1) {
                            dataSource.selectedData.splice(index, 1);
                        }
                    }

                    if (onSelectedItemsChanged) {
                        onSelectedItemsChanged(dataSource.selectedData);
                    }
                }} />), sortFilterValue: (i) => dataSource.selectedData.find(s => s === i) !== undefined
            })
        }

        updatedColumns.push(...columns);

        if (canRemove && onRemove) {
            updatedColumns.push({ header: '', align: 'center', canBulkDelete: true, template: ((i) => <OdsDeleteColumn item={i} onDelete={(i) => onRemoveItem(i)} />), sortFilterValue: () => undefined });
        }

        const filters: ColumnFilter2Value<T>[] = [];
        updatedColumns.forEach(c => {
            if (c.sortDirection) {
                setSortColumn(c);
                setSortColumnDirection(c.sortDirection);
            }
            if (c.filterValues && c.filterValues.length > 0) {
                filters.push({ column: c, filteredValues: c.filterValues });
            }
        });
        setFilters(filters);

        setUpdatedColumns(updatedColumns);
    }, [columns, canEdit, canRemove]);

    useEffect(() => {
        if (overridePageSize) {
            setPageSize(overridePageSize);
        }
    }, [overridePageSize]);

    return (
        <>
            <OdsTableFilterDialog show={showFilterDialog} list={dataSource.filteredData} column={filterColumn} filterValues={filterValues} onComplete={onFilterComplete} />
            <CvxTable
                dataSource={dataSource.pagedData}
                selectedItems={dataSource.selectedData}
                selectedItem={selectedItem}
                heading={
                    <span className="ods-table-header">
                        <span className="ods-table-header-text">{heading}</span>
                        {(canAdd && onAddEdit) ? <span className="ods-add-button"><CvxButton onClick={onAddItem}><BsPlusLg />&nbsp;Add</CvxButton></span> : ''}
                    </span>
                }
                columns={updatedColumns}
                footer={footer}
                isLoading={isLoading}
                supportsPagination={true}
                pageSizeOptions={[5, 10, 25, 100, 500, 5000]}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalCount={dataSource.filteredData.length}
                onPageChanged={onPageChanged}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                onSelectionChanged={onSelectionChanged}
                onBulkEditColumn={onBulkEditColumn}
                onBulkDeleteItem={onBulkDeleteItem}
            />
        </>
    );
};

OdsBaseTable2.defaultProps = {
    canAdd: true,
    canEdit: true,
    canRemove: true,
};

export default OdsBaseTable2;
