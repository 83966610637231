import { HtmlHTMLAttributes, PropsWithChildren } from "react";
import { Popover } from "react-bootstrap";
import { Lod } from "../../models/lod";
import { OdsLodChip } from "../lod-chip/lod-chip";
import { OdsStringColumn } from "../table-columns/string-column/string-column";

interface Item {
    lodId: string;
    lod?: Lod;
};

export interface OdsLdarLodColumnProps {
    item: Item;
    onFetchLod: (id: string) => Promise<Lod>;
};

export const OdsLdarLodColumn: React.FC<PropsWithChildren<OdsLdarLodColumnProps> & HtmlHTMLAttributes<HTMLTableCellElement>> = ({
    item,
    onFetchLod,
    ...props
}) => {
    return (
        <OdsStringColumn tooltip={
            <>
                <Popover.Header>{item.lod?.name}</Popover.Header>
                <Popover.Body>
                    <table>
                        <tr>
                            <td>Id</td>
                            <td>{item.lodId}</td>
                        </tr>
                        <tr>
                            <td>Access Directions</td>
                            <td>{item.lod?.accessDirections}</td>
                        </tr>
                    </table>
                </Popover.Body>
            </>
        }>
            <OdsLodChip id={item.lodId} onFetchLod={onFetchLod} />
        </OdsStringColumn>
    )
}
