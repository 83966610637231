import { Facility, ListResult, Personnel, PersonnelFilter, PersonnelGroup, PersonnelGroupFilter, PersonnelGroupType, PersonnelGroupTypeFilter, Route, RouteFilter } from "../models";
import { ApiService } from "./api-service";
import { ItemCache } from "./item-cache";

export class OdsApiService {
    private apiService: ApiService | undefined;

    public constructor(apiService: ApiService | undefined) {
        this.apiService = apiService;
    }



    //*************************************************************************************************************************************
    //********************************************************   PERSONNEL   **************************************************************
    //*************************************************************************************************************************************

    public async RetrievePersonnelListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: PersonnelFilter): Promise<ListResult<Personnel>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<Personnel>>('/api/v1.0/personnel/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => Personnel.map(d)) };
    }

    public async RetrievePersonnelAsync(personnelId: number): Promise<Personnel> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<Personnel>(`/api/v1.0/personnel/${personnelId}`);

        return Personnel.map(data);
    }

    public async CreatePersonnelAsync(personnel: Personnel): Promise<Personnel> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.post<Personnel>(`/api/v1.0/personnel`, JSON.stringify(personnel));

        return Personnel.map(data);
    }

    public async UpdatePersonnelAsync(personnel: Personnel): Promise<Personnel> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.put<Personnel>(`/api/v1.0/personnel/${personnel.id}`, JSON.stringify(personnel));

        return Personnel.map(data);
    }

    public async DeletePersonnelAsync(personnel: Personnel): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/personnel/${personnel.id}`);
    }

    public async ValidatePersonnelEmailAsync(email: string, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/personnel/validate?email=${email}&id=${id}`);

        return data;
    }

    public async RetrievePersonnelSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/personnel/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportPersonnelListAsync<T>(sort: string, sortDirection: string, filter: PersonnelFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/personnel/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }


    //*************************************************************************************************************************************
    //*****************************************************   PERSONNEL GROUPS   **********************************************************
    //*************************************************************************************************************************************

    public async RetrievePersonnelGroupListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: PersonnelGroupFilter): Promise<ListResult<PersonnelGroup>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<PersonnelGroup>>('/api/v1.0/personnel-groups/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => PersonnelGroup.map(d)) };
    }

    public async RetrievePersonnelGroupAsync(personnelGroupId: number): Promise<PersonnelGroup> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<PersonnelGroup>(`/api/v1.0/personnel-groups/${personnelGroupId}`);

        return PersonnelGroup.map(data);
    }

    public async CreatePersonnelGroupAsync(personnelGroup: PersonnelGroup): Promise<PersonnelGroup> {
        if (!this.apiService) {
            throw new Error();
        }

        let json = {
            name: personnelGroup.name,
            description: personnelGroup.description,
            personnelGroupTypeId: personnelGroup.personnelGroupType?.id,
            personnelIds: personnelGroup.personnel.map(p => p.id),
            isActive: personnelGroup.isActive,
        }

        const data = await this.apiService.post<PersonnelGroup>(`/api/v1.0/personnel-groups`, JSON.stringify(json));

        return PersonnelGroup.map(data);
    }

    public async UpdatePersonnelGroupAsync(personnelGroup: PersonnelGroup): Promise<PersonnelGroup> {
        if (!this.apiService) {
            throw new Error();
        }

        let json = {
            name: personnelGroup.name,
            description: personnelGroup.description,
            personnelGroupTypeId: personnelGroup.personnelGroupType?.id,
            personnelIds: personnelGroup.personnel.map(p => p.id),
            isActive: personnelGroup.isActive,
        }

        const data = await this.apiService.put<PersonnelGroup>(`/api/v1.0/personnel-groups/${personnelGroup.id}`, JSON.stringify(json));

        return PersonnelGroup.map(data);
    }

    public async DeletePersonnelGroupAsync(personnelGroup: PersonnelGroup): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/personnel-groups/${personnelGroup.id}`);
    }

    public async ValidatePersonnelGroupNameAsync(name: string, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/personnel-groups/validate?name=${name}&id=${id}`);

        return data;
    }

    public async RetrievePersonnelGroupSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/personnel-groups/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportPersonnelGroupListAsync<T>(sort: string, sortDirection: string, filter: PersonnelGroupFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/personnel-groups/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }


    //*************************************************************************************************************************************
    //***************************************************   PERSONNEL GROUP TYPES  ********************************************************
    //*************************************************************************************************************************************

    public async RetrievePersonnelGroupTypeListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: PersonnelGroupTypeFilter): Promise<ListResult<PersonnelGroupType>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<PersonnelGroupType>>('/api/v1.0/personnel-group-types/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => PersonnelGroupType.map(d)) };
    }

    public async RetrievePersonnelGroupTypeAsync(personnelGroupTypeId: number): Promise<PersonnelGroupType> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<PersonnelGroupType>(`/api/v1.0/personnel-group-types/${personnelGroupTypeId}`);

        return PersonnelGroupType.map(data);
    }

    public async CreatePersonnelGroupTypeAsync(personnelGroupType: PersonnelGroupType): Promise<PersonnelGroupType> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.post<PersonnelGroupType>(`/api/v1.0/personnel-group-types`, JSON.stringify(personnelGroupType));

        return PersonnelGroupType.map(data);
    }

    public async UpdatePersonnelGroupTypeAsync(personnelGroupType: PersonnelGroupType): Promise<PersonnelGroupType> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.put<PersonnelGroupType>(`/api/v1.0/personnel-group-types/${personnelGroupType.id}`, JSON.stringify(personnelGroupType));

        return PersonnelGroupType.map(data);
    }

    public async DeletePersonnelGroupTypeAsync(personnelGroupType: PersonnelGroupType): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/personnel-group-types/${personnelGroupType.id}`);
    }

    public async ValidatePersonnelGroupTypeNameAsync(name: string, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/personnel-group-types/validate?name=${name}&id=${id}`);

        return data;
    }

    public async RetrievePersonnelGroupTypeSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/personnel-group-types/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportPersonnelGroupTypeListAsync<T>(sort: string, sortDirection: string, filter: PersonnelGroupTypeFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/personnel-group-types/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }


    //*************************************************************************************************************************************
    //**********************************************************   ROUTES  ****************************************************************
    //*************************************************************************************************************************************

    public async RetrieveRouteListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: RouteFilter): Promise<ListResult<Route>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<Route>>('/api/v1.0/routes/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => Route.map(d)) };
    }

    public async RetrieveRouteAsync(routeId: number): Promise<Route> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<Route>(`/api/v1.0/routes/${routeId}`);

        return Route.map(data);
    }

    public async CreateRouteAsync(route: Route): Promise<Route> {
        if (!this.apiService) {
            throw new Error();
        }

        let json = {
            name: route.name,
            description: route.description,
            ecObjectCode: route.ecObjectCode,
            personnelGroupIds: route.personnelGroups.map(p => p.id),
            isActive: route.isActive,
        }

        const data = await this.apiService.post<Route>(`/api/v1.0/routes`, JSON.stringify(json));

        return Route.map(data);
    }

    public async UpdateRouteAsync(route: Route): Promise<Route> {
        if (!this.apiService) {
            throw new Error();
        }

        let json = {
            name: route.name,
            description: route.description,
            ecObjectCode: route.ecObjectCode,
            personnelGroupIds: route.personnelGroups.map(p => p.id),
            isActive: route.isActive,
        }

        const data = await this.apiService.put<Route>(`/api/v1.0/routes/${route.id}`, JSON.stringify(json));

        return Route.map(data);
    }

    public async DeleteRouteAsync(route: Route): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/routes/${route.id}`);
    }

    public async ValidateRouteNameAsync(name: string, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/routes/validate?name=${name}&id=${id}`);

        return data;
    }

    public async RetrieveRouteSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/routes/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportRouteListAsync<T>(sort: string, sortDirection: string, filter: RouteFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/routes/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }

    //*************************************************************************************************************************************
    //**********************************************************   FACILITIES  ************************************************************
    //*************************************************************************************************************************************

    private facilityCache = new ItemCache<number, Facility>();

    public async RetrieveFacilityListAsync(top: number, skip: number, sort: string, sortDirection: string): Promise<ListResult<Facility>> {
        if (!this.apiService) {
            throw new Error();
        }

        let data: ListResult<Facility>;
        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        data = await this.apiService.get<ListResult<Facility>>('/api/v1.0/facilities' + params);

        return { totalCount: data.totalCount, items: data.items.map(d => this.facilityCache.set(d.id, Facility.map(d))) };
    }

    public async RetrieveFacilityAsync(facilityId: number): Promise<Facility> {
        return this.facilityCache.get(facilityId, async (id: number) => {
            if (!this.apiService) {
                throw new Error();
            }

            const data = await this.apiService.get<Facility>(`/api/v1.0/facilities/${facilityId}`);

            return Facility.map(data);
        });
    }
}
