import { Route } from "./route";

export class Facility {
    public id: number = 0;
    public name: string = '';
    public routeId?: number;
    public inspectionRouteId?: number;
    public isActive?:  boolean;
    public state: string = '';

    public route?: Route;
    public inspectionRoute?: Route;

    public static map(data: any): Facility {
        const facility = new Facility();

        facility.id = data.id;
        facility.name = data.name;
        facility.routeId =  parseInt(data.routeId);
        facility.inspectionRouteId = parseInt(data.inspectionRouteId);
        facility.isActive = data.isActive;
        facility.state = data.state;

        return facility;
    };
};
