import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { OrdInspectionRequestTask } from "../../models/ord-inspection-request-task";
import { OdsOrdApiService } from "../../services";

export interface OdsOrdTaskChipProps {
    service: OdsOrdApiService;
    inspectionRequest: Item;
}

interface Item {
    taskId?: number;
    task?: OrdInspectionRequestTask;
}

export const OdsOrdTaskChip: React.FC<OdsOrdTaskChipProps> = ({
    service,
    inspectionRequest
}) => {
    const [data, setData] = useState<OrdInspectionRequestTask>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        if (inspectionRequest && inspectionRequest.taskId) {
            const data = await service.RetrieveOrdInspectionRequestTaskAsync(inspectionRequest.taskId)
            inspectionRequest.task = data;
            setData(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [inspectionRequest])

    return (
        <span className="ods-item-chip">{isLoading === false ? (data?.name === '' ? "NONE" : data?.name) : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>}</span>
    )
}
