import * as yup from 'yup';
import { OdsAsyncSelect } from '../../components';
import OdsInput from "../../components/form-editors/input/input";
import OdsSwitch from '../../components/form-editors/switch/switch';
import { useMyself } from '../../hooks/myself-hook';
import { IntegerFilter } from '../../models';
import { Permission } from '../../models/permission';
import { Personnel } from '../../models/personnel';
import { StateFilter } from '../../models/state-filter';
import { OdsPersonnelApiService } from '../../services';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditPersonnelDialogProps {
    service: OdsPersonnelApiService;
    show: boolean;
    personnel: Personnel | undefined;
    onComplete: (dialogResult: boolean, personnel: Personnel | undefined) => Promise<any>;
}

export const OdsEditPersonnelDialog: React.FC<OdsEditPersonnelDialogProps> = ({
    service,
    show,
    personnel,
    onComplete,
    ...props
}) => {

    const [canEdit, showState, states] = useMyself(service, Permission.EditPersonnel);

    const personnelSchema = yup.object().shape({
        firstName: yup.string().required().max(150),
        lastName: yup.string().required().max(150),
        email: yup.string().email().required().max(500)
            .test('duplicate', 'email is already in use', async (value: string | undefined, testContext: any) => {
                if(value) {
                    return await service.ValidatePersonnelEmailAsync(value, testContext.parent.state?.id, personnel?.id);
                }
                return true;
            }),
        aadId: yup.string().nullable().max(150),
        state: yup.object().required(),
        isActive: yup.boolean().required(),
    });

    const onSearchState = async (top: number, skip: number, filterStr: string) => {
        const filter = new StateFilter();
        const idFilter = new IntegerFilter();

        filter.id = idFilter;
        filter.id.equal = states;

        let data = await service.RetrieveStateListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={personnel?.id ? 'Edit Personnel: ' + personnel.id : 'Add Personnel'}
            validationSchema={personnelSchema}
            initialValue={personnel ?? new Personnel()}
            onComplete={onComplete}
        >
            <OdsInput name="firstName" label="First Name" />
            <OdsInput name="lastName" label="Last Name" />
            <OdsInput name="email" label="Email" />
            <OdsInput name="aadId" label="CAI" />
            <OdsAsyncSelect
                name="state"
                label="State"
                labelKey="name"
                onSearch={onSearchState}
            />
            <OdsSwitch name="isActive" label="Is Active" />
        </OdsBaseAddEditDialog>
    );
};
