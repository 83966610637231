import * as yup from 'yup';
import OdsInput from "../../components/form-editors/input/input";
import { OdsEditRolePermissions } from '../../components/form-editors/role-permissions/role-permissions';
import OdsSwitch from '../../components/form-editors/switch/switch';
import OdsTextArea from '../../components/form-editors/textarea/textarea';
import { StringFilter, Role } from '../../models';
import { PermissionFilter } from '../../models/permission-filter';
import { StateFilter } from '../../models/state-filter';
import { OdsUserApiService } from '../../services';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditRoleDialogProps {
    service: OdsUserApiService;
    show: boolean;
    role: Role | undefined;
    onComplete: (dialogResult: boolean, user: Role | undefined) => Promise<any>;
}

export const OdsEditRoleDialog: React.FC<OdsEditRoleDialogProps> = ({
    service,
    show,
    role,
    onComplete,
    ...props
}) => {

    const schema = yup.object().shape({
        name: yup.string().required().max(150),
        // .test('duplicate', 'name is already in use', async (value: string | undefined) => {
        //     if(value) {
        //         return await service.ValidateRoleNameAsync(value, role?.id);
        //     }
        //     return true;
        // }),
        description: yup.string().nullable(),
        rolePermissions: yup.array().required().min(1).of(yup.object().shape({
            permission: yup.object().required(),
            state: yup.object().required()
        })),
        isActive: yup.boolean().required(),
    });

    const onSearchPermissions = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PermissionFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        let data = await service.RetrievePermissionListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    const onSearchStates = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new StateFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        let data = await service.RetrieveStateListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={role?.id ? 'Edit Role: ' + role.name : 'Add Role'}
            validationSchema={schema}
            initialValue={role ?? new Role()}
            onComplete={onComplete}
        >
            <OdsInput name="name" label="Name" />
            <OdsTextArea name="description" label="Description" />
            <OdsEditRolePermissions
                name="rolePermissions"
                label="Permissions"
                onSearchPermission={onSearchPermissions}
                onSearchState={onSearchStates}
            />
            <OdsSwitch name="isActive" label="Is Active" />
        </OdsBaseAddEditDialog>
    );
};
