export abstract class FontSizes {
    public static H1: string = 'h1';
    public static H2: string = 'h2';
    public static H3: string = 'h3';
    public static H4: string = 'h4';
    public static H5: string = 'h5';

    public static P1: string = 'p1';
    public static P2: string = 'p2';
    public static P3: string = 'p3';
};

export default FontSizes;
