import { HtmlHTMLAttributes } from "react";

export interface OdsDateTimeColumnProps {
    value?: Date
}

export const OdsDateTimeColumn: React.FC<OdsDateTimeColumnProps & HtmlHTMLAttributes<HTMLTableCellElement>> = ({
    value,
    ...props
}) => {
    return (
        <td {...props} className="center-column">{value ? (value > new Date('9000-1-1') ? '' : value.toLocaleDateString() + " " + value.toLocaleTimeString()): props.children}</td>
    )
}
