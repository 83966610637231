import { ListResult, User, UserFilter, BooleanFilter, Role, RoleFilter } from "../models";
import { Permission } from "../models/permission";
import { PermissionFilter } from "../models/permission-filter";
import { ApiService } from "./api-service";
import { OdsApiServiceBase } from "./ods-api-service-base";

export class OdsUserApiService extends OdsApiServiceBase {
    public constructor(apiService: ApiService | undefined) {
        super(apiService);
    }


    //*************************************************************************************************************************************
    //***********************************************************   USERS   ***************************************************************
    //*************************************************************************************************************************************
    public async RetrieveUserListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: UserFilter): Promise<ListResult<User>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<User>>('/api/v1.0/users/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => User.map(d)) };
    }

    public async RetrieveUserAsync(userId: number): Promise<User> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<User>(`/api/v1.0/users/${userId}`);

        return User.map(data);
    }

    public async CreateUserAsync(user: User): Promise<User> {
        if (!this.apiService) {
            throw new Error();
        }

        let body = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            isActive: user.isActive,
            roles: user.roles?.map(d => { return { roleId: d.role?.id, isAssignable: d.isAssignable }; })
        };

        const data = await this.apiService.post<User>(`/api/v1.0/users`, JSON.stringify(User.map(body)));

        return User.map(data);
    }

    public async UpdateUserAsync(user: User): Promise<User> {
        if (!this.apiService) {
            throw new Error();
        }

        let body = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            isActive: user.isActive,
            roles: user.roles?.map(d => { return { roleId: d.role?.id, isAssignable: d.isAssignable }; })
        };

        const data = await this.apiService.put<User>(`/api/v1.0/users/${user.id}`, JSON.stringify(User.map(body)));

        return User.map(data);
    }

    public async DeleteUserAsync(ldarInspectionRequest: User): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/users/${ldarInspectionRequest.id}`);
    }


    public async RetrieveUserSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/users/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportUserListAsync<T>(sort: string, sortDirection: string, filter: UserFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/users/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }

    public async DispatchUserAsync(ldarInspectionRequest: User, users: string[]): Promise<User> {
        if (!this.apiService) {
            throw new Error();
        }

        const body = {
            emailAddresses: users,
        };

        const data = await this.apiService.post<User>(`/api/v1.0/users/${ldarInspectionRequest.id}/dispatch`, JSON.stringify(body));

        return User.map(data);
    }


    //*************************************************************************************************************************************
    //***********************************************************   ROLES   ***************************************************************
    //*************************************************************************************************************************************

    public async RetrieveRoleListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: RoleFilter): Promise<ListResult<Role>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<Role>>('/api/v1.0/roles/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => Role.map(d)) };
    }

    public async RetrieveRoleAsync(ldarHolidayId: number): Promise<Role> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<Role>(`/api/v1.0/roles/${ldarHolidayId}`);

        return Role.map(data);
    }

    public async CreateRoleAsync(role: Role): Promise<Role> {
        if (!this.apiService) {
            throw new Error();
        }

        let body = {
            name: role.name,
            description: role.description,
            isActive: role.isActive,
            rolePermissions: role.rolePermissions?.map(d => { return { permission: d.permission?.id, stateId: d.state?.id }; })
        };

        const data = await this.apiService.post<Role>(`/api/v1.0/roles`, JSON.stringify(body));

        return Role.map(data);
    }

    public async UpdateRoleAsync(role: Role): Promise<Role> {
        if (!this.apiService) {
            throw new Error();
        }

        let body = {
            name: role.name,
            description: role.description,
            isActive: role.isActive,
            rolePermissions: role.rolePermissions?.map(d => { return { permission: d.permission?.id, stateId: d.state?.id }; })
        };

        const data = await this.apiService.put<Role>(`/api/v1.0/roles/${role.id}`, JSON.stringify(body));

        return Role.map(data);
    }

    public async DeleteRoleAsync(ldarHoliday: Role): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/roles/${ldarHoliday.id}`);
    }

    public async ValidateRoleNameAsync(name: string, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/roles/validate?name=${name}&id=${id}`);

        return data;
    }

    public async RetrieveRoleSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/roles/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportRoleListAsync<T>(sort: string, sortDirection: string, filter: RoleFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/roles/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }


    //*************************************************************************************************************************************
    //********************************************************   PERMISSIONS   ************************************************************
    //*************************************************************************************************************************************

    public async RetrievePermissionListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: PermissionFilter): Promise<ListResult<Permission>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<Permission>>('/api/v1.0/permissions/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => Permission.map(d)) };
    }

    public async RetrievePermissionAsync(permissionId: string): Promise<Permission> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<Permission>(`/api/v1.0/permissions/${permissionId}`);

        return Permission.map(data);
    }
}
