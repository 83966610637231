import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { State } from "../../models/state";

interface StateItem {
    stateId: number;
    state?: State;
}

export interface OdsStateLookupProps {
    item: StateItem;
    onFetchState: (stateId: number) => Promise<State>;
}

export const OdsStateLookup: React.FC<OdsStateLookupProps> = ({
    item,
    onFetchState
}) => {
    const [data, setData] = useState<State>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        if (item) {
            const data = await onFetchState(item.stateId);
            item.state = data;
            setData(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [item])

    return (
        <span>{isLoading === false ? data?.name ?? item.stateId : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>}</span>
    )
}
