import React, { HTMLAttributes, useState } from "react";
import { OdsStringFilterDialog } from "../../../dialogs";
import { ListResult } from "../../../models/list-result";
import { StringFilter } from "../../../models/string-filter";
import { ViewHelper } from "../../../services/view-helpers";

export interface OdsStringColumnHeaderProps {
    header: React.ReactNode;
    name: string;
    filter?: StringFilter;
    sort?: string;
    sortDirection?: string;
    onSortChanged?: (sort: string, sortDirection: string) => void;
    onFilterChanged?: (newFilter?: StringFilter) => void;
    onSearch?: (columnName: string, top: number, skip: number, filter: string) => Promise<ListResult<string>>;
}

export const OdsStringColumnHeader: React.FC<OdsStringColumnHeaderProps & HTMLAttributes<HTMLSpanElement>> = ({
    header,
    name,
    filter,
    sort,
    sortDirection,
    onSortChanged,
    onFilterChanged,
    onSearch,
    ...props
}) => {
    const [showFilter, setShowFilter] = useState<boolean>(false);

    const onFilterList = (columnName: string) => {
        setShowFilter(true);
    }

    const onDoSearch = async (top: number, skip: number, filter: string): Promise<ListResult<string>> => {
        if (onSearch) {
            return await onSearch(name, top, skip, filter);
        }
        else {
            return { totalCount: 0, items: [] };
        }
    }

    const onFilterComplete = (dialogResult: boolean, newFilter?: StringFilter) => {
        setShowFilter(false);
        if (dialogResult && onFilterChanged) {
            onFilterChanged(newFilter);
        }
    }

    const onDrawSort = (columnName: string) => {
        return ViewHelper.drawSort(columnName, sort, sortDirection, onSortChanged);
    };

    const onDrawFilter = (columnName: string, filtered: boolean) => {
        return ViewHelper.drawFilter(columnName, filtered, onFilterList, onFilterChanged);
    }

    return (
        <th>
            <OdsStringFilterDialog
                header={header}
                show={showFilter}
                filter={filter}
                onSearch={onDoSearch}
                onComplete={onFilterComplete}
            />
            <span className="ods-column-header" {...props}>{header}{onDrawSort(name)}{onDrawFilter(name, !!filter)}</span>
        </th>
    )
};
