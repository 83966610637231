import { BooleanFilter } from "./boolean-filter";
import { IntegerFilter } from "./integer-filter";
import { StringFilter } from "./string-filter";

export class RoleFilter {
    public id?: IntegerFilter;
    public name?: StringFilter;
    public description?: StringFilter;
    public isActive?: BooleanFilter;
}
