import { MouseEvent, useState } from "react";
import { Spinner } from "react-bootstrap";
import { BsArrowDownUp, BsFunnelFill, BsFunnel, BsArrowDown, BsArrowUp, BsCalendar2WeekFill, BsCalendar2Week, BsChatSquareDotsFill, BsChatSquareDots, BsTrash, BsTrashFill } from "react-icons/bs";
import Themes from "../../helpers/themes";
import CvxFooter from "../footer/footer";
import CvxHeading from "../heading/heading";
import CvxPagination, { CvxPaginationProps } from "../pagination/pagination";
import CvxColumn from "./column";

export interface ColumnDefinition<T> {
    header: JSX.Element | string;
    exportHeader?: string;
    align?: 'left' | 'center' | 'right';
    noWrap?: boolean;
    width?: string;
    maxWidth?: number;
    canSort?: boolean;
    sortDirection?: 'Asc' | 'Desc'
    canFilter?: boolean;
    canBulkEdit?: boolean;
    canBulkDelete?: boolean;
    filterValues?: any[];
    tooltip?: (item: T) => React.ReactNode | string
    template: (item: T) => React.ReactNode | string;
    sortFilterValue?: (item: T) => any;
}

export interface CvxTableProps<T> {
    dataSource: T[];
    selectedItems?: T[];
    selectedItem?: T | undefined;
    heading?: JSX.Element | string;
    columns: ColumnDefinition<T>[];
    footer?: JSX.Element | string;
    supportsPagination?: boolean;
    isLoading?: boolean;
    onSortChanged?: (column: ColumnDefinition<T>) => void;
    onFilterChanged?: (column: ColumnDefinition<T>) => void;
    onSelectionChanged?: (selectedItem: T | undefined, selectedItems: T[]) => void;
    onBulkEditColumn?: (column: ColumnDefinition<T>) => void;
    onBulkDeleteItem?: () => void;
};

export interface CvxTableColumnProps {
};

export const CvxTable = <T extends CvxTableColumnProps & CvxPaginationProps>({
    dataSource,
    selectedItems,
    selectedItem,
    heading,
    columns,
    footer,
    supportsPagination,
    isLoading,
    onSortChanged,
    onFilterChanged,
    onSelectionChanged,
    onBulkEditColumn,
    onBulkDeleteItem,
    ...props
}: CvxTableProps<T> & CvxPaginationProps) => {

    const calcColumnAlign = (column: ColumnDefinition<T>): 'left-column' | 'center-column' | 'right-column' => {
        if (column.align === 'center') {
            return 'center-column';
        } else if (column.align === 'right') {
            return 'right-column';
        } else {
            return 'left-column';
        }
    };

    const sortIcon = (column: ColumnDefinition<T>): JSX.Element => {
        if (column.canSort === true && column.sortFilterValue && onSortChanged) {
            if (column.sortDirection === 'Asc') {
                return <BsArrowDown onClick={() => onSort(column)} />
            } else if (column.sortDirection === 'Desc') {
                return <BsArrowUp onClick={() => onSort(column)} />
            } else {
                return <BsArrowDownUp className="sort-inactive" onClick={() => onSort(column)} />
            }
        } else {
            return <></>;
        }
    }

    const onSort = (column: ColumnDefinition<T>): void => {
        if (onSortChanged) {
            onSortChanged(column);
        }
    }

    const filterIcon = (column: ColumnDefinition<T>): JSX.Element => {
        if (column.canFilter === true && column.sortFilterValue && onFilterChanged) {
            if (column.filterValues && column.filterValues.length > 0) {
                return <BsFunnelFill onClick={() => onFilter(column)} />
            } else {
                return <BsFunnel className="sort-inactive" onClick={() => onFilter(column)} />
            }
        } else {
            return <></>;
        }
    }

    const bulkEditIcon = (column: ColumnDefinition<T>): JSX.Element => {
        if (column.canBulkEdit === true && onBulkEditColumn) {
            if (selectedItems && selectedItems.length > 1) {
                return <BsChatSquareDotsFill className="edit-active" onClick={() => onBulkEditColumn(column)} />
            } else {
                return <BsChatSquareDots className="edit-inactive" />
            }
        } else {
            return <></>;
        }
    }

    const bulkDeleteIcon = (column: ColumnDefinition<T>): JSX.Element => {
        if (column.canBulkDelete === true && onBulkDeleteItem) {
            if (selectedItems && selectedItems.length > 1) {
                return <BsTrashFill className={Themes.WarningDarkColor} onClick={() => onBulkDeleteItem()} />
            } else {
                return <BsTrash className="edit-inactive" />
            }
        } else {
            return <></>;
        }
    }

    const onFilter = (column: ColumnDefinition<T>): void => {
        if (onFilterChanged) {
            onFilterChanged(column);
        }
    }

    const onRowClick = (evt: MouseEvent, item: T) => {
        if (onSelectionChanged) {
            const shift = evt.getModifierState("Shift");
            const ctrl = evt.getModifierState("Control");

            if (shift == false && ctrl == false) {
                const selection: T[] = [];
                if (item != selectedItem) {
                    selection.push(item);
                    onSelectionChanged(item, selection);
                } else {
                    onSelectionChanged(undefined, selection);
                }
            }

            if (shift && selectedItem) {
                let start = dataSource.indexOf(selectedItem);
                let end = dataSource.indexOf(item);
                const selection: T[] = selectedItems ?? [];

                if (end < start) {
                    const temp = end;
                    end = start;
                    start = temp;
                }

                for (let i = start; i <= end; i++) {
                    if (selectedItems?.find(item => item == dataSource[i]) === undefined) {
                        selection.push(dataSource[i]);
                    }
                }

                onSelectionChanged(item, selection);
            }

            if(ctrl) {
                const selection: T[] = selectedItems ?? [];
                const index = selection.indexOf(item);
                if (index > -1) {
                    selection.splice(index, 1);
                    const newSelection: T[] = [];
                    selection.forEach(item => newSelection.push(item));
                    onSelectionChanged(undefined, newSelection);
                } else {
                    selection.push(item);
                    onSelectionChanged(item, selection);
                }
            }
        }
    }


    return (
        <div className="cvx-table">
            {heading ? <CvxHeading heading={heading} /> : ''}
            <div className="table-scroll">
                <table>
                    <thead>
                        <tr>
                            {columns.map((c, i) => <th key={i} className={calcColumnAlign(c)}><span className="header">{c.header}{sortIcon(c)}{filterIcon(c)}{bulkEditIcon(c)}{bulkDeleteIcon(c)}</span></th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading
                            ? <tr className="loading">
                                <td colSpan={columns.length}>
                                    <Spinner animation="border" role="status" variant="primary">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </td>
                            </tr>
                            : dataSource.map((item, j) =>
                                <tr key={j} onClick={(evt) => onRowClick(evt, item)} className={selectedItems?.find(i => i == item) ? 'selected' : ''}>
                                    {columns.map((c, i) => <CvxColumn key={i * 10000 + j} item={item} template={c.template} tooltip={c.tooltip} align={calcColumnAlign(c)} noWrap={c.noWrap} width={c.width} maxWidth={c.maxWidth} />)}
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
            {supportsPagination ? <CvxPagination {...props} /> : ''}
            {footer ? <CvxFooter footer={footer} /> : ''}
        </div>
    );
};

export default CvxTable;
