import { useEffect, useReducer, useState } from "react";
import { ListResult } from "../models";
import { PageChangedEvent } from "../modules/cvx-ui-module";
import { ViewHelper } from "../services";

export const usePagedListResult = <T, TFilter>(
    defaultSort: string,
    defaultSortDirection: string,
    defaultFilter: TFilter,
    onFetchData: (top: number, skip: number, sort: string, sortDirection: string, filter: TFilter) => Promise<ListResult<T>>
) => {
    const [ignored, forceUpdate] = useReducer(x => x = -x, 1);
    
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, setData] = useState<ListResult<T>>({ totalCount: 0, items: [] });
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const [selectedItems, setSelectedItems] = useState<T[]>([]);
    const [selectedItem, setSelectedItem] = useState<T>();

    const [sort, setSort] = useState<string>(defaultSort);
    const [sortDirection, setSortDirection] = useState<string>(defaultSortDirection);

    const [filter, setFilter] = useState<TFilter>(defaultFilter);


    const fetchData = async (top: number, skip: number, sort: string, sortDirection: string, filter: TFilter) => {
        setIsLoading(true);
        setSelectedItem(undefined);
        setSelectedItems([]);
        setData(await onFetchData(top, skip, sort, sortDirection, filter));
        setIsLoading(false);
    };

    const onRefresh = () => {
        if (pageIndex == 0) {
            fetchData(pageSize, pageIndex * pageSize, sort, sortDirection, filter);
        } else {
            setPageIndex(0);
        }
    };

    const onPageChanged = (e: PageChangedEvent) => { setPageIndex(e.pageIndex); setPageSize(e.pageSize); };

    const onSortChanged = (sort: string, sortDirection: string) => { setSort(sort); setSortDirection(sortDirection); };

    const onRowSelect = (evt: React.MouseEvent<Element, MouseEvent>, item: T) => {
        setSelectedItems(ViewHelper.rowSelect(evt, item, selectedItem, selectedItems, data.items));
        setSelectedItem(item);
        forceUpdate();
    };

    const onClearFilter = () => setFilter(defaultFilter);

    useEffect(() => {
        fetchData(pageSize, pageIndex * pageSize, sort, sortDirection, filter);
    }, [pageIndex, pageSize, sort, sortDirection, filter]);


    return [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading, 
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect] as const;
};
