import { DispatchFormFilter, ItemFilter, MultiEditOrdInspectionRequest, OrdInspectionRequest, OrdInspectionRequestStatusFilter, OrdInspectionRequestTaskFilter, StringFilter } from "../../models";
import { OdsOrdApiService } from "../../services";
import * as yup from 'yup';
import { useEffect, useState } from "react";
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";
import { OdsMultiAsyncSelect, OdsMultiComments, OdsMultiDate, OdsMultiInput } from "../../components";


export interface OdsMultiEditOrdInspectionRequestDialogProps {
    service: OdsOrdApiService;
    show: boolean;
    inspectionRequests: OrdInspectionRequest[];
    onComplete: (dialogResult: boolean, inspection: MultiEditOrdInspectionRequest | undefined) => Promise<any>;
}

export const OdsMultiEditOrdInspectionRequestDialog: React.FC<OdsMultiEditOrdInspectionRequestDialogProps> = ({
    service,
    show,
    inspectionRequests,
    onComplete,
    ...props
}) => {
    const schema = yup.object().shape({
        form: yup.object().nullable().notRequired(),
        task: yup.object().nullable(),
        status: yup.object().nullable().notRequired(),
        startDate: yup.object().nullable().notRequired(),
        dueDate: yup.object().nullable().notRequired(),
        userDefined1: yup.object().nullable().notRequired(),
        userDefined2: yup.object().nullable().notRequired(),
        masterId: yup.object().nullable().notRequired(),
        requestId: yup.object().nullable().notRequired(),
        comments: yup.object().nullable().notRequired(),
    });


    const [inspectionRequest, setInspectionRequest] = useState<MultiEditOrdInspectionRequest>(new MultiEditOrdInspectionRequest());


    const onSearchForm = async (top: number, skip: number, filterStr: string) => {
        const filter = new DispatchFormFilter();

        filter.parent = new ItemFilter();
        filter.parent.equal = ["FAM_FORMS", "ORD_FORMS"];

        filter.name = new StringFilter();
        filter.name.like = filterStr;

        let data = await service.RetrieveDispatchFormListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    const onSearchTask = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new OrdInspectionRequestTaskFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        let data = await service.RetrieveOrdInspectionRequestTaskListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    const onSearchStatus = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new OrdInspectionRequestStatusFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        let data = await service.RetrieveOrdInspectionRequestStatusListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };


    useEffect(() => {
        if (show && inspectionRequests) {
            let edit = new MultiEditOrdInspectionRequest();
            let ir = inspectionRequests[0];

            setInspectionRequest(edit);
        } else {
            setInspectionRequest(new MultiEditOrdInspectionRequest());
        }
    }, [show, inspectionRequests])

    return (
        <OdsBaseAddEditDialog
            show={show}
            title="Edit Inspection Requests"
            validationSchema={schema}
            initialValue={inspectionRequest}
            onComplete={onComplete}
        >
            <OdsMultiAsyncSelect name='form' label='Form' labelKey='name' onSearch={onSearchForm} />
            <OdsMultiAsyncSelect name='task' label='Task' labelKey='name' onSearch={onSearchTask} />
            <OdsMultiAsyncSelect name='status' label='Status' labelKey='name' onSearch={onSearchStatus} />
            <OdsMultiDate name="startDate" label="Start Date" />
            <OdsMultiDate name="dueDate" label="Due Date" />
            <OdsMultiInput name="userDefined1" label="User Defined 1" />
            <OdsMultiInput name="userDefined2" label="User Defined 2" />
            <OdsMultiInput name="masterId" label="Master Id" />
            <OdsMultiInput name="requestId" label="Request Id" />
            <OdsMultiComments name="comments" label="Comments" />

        </OdsBaseAddEditDialog>
    );
};
