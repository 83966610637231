export class FilterableData<T> {
    public data: T[] = [];
    public retrievedData: T[] = [];
    public pagedData: T[] = [];
    public filteredData: T[] = [];
    public selectedData: T[] = [];
    public dispatchData: T[] = [];

    public constructor(data: T[]) {
        this.data = data;
        this.retrievedData = data;

        this.pagedData = [];
        this.filteredData = [];
        this.selectedData = [];
        this.dispatchData = [];
    };
}
