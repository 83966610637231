import { ColumnDefinition } from "../modules/cvx-ui-module";

export class DataToCsvService {
    public static generateCsvData<T>(columns: ColumnDefinition<T>[], data: T[]) {
        let blobData: string = '';
        columns.forEach((c) => blobData += `"${c.exportHeader ? c.exportHeader : c.header.toString()}",`);
        blobData += '\r\n';

        data.forEach((d) => {
            columns.forEach((c) => {
                const val = c.template(d);

                if (val !== null) {
                    blobData += `"${val}",`;
                } else {
                    blobData += `"",`;
                }
            });
            blobData += '\r\n';
        });

        return new Blob([blobData], { type: 'text/csv' });;
    };
}
