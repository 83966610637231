import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { ReportMonth } from "../../models/report-month";
import { CvxButton } from "../../modules/cvx-ui-module";
import CvxContentBlock from "../../modules/cvx-ui-module/components/content-block/content-block";

export interface OdsReg7HaulerDashboardFilterProps {
    reportMonths: ReportMonth[];
    selectedReportMonthId: number;
    isLatestMonth: boolean;
    isCurrentMonth: boolean;
    onSelectMonth: (reportingMonthId: number) => void;
    onRefresh?: () => void;
    onExport?: () => void;
    onRollover?: () => void;
}

const OdsReg7HaulerDashboardFilter: React.FC<OdsReg7HaulerDashboardFilterProps> = ({
    reportMonths,
    selectedReportMonthId,
    isLatestMonth,
    isCurrentMonth,
    onSelectMonth,
    onRefresh,
    onExport,
    onRollover,
    ...props
}) => {

    return (
        <CvxContentBlock>
            <Container className="ods-bradenhead-dashboard-filter">
                <Row>
                    <Col>
                        <Form.Label>Month</Form.Label>
                        <Form.Select value={selectedReportMonthId} onChange={(e) => onSelectMonth(parseInt(e.target.value))}>
                            {
                                reportMonths && reportMonths.map((item: any) => {
                                    return <option key={item.id} value={item.id}>{item.name}</option>
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton disabled={!isLatestMonth} onClick={onRollover}>Rollover</CvxButton>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton onClick={onExport}>Export</CvxButton>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton onClick={onRefresh}>Refresh</CvxButton>
                    </Col>
                </Row>
            </Container>
        </CvxContentBlock>
    );
};

export default OdsReg7HaulerDashboardFilter;
