import { useReducer, useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import { OdsBooleanColumn, OdsBooleanColumnHeader, OdsEditItemColumn, OdsIntegerColumn, OdsIntegerColumnHeader, OdsRemoveItemColumn, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsStateLookup } from "../../../components/state-lookup/state-lookup";
import { OdsConfirmDeleteDialog } from "../../../dialogs";
import { OdsEditRoleDialog } from "../../../dialogs/edit-role/edit-role.dialog";
import { BooleanFilter, IntegerFilter, ListResult, Role, RoleFilter, StringFilter } from "../../../models";
import { RolePermission } from "../../../models/role-permission";
import { State } from "../../../models/state";
import { PageChangedEvent, CvxPage, CvxLayoutCenterColumn, CvxContentBlock, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import { OdsUserApiService, ViewHelper } from "../../../services";

export interface OdsRolesViewProps {
    service: OdsUserApiService;
}

export const OdsRolesView: React.FC<OdsRolesViewProps> = ({
    service,
}) => {
    const [ignored, forceUpdate] = useReducer(x => x = -x, 1);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, setData] = useState<ListResult<Role>>({ totalCount: 0, items: [] });
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);

    const [selectedItems, setSelectedItems] = useState<Role[]>([]);
    const [selectedItem, setSelectedItem] = useState<Role>();

    const [sort, setSort] = useState<string>('Name');
    const [sortDirection, setSortDirection] = useState<string>('Asc');

    const [filter, setFilter] = useState<RoleFilter>(new RoleFilter());

    const [editItem, setEditItem] = useState<Role>();
    const [removeItem, setRemoveItem] = useState<Role>();


    const fetchData = async (top: number, skip: number, sort: string, sortDirection: string, filter: RoleFilter) => {
        setIsLoading(true);
        setSelectedItem(undefined);
        setSelectedItems([]);
        setData(await service.RetrieveRoleListAsync(top, skip, sort, sortDirection, filter));
        setIsLoading(false);
    }

    const onFetchState = async (id: number) => await service.RetrieveStateAsync(id);

    const onRefresh = () => {
        if (pageIndex == 0) {
            fetchData(pageSize, pageIndex * pageSize, sort, sortDirection, filter);
        } else {
            setPageIndex(0);
        }
    }

    const onPageChanged = (e: PageChangedEvent) => { setPageIndex(e.pageIndex); setPageSize(e.pageSize); }

    const onSortChanged = (sort: string, sortDirection: string) => { setSort(sort); setSortDirection(sortDirection); }

    const onRowSelect = (evt: React.MouseEvent<Element, MouseEvent>, item: Role) => {
        setSelectedItems(ViewHelper.rowSelect(evt, item, selectedItem, selectedItems, data.items));
        setSelectedItem(item);
        forceUpdate();
    }

    const onAddItem = () => setEditItem(new Role());

    const onEditItem = (item: Role) => setEditItem(item);

    const onEditItemCompleted = async (dialogResult: boolean, item?: Role) => {
        if (dialogResult && item) {
            if (item.id) {
                await service.UpdateRoleAsync(item);
            } else {
                await service.CreateRoleAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    }

    const onRemoveItem = (item: Role) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await service.DeleteRoleAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    }

    const onExport = async () => {
        const blob = await service.ExportRoleListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`role-${(new Date()).toLocaleDateString()}.csv`, blob)
    }

    const clearFilter = () => setFilter(new RoleFilter());

    const onFilterIdComplete = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); }

    const onFilterNameComplete = (newFilter?: StringFilter) => { filter.name = newFilter; onRefresh(); }

    const onFilterDescriptionComplete = (newFilter?: StringFilter) => { filter.description = newFilter; onRefresh(); }

    const onFilterIsActiveComplete = (newFilter?: BooleanFilter) => { filter.isActive = newFilter; onRefresh(); }

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveRoleSearchListAsync(columnName, top, skip, filter);

    useEffect(() => {
        fetchData(pageSize, pageIndex * pageSize, sort, sortDirection, filter);
    }, [service, pageIndex, pageSize, sort, sortDirection, filter]);

    return (
        <>
            <OdsEditRoleDialog service={service} show={!!editItem} role={editItem} onComplete={onEditItemCompleted} />
            <OdsConfirmDeleteDialog show={!!removeItem} text={removeItem?.name} onComplete={onRemoveItemCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onAddItem={onAddItem} onClearFilter={clearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="Role" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterIdComplete} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Name" header="Name" filter={filter.name} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterNameComplete} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Description" header="Description" filter={filter.description} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDescriptionComplete} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Permissions" header="Permissions" filter={filter.description} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDescriptionComplete} onSearch={onFilterValueSearch} />
                                    <OdsBooleanColumnHeader name="IsActive" header="Is&nbsp;Active" filter={filter.isActive} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterIsActiveComplete} />
                                    <th></th>
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    <OdsEditItemColumn item={item} onEditItem={onEditItem} />
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.id}</OdsIntegerColumn>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td>{item.rolePermissions.map(d => <Badge pill bg="light" text="dark">{d.permission.name} (<OdsStateLookup item={d} onFetchState={onFetchState} />)</Badge>)}</td>
                                    <OdsBooleanColumn value={item.isActive} />
                                    <OdsRemoveItemColumn item={item} onRemoveItem={onRemoveItem} />
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
