import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Route } from "../../models";

export interface OdsRouteChipProps {
    id?: number,
    onFetchRoute: (id: number) => Promise<Route>
}

export const OdsRouteChip: React.FC<OdsRouteChipProps> = ({
    id,
    onFetchRoute
}) => {
    const [data, setData] = useState<Route>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        if (id) {
            const data = await onFetchRoute(id)
            setData(data);    
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [id])

    return (
        <span className="ods-item-chip">{isLoading === false ? data?.name : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>}</span>
    )
}
