import React, { HTMLAttributes, useState } from "react";
import { BsTrash, BsTrashFill } from "react-icons/bs";
import { OdsConfirmBulkRemoveDialog } from "../../../dialogs/confirm-bulk-remove-item/confirm-bulk-remove-item.dialog";
import { Themes } from "../../../modules/cvx-ui-module";

export interface OdsRemoveItemColumnHeaderProps<T> {
    selectedItems: T[],
    itemLabel: (item: T) => React.ReactNode,
    onRemoveItem: (item: T) => Promise<void>,
    onRefresh: () => void;
};

interface Item {
};

export const OdsRemoveItemColumnHeader = <T extends Item>({
    selectedItems,
    itemLabel,
    onRemoveItem,
    onRefresh,
    ...props
}: OdsRemoveItemColumnHeaderProps<T> & HTMLAttributes<HTMLSpanElement>) => {

    const [show, setShow] = useState<boolean>(false);

    const onBulkRemove = () => {
        setShow(true);
    }

    const onComplete = async (dialogResult: boolean) => {
        if (dialogResult) {
            for (const item of selectedItems) {
                await onRemoveItem(item);
            };
        }
        onRefresh();
        setShow(false);
    }

    const bulkDeleteIcon = () => {
        if (selectedItems && selectedItems.length > 1) {
            return <BsTrashFill className={Themes.WarningDarkColor} onClick={onBulkRemove} />
        } else {
            return <BsTrash className="edit-inactive" />
        }
    }

    return (
        <>
            <OdsConfirmBulkRemoveDialog show={show} items={selectedItems} itemLabel={itemLabel} onComplete={onComplete} />
            <th>
                <span {...props} style={{ width: '25px' }}>{bulkDeleteIcon()}</span>
            </th>
        </>
    )
};
