import { FormAttribute } from "./form-attribute";

export class FormDefinition {
    public id: number = 0;
    public parentId: number = 0;
    public name: string = '';
    public description: string = '';
    public dispatchFormId?: number;
    public dispatchBody: string = '';
    public retrieveFormSubmissions: boolean = false;
    public submissionLastReceiveDateTimeUtc?: Date;
    public sortOrder: number = 999999;

    public children: FormDefinition[] = [];
    public attributes: FormAttribute[] = [];

    public isCollapsed: boolean = false;


    public static mapDefinition(data: any): FormDefinition {
        const type = new FormDefinition;

        type.id = data.id;
        type.parentId = data.parentId ?? 0;
        type.name = data.name;
        type.description = data.description;
        type.dispatchFormId = data.dispatchFormId;
        type.dispatchBody = data.dispatchBody;
        type.retrieveFormSubmissions = data.retrieveFormSubmissions;
        type.sortOrder = data.sortOrder;
        type.submissionLastReceiveDateTimeUtc = data.submissionLastReceiveDateTimeUtc ? new Date(data.submissionLastReceiveDateTimeUtc) : undefined;

        type.attributes = (data?.attributes ?? []).map((d: FormAttribute) => FormAttribute.mapDefinition(d))

        return type;
    };
}
