import React from "react";
import { BsFillPencilFill, BsTrash } from "react-icons/bs";
import { Themes } from "../../../modules/cvx-ui-module";

export interface OdsDeleteColumnProps {
    item: any,
    onDelete: (item: any) => void,
}

const OdsDeleteColumn: React.FC<OdsDeleteColumnProps> = ({
    item,
    onDelete,
    ...props
}) => {
    return (
        <BsTrash className={Themes.WarningDarkColor} onClick={() => onDelete(item)} />
    )
};

export default OdsDeleteColumn;
