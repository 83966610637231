import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { LdarInspectionRequestType } from "../../models/ldar-inspection-request-type";
import { OdsLdarApiService } from "../../services/ods-ldar-api-service";

interface Item {
    typeId: number;
    type?: LdarInspectionRequestType;
}

export interface OdsLdarTypeChipProps {
    service: OdsLdarApiService;
    inspectionRequest: Item;
};

export const OdsLdarTypeChip: React.FC<OdsLdarTypeChipProps> = ({
    service,
    inspectionRequest
}) => {
    const [data, setData] = useState<LdarInspectionRequestType>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        if (inspectionRequest && inspectionRequest.typeId) {
            const data = await service.RetrieveLdarInspectionRequestTypeAsync(inspectionRequest.typeId)
            inspectionRequest.type = data;
            setData(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [inspectionRequest])

    return (
        <span className="ods-item-chip">{isLoading === false ? data?.name : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>}</span>
    )
}
