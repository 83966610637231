import { FieldHookConfig, useField } from "formik";
import React from "react";
import { Form, Row } from "react-bootstrap";

export interface OdsSelectProps {
    label: string;
    items: any[];
}

const OdsSelect: React.FC<OdsSelectProps & FieldHookConfig<number>> = ({
    label,
    items,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);

    return (
        <Row>
            <Form.Group controlId={field.name}>
                <Form.Label>{label}</Form.Label>
                <Form.Select
                    name={field.name}
                    placeholder={label}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    isInvalid={!!meta.touched && !!meta.error}
                >
                    <option></option>
                    {
                        items && items.map((item: any, index: number) => {
                            return <option key={item.id} value={item.id}>{item.name}</option>
                        })
                    }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {meta.error}
                </Form.Control.Feedback>
            </Form.Group>
        </Row>
    )
};

export default OdsSelect;
