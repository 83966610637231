import React, { useEffect, useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { Role } from "../../models/role";
import { UserRole } from "../../models/user-role";
import { OdsUserApiService } from "../../services";

export interface OdsUserRoleChipProps {
    service?: OdsUserApiService,
    userRole: UserRole;
    onFetchRole?: (id: number) => Promise<Role>
}

export const OdsUserRoleChip: React.FC<OdsUserRoleChipProps> = ({
    service,
    userRole,
    onFetchRole
}) => {
    const [data, setData] = useState<Role>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        if (userRole?.roleId) {
            if (onFetchRole) {
                const data = await onFetchRole(userRole.roleId)
                userRole.role = data;
                setData(data);
            } else if (service) {
                const data = await service.RetrieveRoleAsync(userRole.roleId)
                userRole.role = data;
                setData(data);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [userRole])

    return (
        <span className="ods-item-chip">{isLoading === false ? <Badge pill bg={userRole?.isAssignable ? 'primary' : 'secondary'}>{data?.name}</Badge> : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>}</span>
    )
}
