import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { BsCloudHazeFill, BsDice4Fill, BsDice2Fill, BsDiscFill, BsFillHouseFill } from "react-icons/bs";
import { BooleanFilter, LdarInspectionRequestFilter } from "../../../models";
import { CvxButton } from "../../../modules/cvx-ui-module";

export interface OdsLdarFlagsFilterDialogProps {
    show: boolean;
    header: React.ReactNode;
    filter?: LdarInspectionRequestFilter;
    onComplete?: (dialogResult: boolean, filter?: LdarInspectionRequestFilter) => void;
}

export const OdsLdarFlagsFilterDialog: React.FC<OdsLdarFlagsFilterDialogProps> = ({
    show,
    header,
    filter,
    onComplete,
    ...props
}) => {
    const [consentDecreeValue, setConsentDecreeValue] = useState<boolean>(false);
    const [occupiedAreaValue, setOccupiedAreaValue] = useState<boolean>(false);
    const [dicValue, setDicValue] = useState<boolean>(false);
    const [ooooaValue, setOoooaValue] = useState<boolean>(false);
    const [oooobValue, setOooobValue] = useState<boolean>(false);

    const onCancel = () => {
        if (onComplete) {
            onComplete(false, undefined);
        }
    }

    const onClear = () => {

        setConsentDecreeValue(false);
        setOccupiedAreaValue(false);
        setDicValue(false);
        setOoooaValue(false);
        setOooobValue(false);

        if (onComplete) {
            onComplete(true, undefined);
        }
    }

    const onApply = () => {
        if (!consentDecreeValue && !occupiedAreaValue && !dicValue && !ooooaValue && !oooobValue) {
            onClear();
        } else {

            if(!filter) {
                filter = new LdarInspectionRequestFilter();
            }

            filter.isConsentDecree = new BooleanFilter();
            filter.isConsentDecree.equal = consentDecreeValue;

            filter.isOccupiedArea = new BooleanFilter();
            filter.isOccupiedArea.equal = occupiedAreaValue;

            filter.isDisproportionatelyImpactedCommunity = new BooleanFilter();
            filter.isDisproportionatelyImpactedCommunity.equal = dicValue;

            filter.isOOOOa = new BooleanFilter();
            filter.isOOOOa.equal = ooooaValue;

            filter.isOOOOb = new BooleanFilter();
            filter.isOOOOb.equal = oooobValue;

            if (onComplete) {
                onComplete(true, filter);
            }
        }
    }

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Filter '{header}'</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md="auto">
                            <Form.Check type="switch" checked={consentDecreeValue} onChange={() => setConsentDecreeValue(!consentDecreeValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="dicFilter">
                                <Form.Label><span className="cvx-primary-dark-color"><BsDiscFill /></span> Consent Decree</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Check type="switch" checked={occupiedAreaValue} onChange={() => setOccupiedAreaValue(!occupiedAreaValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="dicFilter">
                                <Form.Label><span className="cvx-primary-dark-color"><BsFillHouseFill /></span> Occupied Area</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Check type="switch" checked={dicValue} onChange={() => setDicValue(!dicValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="dicFilter">
                                <Form.Label><span className="cvx-primary-dark-color"><BsCloudHazeFill /></span> Disproportionately Impacted Community</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Check type="switch" checked={ooooaValue} onChange={() => setOoooaValue(!ooooaValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="ooooaFilter">
                                <Form.Label><span className="cvx-primary-dark-color"><BsDice4Fill /></span> OOOOa</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Check type="switch" checked={oooobValue} onChange={() => setOooobValue(!oooobValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="oooobFilter">
                                <Form.Label><span className="cvx-primary-dark-color"><BsDice2Fill /></span> OOOOb</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxButton theme='primary' onClick={onClear}>Clear</CvxButton>
                <CvxButton theme='primary' onClick={onApply}>Apply</CvxButton>
            </Modal.Footer>
        </Modal>
    );
};
