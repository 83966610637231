import React, { useEffect, useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { Personnel } from "../../models";

export interface OdsPersonnelChipProps {
    id: number,
    item: any,
    onFetchPersonnel: (item: any, id: number) => Promise<Personnel>;
}

export const OdsPersonnelChip: React.FC<OdsPersonnelChipProps> = ({
    id,
    item,
    onFetchPersonnel,
    ...props
}) => {
    const [data, setData] = useState<Personnel>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        const data = await onFetchPersonnel(item, id);
        setData(data);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [id])

    return (
        <span className="ods-item-chip">{isLoading === false
            ? <Badge pill bg="secondary">{!data ? id : `${data.firstName} ${data.lastName}`}</Badge>
            : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>}</span>
    )
}
