export interface CvxLayoutCenterColumnProps {
}

export const CvxLayoutCenterColumn: React.FC<CvxLayoutCenterColumnProps> = ({
    ...props
}) => {
    return (
        <div className="cvx-layout-center-column">
            <div className="cvx-center-column">
                {props.children}
            </div>
        </div>
    );
};

export default CvxLayoutCenterColumn;
