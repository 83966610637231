export class EntityView {
    public entityId?: number;
    public entityName?: string;
    public entityTypeId?: number;
    public entityTypeName?: string;
    public externalId?: string;
    public attributeId?: number;
    public attributeName?: string;
    public attributeValueId?: number;
    public attributeValue?: string;
    public sequence?: string;

    public updated: boolean = false;

    public static mapEntity(data: any): EntityView {
        const f = new EntityView();

        f.entityId = data.entityId;
        f.entityName = data.entityName;
        f.entityTypeId = data.entityTypeId;
        f.entityTypeName = data.entityTypeName;
        f.externalId = data.externalId;
        f.attributeId = data.attributeId;
        f.attributeName = data.attributeName;
        f.attributeValueId = data.attributeValueId;
        f.attributeValue = data.attributeValue;
        f.sequence = data.sequence;

        return f;
    };
};
