import { useEffect, useState } from "react";
import { OdsApiServiceBase } from "../services/ods-api-service-base";

export const useMyself = (service: OdsApiServiceBase, editPermission: string) => {
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [showState, setShowState] = useState<boolean>(false);
    const [states, setStates] = useState<number[]>([]);

    const fetchMyself = async () => {
        let myself = await service.RetrieveMyselfAsync();
        let edit = myself.permissions.filter(d => d.permission === editPermission);

        setCanEdit(edit.length > 0);
        setShowState(edit.length > 1);
        setStates(edit.map(d => d.stateId));
    };

    useEffect(() => {
        fetchMyself();
    }, [service]);

    return [canEdit, showState, states] as const;
};
