import { Field, FieldHookConfig, useField } from "formik";
import React, { useReducer } from "react";
import { Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

export interface OdsDateProps {
    label: string;
}

const OdsDate: React.FC<OdsDateProps & FieldHookConfig<Date | undefined | null>> = ({
    label,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);

    const [ignored, forceUpdate] = useReducer(x => x = -x, 1);

    return (
        <Row>
            <Form.Group controlId={field.name}>
                <Form.Label>{label}</Form.Label>
                <DatePicker 
                    className="form-control"
                    selected={field.value || undefined}
                    onChange={(val?: Date | null) => {
                        helpers.setTouched(true);
                        helpers.setValue(val, true);
                    }}
                    closeOnScroll={true}
                    popperProps={{
                        positionFixed: true
                    }}
                />
                <Form.Control.Feedback type="invalid">
                    {meta.error}
                </Form.Control.Feedback>
            </Form.Group>
        </Row>
    )
};

export default OdsDate;
