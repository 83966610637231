import { useState } from "react";
import { OdsTableBanner, OdsTable, OdsIntegerColumnHeader, OdsStringColumnHeader, OdsBooleanColumnHeader, OdsSelectableTableRow, OdsEditItemColumn, OdsSelectItemColumn, OdsIntegerColumn, OdsBooleanColumn, OdsRemoveItemColumn } from "../../../components";
import { OdsEditPersonnelGroupTypeDialog, OdsConfirmDeleteDialog } from "../../../dialogs";
import { usePagedListResult } from "../../../hooks/list-result-hook";
import { useMyself } from "../../../hooks/myself-hook";
import { PersonnelGroupType, PersonnelGroupTypeFilter, IntegerFilter, StringFilter, BooleanFilter } from "../../../models";
import { Permission } from "../../../models/permission";
import { CvxPage, CvxLayoutCenterColumn, CvxContentBlock, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import { OdsPersonnelApiService, ViewHelper } from "../../../services";

export interface OdsPersonnelGroupTypesViewProps {
    service: OdsPersonnelApiService;
}

export const OdsPersonnelGroupTypesView: React.FC<OdsPersonnelGroupTypesViewProps> = ({
    service,
}) => {

    const [canEdit] = useMyself(service, Permission.EditPersonnelGroupType);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: PersonnelGroupTypeFilter) => {
        return service.RetrievePersonnelGroupTypeListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('Name', 'Asc', new PersonnelGroupTypeFilter(), onFetchData);

    const [editItem, setEditItem] = useState<PersonnelGroupType>();
    const [removeItem, setRemoveItem] = useState<PersonnelGroupType>();


    const onAddItem = () => setEditItem(new PersonnelGroupType());

    const onEditItem = (item: PersonnelGroupType) => setEditItem(item);

    const onEditItemCompleted = async (dialogResult: boolean, item?: PersonnelGroupType) => {
        if (dialogResult && item) {
            if (item.id) {
                await service.UpdatePersonnelGroupTypeAsync(item);
            } else {
                await service.CreatePersonnelGroupTypeAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    }

    const onRemoveItem = (item: PersonnelGroupType) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await service.DeletePersonnelGroupTypeAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    }

    const onExport = async () => {
        const blob = await service.ExportPersonnelGroupTypeListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`personnel-group-types-${(new Date()).toLocaleDateString()}.csv`, blob)
    }

    const onFilterId = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); }
    const onFilterName = (newFilter?: StringFilter) => { filter.name = newFilter; onRefresh(); }
    const onFilterDescription = (newFilter?: StringFilter) => { filter.description = newFilter; onRefresh(); }
    const onFilterIsActive = (newFilter?: BooleanFilter) => { filter.isActive = newFilter; onRefresh(); }

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrievePersonnelGroupTypeSearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsEditPersonnelGroupTypeDialog service={service} show={!!editItem} groupType={editItem} onComplete={onEditItemCompleted} />
            <OdsConfirmDeleteDialog show={!!removeItem} text={removeItem?.name} onComplete={onRemoveItemCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onAddItem={canEdit ? onAddItem : undefined} onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="Personnel Group Types" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    {canEdit ? <th></th> : ''}
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Name" header="Name" filter={filter.name} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterName} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Description" header="Description" filter={filter.description} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDescription} onSearch={onFilterValueSearch} />
                                    <OdsBooleanColumnHeader name="IsActive" header="Is&nbsp;Active" filter={filter.isActive}
                                        onFilterChanged={onFilterIsActive} />
                                    {canEdit ? <th></th> : ''}
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    {canEdit ? <OdsEditItemColumn item={item} onEditItem={onEditItem} /> : ''}
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.id}</OdsIntegerColumn>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <OdsBooleanColumn value={item.isActive} />
                                    {canEdit ? <OdsRemoveItemColumn item={item} onRemoveItem={onRemoveItem} /> : ''}
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
