import { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { OdsAsyncMultiSelectControl, OdsTable } from "../../components";
import OdsBaseTable from "../../components/base-table/base-table";
import { BooleanFilter, PersonnelFilter, PersonnelGroupFilter, PersonnelGroupTypeFilter, StringFilter } from "../../models";
import { ApiError } from "../../models/api-error";
import { Personnel } from "../../models/personnel";
import { PersonnelGroup } from "../../models/personnel-group";
import { PersonnelGroupType } from "../../models/personnel-group-type";
import { Route } from "../../models/route";
import { RouteFilter } from "../../models/route-filter";
import { ColumnDefinition, CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";
import { OdsPersonnelApiService } from "../../services";

export interface OdsAutoDispatchDialogProps {
    service: OdsPersonnelApiService;
    show: boolean;
    inspections: any[];
    columns?: ColumnDefinition<any>[];
    onComplete: (dialogResult: boolean) => void;
    onAutoDispatchItem: (item: any, users: string[]) => Promise<any>;
}

const OdsAutoDispatchDialog: React.FC<OdsAutoDispatchDialogProps> = ({
    service,
    show,
    inspections,
    columns,
    onComplete,
    onAutoDispatchItem,
    ...props
}) => {
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [populating, setPopulating] = useState<boolean>(false);

    const [selectedGroupTypes, setSelectedGroupTypes] = useState<PersonnelGroupType[]>([]);
    const [selectedPersonnel, setSelectedPersonnel] = useState<Personnel[]>([]);
    const [selectedPersonnelGroups, setSelectedPersonnelGroups] = useState<PersonnelGroup[]>([]);

    const [personnel, setPersonnel] = useState<Map<number, Promise<Personnel>>>(new Map());
    const [personnelGroups, setPersonnelGroups] = useState<Map<number, Promise<PersonnelGroup>>>(new Map());
    const [routes, setRoutes] = useState<Map<string, Promise<Route | undefined>>>(new Map());

    const [totalDispatches, setTotalDispatches] = useState<number>(0);

    const onSubmit = async (): Promise<any> => {
        setIsSaving(true);

        const inspec: any[] = [];

        let hasDispatchErrors: boolean = false;

        inspections.forEach((i: any) => inspec.push(i));

        for (let i = 0; i < inspec.length; i++) {
            try {
                if (inspec[i].dispatchUserList.length > 0) {
                    await onAutoDispatchItem(inspec[i], inspec[i].dispatchUserList);
                    const index = inspections.indexOf(inspec);
                    if (index > -1) {
                        inspections.splice(index, 1);
                    }
                }
            } catch (error: any) {
                hasDispatchErrors = true;

                if (error instanceof ApiError) {
                    inspec[i].error = error.error.detail;
                } else if (error instanceof Error) {
                    inspec[i].error = error.message;
                } else {
                    inspec[i].error = error.toString();
                }
            }
        }

        setIsSaving(false);

        if (hasDispatchErrors == false && onComplete) {
            onComplete(true);
        }
    };

    const onCancel = () => {
        if (onComplete) {
            onComplete(false);
        }
    };

    const onSearchPersonnelGroupTypes = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelGroupTypeFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        //here is where you add the active value to the filter
        //only want to search on active
        const boolFilter = new BooleanFilter();
        filter.isActive = boolFilter;
        filter.isActive.equal = true;

        return await service.RetrievePersonnelGroupTypeListAsync(top, skip, 'Name', 'Asc', filter);
    };

    const onSearchPersonnelGroups = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelGroupFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        //here is where you add the active value to the filter
        //only want to search on active
        const boolFilter = new BooleanFilter();
        filter.isActive = boolFilter;
        filter.isActive.equal = true;

        return await service.RetrievePersonnelGroupListAsync(top, skip, 'Name', 'Asc', filter);
    };

    const onSearchPersonnel = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelFilter();

        filter.fullName = stringFilter;
        filter.fullName.like = filterStr;

        //here is where you add the active value to the filter
        //only want to search on active
        const boolFilter = new BooleanFilter();
        filter.isActive = boolFilter;
        filter.isActive.equal = true;

        return await service.RetrievePersonnelListAsync(top, skip, 'LastName', 'Asc', filter);
    };

    const fetchPersonnel = (id: number) => {
        let data = personnel.get(id);
        if (!data) {
            data = service.RetrievePersonnelAsync(id);
            personnel.set(id, data);
        }
        return data;
    }

    const fetchPersonnelGroup = (id: number) => {
        let data = personnelGroups.get(id);
        if (!data) {
            data = service.RetrievePersonnelGroupAsync(id);
            personnelGroups.set(id, data);
        }
        return data;
    }

    const fetchRoute = (routeName: string) => {
        let data = routes.get(routeName);
        if (!data) {
            const filter = new RouteFilter();
            filter.name = new StringFilter();
            filter.name.equal = [routeName];

            data = (async () => (await service.RetrieveRouteListAsync(1, 0, 'Name', 'Asc', filter)).items[0])();
            routes.set(routeName, data);
        }
        return data;
    }

    const onAssignDispatches = async () => {
        setPopulating(true);

        if (inspections) {
            inspections.forEach(i => i.dispatchUserList = []);
            inspections.forEach(i => i.dispatchUsers = '');

            for (let selectedGroupType of selectedGroupTypes) {
                for (let inspection of inspections) {
                    const route = await fetchRoute(inspection.route);

                    if (route) {
                        for (let personnelGroupId of route.personnelGroupIds) {
                            const personnelGroup = await fetchPersonnelGroup(personnelGroupId);

                            if (personnelGroup.personnelGroupTypeId === selectedGroupType.id) {
                                for (let id of personnelGroup.personnelIds) {
                                    const personnel = await fetchPersonnel(id);
                                    if (personnel.email) {
                                        inspection.dispatchUserList.push(personnel.email);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            for (let g of selectedPersonnelGroups) {
                for (let id of g.personnelIds) {
                    const personnel = await fetchPersonnel(id);
                    inspections.forEach(i => {
                        if (personnel.email) {
                            i.dispatchUserList.push(personnel.email);
                        }
                    });
                }
            }

            for (let personnel of selectedPersonnel) {
                inspections.forEach(i => {
                    if (personnel.email) {
                        i.dispatchUserList.push(personnel.email);
                    }
                });
            }

            let totalDispatches = 0;

            inspections.forEach(i => {
                i.dispatchUserList = i.dispatchUserList.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
                totalDispatches += i.dispatchUserList.length;
                i.dispatchUsers = i.dispatchUserList.join("; ")
            });

            setTotalDispatches(totalDispatches);
        }
        setPopulating(false);
    }

    useEffect(() => {
        onAssignDispatches();
    }, [selectedGroupTypes, selectedPersonnel, selectedPersonnelGroups]);

    useEffect(() => {
        setTotalDispatches(0);
        setSelectedGroupTypes([]);
        setSelectedPersonnel([]);
        setSelectedPersonnelGroups([]);
    }, [show]);

    return (
        <Modal className="theme-blue" size="xl" show={show} centered backdrop="static" onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Dispatch Inspections</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            
                            <Form.Label>Personnel Group Type</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelGroupType"
                                labelKey="name"
                                placeholder="Personnel Group Type"
                                multiple={true}
                                onSearch={onSearchPersonnelGroupTypes}
                                onChange={(item) => setSelectedGroupTypes(item)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Personnel</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnel"
                                labelKey="fullName"
                                placeholder="Personnel"
                                multiple={true}
                                onSearch={onSearchPersonnel}
                                onChange={(item) => setSelectedPersonnel(item)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Personnel Group</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelGroups"
                                labelKey="name"
                                placeholder="Personnel Groups"
                                multiple={true}
                                onSearch={onSearchPersonnelGroups}
                                onChange={(item) => setSelectedPersonnelGroups(item)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginTop: '20px' }}>
                            <OdsBaseTable heading="Inspections" columns={columns ?? []} dataSource={inspections} isLoading={false} overridePageSize={10} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Cancel</CvxButton>
                <CvxSpinnerButton theme='primary' disabled={populating} showSpinner={isSaving} onClick={onSubmit}>Dispatch</CvxSpinnerButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsAutoDispatchDialog;
