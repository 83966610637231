import { useEffect, useState } from "react";
import { LayoutManager, LayoutMode } from "../../services/layout-manager";

export interface CvxPageProps {
    layoutManager?: LayoutManager;
    desktop?: JSX.Element | string | undefined;
    tablet?: JSX.Element | string | undefined;
    mobile?: JSX.Element | string | undefined;
}

export const CvxPage: React.FC<CvxPageProps> = ({
    layoutManager,
    desktop,
    tablet,
    mobile,
    ...props
}) => {
    const [layoutMode, setLayoutMode] = useState<LayoutMode>(LayoutMode.desktop);

    useEffect(() => {
        //setLayoutMode(layoutManager.LayoutMode);
    }, []);

    return (
        <div className="cvx-page">
            {layoutMode === LayoutMode.desktop ? 
                (desktop ? desktop :
                (tablet ? tablet : mobile))
            : (layoutMode === LayoutMode.tablet ?
                (tablet ? tablet :
                (desktop ? desktop : mobile))
            :
                (mobile ? mobile :
                (tablet ? tablet : desktop))
            )}
        </div>
    );
};

export default CvxPage;
