import React, { useState } from "react";
import { Col, FormControl, FormLabel, Modal, Row } from "react-bootstrap";
import { CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";

export interface OdsRemoveDispatchDialogProps {
    show: boolean;
    dispatchId: number | undefined;
    onComplete: (dialogResult: boolean, comments: string) => Promise<any>;
}

const OdsRemoveDispatchDialog: React.FC<OdsRemoveDispatchDialogProps> = ({
    show,
    dispatchId,
    onComplete,
    ...props
}) => {
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [comments, setComments] = useState<string>('');

    const onCancel = async (): Promise<any> => {
        if (onComplete) {
            await onComplete(false, comments);
        }

        setIsSaving(false);
    };

    const onRemove = async (): Promise<any> => {
        if (onComplete) {
            setIsSaving(true);
            await onComplete(true, comments);
            setIsSaving(false);
        }
    }

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Remove dispatch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <span>Remove dispatch '{dispatchId}'?</span>
                </Row>
                <Row>
                    <Col>
                        <FormLabel>Comments</FormLabel>
                        <FormControl as="textarea" onChange={(e) => setComments(e.target.value)} />
                    </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxSpinnerButton theme='primary' showSpinner={isSaving} onClick={() => onRemove()}>Remove</CvxSpinnerButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsRemoveDispatchDialog;
