import { Role } from "./role";

export class UserRole {
    public roleId: number = 0;
    public isAssignable: boolean = false;

    public role?: Role;

    public static map(data: any): UserRole {
        const m = new UserRole;

        m.roleId = parseInt(data.roleId);
        m.isAssignable = data.isAssignable;

        return m;
    };
}
