import React, { HtmlHTMLAttributes, MouseEventHandler } from "react";
import { Button, Spinner } from "react-bootstrap";

export interface CvxSpinnerButtonProps {
    theme?: 'primary' | 'accent' | 'warning';
    disabled?: boolean;
    showSpinner?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const CvxSpinnerButton: React.FC<CvxSpinnerButtonProps & HtmlHTMLAttributes<HTMLButtonElement>> = ({
    theme,
    disabled,
    showSpinner,
    onClick,
    ...props
}) => {
    return (
        <Button variant={theme ?? 'primary'} disabled={disabled || showSpinner} onClick={onClick} {...props}>
            {showSpinner ? <Spinner animation="border" /> : props.children}
        </Button>
    );
};

export default CvxSpinnerButton;
