export class Permission {
    public id: string = '';
    public name: string = '';

    public static map(data: any): Permission {
        const role = new Permission();

        role.id = data.id;
        role.name = data.name ?? '';

        return role;
    };


    public static get ViewPermissions(): string { return 'ViewPermissions' };
    public static get EditPermissions(): string { return 'EditPermissions' };

    public static get ViewLDARInspectionRequests(): string { return 'ViewLDARInspectionRequests' };
    public static get EditLDARInspectionRequests(): string { return 'EditLDARInspectionRequests' };

    public static get ViewBradenheadInspectionRequests(): string { return 'ViewBradenheadInspectionRequests' };
    public static get EditBradenheadInspectionRequests(): string { return 'EditBradenheadInspectionRequests' };

    public static get ViewORDInspectionRequests(): string { return 'ViewORDInspectionRequests' };
    public static get EditORDInspectionRequests(): string { return 'EditORDInspectionRequests' };

    public static get ViewAvoVocInspectionRequests(): string { return 'ViewAvoVocInspectionRequests' };
    public static get EditAvoVocInspectionRequests(): string { return 'EditAvoVocInspectionRequests' };

    public static get ViewPersonnel(): string { return 'ViewPersonnel' };
    public static get EditPersonnel(): string { return 'EditPersonnel' };

    public static get ViewPersonnelGroupType(): string { return 'ViewPersonnel' };
    public static get EditPersonnelGroupType(): string { return 'EditPersonnelGroupType' };

    public static get Administrator(): string { return 'Administrator' };

    public static get EditFormSubmissions(): string {return 'EditFormSubmissions' };

    public static get ViewFormDataSources(): string {return 'ViewFormDataSources' };
    public static get EditFormDataSources(): string {return 'EditFormDataSources' };
};
