import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import OdsListFilterDialog from "../../dialogs/list-filter-dialog/list-fliter-dialog";
import { NamedFilter } from "../../models/named-filter";
import { ReportMonth } from "../../models/report-month";
import { CvxButton } from "../../modules/cvx-ui-module";
import CvxContentBlock from "../../modules/cvx-ui-module/components/content-block/content-block";

export interface OdsBradenheadDashboardFilterProps {
    reportMonths: ReportMonth[];
    selectedReportMonthId: number;
    isLatestMonth: boolean;
    isCurrentMonth: boolean;
    onSelectMonth: (reportingMonthId: number) => void;
    onDispatch: () => void;
    onAutoDispatchAll: () => void;
    onAutoDispatchLO: () => void;
    onAutoDispatchProblemWell: () => void;
    onAutoDispatchARO: () => void;
    onFilterChanged?: (filter: NamedFilter | undefined) => void;
    onRefresh?: () => void;
    onRollover?: () => void;
    onExport?: () => void;
}

export abstract class BradenheadDashboardPreDefinedFilterValues {
    public static Empty: string = 'Empty'
    public static List: string = 'List';
    public static InComplete: string = 'InComplete';
    public static InCompleteDispatched: string = 'InCompleteDispatched';
    public static InCompleteUndispatched: string = 'InCompleteUndispatched'
}

const OdsBradenheadDashboardFilter: React.FC<OdsBradenheadDashboardFilterProps> = ({
    reportMonths,
    selectedReportMonthId,
    isLatestMonth,
    isCurrentMonth,
    onSelectMonth,
    onDispatch,
    onAutoDispatchAll,
    onAutoDispatchLO,
    onAutoDispatchProblemWell,
    onAutoDispatchARO,
    onFilterChanged,
    onRefresh,
    onRollover,
    onExport,
    ...props
}) => {

    const [showListFilterDialog, setShowListFilterDialog] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<string>('');
    const [selectedListFilter, setSelectedListFilter] = useState<NamedFilter | undefined>(undefined);

    const onClearFilter = () => {
        setSelectedFilter('');

        if (onFilterChanged) {
            onFilterChanged(undefined);
        }
    }

    const onShowListFilter = () => {
        setShowListFilterDialog(true);
    }

    const onSelectPredefinedFilter = (value: string) => {
        setSelectedFilter(value);

        if(onFilterChanged) {
            let namedFilter: NamedFilter | undefined = undefined;
            if(value === BradenheadDashboardPreDefinedFilterValues.List && selectedListFilter) {
                namedFilter = selectedListFilter;
            } else if (value === BradenheadDashboardPreDefinedFilterValues.InComplete || 
                        value === BradenheadDashboardPreDefinedFilterValues.InCompleteDispatched ||
                        value === BradenheadDashboardPreDefinedFilterValues.InCompleteUndispatched) {
                namedFilter = { filterName: value, filterValues: ['incomplete']};
            }
            onFilterChanged(namedFilter);
        }
    }
    const onChangeMonth = (value: number) => {
        onSelectMonth(value);
        onClearFilter();
    }

    const onListFilterComplete = (dialogResult: boolean, filterValues: any[]) => {
        setSelectedFilter('');

        if (onFilterChanged) {

            if (filterValues?.length > 0) {
                setSelectedFilter(BradenheadDashboardPreDefinedFilterValues.List);
            }
            const listFilter: NamedFilter = { filterName: BradenheadDashboardPreDefinedFilterValues.List, filterValues: filterValues }
            onFilterChanged(listFilter);
            setSelectedListFilter(listFilter);
        }

        setShowListFilterDialog(false);
    }

    return (
        <CvxContentBlock>
            <OdsListFilterDialog show={showListFilterDialog}
                columnHeader='API No'
                onComplete={onListFilterComplete} />
            <Container className="ods-bradenhead-dashboard-filter">
                <Row>
                    <Col>
                        <Form.Label>Month</Form.Label>
                        <Form.Select value={selectedReportMonthId} onChange={(e) => onChangeMonth(parseInt(e.target.value))}>
                            {
                                reportMonths && reportMonths.map((item: any) => {
                                    return <option key={item.id} value={item.id}>{item.name}</option>
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton disabled={!isLatestMonth} onClick={onRollover}>Rollover</CvxButton>
                    </Col>
                    <Col>
                        <Form.Label>Filter</Form.Label>
                        <Form.Select onChange={(e) => onSelectPredefinedFilter(e.target.value)} value={selectedFilter}>
                            <option value={BradenheadDashboardPreDefinedFilterValues.Empty}></option>
                            <option value={BradenheadDashboardPreDefinedFilterValues.List}>List</option>
                            <option value={BradenheadDashboardPreDefinedFilterValues.InComplete}>Incomplete</option>
                            <option value={BradenheadDashboardPreDefinedFilterValues.InCompleteDispatched}>Incomplete - Dispatched</option>
                            <option value={BradenheadDashboardPreDefinedFilterValues.InCompleteUndispatched}>Incomplete - Undispatched</option>
                        </Form.Select>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton onClick={() => onShowListFilter()}>List</CvxButton>
                        <CvxButton onClick={() => onClearFilter()}>Clear</CvxButton>
                    </Col>
                    <Col>
                        <Form.Label>Form to Dispatch</Form.Label>
                        <Form.Select>
                            <option>Form 3</option>
                        </Form.Select>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton disabled={!isCurrentMonth} onClick={() => onDispatch()}>Dispatch</CvxButton>  
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton disabled={!isCurrentMonth} onClick={() => onAutoDispatchAll()}>Dispatch All</CvxButton>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton disabled={!isCurrentMonth} onClick={() => onAutoDispatchLO()}>Dispatch LO</CvxButton>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton disabled={!isCurrentMonth} onClick={() => onAutoDispatchARO()}>Dispatch ARO</CvxButton>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton disabled={!isCurrentMonth} onClick={() => onAutoDispatchProblemWell()}>Dispatch Problem..</CvxButton>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton onClick={onExport}>Export</CvxButton>
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton onClick={onRefresh}>Refresh</CvxButton>
                    </Col>
                </Row>
            </Container>
        </CvxContentBlock>
    );
};

export default OdsBradenheadDashboardFilter;
