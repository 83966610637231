import { FormDataSource, FormDataSourceFilter, ListResult } from "../models";
import { ApiService } from "./api-service";
import { OdsApiServiceBase } from "./ods-api-service-base";

export class OdsFormApiService extends OdsApiServiceBase {
    public constructor(apiService: ApiService | undefined) {
        super(apiService);
    }

    //*************************************************************************************************************************************
    //*****************************************************   FORM DATA SOURCES  **********************************************************
    //*************************************************************************************************************************************

    public async RetrieveFormDataSourceListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: FormDataSourceFilter): Promise<ListResult<FormDataSource>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<FormDataSource>>('/api/v1.0/form-data-sources/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => FormDataSource.map(d)) };
    }

    public async RetrieveFormDataSourceAsync(formSpaceId: number, dataSourceId: number): Promise<FormDataSource> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<FormDataSource>(`/api/v1.0/form-data-sources/${formSpaceId}/${dataSourceId}`);

        return FormDataSource.map(data);
    }

    public async CreateFormDataSourceAsync(formDataSource: FormDataSource): Promise<FormDataSource> {
        if (!this.apiService) {
            throw new Error();
        }

        // const body = {
        //     formSpaceId: parseInt(formDataSource.formSpaceId),
        //     dataSourceId: parseInt(formDataSource.dataSourceId)
        // };

        const data = await this.apiService.post<FormDataSource>(`/api/v1.0/form-data-sources`, JSON.stringify(formDataSource));

        return FormDataSource.map(data);
    }

    public async UpdateFormDataSourceAsync(formDataSource: FormDataSource): Promise<FormDataSource> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.put<FormDataSource>(`/api/v1.0/form-data-sources/${formDataSource.formSpaceId}/${formDataSource.dataSourceId}`, JSON.stringify(formDataSource));

        return FormDataSource.map(data);
    }

    public async DeleteFormDataSourceAsync(formDataSource: FormDataSource): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/form-data-sources/${formDataSource.formSpaceId}/${formDataSource.dataSourceId}`);
    }

    public async ValidateFormDataSourceNameAsync(name: string, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/form-data-sources/validate?name=${name}&id=${id}`);

        return data;
    }

    public async RetrieveFormDataSourceSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/form-data-sources/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportFormDataSourceListAsync<T>(sort: string, sortDirection: string, filter: FormDataSourceFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/form-data-sources/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }
};
