import { useEffect, useReducer, useState } from "react";
import { Popover } from "react-bootstrap";
import { OdsDateColumn, OdsDateColumnHeader, OdsEditItemColumn, OdsIntegerColumn, OdsIntegerColumnHeader, OdsItemColumnHeader, OdsPersonnelChip, OdsRouteChip, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsLdarFlagIcon } from "../../../components/ldar-flag-icon/ldar-flag-icon";
import { OdsLdarLodColumn } from "../../../components/ldar-lod-column/ldar-lod-column";
import { OdsLdarStatusChip } from "../../../components/ldar-status-chip/ldar-status-chip";
import { OdsLdarTypeChip } from "../../../components/ldar-type-chip/ldar-type-chip";
import { OdsLodChip } from "../../../components/lod-chip/lod-chip";
import { OdsStateLookup } from "../../../components/state-lookup/state-lookup";
import { OdsLdarFlagsColumnHeader } from "../../../components/table-column-headers/ldar-flags-column-header/ldar-flags-column-header";
import { OdsConfirmDeleteDialog } from "../../../dialogs";
import OdsDispatchDialog from "../../../dialogs/dispatch-dialog/dispatch-dialog";
import { OdsEditLdarInspectionRequestDialog } from "../../../dialogs/edit-ldar-inspection-request/edit-ldar-inspection-request.dialog";
import { OdsLdarDispatchDialog } from "../../../dialogs/ldar-dispatch-dialog/ldar-dispatch-dialog";
import { useMyself, usePagedListResult } from "../../../hooks";
import { DateFilter, IntegerFilter, ItemFilter, LdarInspectionRequest, LdarInspectionRequestFilter, ListResult, StringFilter } from "../../../models";
import { Permission } from "../../../models/permission";
import { CvxContentBlock, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock, PageChangedEvent } from "../../../modules/cvx-ui-module";
import { OdsLdarApiService, OdsPersonnelApiService, ViewHelper } from "../../../services";


export interface OdsLdarScheduledInspectionsViewProps {
    service: OdsLdarApiService;
    personnelService: OdsPersonnelApiService;
}

export const OdsLdarScheduledInspectionsView: React.FC<OdsLdarScheduledInspectionsViewProps> = ({
    service,
    personnelService
}) => {
    const [ignored, forceUpdate] = useReducer(x => x = -x, 1);

    const [canEdit, showState, states] = useMyself(service, Permission.EditLDARInspectionRequests);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: LdarInspectionRequestFilter) => {
        return service.RetrieveNewLdarInspectionRequestListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('Id', 'Desc', new LdarInspectionRequestFilter(), onFetchData);

    const [editItem, setEditItem] = useState<LdarInspectionRequest>();
    const [removeItem, setRemoveItem] = useState<LdarInspectionRequest>();

    const [showDispatchDialog, setShowDispatchDialog] = useState<boolean>(false);


    const onFetchLod = async (item: LdarInspectionRequest, id: string) => {
        const data = await service.RetrieveLodAsync(id);
        item.lod = data;
        forceUpdate();

        return data;
    }

    const onFetchState = (id: number) => service.RetrieveStateAsync(id);
    const onFetchRoute = (id: number) => personnelService.RetrieveRouteAsync(id);
    const onFetchPersonnel = (item: any, id: number) => personnelService.RetrievePersonnelAsync(id);


    const onEditItem = (item: LdarInspectionRequest) => setEditItem(item);

    const onEditItemCompleted = async (dialogResult: boolean, item?: LdarInspectionRequest) => {
        if (dialogResult && item) {
            if (item.id) {
                await service.UpdateLdarInspectionRequestAsync(item);
            } else {
                await service.CreateLdarInspectionRequestAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    }

    const onDispatch = () => setShowDispatchDialog(true);

    const onDispatchComplete = () => { setShowDispatchDialog(false); onRefresh() };

    const onDispatchItem = async (item: LdarInspectionRequest, users: string[]) => {
        await service.DispatchLdarInspectionRequestAsync(item, users);
    };

    const onExport = async () => {
        const blob = await service.ExportLdarInspectionRequestListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`ldar-scheduled-inspection-requests-${(new Date()).toLocaleDateString()}.csv`, blob)
    }

    const onFilterId = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); };
    const onFilterLod = (newFilter?: ItemFilter) => { filter.lod = newFilter; onRefresh(); };
    const onFilterType = (newFilter?: ItemFilter) => { filter.type = newFilter; onRefresh(); };
    const onFilterState = (newFilter?: ItemFilter) => { filter.state = newFilter; onRefresh(); };
    const onFilterRoute = (newFilter?: ItemFilter) => { filter.route = newFilter; onRefresh(); };
    const onFilterStatus = (newFilter?: ItemFilter) => { filter.status = newFilter; onRefresh(); };
    const onFilterStartDate = (newFilter?: DateFilter) => { filter.startDate = newFilter; onRefresh(); };
    const onFilterDueDate = (newFilter?: DateFilter) => { filter.dueDate = newFilter; onRefresh(); };
    const onFilterDispatchDate = (newFilter?: DateFilter) => { filter.dispatchDate = newFilter; onRefresh(); };
    const onFilterDispatchUser = (newFilter?: ItemFilter) => { filter.dispatchUser = newFilter; onRefresh(); };
    const onFilterWorkOrder = (newFilter?: StringFilter) => { filter.workOrderNumber = newFilter; onRefresh(); };
    const onFilterSourcePersonnel = (newFilter?: ItemFilter) => { filter.sourcePersonnel = newFilter; onRefresh(); };
    const onFilterComments = (newFilter?: StringFilter) => { filter.comments = newFilter; onRefresh(); };

    const onFilterFlags = (newFilter?: LdarInspectionRequestFilter) => {
        filter.isConsentDecree = newFilter?.isConsentDecree;
        filter.isOccupiedArea = newFilter?.isOccupiedArea;
        filter.isDisproportionatelyImpactedCommunity = newFilter?.isDisproportionatelyImpactedCommunity;
        filter.isOOOOa = newFilter?.isOOOOa;
        filter.isOOOOb = newFilter?.isOOOOb;
        onRefresh();
    };

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveLdarInspectionRequestSearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsEditLdarInspectionRequestDialog service={service} show={!!editItem} ldarInspectionRequest={editItem} onComplete={onEditItemCompleted} />
            <OdsLdarDispatchDialog service={personnelService} show={showDispatchDialog} onComplete={onDispatchComplete} inspectionRequests={selectedItems} onDispatchItem={onDispatchItem} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onClearFilter={onClearFilter} onRefresh={onRefresh} onDispatch={onDispatch} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="Scheduled LDAR Inspections" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged} pageSizeOptions={[5, 10, 25, 100, 500]}
                            tableHeader={
                                <tr>
                                    {canEdit ? <th></th> : ''}
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterId} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Type" header="Type" filter={filter.type} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterType} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Lod" header="LOD" filter={filter.lod} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterLod} onSearch={onFilterValueSearch} />
                                    {showState ? <OdsItemColumnHeader name="State" header="State" filter={filter.state} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterState} onSearch={onFilterValueSearch} /> : ''}
                                    <OdsItemColumnHeader name="Route" header="Route" filter={filter.route} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterRoute} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Status" header="Status" filter={filter.status} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterStatus} onSearch={onFilterValueSearch} />
                                    <OdsLdarFlagsColumnHeader name="Flags" header="Flags" filter={filter} onFilterChanged={onFilterFlags} />
                                    <OdsDateColumnHeader name="StartDate" header="Start Date" filter={filter.startDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterStartDate} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="DueDate" header="Due Date" filter={filter.dueDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDueDate} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="WorkOrderNumber" header="Work Order" filter={filter.workOrderNumber} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterWorkOrder} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="SourcePersonnel" header="Source User" filter={filter.sourcePersonnel} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterSourcePersonnel} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Comments" header="Comments" filter={filter.comments} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterComments} onSearch={onFilterValueSearch} />
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    {canEdit ? <OdsEditItemColumn item={item} onEditItem={onEditItem} /> : ''}
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.id}</OdsIntegerColumn>
                                    <td><OdsLdarTypeChip inspectionRequest={item} service={service} /></td>
                                    <OdsLdarLodColumn item={item} onFetchLod={(i) => onFetchLod(item, i)} />
                                    {showState ? <td><OdsStateLookup item={item} onFetchState={onFetchState} /></td> : ''}
                                    <OdsStringColumn>
                                        <OdsRouteChip id={item.lod?.routeId} onFetchRoute={onFetchRoute} />
                                    </OdsStringColumn>
                                    <td><OdsLdarStatusChip inspectionRequest={item} service={service} /></td>
                                    <td><OdsLdarFlagIcon item={item} /></td>
                                    <OdsDateColumn value={item.startDate} />
                                    <OdsDateColumn value={item.dueDate} />
                                    <OdsStringColumn>{item.workOrderNumber}</OdsStringColumn>
                                    <td>{item.sourcePersonnelId ? <OdsPersonnelChip id={item.sourcePersonnelId} item={item} onFetchPersonnel={onFetchPersonnel} /> : <></>}</td>
                                    <OdsStringColumn tooltip={item.comments}>{item.comments}</OdsStringColumn>
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
