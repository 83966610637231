import { FieldHookConfig } from "formik";
import React from "react";
import { IntegerFilter } from "../../../models";
import { StateFilter } from "../../../models/state-filter";
import { OdsApiServiceBase } from "../../../services/ods-api-service-base";
import { OdsAsyncSelect } from "../async-select/async-select";

export interface OdsStateAsyncSelectProps {
    label: string;
    states: number[];
    service: OdsApiServiceBase;
}

export const OdsStateAsyncSelect: React.FC<OdsStateAsyncSelectProps & FieldHookConfig<string>> = ({
    label,
    states,
    service}) => {

    const onSearchState = async (top: number, skip: number) => {
        const filter = new StateFilter();
        const idFilter = new IntegerFilter();

        filter.id = idFilter;
        filter.id.equal = states;

        let data = await service.RetrieveStateListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    return (
        <OdsAsyncSelect
            name="state"
            label="State"
            labelKey="name"
            onSearch={onSearchState}
        />
    )
};
