import React from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { Themes } from "../../../modules/cvx-ui-module";

export interface OdsEditColumnProps {
    item: any,
    onEdit: (item: any) => void,
}

const OdsEditColumn: React.FC<OdsEditColumnProps> = ({
    item,
    onEdit,
    ...props
}) => {
    return (
        <BsFillPencilFill className={Themes.PrimaryDarkColor} onClick={() => onEdit(item)} />
    )
};

export default OdsEditColumn;
