import { useReducer, useState } from "react";
import { OdsDateColumn, OdsDateColumnHeader, OdsIntegerColumn, OdsIntegerColumnHeader, OdsItemColumnHeader, OdsPersonnelChip, OdsRouteChip, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsLdarFlagIcon } from "../../../components/ldar-flag-icon/ldar-flag-icon";
import { OdsLdarLodColumn } from "../../../components/ldar-lod-column/ldar-lod-column";
import { OdsLdarStatusChip } from "../../../components/ldar-status-chip/ldar-status-chip";
import { OdsLdarTypeChip } from "../../../components/ldar-type-chip/ldar-type-chip";
import { OdsStateLookup } from "../../../components/state-lookup/state-lookup";
import { OdsLdarFlagsColumnHeader } from "../../../components/table-column-headers/ldar-flags-column-header/ldar-flags-column-header";
import { OdsMultiDeleteColumnHeader } from "../../../components/table-column-headers/multi-delete-column-header/multi-delete-column-header";
import { OdsConfirmMultiDeleteDialog } from "../../../dialogs/confirm-multi-delete/confirm-multi-delete.dialog";
import { OdsDateTimeColumn } from "../../../components/table-columns/date-time-column/date-time-column";
import OdsDeleteColumn from "../../../components/table-columns/delete-column/delete-column";
import { OdsConfirmDeleteDialog } from "../../../dialogs";
import { useMyself, usePagedListResult } from "../../../hooks";
import { DateFilter, IntegerFilter, ItemFilter, LdarInspectionRequestFilter, StringFilter } from "../../../models";
import { LdarInspectionRequestDispatch } from "../../../models/ldar-inspection-request-dispatch";
import { LdarInspectionRequestDispatchFilter } from "../../../models/ldar-inspection-request-dispatch-filter";
import { Permission } from "../../../models/permission";
import { CvxContentBlock, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import { OdsLdarApiService, OdsPersonnelApiService, ViewHelper } from "../../../services";


export interface OdsLdarInspectionRequestDispatchesViewProps {
    service: OdsLdarApiService;
    personnelService: OdsPersonnelApiService;
}

export const OdsLdarInspectionRequestDispatchesView: React.FC<OdsLdarInspectionRequestDispatchesViewProps> = ({
    service,
    personnelService
}) => {

    const [ignored, forceUpdate] = useReducer(x => x = -x, 1);
    
    const [canEdit, showState, states] = useMyself(service, Permission.EditLDARInspectionRequests);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: LdarInspectionRequestDispatchFilter) => {
        return service.RetrieveLdarInspectionRequestDispatchListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('DispatchDate', 'Desc', new LdarInspectionRequestDispatchFilter(), onFetchData);

    const [removeItem, setRemoveItem] = useState<LdarInspectionRequestDispatch>();
    const [removeItems, setRemoveItems] = useState<LdarInspectionRequestDispatch[]>();


    const onFetchLod = async (item: LdarInspectionRequestDispatch, id: string) => {
        const data = await service.RetrieveLodAsync(id);
        item.lod = data;
        forceUpdate();

        return data;
    };

    const onFetchState = (id: number) => service.RetrieveStateAsync(id);
    const onFetchRoute = (id: number) => personnelService.RetrieveRouteAsync(id);
    const onFetchPersonnel = async(item: LdarInspectionRequestDispatch, id: number) =>  {
        let data = await personnelService.RetrievePersonnelAsync(id);
        item.personnel = data;
        return data;
    };


    const onRemoveItem = (item: LdarInspectionRequestDispatch) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await service.DeleteLdarInspectionRequestDispatchAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    };

    const onMultiRemoveItems = () => setRemoveItems(selectedItems);

    const onRemoveItemsCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItems) {
            for (let removeItem of removeItems) {
                await service.DeleteLdarInspectionRequestDispatchAsync(removeItem);
            }
            onRefresh();
        }
        setRemoveItems(undefined);
    };


    const onExport = async () => {
        const blob = await service.ExportLdarInspectionRequestListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`ldar-inspection-dispatches-${(new Date()).toLocaleDateString()}.csv`, blob)
    };

    const onFilterId = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); };
    const onFilterLod = (newFilter?: ItemFilter) => { filter.lod = newFilter; onRefresh(); };
    const onFilterType = (newFilter?: ItemFilter) => { filter.type = newFilter; onRefresh(); };
    const onFilterState = (newFilter?: ItemFilter) => { filter.state = newFilter; onRefresh(); };
    const onFilterRoute = (newFilter?: ItemFilter) => { filter.route = newFilter; onRefresh(); };
    const onFilterStatus = (newFilter?: ItemFilter) => { filter.status = newFilter; onRefresh(); };
    const onFilterStartDate = (newFilter?: DateFilter) => { filter.startDate = newFilter; onRefresh(); };
    const onFilterDueDate = (newFilter?: DateFilter) => { filter.dueDate = newFilter; onRefresh(); };
    const onFilterDispatchDate = (newFilter?: DateFilter) => { filter.dispatchDate = newFilter; onRefresh(); };
    const onFilterDispatchNumber = (newFilter?: StringFilter) => { filter.dispatchNumber = newFilter; onRefresh(); };
    const onFilterPersonnel = (newFilter?: ItemFilter) => { filter.personnel = newFilter; onRefresh(); };

    const onFilterFlags = (newFilter?: LdarInspectionRequestFilter) => {
        filter.isConsentDecree = newFilter?.isConsentDecree;
        filter.isOccupiedArea = newFilter?.isOccupiedArea;
        filter.isDisproportionatelyImpactedCommunity = newFilter?.isDisproportionatelyImpactedCommunity;
        filter.isOOOOa = newFilter?.isOOOOa;
        onRefresh();
    };

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveLdarInspectionRequestDispatchSearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsConfirmDeleteDialog show={!!removeItem} text={`${removeItem?.inspectionRequestId} - ${removeItem?.personnel?.fullName}`} onComplete={onRemoveItemCompleted} />
            <OdsConfirmMultiDeleteDialog show={!!removeItems} onComplete={onRemoveItemsCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="LDAR Inspection Dispatches" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged} pageSizeOptions={[5, 10, 25, 100, 500]}
                            tableHeader={
                                <tr>
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterId} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Type" header="Type" filter={filter.type} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterType} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Lod" header="LOD" filter={filter.lod} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterLod} onSearch={onFilterValueSearch} />
                                    {showState ? <OdsItemColumnHeader name="State" header="State" filter={filter.state} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterState} onSearch={onFilterValueSearch} /> : ''}
                                    <OdsItemColumnHeader name="Route" header="Route" filter={filter.route} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterRoute} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Status" header="Status" filter={filter.status} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterStatus} onSearch={onFilterValueSearch} />
                                    <OdsLdarFlagsColumnHeader name="Flags" header="Flags" filter={filter} onFilterChanged={onFilterFlags} />
                                    <OdsDateColumnHeader name="StartDate" header="Start Date" filter={filter.startDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterStartDate} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="DueDate" header="Due Date" filter={filter.dueDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDueDate} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="DispatchDate" header="Dispatch Date" filter={filter.dispatchDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDispatchDate} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="DispatchNumber" header="Dispatch Number" filter={filter.dispatchNumber} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDispatchNumber} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Personnel" header="Dispatch User" filter={filter.personnel} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterPersonnel} onSearch={onFilterValueSearch} />
                                    {canEdit ? <OdsMultiDeleteColumnHeader selectedItems={selectedItems} onClick={onMultiRemoveItems} /> : ''}
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.inspectionRequestId}</OdsIntegerColumn>
                                    <td><OdsLdarTypeChip inspectionRequest={item} service={service} /></td>
                                    <OdsLdarLodColumn item={item} onFetchLod={(i) => onFetchLod(item, i)} />
                                    {showState ? <td><OdsStateLookup item={item} onFetchState={onFetchState} /></td> : ''}
                                    <OdsStringColumn>
                                        <OdsRouteChip id={item.lod?.routeId} onFetchRoute={onFetchRoute} />
                                    </OdsStringColumn>
                                    <td><OdsLdarStatusChip inspectionRequest={item} service={service} /></td>
                                    <td><OdsLdarFlagIcon item={item} /></td>
                                    <OdsDateColumn value={item.startDate} />
                                    <OdsDateColumn value={item.dueDate} />
                                    <OdsDateTimeColumn value={item.dispatchDate} />
                                    <OdsStringColumn>{item.dispatchNumber}</OdsStringColumn>
                                    <td><OdsPersonnelChip id={item.personnelId} item={item} onFetchPersonnel={onFetchPersonnel} /></td>
                                    {canEdit ? <td><OdsDeleteColumn item={item} onDelete={onRemoveItem} /></td> : ''}
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}



