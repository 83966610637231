export class AppResource {
    public id: number = 0;
    public name: string = '';
    public description: string = '';

    public static mapResource(data: AppResource) : AppResource {
        const resource = new AppResource();

        resource.id = data.id;
        resource.name = data.name;
        resource.description = data.description;

        return resource;
    };
};
