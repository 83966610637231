import { BooleanFilter } from "./boolean-filter";
import { DateFilter } from "./date-filter";
import { IntegerFilter } from "./integer-filter";
import { ItemFilter } from "./item-filter";
import { StringFilter } from "./string-filter";

export class LdarInspectionRequestFilter {
    public id?: IntegerFilter;
    public lod?: ItemFilter;
    public type?: ItemFilter;
    public state?: ItemFilter;
    public route?: ItemFilter;
    public status?: ItemFilter;
    public isConsentDecree?: BooleanFilter;
    public isOccupiedArea?: BooleanFilter;
    public isDisproportionatelyImpactedCommunity?: BooleanFilter;
    public isOOOOa?: BooleanFilter;
    public isOOOOb?: BooleanFilter;
    public startDate?: DateFilter;
    public dueDate?: DateFilter;
    public dispatchDate?: DateFilter;
    public dispatchUser?: ItemFilter;
    public formSubmissionDate?: DateFilter;
    public formSubmissionNumber?: StringFilter;
    public workOrderNumber?: StringFilter;
    public sourcePersonnel?: ItemFilter;
    public comments?: StringFilter;

    public isDispatched?: BooleanFilter;
    public isCompleted?: BooleanFilter;
}
