import React, { MouseEventHandler, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsArrowRepeat, BsArrowUpRightCircle, BsArrowUpRightCircleFill, BsBoxArrowDown, BsFilterSquare, BsPlusLg } from "react-icons/bs";
import { CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";

export interface OdsTableBannerProps {
    onAddItem?: () => void;
    onRefresh: () => void;
    onClearFilter: () => void;
    onExport: () => Promise<void>;
    onDispatch?: () => void;
    onImportItems?: () => void;
}

export const OdsTableBanner: React.FC<OdsTableBannerProps> = ({
    onAddItem,
    onRefresh,
    onClearFilter,
    onExport,
    onDispatch,
    onImportItems,
    ...props
}) => {

    const [isExporting, setIsExporting] = useState<boolean>(false);

    const doExport = async () => {
        setIsExporting(true);
        await onExport();
        setIsExporting(false);
    }

    return (
        <Container className="ods-ldar-dashboard-filter">
            <Row>
                <Col className="align-self-end">
                    {
                        onAddItem ?
                            <CvxButton onClick={onAddItem}><BsPlusLg />&nbsp;Add</CvxButton>
                            : ''
                    }
                </Col>
                <Col>
                    {
                        onImportItems ?
                            <CvxButton onClick={onImportItems}><BsPlusLg />&nbsp;Import</CvxButton>
                            : ''
                    }
                </Col>
                <Col className="align-self-end">
                    {
                        onDispatch ?
                            <CvxButton onClick={onDispatch}><BsArrowUpRightCircle />&nbsp;Dispatch</CvxButton>
                            : ''
                    }
                </Col>
                <Col></Col>
                <Col className="align-self-end">
                    <CvxButton onClick={onClearFilter}><BsFilterSquare />&nbsp;Clear</CvxButton>
                </Col>
                <Col className="align-self-end">
                    <CvxSpinnerButton theme='primary' disabled={isExporting} showSpinner={isExporting} onClick={doExport}><BsBoxArrowDown />&nbsp;Export</CvxSpinnerButton>
                </Col>
                <Col className="align-self-end">
                    <CvxButton onClick={onRefresh}><BsArrowRepeat />&nbsp;Refresh</CvxButton>
                </Col>
            </Row>
        </Container>
    )
};
