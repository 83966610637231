import { BehaviorSubject } from "rxjs";

export class MenuItemDefinition {
    public label: string = '';
    public href?: string | undefined;
    public to?: string | undefined;
    public target?: '_self' | '_blank' | '_parent' | '_top' = '_self';

    public menuItems?: MenuItemDefinition[] | undefined;
}

export class NavigationService {
    private quickLinksBehaviorSubject = new BehaviorSubject<MenuItemDefinition[]>([]);
    public quickLinks = this.quickLinksBehaviorSubject.asObservable();

    private siteMenuBehaviorSubject = new BehaviorSubject<MenuItemDefinition[]>([]);
    public siteMenu = this.siteMenuBehaviorSubject.asObservable();


    public static configure(configuration: NavigationConfiguration): NavigationService {
        const navigationService = new NavigationService();

        navigationService.setQuickLinks(configuration?.quickLink);
        navigationService.setSiteMenu(configuration?.siteMenu);

        return navigationService;
    };


    public setQuickLinks(quickLinks: MenuItemDefinition[] | undefined): void {
        this.quickLinksBehaviorSubject.next(quickLinks ?? []);
    }

    public setSiteMenu(siteMenu: MenuItemDefinition[] | undefined): void {
        this.siteMenuBehaviorSubject.next(siteMenu ?? []);
    }
}

export class NavigationConfiguration {
    public quickLink?: MenuItemDefinition[];
    public siteMenu?: MenuItemDefinition[];
}
