import { AppResource } from "./app-resource";

export class AppRole {
    public id?: number;
    public name: string = '';
    public description: string = '';
    public isActive: boolean = true;
    public resources: (AppResource | undefined)[] = [];
    public resourceIds: number[] = [];

    public static mapRole(data: AppRole): AppRole {
        const role = new AppRole();

        role.id = data.id;
        role.name = data.name ?? '';
        role.description = data.description ?? '';
        role.isActive = data.isActive ?? false;
        role.resourceIds = data.resourceIds ?? [];

        return role;
    };
};
