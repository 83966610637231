import { useState } from "react";
import { Popover } from "react-bootstrap";
import { OdsBooleanColumn, OdsBooleanColumnHeader, OdsDateColumnHeader, OdsEditItemColumn, OdsIntegerColumn, OdsIntegerColumnHeader, OdsRemoveItemColumn, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsDateTimeColumn } from "../../../components/table-columns/date-time-column/date-time-column";
import { OdsSuccessColumn } from "../../../components/table-columns/success-column/success-column";
import { OdsConfirmDeleteDialog } from "../../../dialogs";
import { OdsEditFormDataSource } from "../../../dialogs/edit-form-data-source/edit-form-data-source.dialog";
import { usePagedListResult } from "../../../hooks/list-result-hook";
import { useMyself } from "../../../hooks/myself-hook";
import { BooleanFilter, FormDataSource, FormDataSourceFilter, IntegerFilter, StringFilter } from "../../../models";
import { Permission } from "../../../models/permission";
import { CvxContentBlock, CvxLayoutCenterColumn, CvxPage } from "../../../modules/cvx-ui-module";
import { OdsFormApiService, ViewHelper } from "../../../services";


export interface OdsFormDataSourcesViewProps {
    service: OdsFormApiService;
}

export const OdsFormDataSourcesView: React.FC<OdsFormDataSourcesViewProps> = ({
    service,
    ...props
}) => {

    const [canEdit, showState, states] = useMyself(service, Permission.Administrator);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: FormDataSourceFilter) => {
        return service.RetrieveFormDataSourceListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('DataSourceId', 'Asc', new FormDataSourceFilter(), onFetchData);

    const [editItem, setEditItem] = useState<FormDataSource>();
    const [removeItem, setRemoveItem] = useState<FormDataSource>();


    const onAddItem = () => setEditItem(new FormDataSource());

    const onEditItem = (item: FormDataSource) => setEditItem(item);

    const onEditItemCompleted = async (dialogResult: boolean, item?: FormDataSource) => {
        if (dialogResult && item) {
            if (item.modifiedDate) {
                await service.UpdateFormDataSourceAsync(item);
            } else {
                await service.CreateFormDataSourceAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    }

    const onRemoveItem = (item: FormDataSource) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.formSpaceId && removeItem.dataSourceId) {
            await service.DeleteFormDataSourceAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    }

    const onExport = async () => {
        const blob = await service.ExportFormDataSourceListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`form-data-sources-${(new Date()).toLocaleDateString()}.csv`, blob)
    }


    const onFilterFormSpaceId = (newFilter?: IntegerFilter) => { filter.formSpaceId = newFilter; onRefresh(); }
    const onFilterDataSourceId = (newFilter?: IntegerFilter) => { filter.dataSourceId = newFilter; onRefresh(); }
    const onFilterName = (newFilter?: StringFilter) => { filter.name = newFilter; onRefresh(); }
    const onFilterDescription = (newFilter?: StringFilter) => { filter.description = newFilter; onRefresh(); }
    const onFilterSqlQuery = (newFilter?: StringFilter) => { filter.sqlQuery = newFilter; onRefresh(); }
    const onFilterIsActive = (newFilter?: BooleanFilter) => { filter.isActive = newFilter; onRefresh(); };

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveFormDataSourceSearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsEditFormDataSource service={service} show={!!editItem} formDataSource={editItem} onComplete={onEditItemCompleted} />
            <OdsConfirmDeleteDialog show={!!removeItem} text={removeItem?.name} onComplete={onRemoveItemCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onAddItem={onAddItem} onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxContentBlock>
                        <OdsTable heading="Form Data Sources" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <OdsIntegerColumnHeader name="FormSpaceId" header="Form Space" filter={filter.formSpaceId} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterFormSpaceId} onSearch={onFilterValueSearch} />
                                    <OdsIntegerColumnHeader name="DataSourceId" header="Data Source" filter={filter.dataSourceId} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDataSourceId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Name" header="Name" filter={filter.name} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterName} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Description" header="Description" filter={filter.description} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDescription} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="SqlQuery" header="SQL Query" filter={filter.sqlQuery}
                                        onFilterChanged={onFilterSqlQuery} onSearch={onFilterValueSearch} />
                                    <OdsBooleanColumnHeader name="LastPushSuccess" header="Last Push Success" />
                                    <OdsDateColumnHeader name="LastPushDate" header="Last Push Date" />
                                    <OdsIntegerColumnHeader name="LastPushRows" header="Last Push Rows" />
                                    <OdsIntegerColumnHeader name="LastPushError" header="Last Errors" />
                                    <OdsBooleanColumnHeader name="IsActive" header="Is&nbsp;Active" filter={filter.isActive} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterIsActive} />
                                    <th></th>
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    {canEdit ? <OdsEditItemColumn item={item} onEditItem={onEditItem} /> : <td></td>}
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.formSpaceId}</OdsIntegerColumn>
                                    <OdsIntegerColumn>{item.dataSourceId}</OdsIntegerColumn>
                                    <OdsStringColumn>{item.name}</OdsStringColumn>
                                    <OdsStringColumn value={item.description} tooltip={<Popover.Body>{item.description}</Popover.Body>} maxWidth={50} noWrap />
                                    <OdsStringColumn value={item.sqlQuery} tooltip={<Popover.Body>{item.sqlQuery}</Popover.Body>} maxWidth={50} noWrap />
                                    <OdsSuccessColumn value={item.lastPushIsSuccess} />
                                    <OdsDateTimeColumn value={item.lastPushedDateTime} />
                                    <OdsIntegerColumn>{item.lastRowsPushed.toLocaleString()}</OdsIntegerColumn>
                                    <OdsStringColumn value={item.lastPushError} tooltip={<Popover.Body>{item.lastPushError}</Popover.Body>} maxWidth={50} noWrap />
                                    <OdsBooleanColumn value={item.isActive} />
                                    {canEdit ? <OdsRemoveItemColumn item={item} onRemoveItem={onRemoveItem} /> : <td></td>}
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
