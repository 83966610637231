import { PersonnelFilter, ListResult, Personnel, PersonnelGroupFilter, PersonnelGroup, PersonnelGroupTypeFilter, PersonnelGroupType, Route, RouteFilter } from "../models";
import { ApiService } from "./api-service";
import { ItemCache } from "./item-cache";
import { OdsApiServiceBase } from "./ods-api-service-base";


export class OdsPersonnelApiService extends OdsApiServiceBase {
    public constructor(apiService: ApiService | undefined) {
        super(apiService);
    }

    //*************************************************************************************************************************************
    //********************************************************   PERSONNEL   **************************************************************
    //*************************************************************************************************************************************

    private personnelCache = new ItemCache<number, Personnel>();

    public async RetrievePersonnelListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: PersonnelFilter): Promise<ListResult<Personnel>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<Personnel>>('/api/v1.0/personnel/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(data => this.personnelCache.set(data.id, Personnel.map(data))) };
    }

    public async RetrievePersonnelAsync(personnelId: number): Promise<Personnel> {
        return this.personnelCache.get(personnelId, async (id: number) => {
            if (!this.apiService) {
                throw new Error();
            }

            const data = await this.apiService.get<Personnel>(`/api/v1.0/personnel/${id}`);

            return Personnel.map(data);
        });
    }

    public async CreatePersonnelAsync(personnel: Personnel): Promise<Personnel> {
        if (!this.apiService) {
            throw new Error();
        }

        personnel.stateId = personnel.state?.id ?? 0;

        const data = await this.apiService.post<Personnel>(`/api/v1.0/personnel`, JSON.stringify(personnel));

        return this.personnelCache.set(data.id, Personnel.map(data));
    }

    public async UpdatePersonnelAsync(personnel: Personnel): Promise<Personnel> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.put<Personnel>(`/api/v1.0/personnel/${personnel.id}`, JSON.stringify(personnel));

        return this.personnelCache.set(data.id, Personnel.map(data));
    }

    public async DeletePersonnelAsync(personnel: Personnel): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/personnel/${personnel.id}`);
        this.personnelCache.clear(personnel.id);
    }

    public async ValidatePersonnelEmailAsync(email: string, stateId?: number, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/personnel/validate?email=${email}&stateId=${stateId}&id=${id}`);

        return data;
    }

    public async RetrievePersonnelSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/personnel/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportPersonnelListAsync<T>(sort: string, sortDirection: string, filter: PersonnelFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/personnel/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }


    //*************************************************************************************************************************************
    //*****************************************************   PERSONNEL GROUPS   **********************************************************
    //*************************************************************************************************************************************

    private personnelGroupCache = new ItemCache<number, PersonnelGroup>();

    public async RetrievePersonnelGroupListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: PersonnelGroupFilter): Promise<ListResult<PersonnelGroup>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<PersonnelGroup>>('/api/v1.0/personnel-groups/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => this.personnelGroupCache.set(d.id, PersonnelGroup.map(d))) };
    }

    public async RetrievePersonnelGroupAsync(personnelGroupId: number): Promise<PersonnelGroup> {
        return this.personnelGroupCache.get(personnelGroupId, async (id: number) => {
            if (!this.apiService) {
                throw new Error();
            }

            const data = await this.apiService.get<PersonnelGroup>(`/api/v1.0/personnel-groups/${personnelGroupId}`);

            return PersonnelGroup.map(data);
        });
    }

    public async CreatePersonnelGroupAsync(personnelGroup: PersonnelGroup): Promise<PersonnelGroup> {
        if (!this.apiService) {
            throw new Error();
        }

        let json = {
            name: personnelGroup.name,
            description: personnelGroup.description,
            personnelGroupTypeId: personnelGroup.personnelGroupType?.id,
            personnelIds: personnelGroup.personnel.map(p => p.id),
            stateId: personnelGroup.state?.id ?? 0,
            isActive: personnelGroup.isActive,
        }

        const data = await this.apiService.post<PersonnelGroup>(`/api/v1.0/personnel-groups`, JSON.stringify(json));

        return this.personnelGroupCache.set(data.id, PersonnelGroup.map(data));
    }

    public async UpdatePersonnelGroupAsync(personnelGroup: PersonnelGroup): Promise<PersonnelGroup> {
        if (!this.apiService) {
            throw new Error();
        }

        let json = {
            name: personnelGroup.name,
            description: personnelGroup.description,
            personnelGroupTypeId: personnelGroup.personnelGroupType?.id,
            personnelIds: personnelGroup.personnel.map(p => p.id),
            stateId: personnelGroup.state?.id ?? 0,
            isActive: personnelGroup.isActive,
        }

        const data = await this.apiService.put<PersonnelGroup>(`/api/v1.0/personnel-groups/${personnelGroup.id}`, JSON.stringify(json));

        return this.personnelGroupCache.set(data.id, PersonnelGroup.map(data));
    }

    public async DeletePersonnelGroupAsync(personnelGroup: PersonnelGroup): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/personnel-groups/${personnelGroup.id}`);
        this.personnelGroupCache.clear(personnelGroup.id);
    }

    public async ValidatePersonnelGroupNameAsync(name: string, stateId?: number, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/personnel-groups/validate?name=${name}&stateId=${stateId}&id=${id}`);

        return data;
    }

    public async RetrievePersonnelGroupSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/personnel-groups/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportPersonnelGroupListAsync<T>(sort: string, sortDirection: string, filter: PersonnelGroupFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/personnel-groups/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }


    //*************************************************************************************************************************************
    //***************************************************   PERSONNEL GROUP TYPES  ********************************************************
    //*************************************************************************************************************************************

    private personnelGroupTypeCache = new ItemCache<number, PersonnelGroupType>();

    public async RetrievePersonnelGroupTypeListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: PersonnelGroupTypeFilter): Promise<ListResult<PersonnelGroupType>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<PersonnelGroupType>>('/api/v1.0/personnel-group-types/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => this.personnelGroupTypeCache.set(d.id, PersonnelGroupType.map(d))) };
    }

    public async RetrievePersonnelGroupTypeAsync(personnelGroupTypeId: number): Promise<PersonnelGroupType> {
        return this.personnelGroupTypeCache.get(personnelGroupTypeId, async (id: number) => {
            if (!this.apiService) {
                throw new Error();
            }

            const data = await this.apiService.get<PersonnelGroupType>(`/api/v1.0/personnel-group-types/${personnelGroupTypeId}`);

            return PersonnelGroupType.map(data);
        });
    }

    public async CreatePersonnelGroupTypeAsync(personnelGroupType: PersonnelGroupType): Promise<PersonnelGroupType> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.post<PersonnelGroupType>(`/api/v1.0/personnel-group-types`, JSON.stringify(personnelGroupType));

        return this.personnelGroupTypeCache.set(data.id, PersonnelGroupType.map(data));
    }

    public async UpdatePersonnelGroupTypeAsync(personnelGroupType: PersonnelGroupType): Promise<PersonnelGroupType> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.put<PersonnelGroupType>(`/api/v1.0/personnel-group-types/${personnelGroupType.id}`, JSON.stringify(personnelGroupType));

        return this.personnelGroupTypeCache.set(data.id, PersonnelGroupType.map(data));
    }

    public async DeletePersonnelGroupTypeAsync(personnelGroupType: PersonnelGroupType): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/personnel-group-types/${personnelGroupType.id}`);
        this.personnelGroupTypeCache.clear(personnelGroupType.id);
    }

    public async ValidatePersonnelGroupTypeNameAsync(name: string, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/personnel-group-types/validate?name=${name}&id=${id}`);

        return data;
    }

    public async RetrievePersonnelGroupTypeSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/personnel-group-types/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportPersonnelGroupTypeListAsync<T>(sort: string, sortDirection: string, filter: PersonnelGroupTypeFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/personnel-group-types/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }


    //*************************************************************************************************************************************
    //**********************************************************   ROUTES  ****************************************************************
    //*************************************************************************************************************************************

    private routeCache = new ItemCache<number, Route>();

    public async RetrieveRouteListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: RouteFilter): Promise<ListResult<Route>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<Route>>('/api/v1.0/routes/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => this.routeCache.set(d.id, Route.map(d))) };
    }

    public async RetrieveRouteAsync(routeId: number): Promise<Route> {
        return this.routeCache.get(routeId, async (id: number) => {
            if (!this.apiService) {
                throw new Error();
            }

            const data = await this.apiService.get<Route>(`/api/v1.0/routes/${routeId}`);

            return Route.map(data);
        });
    }

    public async CreateRouteAsync(route: Route): Promise<Route> {
        if (!this.apiService) {
            throw new Error();
        }

        let json = {
            name: route.name,
            description: route.description,
            ecObjectCode: route.ecObjectCode,
            inforRouteCode: route.inforRouteCode,
            personnelGroupIds: route.personnelGroups.map(p => p.id),
            stateId: route.state?.id ?? 0,
            isActive: route.isActive,
        }

        const data = await this.apiService.post<Route>(`/api/v1.0/routes`, JSON.stringify(json));

        return this.routeCache.set(data.id, Route.map(data));
    }

    public async UpdateRouteAsync(route: Route): Promise<Route> {
        if (!this.apiService) {
            throw new Error();
        }

        let json = {
            name: route.name,
            description: route.description,
            ecObjectCode: route.ecObjectCode,
            inforRouteCode: route.inforRouteCode,
            stateId: route.state?.id ?? 0,
            personnelGroupIds: route.personnelGroups.map(p => p.id),
            isActive: route.isActive,
        }

        const data = await this.apiService.put<Route>(`/api/v1.0/routes/${route.id}`, JSON.stringify(json));

        return this.routeCache.set(data.id, Route.map(data));
    }

    public async DeleteRouteAsync(route: Route): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/routes/${route.id}`);
        this.routeCache.clear(route.id);
    }

    public async ValidateRouteNameAsync(name: string, stateId?: number, id?: number): Promise<boolean> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<boolean>(`/api/v1.0/routes/validate?name=${name}&stateId=${stateId}&id=${id}`);

        return data;
    }

    public async RetrieveRouteSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/routes/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportRouteListAsync<T>(sort: string, sortDirection: string, filter: RouteFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/routes/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }
}
