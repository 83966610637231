import { UserRole } from "./user-role";

export class User {
    public id: number = 0;
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public isActive: boolean = true;

    public roles: UserRole[] = [];
    public permissions: string[] = [];

    public static map(data: any): User {
        const m = new User;

        m.id = parseInt(data.id);
        m.firstName = data.firstName;
        m.lastName = data.lastName;
        m.email = data.email;
        m.isActive = data.isActive;

        m.roles = data.roles?.map((d: any) => UserRole.map(d)) ?? [];
        m.permissions = data.permissions ?? [];

        return m;
    };
}
