import React, { HtmlHTMLAttributes } from "react";
import { BsPlusCircleDotted } from "react-icons/bs";

export interface OdsSelectItemColumnProps<T> {
    item: T,
    onSelectItem: (e: React.MouseEvent<Element, MouseEvent>, item: T) => void,
}

interface Item {

}

export const OdsSelectItemColumn = <T extends Item>({
    item,
    onSelectItem,
    ...props
}: OdsSelectItemColumnProps<T> & HtmlHTMLAttributes<HTMLTableCellElement>) => {
    return (
        <td {...props} style={{ width: '25px', userSelect: 'none' }}><span onClick={(e) => onSelectItem(e, item)}><BsPlusCircleDotted /></span></td>
    )
}
