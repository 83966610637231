import { HtmlHTMLAttributes } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { Themes } from "../../../modules/cvx-ui-module";

export interface OdsEditItemColumnProps<T> {
    item: T,
    onEditItem: (item: T) => void,
}

interface Item {

}

export const OdsEditItemColumn = <T extends Item>({
    item,
    onEditItem,
    ...props
}: OdsEditItemColumnProps<T> & HtmlHTMLAttributes<HTMLTableCellElement>) => {
    return (
        <td {...props} style={{ width: '25px' }}><BsFillPencilFill className={Themes.PrimaryDarkColor} onClick={() => { onEditItem(item) }} /></td>
    )
}
