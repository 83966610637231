import React, { PropsWithChildren, ReactChildren } from "react";

export interface OdsSelectableTableRowProps<T> {
    item: T;
    selectedItems: T[];
}

interface Item {
    id?: number | string;
}

export const OdsSelectableTableRow = <T extends Item>({
    item,
    selectedItems,
    ...props
}: PropsWithChildren<OdsSelectableTableRowProps<T>>) => {
    return (
        <tr key={item.id} className={`user-select ${selectedItems.find(i => i === item) ? " selected" : ""}`}>
            {props.children}
        </tr>
    )
}
