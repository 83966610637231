import * as yup from 'yup';
import { OdsAsyncMultiSelect } from '../../components';
import OdsInput from "../../components/form-editors/input/input";
import OdsSwitch from '../../components/form-editors/switch/switch';
import { OdsEditUserRoles } from '../../components/form-editors/user-roles/user-roles';
import { RoleFilter, StringFilter, User } from '../../models';
import { OdsUserApiService } from '../../services';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditUserDialogProps {
    service: OdsUserApiService;
    show: boolean;
    user: User | undefined;
    onComplete: (dialogResult: boolean, user: User | undefined) => Promise<any>;
}

export const OdsEditUserDialog: React.FC<OdsEditUserDialogProps> = ({
    service,
    show,
    user,
    onComplete,
    ...props
}) => {

    const userSchema = yup.object().shape({
        firstName: yup.string().required().max(150),
        lastName: yup.string().required().max(150),
        email: yup.string().email().required().max(500),
        // .test('duplicate', 'email is already in use', async (value: string | undefined) => {
        //     if(value) {
        //         return await service.ValidateUserEmailAsync(value, user?.id);
        //     }
        //     return true;
        // }),
        roles: yup.array().required().of(yup.object().shape({
            role: yup.object().required(),
            isAssignable: yup.boolean().required()
        })),
        isActive: yup.boolean().required(),
    });

    const onSearchRoles = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new RoleFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        let data = await service.RetrieveRoleListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={user?.id ? 'Edit User: ' + user.id : 'Add User'}
            validationSchema={userSchema}
            initialValue={user ?? new User()}
            onComplete={onComplete}
        >
            <OdsInput name="firstName" label="First Name" />
            <OdsInput name="lastName" label="Last Name" />
            <OdsInput name="email" label="Email" />
            <OdsEditUserRoles
                name="roles"
                label="Roles"
                onSearchRole={onSearchRoles}
            />
            <OdsSwitch name="isActive" label="Is Active" />
        </OdsBaseAddEditDialog>
    );
};
