import React from "react";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { Themes } from "../../../modules/cvx-ui-module";

export interface OdsShouldBeDeletedColumnProps {
}

const OdsShouldBeDeletedColumn: React.FC<OdsShouldBeDeletedColumnProps> = ({
    ...props
}) => {
    return (
        <BsFillExclamationTriangleFill className={Themes.WarningDarkColor} />
    )
};

export default OdsShouldBeDeletedColumn;
