export class CreateBradenheadDispatch {
    public formId?: number;
    public apiNo?: string;
    public wellCategory?: string;
    public wellName?: string;
    public wellId?: number;
    public route?: string;
    public reportingMonth?: string;
    public dueDate?: Date;
    public recipients?: string[];
}
