export class BradenheadPressureData {
    public api?: string;
    public wellId?: number;
    public wellName?: string;
    public route?: string;
    public recordDate?: Date;
    public bradenheadPressure?: number;
    public productionCasingPressure?: number;
    public intermediateCasingPressure?: number;
    public datasourceName?: string;
    public datasourceContext?: string;
    public wellCategory?: string;
    public wellCategoryId?: number;
    public userName?: string;
    public formName?: string;
    public areaName?: string;
    public dispatchForm?: string;
    public dispatchReferences?: string;
    public dispatchUsers?: string;
    public lct?: Date;
    public status?: string;
    public commentText?: string;
    public aocFluidType?: string;
    public shouldBeDeleted?: boolean;
    public recentlyAdded?: boolean;
    public automationStatus?: string;
    public needsDispatch?: string;
    public availableToDispatch?: string;

    public static mapData(data: any): BradenheadPressureData {
        const d = new BradenheadPressureData;

        d.api = data.api;
        d.wellId = data.wellId;
        d.wellName = data.wellName;
        d.route = data.routeName;
        d.recordDate = (data.recordDate !== undefined && data.recordDate !== null) ? new Date(data.recordDate) : undefined;
        d.bradenheadPressure = data.bradenheadPressure;
        d.productionCasingPressure = data.productionCasingPressure;
        d.intermediateCasingPressure = data.intermediateCasingPressure;
        d.datasourceName = data.datasourceName;
        d.datasourceContext = data.datasourceContext;
        d.wellCategory = data.wellCategory;
        d.userName = data.userName;
        d.formName = data.formName;
        d.areaName = data.areaName;
        d.dispatchForm = data.dispatchFormName;
        d.dispatchReferences = data.dispatchReferences;
        d.dispatchUsers = data.dispatchUsers;
        d.lct = data.lct ? new Date(data.lct) : undefined;
        d.status = data.status;
        d.commentText = data.commentText;
        d.aocFluidType = data.aocFluidType;
        d.shouldBeDeleted = data.shouldBeDeleted;
        d.recentlyAdded = data.recentlyAdded;
        d.automationStatus = data.automationStatus;
        d.needsDispatch = data.needsDispatch;
        d.availableToDispatch = data.availableToDispatch;

        return d;
    };
};
