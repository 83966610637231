import * as yup from 'yup';
import OdsInput from "../../components/form-editors/input/input";
import OdsSwitch from '../../components/form-editors/switch/switch';
import OdsTextArea from '../../components/form-editors/textarea/textarea';
import { FormAttribute } from '../../models/form-attribute';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditFormAttributeDialogProps {
    show: boolean;
    attribute: FormAttribute | undefined;
    onComplete: (dialogResult: boolean, attribute: FormAttribute | undefined) => Promise<any>;
}

const OdsEditFormAttributeDialog: React.FC<OdsEditFormAttributeDialogProps> = ({
    show,
    attribute,
    onComplete,
    ...props
}) => {

    const schema = yup.object().shape({
        name: yup.string().required().max(100),
        description: yup.string().nullable().max(2000),
        tag: yup.string().nullable().max(100),
        xPath: yup.string().nullable().max(2000),
        isImage: yup.boolean().required(),
        isRepeating: yup.boolean().required(),
        isActive: yup.boolean().required(),
    });

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={attribute ? 'Edit Attribute: ' + attribute.id : 'Add Attribute'}
            validationSchema={schema}
            initialValue={attribute ?? new FormAttribute()}
            onComplete={onComplete}
        >
            <OdsInput name="name" label="Name" />
            <OdsTextArea name="description" label="Description" />
            <OdsInput name="tag" label="Tag" />
            <OdsInput name="xPath" label="XPath" />
            <OdsSwitch name="isImage" label="Is Image?" />
            <OdsSwitch name="isRepeating" label="Is Repeating Section?" />
            <OdsSwitch name="isActive" label="Is Active?" />
        </OdsBaseAddEditDialog>
    );
};

export default OdsEditFormAttributeDialog;
