import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { BsClipboardPlus } from "react-icons/bs";
import { OdsAsyncMultiSelectControl } from "../../../components/async-multi-select/async-multi-select.control";
import { ListResult } from "../../../models/list-result";
import { StringFilter } from "../../../models/string-filter";
import { CvxButton } from "../../../modules/cvx-ui-module";

export interface OdsStringFilterDialogProps {
    show: boolean;
    header: React.ReactNode;
    filter?: StringFilter;
    onSearch: (top: number, skip: number, filter: string) => Promise<ListResult<string>>;
    onComplete?: (dialogResult: boolean, filter?: StringFilter) => void;
}

export const OdsStringFilterDialog: React.FC<OdsStringFilterDialogProps> = ({
    show,
    header,
    filter,
    onSearch,
    onComplete,
    ...props
}) => {
    const [likeValue, setLikeValue] = useState<string>('');
    const [useLikeValue, setUseLikeValue] = useState<boolean>(false);
    const [equalValue, setEqualValue] = useState<string[]>([]);
    const [useEqualValue, setUseEqualValue] = useState<boolean>(false);
    const [useBlanksValue, setUseBlanksValue] = useState<boolean>(false);

    const onLikeValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLikeValue(e.target.value);
        setUseLikeValue(e.target.value !== '' && e.target.value !== undefined);
    }

    const onEqualValueChanged = (selected: string[]) => {
        setEqualValue(selected);
        setUseEqualValue(selected?.length > 0);
    }

    const onPasteItems = async () => {
        const text = await navigator.clipboard.readText();

        const selected: string[] = [];
        (text ?? '').split('\n').forEach(i => {
            i = i.trim();
            if (i.trim().length > 0) {
                selected.push(i);
            }
        });

        setEqualValue(selected);
        setUseEqualValue(selected?.length > 0);
    }

    const onCancel = () => {
        if (onComplete) {
            onComplete(false, undefined);
        }
    }

    const onClear = () => {
        setLikeValue('');
        setUseLikeValue(false);

        setEqualValue([]);
        setUseEqualValue(false);

        setUseBlanksValue(false);

        if (onComplete) {
            onComplete(true, undefined);
        }
    }

    const onApply = () => {
        if (!useLikeValue && !useEqualValue && !useBlanksValue) {
            onClear();
        } else {
            const filter = new StringFilter();

            if (useLikeValue) {
                filter.like = likeValue;
            }

            if (useEqualValue) {
                filter.equal = equalValue;
            }

            if (useBlanksValue) {
                filter.includeBlanks = true;
            }

            if (onComplete) {
                onComplete(true, filter);
            }
        }
    }

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Filter '{header}'</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useLikeValue} onChange={() => setUseLikeValue(!useLikeValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="likeFilter">
                                <Form.Label>{header} like </Form.Label>
                                <Form.Control type="text" value={likeValue} onChange={onLikeValueChanged}></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useEqualValue} onChange={() => setUseEqualValue(!useEqualValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="inFilter">
                                <Form.Label>{header} equals </Form.Label>
                                <OdsAsyncMultiSelectControl
                                    id="equals filter"
                                    onSearch={onSearch}
                                    multiple={true}
                                    selected={equalValue}
                                    onChange={onEqualValueChanged}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="align-self-end" md="auto">
                            <CvxButton onClick={onPasteItems}><BsClipboardPlus /></CvxButton>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Check type="switch" checked={useBlanksValue} onChange={() => setUseBlanksValue(!useBlanksValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="blankFilter">
                                <Form.Label>Blank Values</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxButton theme='primary' onClick={onClear}>Clear</CvxButton>
                <CvxButton theme='primary' onClick={onApply}>Apply</CvxButton>
            </Modal.Footer>
        </Modal>
    );
};
