import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface CvxColumnProps {
    item: any;
    align?: 'left-column' | 'center-column' | 'right-column';
    noWrap?: boolean;
    width?: string;
    maxWidth?: number;
    tooltip?: (item: any) => React.ReactNode | string;
    template: (item: any) => React.ReactNode | string;
}

const CvxColumn: React.FC<CvxColumnProps> = ({
    item,
    align,
    noWrap,
    width,
    maxWidth,
    tooltip,
    template,
    ...props
}) => {

    const renderTooltip = (props: any) => (
        <Tooltip {...props} className="ods-tooltip">
            {tooltip ? tooltip(item) : ''}
        </Tooltip>
    );

    return (
        <>
            {tooltip ?
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <td className={align} style={{ width: `${width}`, maxWidth: maxWidth ? `${maxWidth}px` : 'unset', whiteSpace: noWrap ? 'nowrap' : 'unset' }}>{template(item)}</td>
                </OverlayTrigger>
                :
                <td className={align} style={{ width: `${width}`, maxWidth: maxWidth ? `${maxWidth}px` : 'unset', whiteSpace: noWrap ? 'nowrap' : 'unset' }}>{template(item)}</td>
            }
        </>
    );
};

export default CvxColumn;
