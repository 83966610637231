import { BsArrowDown, BsArrowDownUp, BsArrowUp, BsFileRuled, BsFileRuledFill, BsFunnel, BsFunnelFill } from "react-icons/bs";
import { State } from "../models/state";
import { OdsApiServiceBase } from "./ods-api-service-base";

interface ItemWithState {
    stateId: number;
    state?: State;       
}

export class ViewHelper {
    public static drawSort(columnName: string, sort?: string, sortDirection?: string, onSortChanged?: (columnName: string, sortDirection: string) => void) {
        if (sort && sortDirection && onSortChanged) {
            if (sort === columnName) {
                if (sortDirection == 'Asc') {
                    return <span onClick={() => onSortChanged(columnName, 'Desc')}> <BsArrowDown /></span >
                } else {
                    return <span onClick={() => onSortChanged(columnName, 'Asc')}> <BsArrowUp /></span >
                }
            } else {
                return <span onClick={() => onSortChanged(columnName, 'Asc')}> <BsArrowDownUp className="sort-inactive" /> </span>;
            }
        } else {
            return <span></span>;
        }
    };

    public static drawFilter(columnName: string, filtered: boolean, onShowFilter: (columnName: string) => void, onFilterChanged?: (newFilter?: any) => void) {
        if (onFilterChanged) {
            if (filtered) {
                return <span onClick={() => onShowFilter(columnName)}><BsFunnelFill /></span>;
            } else {
                return <span onClick={() => onShowFilter(columnName)}><BsFunnel className="sort-inactive" /></span>;
            }
        } else {
            return <span></span>;
        }
    };

    public static drawEdit(columnName: string, selectedItems: []) {
        if (selectedItems) {
            if (selectedItems.length > 1) {
                return <span><BsFileRuledFill /></span>;
            } else {
                return <span><BsFileRuled className="sort-inactive" /></span>;
            }
        } else {
            return <span></span>;
        }
    };

    public static exportData(fileName: string, file: Blob) {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(file);
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };


    public static rowSelect<T>(evt: React.MouseEvent<Element, MouseEvent>, item: T, selectedItem: T | undefined, selectedItems: T[], availableItems: T[]): T[] {
        const shift = evt.getModifierState("Shift");
        const ctrl = evt.getModifierState("Control");

        const selection: T[] = selectedItems ?? [];

        if (shift == false && ctrl == false) {
            selection.length = 0;
            if (item != selectedItem) {
                selection.push(item);
                return (selection);
            } else {
                return (selection);
            }
        }

        if (shift && selectedItem) {
            let start = availableItems.indexOf(selectedItem);
            let end = availableItems.indexOf(item);

            if (end < start) {
                const temp = end;
                end = start;
                start = temp;
            }

            for (let i = start; i <= end; i++) {
                if (selection?.find(j => j == availableItems[i]) === undefined) {
                    selection.push(availableItems[i]);
                }
            }

            return (selection);
        }

        if (ctrl) {
            const index = selection.indexOf(item);
            if (index > -1) {
                selection.splice(index, 1);
                const newSelection: T[] = [];
                selection.forEach(item => newSelection.push(item));
                return (newSelection);
            } else {
                selection.push(item);
                return (selection);
            }
        }

        return [];
    };

    public static async ensureStateId<T extends ItemWithState>(service: OdsApiServiceBase, item: T, stateIds: number[]) {
        if ((item.stateId ?? 0) > 0 && item.state === undefined) {
            let state = await service.RetrieveStateAsync(item.stateId);
            item.state = state;
        }

        if (item.state === undefined && stateIds.length == 1) {
            let state = await service.RetrieveStateAsync(stateIds[0]);
            item.state = state;
        }

        return item;
    };
};
