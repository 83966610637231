import React, { useEffect, useLayoutEffect, useState } from "react";
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronLeft, BsChevronRight } from "react-icons/bs";

export interface PageChangedEvent {
    pageSize: number;
    pageIndex: number;
}

export interface CvxPaginationProps {
    pageSize?: number;
    pageIndex?: number;
    totalCount?: number;
    pageSizeOptions?: number[];
    onPageChanged?: (e: PageChangedEvent) => void;
}

export const CvxPagination: React.FC<CvxPaginationProps> = ({
    pageSize,
    pageIndex,
    totalCount,
    pageSizeOptions,
    onPageChanged,
    ...props
}) => {
    const [displayedPages, setDisplayedPages] = useState<number[]>([]);

    useEffect(() => {
        let result: number[] = [];

        if (!pageIndex) { pageIndex = 0; }
        if (!pageSize) { pageSize = 10; }
        if (!totalCount) { totalCount = pageSize; }

        const totalPages = Math.ceil(Math.max(totalCount / pageSize, 1));
        const maxPagesToDisplay = Math.min(10, totalPages);

        result.push(pageIndex);

        let i = 1;
        while (result.length < maxPagesToDisplay) {
            if (pageIndex - i >= 0) {
                result.push(pageIndex - i);
            }
            if (pageIndex + i < totalPages) {
                result.push(pageIndex + i);
            }
            i++;
        }

        setDisplayedPages(result.sort((l, r) => l - r));
    }, [pageSize, pageIndex, totalCount]);

    const onFirst = () => {
        pageIndex = 0;

        if (onPageChanged) {
            onPageChanged({ pageIndex, pageSize: pageSize ?? 10 });
        }
    };

    const onPrevious = () => {
        pageIndex = Math.max(0, (pageIndex ?? 0) - 1);

        if (onPageChanged) {
            onPageChanged({ pageIndex, pageSize: pageSize ?? 10 });
        }
    };

    const onNext = () => {
        pageIndex = Math.ceil(Math.min(Math.max((totalCount ?? 1) / (pageSize ?? 1), 1) - 1, (pageIndex ?? 0) + 1));

        if (onPageChanged) {
            onPageChanged({ pageIndex, pageSize: pageSize ?? 10 });
        }
    };

    const onLast = () => {
        pageIndex = Math.ceil(Math.max((totalCount ?? 1) / (pageSize ?? 1), 1)) - 1;

        if (onPageChanged) {
            onPageChanged({ pageIndex, pageSize: pageSize ?? 10 });
        }
    };

    const onSelectPage = (pageIndex: number) => {
        if (onPageChanged) {
            onPageChanged({ pageIndex, pageSize: pageSize ?? 10 });
        }
    };

    const onPageSizeChange = (e: any) => {
        if (pageSizeOptions) {
            let pageSize = pageSizeOptions[e.target.options.selectedIndex ?? 0];

            if (onPageChanged) {
                onPageChanged({ pageIndex: 0, pageSize: pageSize ?? 10 });
            }
        }
    }

    return (
        <div className="cvx-pagination cvx-primary-medium-color">
            <div className="total-count">
                <span className="label">total count </span><span>{totalCount}</span>
            </div>
            <nav>
                <ul>
                    <li className="previous" onClick={() => onFirst()}><BsChevronDoubleLeft /></li>
                    <li className="previous" onClick={() => onPrevious()}><BsChevronLeft /></li>
                    {displayedPages.map(d => <li key={d} className={pageIndex === d ? 'selected' : ''} onClick={() => onSelectPage(d)}>{Math.round(d + 1)}</li>)}
                    <li className="next" onClick={() => onNext()}><BsChevronRight /></li>
                    <li className="next" onClick={() => onLast()}><BsChevronDoubleRight /></li>
                </ul>
            </nav>
            <div className="page-size-options">
                <span>items per page</span>
                <select aria-label="items per page" onChange={(e) => onPageSizeChange(e)}>
                    {pageSizeOptions?.map(o => <option key={o} selected={o === pageSize}>{o}</option>)}
                </select>
            </div>
        </div>
    );
};

export default CvxPagination;
