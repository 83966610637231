import { useReducer, useState } from "react";
import { OdsBooleanColumn, OdsBooleanColumnHeader, OdsEditItemColumn, OdsIntegerColumn, OdsIntegerColumnHeader, OdsItemColumnHeader, OdsPersonnelChip, OdsPersonnelGroupTypeChip, OdsRemoveItemColumn, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsStateLookup } from "../../../components/state-lookup/state-lookup";
import { OdsConfirmDeleteDialog, OdsEditPersonnelGroupDialog } from "../../../dialogs";
import { usePagedListResult } from "../../../hooks/list-result-hook";
import { useMyself } from "../../../hooks/myself-hook";
import { BooleanFilter, IntegerFilter, ItemFilter, Personnel, PersonnelGroup, PersonnelGroupFilter, PersonnelGroupType, StringFilter } from "../../../models";
import { Permission } from "../../../models/permission";
import { CvxContentBlock, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import { OdsPersonnelApiService, ViewHelper } from "../../../services";

export interface OdsPersonnelGroupsViewProps {
    service: OdsPersonnelApiService;
}

export const OdsPersonnelGroupsView: React.FC<OdsPersonnelGroupsViewProps> = ({
    service,
}) => {
    const [ignored, forceUpdate] = useReducer(x => x = -x, 1);

    const [canEdit, showState, states] = useMyself(service, Permission.EditPersonnel);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: PersonnelGroupFilter) => {
        return service.RetrievePersonnelGroupListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('Name', 'Asc', new PersonnelGroupFilter(), onFetchData);

    const [editItem, setEditItem] = useState<PersonnelGroup>();
    const [removeItem, setRemoveItem] = useState<PersonnelGroup>();


    const onFetchState = async (id: number) => await service.RetrieveStateAsync(id);

    const onFetchPersonnel = async (item: PersonnelGroup, id: number) => {
        const data = await service.RetrievePersonnelAsync(id);
        item.personnel.push(data);
        return data;
    }

    const onFetchPersonnelGroupType = async (id: number) => await service.RetrievePersonnelGroupTypeAsync(id);


    const onAddItem = () => setEditItem(new PersonnelGroup());

    const onEditItem = (item: PersonnelGroup) => setupEditItem(item);

    const setupEditItem = async (item: PersonnelGroup) => {
        setEditItem(await ViewHelper.ensureStateId(service, item, states));
    };

    const onEditItemCompleted = async (dialogResult: boolean, item?: PersonnelGroup) => {
        if (dialogResult && item) {
            if (item.id) {
                await service.UpdatePersonnelGroupAsync(item);
            } else {
                await service.CreatePersonnelGroupAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    }

    const onRemoveItem = (item: PersonnelGroup) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await service.DeletePersonnelGroupAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    }

    const onExport = async () => {
        const blob = await service.ExportPersonnelGroupTypeListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`personnel-group-${(new Date()).toLocaleDateString()}.csv`, blob)
    }


    const onFilterId = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); }
    const onFilterName = (newFilter?: StringFilter) => { filter.name = newFilter; onRefresh(); }
    const onFilterDescription = (newFilter?: StringFilter) => { filter.description = newFilter; onRefresh(); }
    const onFilterPersonnelGroupType = (newFilter?: ItemFilter) => { filter.personnelGroupType = newFilter; onRefresh(); }
    const onFilterPersonnel = (newFilter?: ItemFilter) => { filter.personnel = newFilter; onRefresh(); }
    const onFilterState = (newFilter?: StringFilter) => { filter.state = newFilter; onRefresh(); }
    const onFilterIsActive = (newFilter?: BooleanFilter) => { filter.isActive = newFilter; onRefresh(); }

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrievePersonnelGroupSearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsEditPersonnelGroupDialog service={service} show={!!editItem} personnelGroup={editItem} onComplete={onEditItemCompleted} />
            <OdsConfirmDeleteDialog show={!!removeItem} text={removeItem?.name} onComplete={onRemoveItemCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onAddItem={canEdit ? onAddItem : undefined} onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="Personnel Groups" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    {canEdit ? <th></th> : ''}
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Name" header="Name" filter={filter.name} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterName} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Description" header="Description" filter={filter.description} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDescription} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="PersonnelGroupType" header="Group&nbsp;Type" filter={filter.personnelGroupType} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterPersonnelGroupType} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Personnel" header="Personnel" filter={filter.personnel}
                                        onFilterChanged={onFilterPersonnel} onSearch={onFilterValueSearch} />
                                    {showState ? <OdsStringColumnHeader name="State" header="State" filter={filter.state} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterState} onSearch={onFilterValueSearch} /> : ''}
                                    <OdsBooleanColumnHeader name="IsActive" header="Is&nbsp;Active" filter={filter.isActive} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterIsActive} />
                                    {canEdit ? <th></th> : ''}
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    {canEdit ? ((states.filter(d => d === item.stateId).length > 0) ? <OdsEditItemColumn item={item} onEditItem={onEditItem} /> : <td></td>) : ''}
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.id}</OdsIntegerColumn>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td>{<OdsPersonnelGroupTypeChip item={item} id={item.personnelGroupTypeId} onFetchPersonnelGroupType={onFetchPersonnelGroupType} />}</td>
                                    <td>{item.personnelIds?.map(p => <span key={p}><OdsPersonnelChip item={item} id={p} onFetchPersonnel={onFetchPersonnel} /></span>)}</td>
                                    {showState ? <td><OdsStateLookup item={item} onFetchState={onFetchState} /></td> : ''}
                                    <OdsBooleanColumn value={item.isActive} />
                                    {canEdit ? ((states.filter(d => d === item.stateId).length > 0) ? <OdsRemoveItemColumn item={item} onRemoveItem={onRemoveItem} /> : <td></td>) : ''}
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
