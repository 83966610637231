import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";

export interface OdsConfirmDeleteDialogProps {
    show: boolean;
    text: React.ReactNode;
    onComplete: (dialogResult: boolean) => Promise<any>;
}

export const OdsConfirmDeleteDialog: React.FC<OdsConfirmDeleteDialogProps> = ({
    show,
    text,
    onComplete,
    ...props
}) => {
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onCancel = async (): Promise<any> => {
        if (onComplete) {
            await onComplete(false);
        }
    };

    const onSubmit = async (): Promise<any> => {
        if (onComplete) {
            setIsSaving(true);
            await onComplete(true);
            setIsSaving(false);
        }
    };

    useEffect(() => {
        setIsSaving(false);
    }, [show]);

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' scrollable={true} onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete {text}?</Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxSpinnerButton theme='warning' showSpinner={isSaving} onClick={onSubmit}>Delete</CvxSpinnerButton>
            </Modal.Footer>
        </Modal>
    );
};
