import React from "react";
import { BsCheckSquare, BsCheckSquareFill, BsSquare } from "react-icons/bs";

export interface OdsIsSelectedColumnProps {
    isSelected: boolean,
    onSelectedChanged: (isSelected: boolean) => void;
}

export const OdsIsSelectedColumn: React.FC<OdsIsSelectedColumnProps> = ({
    isSelected,
    onSelectedChanged,
    ...props
}) => {

    return (
        isSelected ? <span onClick={() => onSelectedChanged(false)}><BsCheckSquareFill /></span> : <span onClick={() => onSelectedChanged(true)}><BsSquare /></span>
    )
};

export default OdsIsSelectedColumn;
