import { DispatchForm } from "./dispatch-form";
import { MultiEditCommentField, MultiEditField } from "./multi-edit-field";
import { OrdInspectionRequest } from "./ord-inspection-request";
import { OrdInspectionRequestStatus } from "./ord-inspection-request-status";
import { OrdInspectionRequestTask } from "./ord-inspection-request-task";

export class MultiEditOrdInspectionRequest {

    public form?: MultiEditField<DispatchForm>;
    public task?: MultiEditField<OrdInspectionRequestTask>;
    public status?: MultiEditField<OrdInspectionRequestStatus>;

    public startDate?: MultiEditField<Date>;
    public dueDate?: MultiEditField<Date>;

    public masterId?: MultiEditField<string>;
    public requestId?: MultiEditField<string>;
    public userDefined1?: MultiEditField<string>;
    public userDefined2?: MultiEditField<string>;

    public comments?: MultiEditCommentField;


    public update(inspectionRequest: OrdInspectionRequest): OrdInspectionRequest {
        if (this.form && this.form.update) {
            inspectionRequest.form = this.form.value;
        }
        if (this.status && this.status.update) {
            inspectionRequest.status = this.status.value;
        }
        if (this.task && this.task.update) {
            inspectionRequest.task = this.task.value;
        }
        if(this.masterId && this.masterId.update) {
            inspectionRequest.masterId = this.masterId.value;
        }
        if(this.requestId && this.requestId.update) {
            inspectionRequest.requestId = this.requestId.value;
        }
        if(this.userDefined1 && this.userDefined1.update) {
            inspectionRequest.userDefined1 = this.userDefined1.value;
        }
        if(this.userDefined2 && this.userDefined2.update) {
            inspectionRequest.userDefined2 = this.userDefined2.value;
        }
        if (this.startDate && this.startDate.update) {
            inspectionRequest.startDate = this.startDate.value;
        }
        if (this.dueDate && this.dueDate.update) {
            inspectionRequest.dueDate = this.dueDate.value;
        }
        if(this.comments && this.comments.update) {
            if(this.comments.append) {
                inspectionRequest.comments = this.comments.value + " - " + inspectionRequest.comments;
            } else {
                inspectionRequest.comments = this.comments.value;
            }
        }

        return inspectionRequest
    }
}
