import { useState } from "react";
import { OdsBooleanColumn, OdsBooleanColumnHeader, OdsEditItemColumn, OdsIntegerColumn, OdsIntegerColumnHeader, OdsItemColumnHeader, OdsRemoveItemColumn, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsRemoveItemColumnHeader } from "../../../components/table-column-headers/remove-item-header/remove-item-header";
import { OdsUserRoleChip } from "../../../components/user-role-chip/user-role-chip";
import { OdsConfirmDeleteDialog } from "../../../dialogs";
import { OdsEditUserDialog } from "../../../dialogs/edit-user/edit-user.dialog";
import { usePagedListResult } from "../../../hooks/list-result-hook";
import { useMyself } from "../../../hooks/myself-hook";
import { BooleanFilter, IntegerFilter, User, UserFilter, StringFilter, ItemFilter } from "../../../models";
import { Permission } from "../../../models/permission";
import { CvxPage, CvxLayoutCenterColumn, CvxContentBlock, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import { OdsUserApiService, ViewHelper } from "../../../services";

export interface OdsUsersViewProps {
    service: OdsUserApiService;
}

export const OdsUsersView: React.FC<OdsUsersViewProps> = ({
    service,
}) => {

    const [canEdit, showState, states] = useMyself(service, Permission.EditPermissions);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: UserFilter) => {
        return service.RetrieveUserListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('LastName', 'Asc', new UserFilter(), onFetchData);

    const [editItem, setEditItem] = useState<User>();
    const [removeItem, setRemoveItem] = useState<User>();

    const onAddItem = () => setEditItem(new User());

    const onEditItem = (item: User) => setEditItem(item);

    const onEditItemCompleted = async (dialogResult: boolean, item?: User) => {
        if (dialogResult && item) {
            if (item.id) {
                await service.UpdateUserAsync(item);
            } else {
                await service.CreateUserAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    }

    const onRemoveItemConfirm = (item: User) => setRemoveItem(item);

    const onRemoveItem = async (item: User) => {
        await service.DeleteUserAsync(item);
    }

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await onRemoveItem(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    }

    const onExport = async () => {
        const blob = await service.ExportUserListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`user-${(new Date()).toLocaleDateString()}.csv`, blob)
    }

    const onFilterId = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); }
    const onFilterFirstName = (newFilter?: StringFilter) => { filter.firstName = newFilter; onRefresh(); }
    const onFilterLastName = (newFilter?: StringFilter) => { filter.lastName = newFilter; onRefresh(); }
    const onFilterEmail = (newFilter?: StringFilter) => { filter.email = newFilter; onRefresh(); }
    const onFilterRoles = (newFilter?: ItemFilter) => { filter.roles = newFilter; onRefresh(); }
    const onFilterIsActive = (newFilter?: BooleanFilter) => { filter.isActive = newFilter; onRefresh(); }

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveUserSearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsEditUserDialog service={service} show={!!editItem} user={editItem} onComplete={onEditItemCompleted} />
            <OdsConfirmDeleteDialog show={!!removeItem} text={`${removeItem?.firstName} ${removeItem?.lastName}`} onComplete={onRemoveItemCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onAddItem={onAddItem} onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="User" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    {canEdit ? <th></th> : ''}
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="FirstName" header="First&nbsp;Name" filter={filter.firstName} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterFirstName} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="LastName" header="Last&nbsp;Name" filter={filter.lastName} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterLastName} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Email" header="Email" filter={filter.email} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterEmail} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Roles" header="Roles" filter={filter.roles}
                                        onFilterChanged={onFilterRoles} onSearch={onFilterValueSearch} />
                                    <OdsBooleanColumnHeader name="IsActive" header="Is&nbsp;Active" filter={filter.isActive} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterIsActive} />
                                    {canEdit ? <OdsRemoveItemColumnHeader selectedItems={selectedItems} onRemoveItem={onRemoveItem} onRefresh={onRefresh}
                                        itemLabel={(item) => `${item.firstName} ${item.lastName} (${item.email})`} /> : ''}
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    {canEdit ? <OdsEditItemColumn item={item} onEditItem={onEditItem} /> : ''}
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.id}</OdsIntegerColumn>
                                    <td>{item.firstName}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.email}</td>
                                    <td>{item.roles.map(r => <OdsUserRoleChip userRole={r} service={service} />)}</td>
                                    <OdsBooleanColumn value={item.isActive} />
                                    {canEdit ? <OdsRemoveItemColumn item={item} onRemoveItem={onRemoveItemConfirm} /> : ''}
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
