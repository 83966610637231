import { Formik, FormikHelpers } from "formik";
import React, { ReactNode, useState } from "react";
import { Form, Modal } from "react-bootstrap"
import { ApiError } from "../../models/api-error";
import { CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";

export interface OdsBaseAddEditDialogProps<T> {
    title: React.ReactNode | string;
    validationSchema: any;
    initialValue: T;
    show: boolean;
    onComplete: (dialogResult: boolean, item: T | undefined) => Promise<any>;
    children?: ReactNode;
}

export interface OdsDialogProps {

};

const OdsBaseAddEditDialog = <T extends OdsDialogProps>({
    title,
    validationSchema,
    initialValue,
    show,
    onComplete,
    children,
    ...props
}: OdsBaseAddEditDialogProps<T>) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onSubmit = async (values: T, helpers: FormikHelpers<T>): Promise<any> => {
        if (onComplete) {
            setIsSaving(true);
            try {
                await onComplete(true, values);
            } catch (e) {
                if(e instanceof ApiError) {
                    if(e.error.status == 400) {
                        e.error.errors?.forEach(f => {
                            console.log(f);
                            helpers.setFieldError(camelCase(f.title), f.detail);
                        });
                    }
                    console.log(e.error);
                } else {
                }
            }
            setIsSaving(false);
        }
    };

    const onCancel = async (): Promise<any> => {
        setIsSaving(false);
        if (onComplete) {
            await onComplete(false, undefined);
        }
    };

    const camelCase = (str?: string) : string => {
        return (str ?? '').replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
          }).replace(/\s+/g, '');
    };

    return (
        <Modal className="theme-blue" size="lg" show={show} centered backdrop='static' scrollable={true} onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Formik validationSchema={validationSchema} onSubmit={onSubmit} initialValues={initialValue}>
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors,
                    ...props
                }) => (
                    <>
                        <Modal.Body>
                            <Form noValidate onSubmit={handleSubmit}>
                                {children}
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <CvxButton theme='accent' onClick={onCancel}>Cancel</CvxButton>
                            <CvxSpinnerButton theme='primary' disabled={!isValid} showSpinner={isSaving} onClick={() => handleSubmit()}>Save</CvxSpinnerButton>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default OdsBaseAddEditDialog;
