export class FormXmlData {
    public pnfRequestId?: number;
    public xml?: string;

    public static mapFormData(data: any): FormXmlData {
        const f = new FormXmlData();

        f.pnfRequestId = data.pnfRequestId;
        f.xml = data.xml;

        return f;
    };
};
