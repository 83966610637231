import { useEffect, useState } from "react";
import { AsyncTypeahead, StringPropertyNames, TokenProps, TypeaheadLabelKey, TypeaheadModel } from "react-bootstrap-typeahead";
import { ListResult } from "../../models/list-result";

export interface OdsAsyncMultiSelectControlProps<T extends TypeaheadModel> {
    id?: string | number;
    placeholder?: string;
    labelKey?: TypeaheadLabelKey<T>;
    multiple?: boolean;
    isInvalid?: boolean;
    selected?: T[];
    onSearch: (top: number, skip: number, filter: string) => Promise<ListResult<T>>;
    onChange?: (selected: T[]) => void;
    onMenuToggle?: (show: boolean) => void;
    onBlur?: (e: Event) => void;
    renderMenuItemChildren?: (item: T) => JSX.Element;
    renderToken?: (item: T, props: TokenProps, index: number) => React.ReactNode;
};

export const OdsAsyncMultiSelectControl = <T extends TypeaheadModel>({
    id,
    labelKey,
    placeholder,
    multiple,
    isInvalid,
    selected,
    onSearch,
    onChange,
    onMenuToggle,
    onBlur,
    renderMenuItemChildren,
    renderToken
}: OdsAsyncMultiSelectControlProps<T>) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<ListResult<T>>({ totalCount: 0, items: [] });

    const doSearch = async (filter: string) => {
        setIsLoading(true);
        setOptions(await onSearch(10, 0, filter));
        setIsLoading(false);
    };

    useEffect(() => {
        doSearch('');
    }, []);

    return (
        <AsyncTypeahead
            delay={500}
            id={id}
            labelKey={labelKey}
            isLoading={isLoading}
            placeholder={placeholder}
            ignoreDiacritics={true}
            options={options.items}
            useCache={false}
            selected={selected}
            emptyLabel="no items found"
            minLength={0}
            positionFixed={true}
            multiple={multiple}
            paginate={true}
            paginationText="additional results..."
            onSearch={doSearch}
            onChange={onChange}
            onMenuToggle={onMenuToggle}
            onBlur={onBlur}
            isInvalid={isInvalid}
            renderMenuItemChildren={renderMenuItemChildren}
            renderToken={renderToken}
        />
    );
};
