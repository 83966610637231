import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { DispatchForm } from "../../models/dispatch-form";
import { OdsApiServiceBase } from "../../services/ods-api-service-base";

export interface OdsDispatchFormChipProps {
    item: Item,
    service: OdsApiServiceBase
}

interface Item {
    formId: number,
    form?: DispatchForm,
}

export const OdsDispatchFormChip: React.FC<OdsDispatchFormChipProps> = ({
    item,
    service
}) => {
    const [data, setData] = useState<DispatchForm>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        if (item) {
            const data = await service.RetrieveDispatchFormAsync(item.formId);
            item.form = data;
            setData(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [item])

    return (
        <span className="ods-item-chip">{isLoading === false ? data?.name : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>}</span>
    )
}
