export class InspectionRequestDashboardItem {
    public inspectionRequestId?: string;
    public assetId?: string;
    public apiNum?: string;
    public assetClass?: string;
    public assetDescription?: string;
    public assetNumber?: string;
    public assetNum?: string;
    public assetStatus?: string;
    public comments?: string;
    public commentText?: string;
    public componentType?: string;
    public contextData?: string;
    public dispatchBody?: string;
    public dueDate?: Date;
    public equipmentId?: string;
    public equipmentType?: string;
    public facilityId?: string;
    public formName?: string;
    public inspectionType?: string;
    public lodId?: string;
    public masterId?: string;
    public requestBody?: string;
    public requestId?: string;
    public inspectionRequestStatus?: string;
    public sourceInspectionReference?: string;
    public sourceSubmissionNumber?: string;
    public sourceUserName?: string;
    public startDate?: Date;
    public subsiteId?: string;
    public subsiteName?: string;
    public taskDescription?: string;
    public taskId?: string;
    public userDefined1?: string;
    public userDefined2?: string;
    public wellId?: string;
    public workOrderNumber?: string;
    public lodName?: string;
    public wellName?: string;
    public parentEntityTypeName?: string;
    public formId?: string;
    public route?: string;
    public accessDirections?: string;
    public constentDecree?: string;
    public closeProximity?: string;
    public latitude?: string;
    public longitude?: string;
    public siteType?: string;
    public siteStatus?: string;
    public occupiedArea?: string;
    public referenceNumberList?: string;
    public userNameList?: string;
    public nblPnfRequestId?: string;
    public sentDate?: Date;
    public inspectionStatus?: string;
    public equipmentSerialNumber?: string;
    public regBy1?: string;
    public regBy2?: string;
    public make?: string;
    public mawp?: string;
    public manufacturedDate?: string;
    public surfaceCasingDepth?: string;
    public wellCasingStrings?: string;
    public createDate?: Date;
    public createUser?: string;
    public isActive?: boolean;

    public isSelected: boolean = false;

    public static mapInspectionRequestData(data: any): InspectionRequestDashboardItem {
        const d = new InspectionRequestDashboardItem();

        d.inspectionRequestId = data.inspectionRequestId;
        d.assetId = data.assetId;
        d.apiNum = data.apiNum;
        d.assetClass = data.assetClass;
        d.assetDescription = data.assetDescription;
        d.assetNumber = data.assetNumber;
        d.assetNum = data.assetNum;
        d.assetStatus = data.assetStatus;
        d.comments = data.comments;
        d.commentText = data.commentText;
        d.componentType = data.componentType;
        d.contextData = data.contextData;
        d.dispatchBody = data.dispatchBody;
        d.dueDate = data.dueDate ? new Date(data.dueDate) : undefined;
        d.equipmentId = data.equipmentId;
        d.equipmentType = data.equipmentType;
        d.facilityId = data.facilityId;
        d.formName = data.formName;
        d.inspectionType = data.inspectionType;
        d.lodId = data.lodId;
        d.masterId = data.masterId;
        d.requestBody = data.requestBody;
        d.requestId = data.requestId;
        d.inspectionRequestStatus = data.inspectionRequestStatus;
        d.sourceInspectionReference = data.sourceInspectionReference;
        d.sourceSubmissionNumber = data.sourceSubmissionNumber;
        d.sourceUserName = data.sourceUserName;
        d.startDate = data.startDate ? new Date(data.startDate) : undefined;
        d.subsiteId = data.subsiteId;
        d.subsiteName = data.subsiteName;
        d.taskDescription = data.taskDescription;
        d.taskId = data.taskId;
        d.userDefined1 = data.userDefined1;
        d.userDefined2 = data.userDefined2;
        d.wellId = data.wellId;
        d.workOrderNumber = data.workOrderNumber;
        d.lodName = data.lodName;
        d.wellName = data.wellName;
        d.parentEntityTypeName = data.parentEntityTypeName;
        d.formId = data.formId;
        d.route = data.route;
        d.accessDirections = data.accessDirections;
        d.constentDecree = data.constentDecree;
        d.closeProximity = data.closeProximity;
        d.latitude = data.latitude;
        d.longitude = data.longitude;
        d.siteType = data.siteType;
        d.siteStatus = data.siteStatus;
        d.occupiedArea = data.occupiedArea;
        d.referenceNumberList = data.referenceNumberList;
        d.userNameList = data.userNameList;
        d.nblPnfRequestId = data.nblPnfRequestId;
        d.sentDate = data.sentDate ? new Date(data.sentDate) : undefined;
        d.inspectionStatus = data.inspectionStatus;
        d.equipmentSerialNumber = data.equipmentSerialNumber;
        d.regBy1 = data.regBy1;
        d.regBy2 = data.regBy2;
        d.make = data.make;
        d.mawp = data.mawp;
        d.manufacturedDate = data.manufacturedDate;
        d.surfaceCasingDepth = data.surfaceCasingDepth;
        d.wellCasingStrings = data.wellCasingStrings;
        d.createDate = data.createDate ? new Date(data.createDate) : undefined;
        d.createUser = data.createUser;
        d.isActive = data.isActive;

        return d;
    };
};
