import { HtmlHTMLAttributes } from "react";
import { BsCheckCircleFill, BsXCircle } from "react-icons/bs";

export interface OdsBooleanColumnProps {
    value: boolean
}

export const OdsBooleanColumn: React.FC<OdsBooleanColumnProps & HtmlHTMLAttributes<HTMLTableCellElement>> = ({
    value,
    ...props
}) => {
    return (
        <td {...props} className="center-column">{(value ? <BsCheckCircleFill /> : <BsXCircle />)}</td>
    )
}
