export class LdarCompletedInspections {
    public inspectionRequestId?: number;
    public commentText?: string;
    public dueDate?: Date;
    public formName?: string;
    public lodId?: string;
    public inspectionType?: string;
    public inspectionRequestStatus?: string;
    public startDate?: Date;
    public lodName?: string;
    public formId?: string;
    public isActive?: boolean;

    public isSelected: boolean = false;

    public static mapData(data: any): LdarCompletedInspections {
        const d = new LdarCompletedInspections();

        d.inspectionRequestId = parseInt(data.inspectionRequestId);
        d.commentText = data.commentText;
        d.dueDate = data.dueDate ? new Date(data.dueDate) : undefined;
        d.formName = data.formName;
        d.lodId = data.lodId;
        d.inspectionType = data.inspectionType;
        d.inspectionRequestStatus = data.inspectionRequestStatus;
        d.startDate = data.startDate ? new Date(data.startDate) : undefined;
        d.lodName = data.lodName;
        d.formId = data.formId;
        d.isActive = data.isActive;

        return d;
    };
};
