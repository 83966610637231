export class ItemCache<TKey, TItem> {
    private items: Map<TKey, Promise<TItem>> = new Map<TKey, Promise<TItem>>();
    private timeouts: Map<TKey, any> = new Map<TKey, any>();

    private ttl = 10 * 60 * 1000;


    public get(key: TKey, lookupFunc: (key: TKey) => Promise<TItem>) {
        let item = this.items.get(key);
        if (item === undefined) {
            item = lookupFunc(key);
            this.items.set(key, item);
            this.timeouts.set(key, setTimeout(() => this.clear(key), this.ttl));
        }

        return item;
    }

    public set(key: TKey, value: TItem) {
        this.clear(key);
        this.get(key, async (k) => value);

        return value;
    }

    public clear(key: TKey) {
        if (this.timeouts.has(key)) {
            clearTimeout(this.timeouts.get(key))
        }

        this.timeouts.delete(key);
        this.items.delete(key);
    }
};
