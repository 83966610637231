import { FieldHookConfig, useField } from "formik";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { MultiEditCommentField } from "../../../models";

export interface OdsMultiCommentsProps {
    label: string;
}

export const OdsMultiComments: React.FC<OdsMultiCommentsProps & FieldHookConfig<MultiEditCommentField>> = ({
    label,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);

    const doValueChanged = (selected: any) => {
        helpers.setValue(new MultiEditCommentField(selected.target.value, field.value?.append ?? true, true), true);
    }

    const doAppendChanged = (update: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(new MultiEditCommentField(field.value?.value, !(field.value?.append ?? true), field.value?.update ?? false), true);
    }

    const doUpdateChanged = (update: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(new MultiEditCommentField(field.value?.value, field.value?.append ?? true, !(field.value?.update ?? true)), true);
    }

    return (
        <Row>
            <Col md="auto">
                <Form.Group controlId={`${field.name}Update`}>
                    <Form.Label>Update&nbsp;&nbsp;</Form.Label>
                    <Form.Check
                        name={`${field.name}Update`}
                        type="switch"
                        placeholder={`Update ${label}`}
                        checked={field.value?.update ?? false}
                        onChange={doUpdateChanged}
                        onBlur={field.onBlur}
                        isInvalid={!!meta.touched && !!meta.error}
                    />
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId={`${field.name}Append`}>
                    <Form.Label>Append</Form.Label>
                    <Form.Check
                        name={`${field.name}Append`}
                        type="switch"
                        placeholder={`Update ${label}`}
                        checked={field.value?.append ?? true}
                        onChange={doAppendChanged}
                        onBlur={field.onBlur}
                        isInvalid={!!meta.touched && !!meta.error}
                    />
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId={field.name}>
                    <Form.Label>{label}</Form.Label>
                    <Form.Control
                        name={field.name}
                        as="textarea"
                        placeholder={label}
                        value={field.value?.value}
                        onChange={doValueChanged}
                        onBlur={field.onBlur}
                        isInvalid={!!meta.touched && !!meta.error}
                        style={{ height: '86px' }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    )
};
