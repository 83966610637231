import React, { useEffect, useState } from "react";
import { PersonnelGroupType } from "../../models";

interface Item {
    personnelGroupType?: PersonnelGroupType;
}

export interface OdsPersonnelGroupTypeChipProps {
    id?: number,
    item: Item,
    onFetchPersonnelGroupType: (id: number) => Promise<PersonnelGroupType>;
}

export const OdsPersonnelGroupTypeChip: React.FC<OdsPersonnelGroupTypeChipProps> = ({
    id,
    item,
    onFetchPersonnelGroupType,
    ...props
}) => {
    const [data, setData] = useState<PersonnelGroupType>();

    const fetchData = async () => {
        if (id) {
            const data = await onFetchPersonnelGroupType(id);
            item.personnelGroupType = data;
            setData(data);
        }
    }

    useEffect(() => {
        fetchData();
    }, [id])

    return (
        <span className="ods-item-chip">{!data ? id : data.name}</span>
    )
}
