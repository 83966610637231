import { BooleanFilter, ItemFilter, ListResult, OrdInspectionRequest, OrdInspectionRequestFilter, OrdInspectionRequestStatusFilter, OrdInspectionRequestTaskFilter, Route, StringFilter } from "../models";
import { OrdInspectionRequestDispatch } from "../models/ord-inspection-request-dispatch";
import { OrdInspectionRequestDispatchFilter } from "../models/ord-inspection-request-dispatch-filter";
import { OrdInspectionRequestStatus } from "../models/ord-inspection-request-status";
import { OrdInspectionRequestTask } from "../models/ord-inspection-request-task";
import { ApiService } from "./api-service";
import { OdsApiServiceBase } from "./ods-api-service-base";

export class OdsOrdApiService extends OdsApiServiceBase {
    public constructor(apiService: ApiService | undefined) {
        super(apiService);
    }


    //*************************************************************************************************************************************
    //***************************************************   ORD INSPECTION REQUESTS   *****************************************************
    //*************************************************************************************************************************************
    public async RetrieveOrdInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestFilter): Promise<ListResult<OrdInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<OrdInspectionRequest>>('/api/v1.0/ord-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => OrdInspectionRequest.map(d)) };
    }

    public async RetrieveActiveOrdInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestFilter): Promise<ListResult<OrdInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = false;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<OrdInspectionRequest>>('/api/v1.0/ord-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => OrdInspectionRequest.map(d)) };
    }

    public async RetrieveNewOrdInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestFilter): Promise<ListResult<OrdInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isDispatched = new BooleanFilter();
        filter.isDispatched.equal = false;

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = false;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<OrdInspectionRequest>>('/api/v1.0/ord-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => OrdInspectionRequest.map(d)) };
    }

    public async RetrieveDispatchedOrdInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestFilter): Promise<ListResult<OrdInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isDispatched = new BooleanFilter();
        filter.isDispatched.equal = true;

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = false;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<OrdInspectionRequest>>('/api/v1.0/ord-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => OrdInspectionRequest.map(d)) };
    }

    public async RetrieveOnHoldOrdInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestFilter): Promise<ListResult<OrdInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = false;

        filter.status = new ItemFilter();
        filter.status.equal = ["On-Hold", "Awaiting External Services"];

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<OrdInspectionRequest>>('/api/v1.0/ord-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => OrdInspectionRequest.map(d)) };
    }

    public async RetrieveCompletedOrdInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestFilter): Promise<ListResult<OrdInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = true;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<OrdInspectionRequest>>('/api/v1.0/ord-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => OrdInspectionRequest.map(d)) };
    }

    public async RetrieveOrdInspectionRequestAsync(ordInspectionRequestId: number): Promise<OrdInspectionRequest> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<OrdInspectionRequest>(`/api/v1.0/ord-inspection-requests/${ordInspectionRequestId}`);

        return OrdInspectionRequest.map(data);
    }

    public async CreateOrdInspectionRequestAsync(inspectionRequest: OrdInspectionRequest): Promise<OrdInspectionRequest> {
        if (!this.apiService) {
            throw new Error();
        }

        inspectionRequest.formId = inspectionRequest.form?.id ?? inspectionRequest.formId;
        inspectionRequest.taskId = inspectionRequest.task?.id ?? inspectionRequest.taskId;
        inspectionRequest.statusId = inspectionRequest.status?.id ?? inspectionRequest.statusId;

        const data = await this.apiService.post<OrdInspectionRequest>(`/api/v1.0/ord-inspection-requests`, JSON.stringify(OrdInspectionRequest.map(inspectionRequest)));

        return OrdInspectionRequest.map(data);
    }

    public async UpdateOrdInspectionRequestAsync(inspectionRequest: OrdInspectionRequest): Promise<OrdInspectionRequest> {
        if (!this.apiService) {
            throw new Error();
        }

        inspectionRequest.formId = inspectionRequest.form?.id ?? inspectionRequest.formId;
        inspectionRequest.taskId = inspectionRequest.task?.id ?? inspectionRequest.taskId;
        inspectionRequest.statusId = inspectionRequest.status?.id ?? inspectionRequest.statusId;

        const data = await this.apiService.put<OrdInspectionRequest>(`/api/v1.0/ord-inspection-requests/${inspectionRequest.id}`, JSON.stringify(OrdInspectionRequest.map(inspectionRequest)));

        return OrdInspectionRequest.map(data);
    }

    public async DeleteOrdInspectionRequestAsync(ordInspectionRequest: OrdInspectionRequest): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/ord-inspection-requests/${ordInspectionRequest.id}`);
    }


    public async RetrieveOrdInspectionRequestSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/ord-inspection-requests/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportOrdInspectionRequestListAsync<T>(sort: string, sortDirection: string, filter: OrdInspectionRequestFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/ord-inspection-requests/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }

    public async DispatchOrdInspectionRequestAsync(ldarInspectionRequest: OrdInspectionRequest, users: string[]): Promise<OrdInspectionRequest> {
        if (!this.apiService) {
            throw new Error();
        }

        const body = {
            emailAddresses: users,
        };

        const data = await this.apiService.post<OrdInspectionRequest>(`/api/v1.0/ord-inspection-requests/${ldarInspectionRequest.id}/dispatch`, JSON.stringify(body));

        return OrdInspectionRequest.map(data);
    }


    //*************************************************************************************************************************************
    //***********************************************   ORD INSPECTION REQUEST DISPATCHES   ***********************************************
    //*************************************************************************************************************************************

    public async RetrieveOrdInspectionRequestDispatchListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestDispatchFilter): Promise<ListResult<OrdInspectionRequestDispatch>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<OrdInspectionRequestDispatch>>('/api/v1.0/ord-inspection-request-dispatches/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => OrdInspectionRequestDispatch.map(d)) };
    }

    public async RetrieveOrdInspectionRequestDispatchSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/ord-inspection-request-dispatches/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async DeleteOrdInspectionRequestDispatchAsync(dispatch: OrdInspectionRequestDispatch): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/ord-inspection-request-dispatches/${dispatch.id}/${dispatch.personnelId}`);
    }

    public async ExportOrdInspectionRequestDispatchListAsync<T>(sort: string, sortDirection: string, filter: OrdInspectionRequestDispatchFilter): Promise<Blob> {

        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/ord-inspection-requests-dispatches/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));

    }







    //*************************************************************************************************************************************
    //***********************************************   ORD INSPECTION REQUEST STATUSES   *************************************************
    //*************************************************************************************************************************************

    private _statuses: Map<number, Promise<OrdInspectionRequestStatus>> = new Map<number, Promise<OrdInspectionRequestStatus>>();

    public async RetrieveOrdInspectionRequestStatusListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestStatusFilter): Promise<ListResult<OrdInspectionRequestStatus>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<OrdInspectionRequestStatus>>('/api/v1.0/ord-inspection-request-statuses/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => OrdInspectionRequestStatus.map(d)) };
    }

    public async RetrieveOrdInspectionRequestStatusAsync(statusId: number): Promise<OrdInspectionRequestStatus> {
        if (!this.apiService) {
            throw new Error();
        }

        let data = this._statuses.get(statusId);
        if (!data) {
            data = this.apiService.get<OrdInspectionRequestStatus>(`/api/v1.0/ord-inspection-request-statuses/${statusId}`)
                .then(d =>
                    OrdInspectionRequestStatus.map(d)
                );

            this._statuses.set(statusId, data);
        }

        return data;
    }


    //*************************************************************************************************************************************
    //*************************************************   ORD INSPECTION REQUEST TASKS   **************************************************
    //*************************************************************************************************************************************

    private _tasks: Map<number, Promise<OrdInspectionRequestTask>> = new Map<number, Promise<OrdInspectionRequestTask>>();

    public async RetrieveOrdInspectionRequestTaskListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestTaskFilter): Promise<ListResult<OrdInspectionRequestStatus>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<OrdInspectionRequestTask>>('/api/v1.0/ord-inspection-request-tasks/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => OrdInspectionRequestTask.map(d)) };
    }

    public async RetrieveOrdInspectionRequestTaskAsync(taskId: number): Promise<OrdInspectionRequestTask> {
        if (!this.apiService) {
            throw new Error();
        }

        let data = this._tasks.get(taskId);
        if (!data) {
            data = this.apiService.get<OrdInspectionRequestTask>(`/api/v1.0/ord-inspection-request-tasks/${taskId}`)
                .then(d =>
                    OrdInspectionRequestTask.map(d)
                );

            this._tasks.set(taskId, data);
        }

        return data;
    }

    public async RetrieveOrdInspectionRequestTaskByNameAsync(taskName: string): Promise<OrdInspectionRequestTask> {
        if (!this.apiService) {
            throw new Error();
        }

        let filter = new OrdInspectionRequestTaskFilter();
        filter.name = new StringFilter();
        filter.name.equal = [taskName];

        const params = `?top=${1}&skip=${0}`;

        const data = await this.apiService.post<ListResult<OrdInspectionRequestTask>>('/api/v1.0/ord-inspection-request-tasks/search' + params, JSON.stringify(filter));

        return data.items?.map(d => OrdInspectionRequestTask.map(d))[0];
    }
}
