import { useEffect, useState } from "react";
import OdsBaseTable2 from "../../../components/base-table copy/base-table";
import OdsBradenheadDispatchFilter from "../../../components/bradenhead-dispatch-filter/bradenhead-dispatch-filter";
import OdsMultiDeleteItemDialog from "../../../dialogs/multi-delete-item/multi-delete-item.dialog";
import OdsRemoveDispatchDialog from "../../../dialogs/remove-dispatch-dialog/remove-dispatch-dialog";
import { BradenheadDispatch } from "../../../models/bradenhead-dispatch";
import { FilterableData } from "../../../models/filterable-data";
import { ReportMonth } from "../../../models/report-month";
import { ColumnDefinition, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import CvxContentBlock from "../../../modules/cvx-ui-module/components/content-block/content-block";
import { DateHelper } from "../../../services/date-helper";
import OdsService from "../../../services/ods-service";


export interface OdsBradenheadDispatchesViewProps {
    odsService: OdsService | undefined;
}


const OdsBradenheadDispatchesView: React.FC<OdsBradenheadDispatchesViewProps> = ({
    odsService,
    ...props
}) => {
    const columns: ColumnDefinition<BradenheadDispatch>[] = [
        {
            header: <span>API No</span>, align: 'left', canSort: true, canFilter: true,
            template: ((i) => i.apiNo), sortFilterValue: ((i) => i.apiNo)
        },
        {
            header: <span>Reference Number</span>, align: 'left', noWrap: true, canSort: true, canFilter: true,
            template: ((i) => i.referenceNumber), sortFilterValue: ((i) => i.referenceNumber)
        },
        {
            header: <span>Dispatch Id</span>, align: 'center', canSort: true, canFilter: true,
            template: ((i) => i.formDispatchId), sortFilterValue: ((i) => i.formDispatchId)
        },
        {
            header: <span>Well Id</span>, align: 'center', canSort: true, canFilter: true,
            template: ((i) => i.wellId), sortFilterValue: ((i) => i.wellId)
        },
        {
            header: <span>Well Name</span>, align: 'left', noWrap: true, canSort: true, canFilter: true,
            template: ((i) => i.wellName), sortFilterValue: ((i) => i.wellName)
        },
        {
            header: <span>Well Category</span>, align: 'left', noWrap: true, canSort: true, canFilter: true,
            template: ((i) => i.wellCategory), sortFilterValue: ((i) => i.wellCategory)
        },
        {
            header: <span>Route</span>, align: 'left', noWrap: true, canSort: true, canFilter: true,
            template: ((i) => i.routeName), sortFilterValue: ((i) => i.routeName)
        },
        {
            header: <span>User Name</span>, align: 'left', noWrap: true, canSort: true, canFilter: true,
            template: ((i) => i.userName), sortFilterValue: ((i) => i.userName)
        },
        {
            header: <span>Form Name</span>, align: 'left', noWrap: true, maxWidth: 250, canSort: true, canFilter: true,
            template: ((i) => i.formName), sortFilterValue: ((i) => i.formName)
        },
        {
            header: <span>Created</span>, align: 'center', canSort: true, canFilter: true,
            template: ((i) => i.creationDateTimeUtc?.toLocaleDateString() + ' ' + i.creationDateTimeUtc?.toLocaleTimeString()), sortFilterValue: ((i) => DateHelper.toSortString(i.creationDateTimeUtc))
        },
        {
            header: <span>Last Updated</span>, align: 'center', canSort: true, canFilter: true,
            template: ((i) => i.updateDateTimeUtc?.toLocaleDateString() + ' ' + i.updateDateTimeUtc?.toLocaleTimeString()), sortFilterValue: ((i) => DateHelper.toSortString(i.updateDateTimeUtc))
        },
    ];

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dispatches, setDispatches] = useState<FilterableData<BradenheadDispatch>>(new FilterableData<BradenheadDispatch>([]));

    const [reportMonths, setReportMonths] = useState<ReportMonth[]>([]);
    const [selectedReportMonthId, setSelectedReportMonthId] = useState<number>(0);

    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [deleteDispatch, setDeleteDispatch] = useState<BradenheadDispatch | undefined>(undefined);

    const [showMultiDelete, setShowMultiDelete] = useState<boolean>(false);

    const [tableColumns, setTableColumns] = useState<ColumnDefinition<BradenheadDispatch>[]>(columns);

    const fetchReportMonths = async () => {
        if (odsService) {
            const rm = await odsService.RetrieveBradenheadReportMonthsAsync()
            setReportMonths(rm);
            setSelectedReportMonthId(rm[0]?.id ?? 0);
        }
    };

    const fetchDispatches = async () => {
        if (odsService && selectedReportMonthId > 0) {
            setIsLoading(true);
            setDispatches(new FilterableData<BradenheadDispatch>([]));

            const data = await odsService.RetrieveBradenheadDispatchesAsync(selectedReportMonthId);

            setDispatches(new FilterableData<BradenheadDispatch>(data));
            setIsLoading(false);
        }
    };

    const clearFilters = () => {
        const updatedColumns: ColumnDefinition<BradenheadDispatch>[] = [];
        columns.forEach(c => {
            c.filterValues = [];
            updatedColumns.push(c);
        });

        setTableColumns(updatedColumns);
    };

    const onShowRemoveDispatch = (item: BradenheadDispatch) => {
        setDeleteDispatch(item);
        setShowDelete(true);
    };

    const onRemoveDispatch = async (dialogResult: boolean) => {
        if (odsService && dialogResult && deleteDispatch) {
            await odsService.RemoveBradenheadDispatchAsync(deleteDispatch.formDispatchId);
            fetchDispatches();
        }

        setShowDelete(false);
    };

    const onMultiDeleteItem = () => {
        setShowMultiDelete(true);
    }

    const onMultiDeleteItemComplete = async (dialogResult: boolean) => {
        if (dialogResult && odsService) {
            for (const data of dispatches.selectedData) {
                await odsService.RemoveBradenheadDispatchAsync(data.formDispatchId);
            }

            fetchDispatches();
        }

        setShowMultiDelete(false);
    }

    const onSelectMonth = (reportMonth: number) => {
        setSelectedReportMonthId(reportMonth);
    }

    useEffect(() => {
        fetchReportMonths();
    }, [odsService]);

    useEffect(() => {
        fetchDispatches();
    }, [selectedReportMonthId]);

    return (
        <CvxPage desktop={
            <CvxLayoutCenterColumn>
                <OdsRemoveDispatchDialog
                    show={showDelete}
                    dispatchId={deleteDispatch?.formDispatchId}
                    onComplete={onRemoveDispatch}
                />
                <OdsMultiDeleteItemDialog
                    header="Dispatches"
                    show={showMultiDelete}
                    data={dispatches.selectedData}
                    columns={columns}
                    onComplete={onMultiDeleteItemComplete}
                />
                <OdsBradenheadDispatchFilter
                    reportMonths={reportMonths}
                    selectedReportMonthId={selectedReportMonthId}
                    onSelectMonth={onSelectMonth}
                />
                <CvxTableContentBlock>
                    <OdsBaseTable2
                        heading={<span>Bradenhead Dispatches</span>}
                        columns={tableColumns}
                        dataSource={dispatches}
                        isLoading={isLoading}
                        canRemove={true}
                        onRemove={onShowRemoveDispatch}
                        onBulkDeleteItem={onMultiDeleteItem}
                    />
                </CvxTableContentBlock>
            </CvxLayoutCenterColumn>
        } />
    );
};

export default OdsBradenheadDispatchesView;
