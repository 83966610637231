import { useState } from "react";
import { OdsDateColumn, OdsDateColumnHeader, OdsDateTimeColumn, OdsEditItemColumn, OdsIntegerColumn, OdsIntegerColumnHeader, OdsItemColumnHeader, OdsPersonnelChip, OdsRemoveItemColumn, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsDispatchFormChip } from "../../../components/dispatch-form-chip/dispatch-form-chip";
import { OdsOrdStatusChip } from "../../../components/ord-status-chip/ord-status-chip";
import { OdsOrdTaskChip } from "../../../components/ord-task-chip/ord-task-chip";
import { OdsMultiEditColumnHeader } from "../../../components/table-column-headers/multi-edit-column-header/multi-edit-column-header";
import { OdsConfirmDeleteDialog, OdsEditOrdInspectionRequestDialog, OdsMultiEditOrdInspectionRequestDialog } from "../../../dialogs";
import { useMyself, usePagedListResult } from "../../../hooks";
import { DateFilter, IntegerFilter, ItemFilter, MultiEditOrdInspectionRequest, OrdInspectionRequest, OrdInspectionRequestFilter, Permission, StringFilter } from "../../../models";
import { CvxContentBlock, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import { OdsOrdApiService, OdsPersonnelApiService, ViewHelper } from "../../../services";

export interface OdsOrdCompletedInspectionRequestsViewProps {
    service: OdsOrdApiService;
    personnelService: OdsPersonnelApiService;
}

export const OdsOrdCompletedInspectionRequestsView: React.FC<OdsOrdCompletedInspectionRequestsViewProps> = ({
    service,
    personnelService,
}) => {
    const [canEdit, showState, states] = useMyself(service, Permission.EditORDInspectionRequests);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestFilter) => {
        return service.RetrieveCompletedOrdInspectionRequestListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('Id', 'Desc', new OrdInspectionRequestFilter(), onFetchData);

    const onFetchPersonnel = (item: any, id: number) => personnelService.RetrievePersonnelAsync(id);


    const [editItem, setEditItem] = useState<OrdInspectionRequest>();
    const [showMultiEdit, setShowMultiEdit] = useState(false);
    const [removeItem, setRemoveItem] = useState<OrdInspectionRequest>();


    const onAddItem = () => setEditItem(new OrdInspectionRequest());

    const onEditItem = (item: OrdInspectionRequest) => setEditItem(item);

    const onEditItemCompleted = async (dialogResult: boolean, item?: OrdInspectionRequest) => {
        if (dialogResult && item) {
            if (item.id) {
                await service.UpdateOrdInspectionRequestAsync(item);
            } else {
                await service.CreateOrdInspectionRequestAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    }

    const onMultiEditItems = () => setShowMultiEdit(true);

    const onMultiEditCompleted = async (dialogResult: boolean, item?: MultiEditOrdInspectionRequest) => {
        if (dialogResult && item) {
            for (let inspectionRequest of selectedItems) {
                await service.UpdateOrdInspectionRequestAsync(item.update(inspectionRequest))
            }
            onRefresh();
        }

        setShowMultiEdit(false);
    }

    const onRemoveItem = (item: OrdInspectionRequest) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await service.DeleteOrdInspectionRequestAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    }

    const onExport = async () => {
        const blob = await service.ExportOrdInspectionRequestListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`ord-inspection-requests-${(new Date()).toLocaleDateString()}.csv`, blob)
    }


    const onFilterId = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); }
    const onFilterAssetNumber = (newFilter?: StringFilter) => { filter.assetNumber = newFilter; onRefresh(); }
    const onFilterAssetDescription = (newFilter?: StringFilter) => { filter.assetDescription = newFilter; onRefresh(); }
    const onFilterForm = (newFilter?: StringFilter) => { filter.form = newFilter; onRefresh(); }
    const onFilterComments = (newFilter?: StringFilter) => { filter.comments = newFilter; onRefresh(); }
    const onFilterSourceSubmissionNumber = (newFilter?: StringFilter) => { filter.sourceSubmissionNumber = newFilter; onRefresh(); }
    const onFilterUserDefined1 = (newFilter?: StringFilter) => { filter.userDefined1 = newFilter; onRefresh(); }
    const onFilterUserDefined2 = (newFilter?: StringFilter) => { filter.userDefined2 = newFilter; onRefresh(); }
    const onFilterStatus = (newFilter?: StringFilter) => { filter.status = newFilter; onRefresh(); }
    const onFilterTask = (newFilter?: StringFilter) => { filter.task = newFilter; onRefresh(); }
    const onFilterRoute = (newFilter?: ItemFilter) => { filter.route = newFilter; onRefresh(); }
    const onFilterMasterId = (newFilter?: StringFilter) => { filter.masterId = newFilter; onRefresh(); }
    const onFilterRequestId = (newFilter?: StringFilter) => { filter.requestId = newFilter; onRefresh(); }
    const onFilterStartDate = (newFilter?: DateFilter) => { filter.startDate = newFilter; onRefresh(); }
    const onFilterDueDate = (newFilter?: DateFilter) => { filter.dueDate = newFilter; onRefresh(); }
    const onFilterDispatchDate = (newFilter?: DateFilter) => { filter.dispatchDate = newFilter; onRefresh(); };
    const onFilterDispatchUser = (newFilter?: ItemFilter) => { filter.dispatchUser = newFilter; onRefresh(); };
    const onFilterFormSubmissionNumber = (newFilter?: StringFilter) => { filter.formSubmissionNumber = newFilter; onRefresh(); };
    const onFilterFormSubmissionDate = (newFilter?: DateFilter) => { filter.formSubmissionDate = newFilter; onRefresh(); };

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveOrdInspectionRequestSearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsEditOrdInspectionRequestDialog service={service} show={!!editItem} inspection={editItem} onComplete={onEditItemCompleted} />
            <OdsMultiEditOrdInspectionRequestDialog service={service} show={showMultiEdit} inspectionRequests={selectedItems} onComplete={onMultiEditCompleted} />
            <OdsConfirmDeleteDialog show={!!removeItem} text={`${removeItem?.id}`} onComplete={onRemoveItemCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onAddItem={onAddItem} onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="Completed ORD Inspections" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    {canEdit ? <OdsMultiEditColumnHeader selectedItems={selectedItems} onClick={onMultiEditItems} /> : ''}
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="AssetNumber" header="Asset Number" filter={filter.assetNumber} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterAssetNumber} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="AssetDescription" header="Asset Description" filter={filter.assetDescription} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterAssetDescription} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Form" header="Form" filter={filter.form} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterForm} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Task" header="Task" filter={filter.task} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterTask} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Status" header="Status" filter={filter.status} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterStatus} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Route" header="Route" filter={filter.route} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterRoute} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="StartDate" header="Start Date" filter={filter.startDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterStartDate} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="DueDate" header="Due Date" filter={filter.dueDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDueDate} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="DispatchDate" header="Dispatch Date" filter={filter.dispatchDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDispatchDate} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="DispatchUser" header="Dispatch Users" filter={filter.dispatchUser}
                                        onFilterChanged={onFilterDispatchUser} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="FormSubmissionNumber" header="Submission Number" filter={filter.formSubmissionNumber}
                                        onFilterChanged={onFilterFormSubmissionNumber} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="FormSubmissionDate" header="Submission Date" filter={filter.formSubmissionDate}
                                        onFilterChanged={onFilterFormSubmissionDate} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="SourceSubmissionNumber" header="Source Submission Number" filter={filter.sourceSubmissionNumber} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterSourceSubmissionNumber} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Comments" header="Comments" filter={filter.comments} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterComments} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="RequestId" header="Request Id" filter={filter.requestId} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterRequestId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="MasterId" header="Master Id" filter={filter.masterId} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterMasterId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="UserDefined1" header="User Defined 1" filter={filter.userDefined1} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterUserDefined1} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="UserDefined2" header="User Defined 2" filter={filter.userDefined2} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterUserDefined2} onSearch={onFilterValueSearch} />
                                    {canEdit ? <th></th> : ''}
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    {canEdit ? <OdsEditItemColumn item={item} onEditItem={onEditItem} /> : ''}
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn >{item.id}</OdsIntegerColumn>
                                    <OdsStringColumn value={item.assetNumber} />
                                    <OdsStringColumn value={item.assetDescription} />
                                    <td><OdsDispatchFormChip item={item} service={service} /></td>
                                    <td><OdsOrdTaskChip inspectionRequest={item} service={service} /></td>
                                    <td><OdsOrdStatusChip inspectionRequest={item} service={service} /></td>
                                    <td>{item.routeId}</td>
                                    <OdsDateColumn value={item.startDate} />
                                    <OdsDateColumn value={item.dueDate} />
                                    <OdsDateTimeColumn value={item.dispatchDate} />
                                    <td>{item.dispatchUserIds.map(d => <OdsPersonnelChip id={d} item={item} onFetchPersonnel={onFetchPersonnel} />)}</td>
                                    <OdsStringColumn value={item.formSubmissionNumber} />
                                    <OdsDateTimeColumn value={item.formSubmissionDate} />
                                    <OdsStringColumn value={item.sourceSubmissionNumber} />
                                    <OdsStringColumn tooltip={item.comments} maxWidth={150} noWrap>{item.comments}</OdsStringColumn>
                                    <OdsStringColumn value={item.requestId} />
                                    <OdsStringColumn value={item.masterId} />
                                    <OdsStringColumn value={item.userDefined1} />
                                    <OdsStringColumn value={item.userDefined2} />
                                    {canEdit ? <OdsRemoveItemColumn item={item} onRemoveItem={onRemoveItem} /> : ''}
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
