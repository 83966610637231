import { AvoVocFacilityInspectionFrequency, AvoVocFacilityInspectionFrequencyFilter, AvoVocInspectionRequestFilter, AvoVocInspectionRequest, Facility, FacilityFilter, ListResult, BooleanFilter } from "../models";
import { ApiService } from "./api-service";
import { OdsApiServiceBase } from "./ods-api-service-base";


export class OdsAvoVocApiService extends OdsApiServiceBase {
    public constructor(apiService: ApiService | undefined) {
        super(apiService);
    }


    //*************************************************************************************************************************************
    //************************************************   AVO/VOC INSPECTION FREQUENCY  ****************************************************
    //*************************************************************************************************************************************

    public async RetrieveAvoVocFacilityInspectionFrequencyListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: AvoVocFacilityInspectionFrequencyFilter): Promise<ListResult<AvoVocFacilityInspectionFrequency>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<AvoVocFacilityInspectionFrequency>>('/api/v1.0/avo-voc-facility-inspection-frequencies/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => AvoVocFacilityInspectionFrequency.map(d)) };
    }

    public async RetrieveAvoVocFacilityInspectionFrequencyAsync(facilityId: number): Promise<AvoVocFacilityInspectionFrequency> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<AvoVocFacilityInspectionFrequency>(`/api/v1.0/avo-voc-facility-inspection-frequencies/${facilityId}`);

        return AvoVocFacilityInspectionFrequency.map(data);
    }

    public async CreateAvoVocFacilityInspectionFrequencyAsync(avoVocFacilityInspectionFrequency: AvoVocFacilityInspectionFrequency): Promise<AvoVocFacilityInspectionFrequency> {
        if (!this.apiService) {
            throw new Error();
        }

        let json = {
            facilityId: avoVocFacilityInspectionFrequency.facility?.id,
            avoInspectionFrequency: avoVocFacilityInspectionFrequency.avoInspectionFrequency,
            vocInspectionFrequency: avoVocFacilityInspectionFrequency.vocInspectionFrequency,
        }

        const data = await this.apiService.post<AvoVocFacilityInspectionFrequency>(`/api/v1.0/avo-voc-facility-inspection-frequencies`, JSON.stringify(json));

        return AvoVocFacilityInspectionFrequency.map(data);
    }

    public async UpdateAvoVocFacilityInspectionFrequencyAsync(avoVocFacilityInspectionFrequency: AvoVocFacilityInspectionFrequency): Promise<AvoVocFacilityInspectionFrequency> {
        if (!this.apiService) {
            throw new Error();
        }

        let json = {
            avoInspectionFrequency: avoVocFacilityInspectionFrequency.avoInspectionFrequency,
            vocInspectionFrequency: avoVocFacilityInspectionFrequency.vocInspectionFrequency
        }

        const data = await this.apiService.put<AvoVocFacilityInspectionFrequency>(`/api/v1.0/avo-voc-facility-inspection-frequencies/${avoVocFacilityInspectionFrequency.id}`, JSON.stringify(json));

        return AvoVocFacilityInspectionFrequency.map(data);
    }

    public async DeleteAvoVocFacilityInspectionFrequencyAsync(avoVocFacilityInspectionFrequency: AvoVocFacilityInspectionFrequency): Promise<void> {
        if (!this.apiService) {
            throw new Error();
        }

        await this.apiService.delete(`/api/v1.0/avo-voc-facility-inspection-frequencies/${avoVocFacilityInspectionFrequency.id}`);
    }

    public async RetrieveAvoVocFacilityInspectionFrequencySearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/avo-voc-facility-inspection-frequencies/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportAvoVocFacilityInspectionFrequencyListAsync<T>(sort: string, sortDirection: string, filter: AvoVocFacilityInspectionFrequencyFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/avo-voc-facility-inspection-frequencies/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }

    public async RetrieveAvoVocFacilityInspectionFrequencyAvailableFacilityValuesAsync(top: number, skip: number, sort: string, sortDirection: string, filter: FacilityFilter): Promise<ListResult<Facility>> {
        if (!this.apiService) {
            throw new Error();
        }

        let data: ListResult<AvoVocFacilityInspectionFrequency>;
        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        data = await this.apiService.post<ListResult<AvoVocFacilityInspectionFrequency>>('/api/v1.0/avo-voc-facility-inspection-frequencies/available-facilities/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => Facility.map(d)) };
    }



    //*************************************************************************************************************************************
    //*************************************************   AVO/VOC INSPECTION REQUESTS  ****************************************************
    //*************************************************************************************************************************************

    public async RetrieveAvoVocInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: AvoVocInspectionRequestFilter): Promise<ListResult<AvoVocInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<AvoVocInspectionRequest>>('/api/v1.0/avo-voc-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => AvoVocInspectionRequest.map(d)) };
    }

    public async RetrieveActiveAvoVocInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: AvoVocInspectionRequestFilter): Promise<ListResult<AvoVocInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = false;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<AvoVocInspectionRequest>>('/api/v1.0/avo-voc-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => AvoVocInspectionRequest.map(d)) };
    }

    public async RetrieveUndispatchedAvoVocInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: AvoVocInspectionRequestFilter): Promise<ListResult<AvoVocInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isDispatched = new BooleanFilter();
        filter.isDispatched.equal = false;

        filter.isActive = new BooleanFilter();
        filter.isActive.equal = true;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<AvoVocInspectionRequest>>('/api/v1.0/avo-voc-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => AvoVocInspectionRequest.map(d)) };
    }

    public async RetrieveCompletedAvoVocInspectionRequestListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: AvoVocInspectionRequestFilter): Promise<ListResult<AvoVocInspectionRequest>> {
        if (!this.apiService) {
            throw new Error();
        }

        filter.isCompleted = new BooleanFilter();
        filter.isCompleted.equal = true;

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;
        const data = await this.apiService.post<ListResult<AvoVocInspectionRequest>>('/api/v1.0/avo-voc-inspection-requests/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => AvoVocInspectionRequest.map(d)) };
    }

    public async RetrieveAvoVocInspectionRequestAsync(inspectionId: number): Promise<AvoVocInspectionRequest> {
        if (!this.apiService) {
            throw new Error();
        }

        const data = await this.apiService.get<AvoVocInspectionRequest>(`/api/v1.0/avo-voc-inspection-requests/${inspectionId}`);

        return AvoVocInspectionRequest.map(data);
    }

    public async RetrieveAvoVocInspectionRequestSearchListAsync(columnName: string, top: number, skip: number, filter: string): Promise<ListResult<string>> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.get<ListResult<string>>(`/api/v1.0/avo-voc-inspection-requests/values/${columnName}?top=${top}&skip=${skip}&filter=${filter}`);
    }

    public async ExportAvoVocInspectionRequestListAsync<T>(sort: string, sortDirection: string, filter: AvoVocInspectionRequestFilter): Promise<Blob> {
        if (!this.apiService) {
            throw new Error();
        }

        return await this.apiService.download(`/api/v1.0/avo-voc-inspection-requests/export?sort=${sort}&sortDirection=${sortDirection}`, JSON.stringify(filter));
    }

    public async DispatchAvoVocInspectionRequestAsync(inspectionRequest: AvoVocInspectionRequest, users: string[]): Promise<AvoVocInspectionRequest> {
        if (!this.apiService) {
            throw new Error();
        }

        const body = {
            emailAddresses: users,
        };

        const data = await this.apiService.post<AvoVocInspectionRequest>(`/api/v1.0/avo-voc-inspection-requests/${inspectionRequest.id}/dispatch`, JSON.stringify(body));

        return AvoVocInspectionRequest.map(data);
    }
}
