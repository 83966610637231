import { State } from "./state";

export class Personnel {
    public id: number = 0;
    public fullName: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public aadId: string = '';
    public stateId: number = 0;
    public isActive: boolean = true;

    public state?: State;

    public static map(data: any): Personnel {
        const m = new Personnel;

        m.id = data.id;
        m.fullName = data.fullName;
        m.firstName = data.firstName;
        m.lastName = data.lastName;
        m.email = data.email;
        m.aadId = data.aadId;
        m.stateId = parseInt(data.stateId);
        m.isActive = data.isActive;

        return m;
    };
};
