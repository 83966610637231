export class Lod {
    public id: string = '';
    public name: string = '';
    public routeId?: number;
    public accessDirections?: string;
    public isConsentDecree?: boolean;
    public isOccupiedArea?: boolean;
    public isDisproportionatelyImpactedCommunity?: boolean;
    public isOOOOa?: boolean;
    public isOOOOb?: boolean;

    public static map(data: any): Lod {
        const m = new Lod;

        m.id = data.id;
        m.name = data.name;
        m.routeId = data.routeId;
        m.accessDirections = data.accessDirections;
        m.isConsentDecree = data.isConsentDecree;
        m.isOccupiedArea = data.isOccupiedArea;
        m.isDisproportionatelyImpactedCommunity = data.isDisproportionatelyImpactedCommunity;
        m.isOOOOa = data.isOOOOa;
        m.isOOOOb = data.isOOOOb;

        return m;
    };
};
