import * as yup from 'yup';
import { OdsAsyncSelect } from '../../components';
import OdsDate from "../../components/form-editors/date/date";
import OdsInput from "../../components/form-editors/input/input";
import OdsTextArea from "../../components/form-editors/textarea/textarea";
import OdsOrdDispatchFilter from '../../components/ord-dispatch-filter/ord-dispatch-filter';
import { DispatchFormFilter, ItemFilter, OrdInspectionRequest, OrdInspectionRequestStatusFilter, OrdInspectionRequestTaskFilter, StringFilter } from "../../models";
import { OdsOrdApiService } from "../../services";
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";


export interface OdsEditOrdInspectionRequestDialogProps {
    service: OdsOrdApiService;
    show: boolean;
    inspection: OrdInspectionRequest | undefined;
    onComplete: (dialogResult: boolean, inspection: OrdInspectionRequest | undefined) => Promise<any>;
}

export const OdsEditOrdInspectionRequestDialog: React.FC<OdsEditOrdInspectionRequestDialogProps> = ({
    service,
    show,
    inspection,
    onComplete,
    ...props
}) => {
    const schema = yup.object().shape({
        form: yup.object().nullable().required(),
        assetNumber: yup.string().required(),
        assetDescription: yup.string().nullable(),
        task: yup.object().nullable(),
        status: yup.object().nullable().required(),
        startDate: yup.date().nullable().required(),
        dueDate: yup.date().nullable().required(),
        userDefined1: yup.string().nullable().max(150),
        userDefined2: yup.string().nullable().max(150),
        masterId: yup.string().nullable().max(150),
        requestId: yup.string().nullable().max(150),
        comments: yup.string().nullable(),
    });

    const onSearchForm = async (top: number, skip: number, filterStr: string) => {
        const filter = new DispatchFormFilter();

        filter.parent = new ItemFilter();
        filter.parent.equal = ["FAM_FORMS", "ORD_FORMS"];

        filter.name = new StringFilter();
        filter.name.like = filterStr;

        let data = await service.RetrieveDispatchFormListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    const onSearchTask = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new OrdInspectionRequestTaskFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        let data = await service.RetrieveOrdInspectionRequestTaskListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    const onSearchStatus = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new OrdInspectionRequestStatusFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        let data = await service.RetrieveOrdInspectionRequestStatusListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={inspection?.id ? 'Edit Inspection Request: ' + inspection.id : 'Add Inspection Request'}
            validationSchema={schema}
            initialValue={inspection ?? new OrdInspectionRequest()}
            onComplete={onComplete}
        >
            <OdsAsyncSelect
                name="form"
                label="Form"
                labelKey="name"
                onSearch={onSearchForm}
            />
            <OdsInput name="assetNumber" label="Asset Number" />
            <OdsInput name="assetDescription" label="Asset Description" />
            <OdsAsyncSelect
                name="task"
                label="Task"
                labelKey="name"
                onSearch={onSearchTask}
            />
            <OdsAsyncSelect
                name="status"
                label="Status"
                labelKey="name"
                onSearch={onSearchStatus}
            />
            <OdsDate name="startDate" label="Start Date" />
            <OdsDate name="dueDate" label="Due Date" />
            <OdsInput name="userDefined1" label="User Defined 1" />
            <OdsInput name="userDefined2" label="User Defined 2" />
            <OdsInput name="masterId" label="Master Id" />
            <OdsInput name="requestId" label="Request Id" />
            <OdsTextArea name="comments" label='Comments' />
        </OdsBaseAddEditDialog>
    );
};

