import React, { useEffect, useState } from 'react';
import { Form, Modal, Row } from 'react-bootstrap';
import OdsBaseTable from '../../components/base-table/base-table';
import { InspectionRequestDashboardItem } from '../../models/inspection-request-dashboard-item';
import { ColumnDefinition, CvxButton, CvxSpinnerButton } from '../../modules/cvx-ui-module';

export interface OdsImportOrdInspectionRequestsDialogProps {
    show: boolean;
    onComplete: (dialogResult: boolean, inspectionRequests: InspectionRequestDashboardItem[]) => Promise<void>;
}


const columns: ColumnDefinition<InspectionRequestDashboardItem>[] = [
    {
        header: <span>Form Name</span>, exportHeader: 'Form Name', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.formName), sortFilterValue: ((i) => i.formName)
    },
    {
        header: <span>Asset Id</span>, exportHeader: 'Asset Id', align: 'left', noWrap: true, canSort: true, canFilter: true,
        template: ((i) => i.assetId), sortFilterValue: ((i) => i.assetId)
    },
    {
        header: <span>Asset Description</span>, exportHeader: 'Asset Description', align: 'left', noWrap: true, maxWidth: 200, canSort: true, canFilter: true,
        template: ((i) => i.assetDescription), sortFilterValue: ((i) => i.assetDescription), tooltip: ((i) => i.assetDescription)
    },
    {
        header: <span>Task Id</span>, exportHeader: 'Task Id', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.taskId), sortFilterValue: ((i) => i.taskId)
    },
    {
        header: <span>User Defined 1</span>, exportHeader: 'User Defined 1', align: 'left', noWrap: true, maxWidth: 200, canSort: true, canFilter: true,
        template: ((i) => i.userDefined1), sortFilterValue: ((i) => i.userDefined1), tooltip: ((i) => i.userDefined1)
    },
    {
        header: <span>User Defined 2</span>, exportHeader: 'User Defined 2', align: 'left', noWrap: true, maxWidth: 200, canSort: true, canFilter: true,
        template: ((i) => i.userDefined2), sortFilterValue: ((i) => i.userDefined2), tooltip: ((i) => i.userDefined2)
    },
    {
        header: <span>Start Date</span>, exportHeader: 'Start Date', align: 'center', noWrap: true, canSort: true, canFilter: true,
        template: ((i) => i.startDate?.toLocaleDateString()), sortFilterValue: ((i) => i.startDate?.toLocaleDateString())
    },
    {
        header: <span>Due Date</span>, exportHeader: 'Due Date', align: 'center', noWrap: true, canSort: true, canFilter: true,
        template: ((i) => i.dueDate?.toLocaleDateString()), sortFilterValue: ((i) => i.dueDate?.toLocaleDateString())
    },
    {
        header: <span>Request Id</span>, exportHeader: 'Request Id', align: 'center', noWrap: true, canSort: true, canFilter: true,
        template: ((i) => i.requestId), sortFilterValue: ((i) => i.requestId)
    },
    {
        header: <span>Master Id</span>, exportHeader: 'Master Id', align: 'center', noWrap: true, canSort: true, canFilter: true,
        template: ((i) => i.masterId), sortFilterValue: ((i) => i.masterId)
    },
    {
        header: <span>Comment Text</span>, exportHeader: 'Comment Text', align: 'left', noWrap: true, maxWidth: 250, canSort: true, canFilter: true,
        template: ((i) => i.commentText), sortFilterValue: ((i) => i.commentText), tooltip: ((i) => i.commentText)
    },
];

const OdsImportOrdInspectionRequestsDialog: React.FC<OdsImportOrdInspectionRequestsDialogProps> = ({
    show,
    onComplete,
    ...props
}) => {

    const [filterStr, setfilterStr] = useState<string>('');
    const [filterValues, setFilterValues] = useState<InspectionRequestDashboardItem[]>([]);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onCancel = () => {
        onComplete(false, []);
    };

    const onApply = async () => {
        setIsSaving(true);
        await onComplete(true, filterValues);
        setIsSaving(false);
    };

    const onClear = () => {
        setFilterValues([]);
    };

    const onChange = (evt: any) => {
        const filter: string = evt.target.value;
        const filterValues: InspectionRequestDashboardItem[] = [];

        (filter ?? '').split('\n').forEach((row, i) => {

            if (row.trim().length > 0) {
                const inspectionRequest = new InspectionRequestDashboardItem();

                row.split('\t').forEach((c, i) => {
                    if (i === 0) {
                        inspectionRequest.formName = c;
                    } else if (i === 1) {
                        inspectionRequest.assetId = c;
                    } else if (i === 2) {
                        inspectionRequest.assetDescription = c;
                    } else if (i === 3) {
                        inspectionRequest.taskId = c;
                    } else if (i === 4) {
                        inspectionRequest.userDefined1 = c;
                    } else if (i === 5) {
                        inspectionRequest.userDefined2 = c;
                    } else if (i === 6) {
                        inspectionRequest.startDate = c ? new Date(c) : undefined;
                    } else if (i === 7) {
                        inspectionRequest.dueDate = c ? new Date(c) : undefined;
                    } else if (i === 8) {
                        inspectionRequest.requestId = c;
                    } else if (i === 9) {
                        inspectionRequest.masterId = c;
                    } else if (i === 10) {
                        inspectionRequest.commentText = c;
                    }
                });

                filterValues.push(inspectionRequest);
            }
        });

        setfilterStr('');
        setFilterValues(filterValues);
    };

    useEffect(() => {
        setIsSaving(false);
        setFilterValues([]);
    }, [show]);

    return (
        <Modal className="theme-blue" show={show} size="xl" centered backdrop='static' scrollable={true} onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Import ORD Inspection Requests</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Form.Group controlId="filter">
                            <Form.Control as="textarea" placeholder='Paste List' value={filterStr} onChange={(evt) => onChange(evt)}></Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <OdsBaseTable
                                isLoading={false}
                                dataSource={filterValues}
                                heading='Inspection Requests'
                                columns={columns}
                                overridePageSize={10}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxButton theme='primary' onClick={onClear}>Clear</CvxButton>
                <CvxSpinnerButton theme='primary' showSpinner={isSaving} onClick={onApply}>Save</CvxSpinnerButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsImportOrdInspectionRequestsDialog;
