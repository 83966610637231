import * as yup from 'yup';
import OdsInput from "../../components/form-editors/input/input";
import OdsSwitch from '../../components/form-editors/switch/switch';
import OdsTextArea from '../../components/form-editors/textarea/textarea';
import { PersonnelGroupType } from '../../models/personnel-group-type';
import { OdsPersonnelApiService } from '../../services';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditPersonnelGroupTypeDialogProps {
    service: OdsPersonnelApiService;
    show: boolean;
    groupType: PersonnelGroupType | undefined;
    onComplete: (dialogResult: boolean, personnel: PersonnelGroupType | undefined) => Promise<any>;
}

export const OdsEditPersonnelGroupTypeDialog: React.FC<OdsEditPersonnelGroupTypeDialogProps> = ({
    service,
    show,
    groupType,
    onComplete,
    ...props
}) => {

    const groupTypeSchema = yup.object().shape({
        name: yup.string().required().max(250)
            .test('duplicate', 'name is already in use', async (value: string | undefined) => {
                if (value) {
                    return await service.ValidatePersonnelGroupTypeNameAsync(value, groupType?.id);
                }
                return true;
            }),
        description: yup.string().nullable(),
        isActive: yup.boolean().required(),
    });

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={groupType ? 'Edit Group Type: ' + groupType.name : 'Add Group Type'}
            validationSchema={groupTypeSchema}
            initialValue={groupType ?? new PersonnelGroupType()}
            onComplete={onComplete}
        >
            <OdsInput name="name" label="Name" />
            <OdsTextArea name="description" label="Description" />
            <OdsSwitch name="isActive" label="Is Active" />
        </OdsBaseAddEditDialog>
    );
};
