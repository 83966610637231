import * as yup from 'yup';
import { OdsMonaco } from '../../components';
import OdsInput from "../../components/form-editors/input/input";
import { OdsNumber } from '../../components/form-editors/number/number';
import { FormDataSource } from '../../models';
import { OdsFormApiService } from '../../services';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditFormDataSourceProps {
    service: OdsFormApiService;
    show: boolean;
    formDataSource: FormDataSource | undefined;
    onComplete: (dialogResult: boolean, ldarHoliday: FormDataSource | undefined) => Promise<any>;
}

export const OdsEditFormDataSource: React.FC<OdsEditFormDataSourceProps> = ({
    service,
    show,
    formDataSource,
    onComplete,
    ...props
}) => {

    const schema = yup.object().shape({
        formSpaceId: yup.number().integer().required(),
        dataSourceId: yup.number().integer().required(),
        name: yup.string().required().max(150),
        description: yup.string().nullable(),
        sqlQuery: yup.string().required(),
    });

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={formDataSource?.id ? 'Edit Form Data Source: ' + formDataSource.name : 'Add Form Data Source'}
            validationSchema={schema}
            initialValue={formDataSource ?? new FormDataSource()}
            onComplete={onComplete}
        >
            <OdsNumber name="formSpaceId" label="Form Space" />
            <OdsNumber name="dataSourceId" label="Data Source" />
            <OdsInput name="name" label="Name" />
            <OdsInput name="description" label="Description" />
            <OdsMonaco name="sqlQuery" label="SQL Query" language="sql" height={200} />
        </OdsBaseAddEditDialog>
    );
};
