import React, { HTMLAttributes, useState } from "react";
import { OdsBooleanFilterDialog } from "../../../dialogs/filter-dialogs/boolean-filter/boolean-filter.dialog";
import { BooleanFilter } from "../../../models/boolean-filter";
import { ViewHelper } from "../../../services/view-helpers";

export interface OdsBooleanColumnHeaderProps {
    header: React.ReactNode;
    name: string;
    filter?: BooleanFilter;
    sort?: string;
    sortDirection?: string;
    onSortChanged?: (sort: string, sortDirection: string) => void;
    onFilterChanged?: (newFilter?: BooleanFilter) => void;
}

export const OdsBooleanColumnHeader: React.FC<OdsBooleanColumnHeaderProps & HTMLAttributes<HTMLSpanElement>> = ({
    header,
    name,
    filter,
    sort,
    sortDirection,
    onSortChanged,
    onFilterChanged,
    ...props
}) => {
    const [showFilter, setShowFilter] = useState<boolean>(false);

    const onFilterList = (columnName: string) => {
        setShowFilter(true);
    }

    const onFilterComplete = (dialogResult: boolean, newFilter?: BooleanFilter) => {
        setShowFilter(false);
        if (dialogResult && onFilterChanged) {
            onFilterChanged(newFilter);
        }
    }

    const onDrawSort = (columnName: string) => {
        return ViewHelper.drawSort(columnName, sort, sortDirection, onSortChanged);
    };

    const onDrawFilter = (columnName: string, filtered: boolean) => {
        return ViewHelper.drawFilter(columnName, filtered, onFilterList, onFilterChanged);
    }

    const onDrawEdit = (columnName: string) => {
        //return ViewHelper.drawEdit(columnName, selectedItems ?? []);
    }

    return (
        <th>
            <OdsBooleanFilterDialog
                header={header}
                show={showFilter}
                filter={filter}
                onComplete={onFilterComplete}
            />
            <span className="ods-column-header center-column" {...props}>{header}{onDrawSort(name)}{onDrawFilter(name, !!filter)}</span>
        </th>
    )
};
