import { DispatchForm, DispatchFormFilter, Facility, ListResult, Myself, State, StateFilter, StringFilter } from "../models";
import { ApiService } from "./api-service";
import { ItemCache } from "./item-cache";

export class OdsApiServiceBase {
    protected apiService: ApiService | undefined;

    public constructor(apiService: ApiService | undefined) {
        this.apiService = apiService;
    }


    private _myself?: Promise<Myself>;

    public async RetrieveMyselfAsync(): Promise<Myself> {
        if (!this.apiService) {
            throw new Error();
        }

        let data = this._myself;
        if (!data) {
            data = this.apiService.get<Myself>('/api/v1.0/users/me')
                .then(d =>
                    Myself.map(d)
                );

            this._myself = data;
        }

        return data;
    }

    public async RetrieveUserPermissionsAsync(): Promise<string[]> {
        if (!this.apiService) {
            throw new Error();
        }
        
        const data = await this.RetrieveMyselfAsync();
        
        return data.permissions.map(d => d.permission);
    }


    //*************************************************************************************************************************************
    //*******************************************************   DISPATCH FORMS   **********************************************************
    //*************************************************************************************************************************************

    private dispatchFormCache = new ItemCache<number, DispatchForm>();

    public async RetrieveDispatchFormListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: DispatchFormFilter): Promise<ListResult<DispatchForm>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<DispatchForm>>('/api/v1.0/dispatch-forms/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => this.dispatchFormCache.set(d.id, DispatchForm.map(d))) };
    }

    public async RetrieveDispatchFormAsync(formId: number): Promise<DispatchForm> {
        return this.dispatchFormCache.get(formId, async (id: number) => {
            if (!this.apiService) {
                throw new Error();
            }

            const data = await this.apiService.get<DispatchForm>(`/api/v1.0/dispatch-forms/${id}`);

            return DispatchForm.map(data);
        });
    }

    public async RetrieveDispatchFormByNameAsync(dispatchFormName: string): Promise<DispatchForm> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${1}&skip=${0}`;

        let filter = new DispatchFormFilter();
        filter.name = new StringFilter();
        filter.name.equal = [dispatchFormName];

        const data = await this.apiService.post<ListResult<DispatchForm>>('/api/v1.0/dispatch-forms/search' + params, JSON.stringify(filter));

        return data.items?.map(d => DispatchForm.map(d))[0];
    }


    //*************************************************************************************************************************************
    //*********************************************************   FACILITIES   ************************************************************
    //*************************************************************************************************************************************

    private _facilities: Map<number, Promise<Facility>> = new Map<number, Promise<Facility>>();

    public async RetrieveFacilityListAsync(top: number, skip: number, sort: string, sortDirection: string): Promise<ListResult<Facility>> {
        if (!this.apiService) {
            throw new Error();
        }

        let data: ListResult<Facility>;
        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        data = await this.apiService.get<ListResult<Facility>>('/api/v1.0/facilities' + params);

        return { totalCount: data.totalCount, items: data.items.map(d => Facility.map(d)) };
    }

    public async RetrieveFacilityAsync(facilityId: number): Promise<Facility> {
        if (!this.apiService) {
            throw new Error();
        }

        let data = this._facilities.get(facilityId);
        if (!data) {
            data = this.apiService.get<Facility>(`/api/v1.0/facilities/${facilityId}`)
                .then(d =>
                    Facility.map(d)
                );

            this._facilities.set(facilityId, data);
        }

        return data;
    }


    //*************************************************************************************************************************************
    //***********************************************************   STATES   **************************************************************
    //*************************************************************************************************************************************

    private _states: Map<number, Promise<State>> = new Map<number, Promise<State>>();

    public async RetrieveStateListAsync(top: number, skip: number, sort: string, sortDirection: string, filter: StateFilter): Promise<ListResult<State>> {
        if (!this.apiService) {
            throw new Error();
        }

        const params = `?top=${top}&skip=${skip}&sort=${sort}&sortDirection=${sortDirection}`;

        const data = await this.apiService.post<ListResult<State>>('/api/v1.0/states/search' + params, JSON.stringify(filter));

        return { totalCount: data.totalCount, items: data.items.map(d => State.map(d)) };
    }

    public async RetrieveStateAsync(stateId: number): Promise<State> {
        if (!this.apiService) {
            throw new Error();
        }

        let data = this._states.get(stateId);
        if (!data) {
            data = this.apiService.get<State>(`/api/v1.0/states/${stateId}`)
                .then(d =>
                    State.map(d)
                );

            this._states.set(stateId, data);
        }

        return data;
    }
}
