import { useEffect, useReducer, useState } from "react";
import { Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { OdsAsyncMultiSelectControl } from "../../components";
import { BooleanFilter, LdarInspectionRequest, PersonnelFilter, PersonnelGroupFilter, PersonnelGroupTypeFilter, StringFilter } from "../../models";
import { ApiError } from "../../models/api-error";
import { Personnel } from "../../models/personnel";
import { PersonnelGroup } from "../../models/personnel-group";
import { PersonnelGroupType } from "../../models/personnel-group-type";
import { Route } from "../../models/route";
import { CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";
import { OdsPersonnelApiService } from "../../services";

export interface OdsLdarDispatchDialogProps {
    service: OdsPersonnelApiService;
    show: boolean;
    inspectionRequests: LdarInspectionRequest[];
    onComplete: (dialogResult: boolean) => void;
    onDispatchItem: (item: LdarInspectionRequest, users: string[]) => Promise<any>;
}

class LdarInspectionRequestDispatch {
    public item: LdarInspectionRequest;
    public dispatchUserList: string[] = [];
    public error: string = '';

    public constructor(item: LdarInspectionRequest) {
        this.item = item;
    }
}

export const OdsLdarDispatchDialog: React.FC<OdsLdarDispatchDialogProps> = ({
    service,
    show,
    inspectionRequests,
    onComplete,
    onDispatchItem,
    ...props
}) => {
    const [ignored, forceUpdate] = useReducer(x => x = -x, 1);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [populating, setPopulating] = useState<boolean>(false);

    const [dispatchList, setDispatchList] = useState<LdarInspectionRequestDispatch[]>([]);

    const [selectedGroupTypes, setSelectedGroupTypes] = useState<PersonnelGroupType[]>([]);
    const [selectedPersonnel, setSelectedPersonnel] = useState<Personnel[]>([]);
    const [selectedPersonnelGroups, setSelectedPersonnelGroups] = useState<PersonnelGroup[]>([]);

    const [personnel, setPersonnel] = useState<Map<number, Promise<Personnel>>>(new Map());
    const [personnelGroups, setPersonnelGroups] = useState<Map<number, Promise<PersonnelGroup>>>(new Map());
    const [routes, setRoutes] = useState<Map<number, Promise<Route | undefined>>>(new Map());

    const [totalDispatches, setTotalDispatches] = useState<number>(0);

    const onSubmit = async (): Promise<any> => {
        setIsSaving(true);

        const inspec: LdarInspectionRequestDispatch[] = [];

        let hasDispatchErrors: boolean = false;

        dispatchList.forEach((i: LdarInspectionRequestDispatch) => inspec.push(i));

        for (let i = 0; i < inspec.length; i++) {
            try {
                if (inspec[i].dispatchUserList.length > 0) {
                    await onDispatchItem(inspec[i].item, inspec[i].dispatchUserList);
                    const index = dispatchList.indexOf(inspec[i]);
                    if (index > -1) {
                        dispatchList.splice(index, 1);
                    }
                } else {
                    hasDispatchErrors = true;
                    inspec[i].error = 'No personnel to dispatch to';
                }
            } catch (error: any) {
                hasDispatchErrors = true;

                if (error instanceof ApiError) {
                    inspec[i].error = error.error.detail ?? '';
                } else if (error instanceof Error) {
                    inspec[i].error = error.message;
                } else {
                    inspec[i].error = error.toString();
                }
            }

            forceUpdate();
        }

        setIsSaving(false);

        if (hasDispatchErrors == false && onComplete) {
            onComplete(true);
        }
    };

    const onCancel = () => {
        if (onComplete) {
            onComplete(false);
        }
    };

    const onSearchPersonnelGroupTypes = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelGroupTypeFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        //here is where you add the active value to the filter
        //only want to search on active
        const boolFilter = new BooleanFilter();
        filter.isActive = boolFilter;
        filter.isActive.equal = true;

        return await service.RetrievePersonnelGroupTypeListAsync(top, skip, 'Name', 'Asc', filter);
    };

    const onSearchPersonnelGroups = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelGroupFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        //here is where you add the active value to the filter
        //only want to search on active
        const boolFilter = new BooleanFilter();
        filter.isActive = boolFilter;
        filter.isActive.equal = true;

        return await service.RetrievePersonnelGroupListAsync(top, skip, 'Name', 'Asc', filter);
    };

    const onSearchPersonnel = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelFilter();

        filter.fullName = stringFilter;
        filter.fullName.like = filterStr;

        //here is where you add the active value to the filter
        //only want to search on active
        const boolFilter = new BooleanFilter();
        filter.isActive = boolFilter;
        filter.isActive.equal = true;

        return await service.RetrievePersonnelListAsync(top, skip, 'LastName', 'Asc', filter);
    };

    const fetchPersonnel = (id: number) => {
        let data = personnel.get(id);
        if (!data) {
            data = service.RetrievePersonnelAsync(id);
            personnel.set(id, data);
        }
        return data;
    }

    const fetchPersonnelGroup = (id: number) => {
        let data = personnelGroups.get(id);
        if (!data) {
            data = service.RetrievePersonnelGroupAsync(id);
            personnelGroups.set(id, data);
        }
        return data;
    }

    const fetchRoute = (id?: number) => {
        if (id) {
            let data = routes.get(id);
            if (!data) {
                data = service.RetrieveRouteAsync(id);
                routes.set(id, data);
            }
            return data;
        }
    }

    const onAssignDispatches = async () => {
        setPopulating(true);

        if (dispatchList) {
            dispatchList.forEach(i => i.dispatchUserList = []);

            for (let selectedGroupType of selectedGroupTypes) {
                for (let dispatch of dispatchList) {
                    const route = await fetchRoute(dispatch.item.lod?.routeId);

                    if (route) {
                        for (let personnelGroupId of route.personnelGroupIds) {
                            const personnelGroup = await fetchPersonnelGroup(personnelGroupId);

                            if (personnelGroup.personnelGroupTypeId === selectedGroupType.id) {
                                for (let id of personnelGroup.personnelIds) {
                                    const personnel = await fetchPersonnel(id);
                                    if (personnel.email) {
                                        dispatch.dispatchUserList.push(personnel.email);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            for (let g of selectedPersonnelGroups) {
                for (let id of g.personnelIds) {
                    const personnel = await fetchPersonnel(id);
                    dispatchList.forEach(i => {
                        if (personnel.email) {
                            i.dispatchUserList.push(personnel.email);
                        }
                    });
                }
            }

            for (let personnel of selectedPersonnel) {
                dispatchList.forEach(i => {
                    if (personnel.email) {
                        i.dispatchUserList.push(personnel.email);
                    }
                });
            }

            let totalDispatches = 0;

            dispatchList.forEach(i => {
                i.dispatchUserList = i.dispatchUserList.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
                totalDispatches += i.dispatchUserList.length;
            });

            setTotalDispatches(totalDispatches);
        }
        setPopulating(false);
    }

    useEffect(() => {
        onAssignDispatches();
    }, [selectedGroupTypes, selectedPersonnel, selectedPersonnelGroups]);

    useEffect(() => {
        setDispatchList(inspectionRequests?.map(d => new LdarInspectionRequestDispatch(d)) ?? []);
    }, [inspectionRequests]);

    useEffect(() => {
        setTotalDispatches(0);
        setSelectedGroupTypes([]);
        setSelectedPersonnel([]);
        setSelectedPersonnelGroups([]);
        setDispatchList(inspectionRequests?.map(d => new LdarInspectionRequestDispatch(d)) ?? []);
    }, [show]);

    return (
        <Modal className="theme-blue" size="xl" show={show} centered backdrop="static" onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Dispatch Inspections</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <Form.Label>Personnel Group Type</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelGroupType"
                                labelKey="name"
                                placeholder="Personnel Group Type"
                                multiple={true}
                                onSearch={onSearchPersonnelGroupTypes}
                                onChange={(item) => setSelectedGroupTypes(item)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Personnel</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnel"
                                labelKey="fullName"
                                placeholder="Personnel"
                                multiple={true}
                                onSearch={onSearchPersonnel}
                                onChange={(item) => setSelectedPersonnel(item)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Personnel Group</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelGroups"
                                labelKey="name"
                                placeholder="Personnel Groups"
                                multiple={true}
                                onSearch={onSearchPersonnelGroups}
                                onChange={(item) => setSelectedPersonnelGroups(item)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginTop: '20px' }}>
                            <div className="cvx-table">
                                <div className="table-scroll">
                                    <table>
                                        <thead>
                                            <th>Id</th>
                                            <th>LOD</th>
                                            <th>Route</th>
                                            <th>Personnel</th>
                                            <th>Errors</th>
                                        </thead>
                                        <tbody>
                                            {dispatchList?.map(d =>
                                                <tr>
                                                    <td>{d.item.id}</td>
                                                    <td>{d.item.lod?.name}</td>
                                                    <td>{d.item.lod?.routeId}</td>
                                                    <td>{populating ? <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner> : d.dispatchUserList.map(d => d + ', ')}</td>
                                                    <td>{d.error}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <span style={{ margin: 'auto' }}>Dispatches: {totalDispatches}</span>
                <CvxButton theme='accent' onClick={onCancel}>Cancel</CvxButton>
                <CvxSpinnerButton theme='primary' disabled={populating} showSpinner={isSaving} onClick={onSubmit}>Dispatch</CvxSpinnerButton>
            </Modal.Footer>
        </Modal>
    );
};
