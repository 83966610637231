import { useState } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { BsClipboardPlus } from "react-icons/bs";
import { OdsAsyncMultiSelectControl } from "../../../components";
import { DateFilter, ListResult } from "../../../models";
import { CvxButton } from "../../../modules/cvx-ui-module";
import DatePicker from "react-datepicker";


export interface OdsDateFilterDialogProps {
    show: boolean;
    header: React.ReactNode;
    filter?: DateFilter;
    onSearch: (top: number, skip: number, filter: string) => Promise<ListResult<string>>;
    onComplete: (dialogResult: boolean, filter?: DateFilter) => void;
}

export const OdsDateFilterDialog: React.FC<OdsDateFilterDialogProps> = ({
    show,
    header,
    filter,
    onSearch,
    onComplete,
    ...props
}) => {

    const [lessValue, setLessValue] = useState<Date | undefined>(new Date());
    const [useLessValue, setUseLessValue] = useState<boolean>(false);

    const [greaterValue, setGreaterValue] = useState<Date | undefined>(new Date());
    const [useGreaterValue, setUseGreaterValue] = useState<boolean>(false);

    const [equalValue, setEqualValue] = useState<string[]>([]);
    const [useEqualValue, setUseEqualValue] = useState<boolean>(false);

    const onEqualValueChanged = (selected: string[]) => {
        setEqualValue(selected);
        setUseEqualValue(selected?.length > 0);
    }

    const onPasteItems = async () => {
        const text = await navigator.clipboard.readText();

        const selected: string[] = [];
        (text ?? '').split('\n').forEach(i => {
            i = i.trim();
            if (i.trim().length > 0) {
                selected.push(i);
            }
        });

        setEqualValue(selected);
        setUseEqualValue(selected?.length > 0);
    }

    const onCancel = () => {
        onComplete(false, undefined);
    }

    const onClear = () => {
        setLessValue(new Date());
        setUseLessValue(false);

        setGreaterValue(new Date());
        setUseGreaterValue(false);

        setEqualValue([]);
        setUseEqualValue(false);

        onComplete(true, undefined);
    }

    const onApply = () => {
        if (!useLessValue && !useGreaterValue && !useEqualValue) {
            onClear();
        } else {
            const filter = new DateFilter();

            if (useLessValue) {
                filter.lessThan = lessValue;
            }

            if (useGreaterValue) {
                filter.greaterThan = greaterValue;
            }

            if (useEqualValue) {
                filter.equal = equalValue.map(s => new Date(s));
            }

            onComplete(true, filter);
        }
    }

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Filter '{header}'</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useLessValue} onChange={() => setUseLessValue(!useLessValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="lessThanFilter">
                                <Form.Label>{header} less than </Form.Label>
                                <DatePicker
                                    className="form-control"
                                    selected={lessValue}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(val: Date | null) => {
                                        if (val) {
                                            setLessValue(val);
                                            setUseLessValue(true);
                                        } else {
                                            setLessValue(undefined);
                                            setUseLessValue(false);
                                        }
                                    }}
                                    closeOnScroll={true}
                                    popperProps={{
                                        positionFixed: true
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useGreaterValue} onChange={() => setUseGreaterValue(!useGreaterValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="greaterThanFilter">
                                <Form.Label>{header} greater than </Form.Label>
                                <DatePicker
                                    className="form-control"
                                    selected={greaterValue}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(val: Date | null) => {
                                        if (val) {
                                            setGreaterValue(val);
                                            setUseGreaterValue(true);
                                        } else {
                                            setGreaterValue(undefined);
                                            setUseGreaterValue(false);
                                        }
                                    }}
                                    closeOnScroll={true}
                                    popperProps={{
                                        positionFixed: true
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useEqualValue} onChange={() => setUseEqualValue(!useEqualValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="inFilter">
                                <Form.Label>{header} equals </Form.Label>
                                <OdsAsyncMultiSelectControl
                                    id="equals filter"
                                    onSearch={onSearch}
                                    multiple={true}
                                    selected={equalValue}
                                    onChange={onEqualValueChanged}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="align-self-end" md="auto">
                            <CvxButton onClick={onPasteItems}><BsClipboardPlus /></CvxButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxButton theme='primary' onClick={onClear}>Clear</CvxButton>
                <CvxButton theme='primary' onClick={onApply}>Apply</CvxButton>
            </Modal.Footer>
        </Modal>
    );
}
