export class LdarInspectionRequestType {
    public id: number = 0;
    public name: string = '';
    public isOOOOa: boolean = false;
    public isOOOOb: boolean = false;

    public static map(data: any): LdarInspectionRequestType {
        const m = new LdarInspectionRequestType;

        m.id = data.id;
        m.name = data.name;
        m.isOOOOa = data.isOOOOa;
        m.isOOOOb = data.isOOOOb;

        return m;
    };
};
