export class OrdInspectionRequestTask {
    public id?: number;
    public name: string = '';

    public static map(data: any): OrdInspectionRequestTask {
        const m = new OrdInspectionRequestTask;

        m.id = data.id;
        m.name = data.name;

        return m;
    };
};
