import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { BooleanFilter } from "../../../models/boolean-filter";
import { CvxButton } from "../../../modules/cvx-ui-module";

export interface OdsBooleanFilterDialogProps {
    show: boolean;
    header: React.ReactNode;
    filter?: BooleanFilter;
    onComplete: (dialogResult: boolean, filter?: BooleanFilter) => void;
}

export const OdsBooleanFilterDialog: React.FC<OdsBooleanFilterDialogProps> = ({
    show,
    header,
    filter,
    onComplete,
    ...props
}) => {

    const [useTrueValue, setUseTrueValue] = useState<boolean>(false);
    const [useFalseValue, setUseFalseValue] = useState<boolean>(false);

    const onCancel = () => {
        onComplete(false, undefined);
    }

    const onClear = () => {
        setUseTrueValue(false);
        setUseFalseValue(false);

        onComplete(true, undefined);
    }

    const onApply = () => {
        if (!useTrueValue && !useFalseValue) {
            onClear();
        } else {
            const filter = new BooleanFilter();

            if (useTrueValue) {
                filter.equal = true;
            }

            if (useFalseValue) {
                filter.equal = false;
            }

            onComplete(true, filter);
        }
    }

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Filter '{header}'</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useTrueValue} onChange={() => setUseTrueValue(!useTrueValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="trueFilter">
                                <Form.Label>{header} is True </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useFalseValue} onChange={() => setUseFalseValue(!useFalseValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="falseFilter">
                                <Form.Label>{header} is False </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxButton theme='primary' onClick={onClear}>Clear</CvxButton>
                <CvxButton theme='primary' onClick={onApply}>Apply</CvxButton>
            </Modal.Footer>
        </Modal>
    );
};
