import React, { useEffect, useState } from "react";
import { PersonnelGroup } from "../../models";

interface Item {
    personnelGroups: PersonnelGroup[];
}

export interface OdsPersonnelGroupChipProps {
    id: number,
    item: Item,
    onFetchPersonnelGroup: (id: number) => Promise<PersonnelGroup>;
}

export const OdsPersonnelGroupChip: React.FC<OdsPersonnelGroupChipProps> = ({
    id,
    item,
    onFetchPersonnelGroup,
    ...props
}) => {
    const [data, setData] = useState<PersonnelGroup>();

    const fetchData = async () => {
        const data = await onFetchPersonnelGroup(id);
        item.personnelGroups.push(data);
        setData(data);
    }

    useEffect(() => {
        fetchData();
    }, [id])

    return (
        <span className="ods-item-chip">{!data ? id : data.name}</span>
    )
}
