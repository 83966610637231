import React from "react";

export interface CvxHeadingProps {
    heading: JSX.Element | string;
}

export const CvxHeading: React.FC<CvxHeadingProps> = ({
    heading,
    ...props
}) => {
    return (
        <div className="cvx-heading cvx-primary-dark-color">{heading}</div>
    );
};

export default CvxHeading;
