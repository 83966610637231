import React, { HTMLAttributes } from "react";
import { BsTrash, BsTrashFill } from "react-icons/bs";
import { Themes } from "../../../modules/cvx-ui-module";

interface OdsMultiDeleteColumnHeaderProps {
    selectedItems: any[];
    onClick: () => void;
}

export const OdsMultiDeleteColumnHeader: React.FC<OdsMultiDeleteColumnHeaderProps & HTMLAttributes<HTMLSpanElement>> = ({
    selectedItems,
    onClick,
    ...props
}) => {
    return (
        <th>
            {selectedItems !== undefined && selectedItems.length > 1 ?
                <span className={Themes.WarningDarkColor} onClick={onClick}><BsTrashFill /></span>
                : <span className="sort-inactive"><BsTrash /></span>
            }
        </th>
    )
};
