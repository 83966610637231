import { useEffect, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import OdsBaseTable from '../../components/base-table/base-table';
import { BradenheadNewWell } from '../../models/bradenhead-new-well';
import { BradenheadWellCategory } from '../../models/bradenhead-well-category';
import { ColumnDefinition, CvxButton } from '../../modules/cvx-ui-module';
import OdsService from '../../services/ods-service';

export interface OdsAddBradenheadWellDialogProps {
    show: boolean;
    odsService: OdsService | undefined;
    categories: BradenheadWellCategory[];
    reportingMonthId: number;
    columns: ColumnDefinition<BradenheadNewWell>[],
    onComplete: (dialogResult: boolean, newWells: BradenheadNewWell[]) => Promise<any>;
}

const OdsAddBradenheadWellDialog: React.FC<OdsAddBradenheadWellDialogProps> = ({
    show,
    odsService,
    categories,
    reportingMonthId,
    columns,
    onComplete,
    ...props
}) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [newWells, setNewWells] = useState<BradenheadNewWell[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [selectedNewWells, setSelectedNewWells] = useState<BradenheadNewWell[]>([]);

    const fetchNewWells = async () => {
        if (odsService && reportingMonthId > 0) {
            setIsLoading(true);
            setNewWells(await odsService.RetrieveBradenheadNewWellsAsync(reportingMonthId));
            setIsLoading(false);
        }
    };

    const onCancel = () => {
        onComplete(false, []);
    };

    const onApply = () => {
        onComplete(true, selectedNewWells);
    };

    const onSelectionChanged = (selectedItems: BradenheadNewWell[]) => {
        setSelectedNewWells(selectedItems);
    };

    const onSelectWellCategory = (selectedItem: string) => {
        setSelectedCategory(selectedItem);
        selectedNewWells.forEach(element => {
            element.wellCategory = selectedItem;
        });
    };

    const onAssignCategory = () => {
        if (selectedNewWells) {
            selectedNewWells.forEach(element => {
                element.wellCategory = selectedCategory;
            });
        }
    };

    useEffect(() => {
        onAssignCategory()
    }, [selectedCategory])

    useEffect(() => {
        fetchNewWells();
    }, [reportingMonthId]);

    return (
        <Modal className="theme-blue" size='xl' show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Add Wells</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col></Col>
                        <Col>
                            <Form.Label>Well Category</Form.Label>
                            <Form.Select value={selectedCategory} onChange={(e) => onSelectWellCategory(e.target.value)}>
                                <option></option>
                                {
                                    categories && categories.map((item: any) => {
                                        return <option key={item.id} value={item.name}>{item.name}</option>
                                    })
                                }
                            </Form.Select>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col style={{ marginTop: '20px' }}>
                            <OdsBaseTable
                                columns={columns}
                                dataSource={newWells}
                                isLoading={isLoading}
                                overridePageSize={10}
                                onSelectedItemsChanged={onSelectionChanged}
                            />
                        </Col>
                    </Row>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Cancel</CvxButton>
                <CvxButton theme='primary' disabled={selectedNewWells?.length < 1} onClick={onApply}>Add</CvxButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsAddBradenheadWellDialog;
