import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Lod } from "../../models/lod";

export interface OdsLodChipProps {
    id: string;
    onFetchLod: (id: string) => Promise<Lod>
}

export const OdsLodChip: React.FC<OdsLodChipProps> = ({
    id,
    onFetchLod
}) => {
    const [data, setData] = useState<Lod>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        if (id) {
            const data = await onFetchLod(id)
            setData(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [id])

    return (
        <span className="ods-item-chip">{isLoading === false ? data?.name : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>}</span>
    )
}
