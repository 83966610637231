import { FieldArray, FieldHookConfig, useField } from "formik";
import React from "react";
import { Form, InputGroup, Row } from "react-bootstrap";
import { RolePermission } from "../../../models/role-permission";
import { ListResult } from "../../../models";
import { Permission } from "../../../models/permission";
import { State } from "../../../models/state";
import { CvxButton } from "../../../modules/cvx-ui-module";
import { OdsAsyncSelect } from "../async-select/async-select";

export interface OdsEditRolePermissionsProps {
    label: string;
    onSearchPermission: (top: number, skip: number, filter: string) => Promise<ListResult<Permission>>;
    onSearchState: (top: number, skip: number, filter: string) => Promise<ListResult<State>>;
}

export const OdsEditRolePermissions: React.FC<OdsEditRolePermissionsProps & FieldHookConfig<RolePermission[]>> = ({
    label,
    onSearchPermission,
    onSearchState,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);

    return (
        <Row>
            <Form.Group controlId={field.name}>
                <Form.Label>{label}</Form.Label>
                <FieldArray
                    name={field.name}
                    render={arrayHelpers =>
                        <div>
                            {field.value.map((item: RolePermission, index: number) =>
                                <InputGroup key={index}>
                                    <OdsAsyncSelect
                                        label="Permission"
                                        name={`${field.name}.${index}.permission`}
                                        labelKey="name"
                                        onSearch={onSearchPermission}
                                    />
                                    <OdsAsyncSelect
                                        label="State"
                                        name={`${field.name}.${index}.state`}
                                        labelKey="name"
                                        onSearch={onSearchState}
                                    />
                                    <CvxButton onClick={() => arrayHelpers.remove(index)}>-</CvxButton>
                                </InputGroup>
                            )}
                            <CvxButton onClick={() => {
                                arrayHelpers.push(new RolePermission())
                            }}>Add Role Permission</CvxButton>
                        </div>
                    } />
                <Form.Control.Feedback type="invalid">
                    {typeof meta.error === 'string' ? meta.error : ''}
                </Form.Control.Feedback>
            </Form.Group>
        </Row >
    )
};
