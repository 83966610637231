import { useEffect, useReducer, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { BsChevronDown, BsChevronRight, BsFillCloudArrowUpFill, BsFillPencilFill, BsPlusLg, BsTrash } from "react-icons/bs";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import OdsIsActiveColumn from "../../../components/table-columns/is-active-check-column/is-active-check-column";
import OdsEditDispatchBodyDialog from "../../../dialogs/edit-dispatch-body/edit-dispatch-body.dialog";
import OdsEditFormAttributeDialog from "../../../dialogs/edit-form-attribute/edit-form-attribute.dialog";
import { FormAttribute } from "../../../models/form-attribute";
import { FormDefinition } from "../../../models/form-definition";
import { ProntoFormsFormDefinition, ProntoFormsFormPageDefinition, ProntoFormsFormQuestionDefinition, ProntoFormsFormSectionDefinition } from "../../../models/prontoforms-form-definition";
import { CvxButton, CvxHeading, CvxLayoutCenterColumn, CvxPage, CvxSpinnerButton, CvxTableContentBlock, Themes } from "../../../modules/cvx-ui-module";
import CvxContentBlock from "../../../modules/cvx-ui-module/components/content-block/content-block";
import OdsService from "../../../services/ods-service";


export interface OdsFormManagementDetailViewProps {
    odsService: OdsService | undefined;
}


const OdsFormManagementDetailView: React.FC<OdsFormManagementDetailViewProps> = ({
    odsService,
    ...props
}) => {
    const navigate = useNavigate();

    const { formId } = useParams();
    const [query] = useSearchParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [form, setForm] = useState<FormDefinition | undefined>(undefined);
    const [parentForm, setParentForm] = useState<FormDefinition | undefined>(undefined);

    const [showEditDispatchBody, setShowEditDispatchBody] = useState<boolean>(false);

    const [showEditAttribute, setShowEditAttribute] = useState<boolean>(false);
    const [editAttribute, setEditAttribute] = useState<FormAttribute | undefined>(undefined);
    const [editAttributeParent, setEditAttributeParent] = useState<FormAttribute | undefined>(undefined);

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const fetchForms = async () => {
        if (odsService) {
            setIsLoading(true);

            let form: FormDefinition;

            if (formId == 'new') {
                form = new FormDefinition();

                const parentId = query.get('parent');
                if (parentId) {
                    form.parentId = parseInt(parentId);
                }
            } else {
                form = await odsService.RetrieveFormAsync(parseInt(formId ?? ''));
            }

            setForm(form);
            if (form?.parentId) {
                setParentForm(await odsService.RetrieveFormAsync(form.parentId));
            }

            setIsLoading(false);
        }
    };

    const onEditDispatchBody = () => {
        setShowEditDispatchBody(true);
    }

    const onCompleteEditDispatchBody = async (dialogResult: boolean, updatedDispatchBody: string) => {
        if (dialogResult && form) {
            form.dispatchBody = updatedDispatchBody;
            forceUpdate();
        }

        setShowEditDispatchBody(false);
    }

    const onAddAttribute = (parent: FormAttribute | undefined) => {
        setEditAttribute(undefined);
        setEditAttributeParent(parent);
        setShowEditAttribute(true);
    }

    const onEditAttribute = (attribute: FormAttribute, parent: FormAttribute | undefined) => {
        setEditAttribute(attribute);
        setEditAttributeParent(parent);
        setShowEditAttribute(true);
    }

    const onRemoveAttribute = (attribute: FormAttribute, parent: FormAttribute | undefined) => {
        setEditAttribute(undefined);

        attribute.isDeleted = true;
        forceUpdate();
    }

    const onCompleteEditAttribute = async (dialogResult: boolean, attribute: FormAttribute | undefined) => {
        if (dialogResult && form && attribute) {
            if (editAttributeParent) {
                if (editAttributeParent.children.find(a => a.key == attribute.key)) {
                    editAttributeParent.children = editAttributeParent.children.map(a => a.key !== attribute.key ? a : attribute);
                } else {
                    editAttributeParent.children.push(attribute);
                }
            } else {
                if (form.attributes.find(a => a.key == attribute.key)) {
                    form.attributes = form.attributes.map(a => a.key !== attribute.key ? a : attribute);
                } else {
                    form.attributes.push(attribute);
                }
            }

            forceUpdate();
        }

        setShowEditAttribute(false);
    }

    const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (form) {
            form.name = event.target.value;
            forceUpdate();
        }
    }

    const onDescriptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (form) {
            form.description = event.target.value;
            forceUpdate();
        }
    }

    const onDispatchFormIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (form) {
            if (event.target.value && event.target.value.length > 0) {
                form.dispatchFormId = parseInt(event.target.value);
            } else {
                form.dispatchFormId = undefined;
            }
            forceUpdate();
        }
    }

    const onRetreiveSubmissionsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (form) {
            form.retrieveFormSubmissions = event.target.checked;
            forceUpdate();
        }
    }

    const onDateChanged = (date: Date) => {
        if (form) {
            form.submissionLastReceiveDateTimeUtc = date;
            forceUpdate();
        }
    }

    const onSortOrderChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (form) {
            if (event.target.value && event.target.value.length > 0) {
                form.sortOrder = parseInt(event.target.value);
            } else {
                form.sortOrder = 99999;
            }
            forceUpdate();
        }
    }

    const onImportForm = () => {
        const a = document.createElement('input');
        a.type = 'file';
        a.addEventListener('change', (e: any) => {
            e.preventDefault = true;

            const reader = new FileReader();
            reader.onload = (el) => {
                if (form) {
                    const text: string = el.target?.result?.toString() ?? '';
                    const json: ProntoFormsFormDefinition = JSON.parse(text);

                    console.log(json);

                    form.name = json.name ?? '';
                    form.description = json.description ?? '';

                    json.pages.forEach(p => onProcessPage(p, form.attributes, false));

                    forceUpdate();
                }
            };

            reader.readAsText(e.target.files[0]);
        });

        a.click();
    }

    const onProcessPage = (page: ProntoFormsFormPageDefinition, attributes: FormAttribute[], isRepeating: boolean) => {
        const attrib = new FormAttribute();
        attrib.name = page.page?.label ?? '';
        attrib.description = page.page?.name ?? '';
        attrib.tag = page.page?.label ?? '';
        attrib.isRepeating = isRepeating;

        if (isRepeating) {
            attrib.xPath = `//page[@label="${page.page?.label}"]`;
        }

        page.page?.sections.forEach(s => onProcessSection(s, attrib.children));

        attributes.push(attrib);
    }

    const onProcessSection = (section: ProntoFormsFormSectionDefinition, attributes: FormAttribute[]) => {
        const attrib = new FormAttribute();
        attrib.name = section.section?.label ?? '';
        attrib.description = section.section?.name ?? '';
        attrib.tag = section.section?.label ?? '';

        if (section.section?.type == 'Repeat') {
            section.section?.pages.forEach(p => onProcessPage(p, attrib.children, true));
        } else {
            section.section?.questions.forEach(q => onProcessQuestion(q, attrib.children));
        }

        attributes.push(attrib);
    }

    const onProcessQuestion = (question: ProntoFormsFormQuestionDefinition, attributes: FormAttribute[]) => {
        const attrib = new FormAttribute();
        attrib.name = question.question?.label ?? '';
        attrib.description = question.question?.text ?? '';
        attrib.tag = question.question?.label ?? '';

        if (question.question?.data?.type == 'GeoLocation') {
            attrib.name = attrib.name + "_latitude";
            attrib.tag = attrib.tag + "_latitude";
            attrib.xPath = `sections/section/answers/answer[@label="${question.question?.label}"]/values/value/coordinates/latitude`;

            const longAttrib = new FormAttribute();
            longAttrib.name = (question.question?.label ?? '') + "_longitude";
            longAttrib.description = question.question?.text ?? '';
            longAttrib.tag = (question.question?.label ?? '') + "_longitude";
            longAttrib.xPath = `sections/section/answers/answer[@label="${question.question?.label}"]/values/value/coordinates/longitude`;
            attributes.push(longAttrib);

        } else if (question.question?.data?.type == 'Timestamp') {
            attrib.xPath = `sections/section/answers/answer[@label="${question.question?.label}"]/values/value/provided/time`;
        } else if (question.question?.data?.type == 'Image') {
            attrib.isImage = true;
            attrib.xPath = `sections/section/answers/answer[@label="${question.question?.label}"]/values/value/bytes/text()`;
        } else {
            attrib.xPath = `sections/section/answers/answer[@label="${question.question?.label}"]/values/value`;
        }

        attributes.push(attrib);
    }

    const drawFormChildren = (children: FormAttribute[], parent: FormAttribute | undefined, indent: number): React.ReactNode => {
        return children.map(f => {
            if (f.isDeleted === false) {
                return (
                    <>
                        <tr>
                            <td className="center-column" style={{ maxWidth: '25px' }}> {f.id > 0 ? f.id : ''}</td>
                            {/* <td className="center-column" style={{ maxWidth: '100px' }}> {f.key}</td> */}
                            <td style={{ paddingLeft: `${indent}px` }}>
                                {drawAttributePrefix(f)}
                                <span className={`${Themes.PrimaryDarkColor} ods-add-icon`} onClick={() => onAddAttribute(f)}><BsPlusLg /></span>
                                <span onClick={() => onEditAttribute(f, parent)}>
                                    <span className="gotham-bold">{f.name}</span>
                                    <span className={`${Themes.PrimaryDarkColor} ods-edit-icon`}><BsFillPencilFill /></span>
                                </span>
                            </td>
                            <td>{f.description}</td>
                            <td>{f.tag}</td>
                            <td>{f.xPath}</td>
                            <td className="center-column" style={{ maxWidth: '25px' }}><OdsIsActiveColumn isActive={f.isImage} /></td>
                            <td className="center-column" style={{ maxWidth: '25px' }}><OdsIsActiveColumn isActive={f.isRepeating} /></td>
                            <td className="center-column" style={{ maxWidth: '25px' }}><OdsIsActiveColumn isActive={f.isActive} /></td>
                            <td className="center-column" style={{ maxWidth: '25px' }}>
                                {f.children.length === 0 ?
                                    <span className={Themes.WarningDarkColor} onClick={() => onRemoveAttribute(f, parent)}><BsTrash /></span>
                                    : ''}
                            </td>
                        </tr>
                        {f.isCollapsed ? '' : drawFormChildren(f.children ?? [], f, indent + 24)}
                    </>);
            }
        });
    };

    const drawAttributePrefix = (attribute: FormAttribute) => {
        if (attribute.children.length == 0) {
            return <span style={{ paddingLeft: '20px' }}></span>;
        } else if (attribute.isCollapsed) {
            return <span className="ods-expand-icon" onClick={() => updateCollapsed(attribute, false)}><BsChevronRight /></span>;
        } else {
            return <span className="ods-expand-icon" onClick={() => updateCollapsed(attribute, true)}><BsChevronDown /></span>;
        }
    }

    const updateCollapsed = (attribute: FormAttribute, isCollapsed: boolean) => {
        attribute.isCollapsed = isCollapsed;
        forceUpdate();
    }

    const onSave = async () => {
        if (odsService && form) {
            setIsLoading(true);
            if (form.id === 0) {
                const newForm = await odsService.CreateFormAsync(form);
                navigate('../forms/management/' + newForm.id);
            } else {
                setForm(await odsService.UpdateFormAsync(form));
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchForms();
    }, [odsService]);


    return (
        <CvxPage desktop={
            <CvxLayoutCenterColumn>
                <OdsEditDispatchBodyDialog
                    show={showEditDispatchBody}
                    dispatchBody={form?.dispatchBody}
                    onComplete={onCompleteEditDispatchBody}
                />
                <OdsEditFormAttributeDialog
                    show={showEditAttribute}
                    attribute={editAttribute}
                    onComplete={onCompleteEditAttribute}
                />
                <CvxContentBlock>
                    <Container className="ods-content-block">
                        <Row>
                            <Col>
                                <Form.Label>Form Id</Form.Label>
                                <Form.Control disabled={true} value={formId} />
                            </Col>
                            <Col>
                                <Form.Label>Parent</Form.Label>
                                <Form.Control value={parentForm?.name} disabled={true} />
                            </Col>
                            <Col>
                                <Form.Label>Name</Form.Label>
                                <Form.Control value={form?.name} onChange={onNameChanged} />
                            </Col>
                            <Col>
                                <Form.Label>Description</Form.Label>
                                <Form.Control value={form?.description} onChange={onDescriptionChanged} />
                            </Col>
                            <Col>
                                <Form.Label>Priority #</Form.Label>
                                <Form.Control value={form?.sortOrder} onChange={onSortOrderChanged} />
                            </Col>
                            <Col className="align-self-end">
                                <CvxSpinnerButton className="ods-filter-button" theme='primary' showSpinner={isLoading} onClick={onSave}>Save</CvxSpinnerButton>
                                <CvxButton className="ods-filter-button" onClick={() => fetchForms()}>Refresh</CvxButton>
                            </Col>
                        </Row>
                    </Container>
                </CvxContentBlock>
                <CvxContentBlock>
                    <Container className="ods-content-block">
                        <Row>
                            <Col>
                                <Form.Label>Dispatch Form Id</Form.Label>
                                <Form.Control value={form?.dispatchFormId} onChange={onDispatchFormIdChanged} />
                            </Col>
                            <Col>
                                <Form.Label>Dispatch Body</Form.Label>
                                <Form.Control value={form?.dispatchBody} readOnly={true} />
                            </Col>
                            <Col className="align-self-end"><CvxButton onClick={onEditDispatchBody}>Edit</CvxButton></Col>
                            <Col>
                                <Form.Label>Retreive Submissions</Form.Label>
                                <Form.Check type="switch" checked={form?.retrieveFormSubmissions} onChange={onRetreiveSubmissionsChanged} />
                            </Col>
                            <Col>
                                <Form.Label>Last Submission Received</Form.Label>
                                <DatePicker className="form-control"
                                    selected={form?.submissionLastReceiveDateTimeUtc}
                                    onChange={onDateChanged}
                                />
                            </Col>
                        </Row>
                    </Container>
                </CvxContentBlock>
                <CvxTableContentBlock>
                    <div className="cvx-table ods-form-management">
                        <CvxHeading heading={
                            <span className="ods-table-header">
                                <span className="ods-table-header-text">Attributes</span>
                                <span className="ods-add-button">
                                    <CvxButton onClick={() => onAddAttribute(undefined)}><BsPlusLg />&nbsp;Add</CvxButton>
                                    &nbsp;
                                    {form?.attributes?.length === 0 ?
                                        // <input type="file" onChange={onImportForm} />
                                        <CvxButton onClick={onImportForm}><BsFillCloudArrowUpFill />&nbsp;Import&nbsp;Json</CvxButton>
                                        : ''}
                                </span>
                            </span>
                        }
                        />
                        <div className="table-scroll">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="center-column">Id</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Tag</th>
                                        <th>XPath</th>
                                        <th className="center-column">Is Image?</th>
                                        <th className="center-column">Is Repeating?</th>
                                        <th className="center-column">Is Active?</th>
                                        <th className="center-column"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading
                                        ? <tr className="loading">
                                            <td colSpan={10}>
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </td>
                                        </tr> :
                                        form?.attributes ? drawFormChildren(form.attributes, undefined, 0) : ''
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CvxTableContentBlock>
            </CvxLayoutCenterColumn>
        } />
    );
};


export default OdsFormManagementDetailView;
