export class BradenheadDispatch {
    public formDispatchId: number = 0;
    public referenceNumber?: string;
    public wellId?: number;
    public apiNo?: string;
    public wellName?: string;
    public routeName?: string;
    public wellCategory?: string;
    public userName?: string;
    public formId?: number;
    public formName?: string;
    public deleteFl?: string;
    public creationDateTimeUtc?: Date;
    public updateDateTimeUtc?: Date;

    public static mapDispatch(data: any): BradenheadDispatch {
        const d = new BradenheadDispatch;

        d.formDispatchId = data.formDispatchId;
        d.referenceNumber = data.referenceNumber;
        d.wellId = data.wellId;
        d.apiNo = data.apiNo;
        d.wellName = data.wellName;
        d.routeName = data.routeName;
        d.wellCategory = data.wellCategory;
        d.userName = data.userName;
        d.formId = data.formId;
        d.formName = data.formName;
        d.deleteFl = data.deleteFl;
        d.creationDateTimeUtc = new Date(data.creationDateTimeUtc);
        d.updateDateTimeUtc = new Date(data.updateDateTimeUtc);

        return d;
    };
}
