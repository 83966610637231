import { number } from "yup";
import { AvoVocInspectionFrequency } from "./avo-voc-inspection-frequency";
import { Facility } from "./facility";

export class AvoVocFacilityInspectionFrequency {
    public id: number = 0;
    public avoInspectionFrequency: string = 'None';
    public vocInspectionFrequency: string = 'None';

    public get facilityId(): number { return this.id; };
    public facility?: Facility;

    public static map(data: any): AvoVocFacilityInspectionFrequency {
        const freq = new AvoVocFacilityInspectionFrequency();

        freq.id = data.facilityId;
        freq.avoInspectionFrequency = data.avoInspectionFrequency;
        freq.vocInspectionFrequency = data.vocInspectionFrequency;

        return freq;
    };

    public static inspectionFrequencyTypes: AvoVocInspectionFrequency[] = [
        { name: 'None' },
        { name: 'Daily' },
        { name: 'Weekly' },
        { name: 'Monthly' },
    ];
};
