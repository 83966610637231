import { RolePermission } from "./role-permission";

export class Role {
    public id: number = 0;
    public name: string = '';
    public description: string = '';
    public rolePermissions: RolePermission[] = [];
    public isActive: boolean = true;

    public static map(data: any): Role {
        const role = new Role();

        role.id = parseInt(data.id);
        role.name = data.name ?? '';
        role.description = data.description ?? '';
        role.rolePermissions = data.rolePermissions?.map((d: any) => RolePermission.map(d)) ?? [];
        role.isActive = data.isActive ?? false;

        return role;
    };
};
