export class State {
    public id: number = 0;
    public name: string = '';

    public static map(data: any): State {
        const m = new State();

        m.id = parseInt(data.id);
        m.name = data.name ?? '';

        return m;
    };
};
