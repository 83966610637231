import React from "react";

export interface CvxContentBlockProps {
    children?: React.ReactNode;
}

export const CvxContentBlock: React.FC<CvxContentBlockProps> = ({
    ...props
}) => {
    return (
        <div className="cvx-content-block">
            {props.children}
        </div>
    );
};

export default CvxContentBlock;
