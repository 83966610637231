import { ErrorResult } from "./error-result";

export class ApiError extends Error {
    constructor(error: ErrorResult) {
        super(error.title);
        this.error = error;
    }

    public error: ErrorResult;
};
