export class DispatchForm {
    public id: number = 0;
    public name: string = '';
    public description?: string;
    public externalId?: string;

    public static map(data: any): DispatchForm {
        const m = new DispatchForm();

        m.id = data.id;
        m.name = data.name;
        m.description = data.description;
        m.externalId = data.externalId;

        return m;
    };
}
