import Multiselect from 'multiselect-react-dropdown';
import { useEffect, useState } from 'react';
import { Form, Modal, Row } from 'react-bootstrap';
import { ColumnDefinition, CvxButton } from '../../modules/cvx-ui-module';

export interface OdsTableFilterDialogProps<T> {
    show: boolean;
    list?: T[];
    column?: ColumnDefinition<T>;
    filterValues: any[];
    onComplete: (dialogResult: boolean, filterValues: any[]) => Promise<any>;
}

interface Filter {
}

const OdsTableFilterDialog = <T extends Filter>({
    show,
    list,
    column,
    filterValues,
    onComplete,
    ...props
}: OdsTableFilterDialogProps<T>) => {
    const [filterList, setFilterList] = useState<any[]>([]);

    const onCancel = () => {
        onComplete(false, []);
    };

    const onApply = () => {
        onComplete(true, filterValues);
    };

    const onClear = () => {
        onComplete(true, []);
    };

    const onSelect = (selectedList: any, selectedItem: any) => {
        filterValues = selectedList ?? [];
    };

    const onRemove = (selectedList: any, selectedItem: any) => {
        filterValues = selectedList ?? [];
    }

    useEffect(() => {
        if (list && column) {
            const fl: any[] = [];
            list.forEach(i => {
                if (column.sortFilterValue) {
                    const value = column.sortFilterValue(i);
                    if (value) {
                        fl.push(value);
                    }
                }
            });

            setFilterList(fl.filter((v, i, a) => a.indexOf(v) === i));
        }
    }, [list, column]);

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Filter '{column?.header}'</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Form.Group controlId="filter">
                            <Form.Label>Filter</Form.Label>
                            <Multiselect
                                placeholder="Filter"
                                selectedValues={filterValues}
                                options={['-- Blanks --', ...filterList]}
                                isObject={false}
                                onSelect={onSelect}
                                onRemove={onRemove}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxButton theme='primary' onClick={onClear}>Clear</CvxButton>
                <CvxButton theme='primary' onClick={onApply}>Apply</CvxButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsTableFilterDialog;
