import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { OrdInspectionRequestStatus } from "../../models/ord-inspection-request-status";
import { OdsOrdApiService } from "../../services";

export interface OdsOrdStatusChipProps {
    service: OdsOrdApiService;
    inspectionRequest: Item;
}

interface Item {
    statusId?: number;
    status?: OrdInspectionRequestStatus;
}

export const OdsOrdStatusChip: React.FC<OdsOrdStatusChipProps> = ({
    service,
    inspectionRequest
}) => {
    const [data, setData] = useState<OrdInspectionRequestStatus>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        if (inspectionRequest && inspectionRequest.statusId) {
            const data = await service.RetrieveOrdInspectionRequestStatusAsync(inspectionRequest.statusId)
            inspectionRequest.status = data;
            setData(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [inspectionRequest])

    return (
        <span className="ods-item-chip">{isLoading === false ? data?.name : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>}</span>
    )
}
