import { HtmlHTMLAttributes, PropsWithChildren } from "react";

export interface OdsIntegerColumnProps {
}

export const OdsIntegerColumn: React.FC<PropsWithChildren<OdsIntegerColumnProps> & HtmlHTMLAttributes<HTMLTableCellElement>> = ({
    ...props
}) => {
    return (
        <td {...props} className="center-column">{props.children}</td>
    )
}
