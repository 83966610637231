import { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import OdsBaseTable from "../../components/base-table/base-table";
import { ColumnDefinition, CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";

export interface OdsMultiDeleteItemDialogProps {
    show: boolean;
    header: string;
    data: any[];
    columns: ColumnDefinition<any>[];
    onComplete: (dialogResult: boolean) => Promise<void>;
}

const OdsMultiDeleteItemDialog: React.FC<OdsMultiDeleteItemDialogProps> = ({
    show,
    header,
    data,
    columns,
    onComplete,
    ...props
}) => {
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onSubmit = async (): Promise<any> => {
        setIsSaving(true);
        await onComplete(true);
        setIsSaving(false);
    };

    const onCancel = () => {
        if (onComplete) {
            onComplete(false);
        }
    };

    return (
        <Modal className="theme-blue" size="xl" show={show} centered backdrop="static" onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Delete {header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col style={{ marginTop: '20px' }}>
                            <OdsBaseTable heading={`Delete ${header}`} columns={columns} dataSource={data} isLoading={false} overridePageSize={10} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Cancel</CvxButton>
                <CvxSpinnerButton theme='primary' showSpinner={isSaving} onClick={onSubmit}>Delete All</CvxSpinnerButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsMultiDeleteItemDialog;
