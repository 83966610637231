import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { BsClipboardPlus } from "react-icons/bs";
import { OdsAsyncMultiSelectControl } from "../../../components/async-multi-select/async-multi-select.control";
import { IntegerFilter } from "../../../models/integer-filter";
import { ListResult } from "../../../models/list-result";
import { CvxButton } from "../../../modules/cvx-ui-module";

export interface OdsIntegerFilterDialogProps {
    show: boolean;
    header: React.ReactNode;
    filter?: IntegerFilter;
    onSearch: (top: number, skip: number, filter: string) => Promise<ListResult<string>>;
    onComplete: (dialogResult: boolean, filter?: IntegerFilter) => void;
}

export const OdsIntegerFilterDialog: React.FC<OdsIntegerFilterDialogProps> = ({
    show,
    header,
    filter,
    onSearch,
    onComplete,
    ...props
}) => {

    const [lessValue, setLessValue] = useState<string>('');
    const [useLessValue, setUseLessValue] = useState<boolean>(false);

    const [greaterValue, setGreaterValue] = useState<string>('');
    const [useGreaterValue, setUseGreaterValue] = useState<boolean>(false);

    const [equalValue, setEqualValue] = useState<string[]>([]);
    const [useEqualValue, setUseEqualValue] = useState<boolean>(false);

    const onLessValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLessValue(e.target.value);
        setUseLessValue(e.target.value !== '' && e.target.value !== undefined);
    }

    const onGreaterValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGreaterValue(e.target.value);
        setUseGreaterValue(e.target.value !== '' && e.target.value !== undefined);
    }

    const onEqualValueChanged = (selected: string[]) => {
        setEqualValue(selected);
        setUseEqualValue(selected?.length > 0);
    }

    const onPasteItems = async () => {
        const text = await navigator.clipboard.readText();

        const selected: string[] = [];
        (text ?? '').split('\n').forEach(i => {
            i = i.trim();
            if (i.trim().length > 0) {
                selected.push(i);
            }
        });

        setEqualValue(selected);
        setUseEqualValue(selected?.length > 0);
    }

    const onCancel = () => {
        onComplete(false, undefined);
    }

    const onClear = () => {
        setLessValue('');
        setUseLessValue(false);

        setGreaterValue('');
        setUseGreaterValue(false);

        setEqualValue([]);
        setUseEqualValue(false);

        onComplete(true, undefined);
    }

    const onApply = () => {
        if (!useLessValue && !useGreaterValue && !useEqualValue) {
            onClear();
        } else {
            const filter = new IntegerFilter();

            if (useLessValue) {
                filter.lessThan = parseInt(lessValue);
            }

            if (useGreaterValue) {
                filter.greaterThan = parseInt(greaterValue);
            }

            if (useEqualValue) {
                filter.equal = equalValue.map(s => parseInt(s));
            }

            onComplete(true, filter);
        }
    }

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Filter '{header}'</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useLessValue} onChange={() => setUseLessValue(!useLessValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="lessThanFilter">
                                <Form.Label>{header} less than </Form.Label>
                                <Form.Control type="text" value={lessValue} onChange={onLessValueChanged}></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useGreaterValue} onChange={() => setUseGreaterValue(!useGreaterValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="greaterThanFilter">
                                <Form.Label>{header} greater than </Form.Label>
                                <Form.Control type="text" value={greaterValue} onChange={onGreaterValueChanged}></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useEqualValue} onChange={() => setUseEqualValue(!useEqualValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="inFilter">
                                <Form.Label>{header} equals </Form.Label>
                                <OdsAsyncMultiSelectControl
                                    id="equals filter"
                                    onSearch={onSearch}
                                    multiple={true}
                                    selected={equalValue}
                                    onChange={onEqualValueChanged}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="align-self-end" md="auto">
                            <CvxButton onClick={onPasteItems}><BsClipboardPlus /></CvxButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxButton theme='primary' onClick={onClear}>Clear</CvxButton>
                <CvxButton theme='primary' onClick={onApply}>Apply</CvxButton>
            </Modal.Footer>
        </Modal>
    );
};
