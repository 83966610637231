import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FormImage } from "../../../models/form-image";
import { CvxLayoutCenterColumn, CvxPage } from "../../../modules/cvx-ui-module";
import CvxContentBlock from "../../../modules/cvx-ui-module/components/content-block/content-block";
import OdsService from "../../../services/ods-service";


export interface OdsFormImageViewProps {
    odsService: OdsService | undefined;
}


const OdsFormImageView: React.FC<OdsFormImageViewProps> = ({
    odsService,
    ...props
}) => {
    const { imageId } = useParams();

    const [formImage, setFormImage] = useState<FormImage | undefined>(undefined);

    const fetchForms = async () => {
        if (odsService && imageId) {
          setFormImage(await odsService.RetrieveFormImageAsync(imageId));
        }
    };

    useEffect(() => {
        fetchForms();
    }, [odsService]);

    return (
        <CvxPage desktop={
            <CvxLayoutCenterColumn>
                <CvxContentBlock>
                    <img src={`data:${formImage?.imageContentType};base64,${formImage?.imageData}`} />
                </CvxContentBlock>
            </CvxLayoutCenterColumn>
        } />
    );
};


export default OdsFormImageView;
