export class DateHelper {
    public static isLeapYear = function (year: number) {
        return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
    };

    public static getDaysInMonth = function (year: number, month: number) {
        return [31, (DateHelper.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    };

    public static isLeapYearDate = function (date: Date) {
        return DateHelper.isLeapYear(date.getFullYear());
    };

    public static getDaysInMonthDate = function (date: Date) {
        return DateHelper.getDaysInMonth(date.getFullYear(), date.getMonth());
    };

    public static addMonths = function (date: Date, value: number) {
        let n = date.getDate();

        date.setDate(1);
        date.setMonth(date.getMonth() + value);
        date.setDate(Math.min(n, DateHelper.getDaysInMonthDate(date)));

        return date;
    };


    public static toSortString(date: Date | undefined) : string {
        if(!date) {
            return '';
        }

        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');       
    }
}