import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { BsCloudHazeFill, BsDice4Fill, BsDice2Fill, BsDiscFill, BsFillHouseFill } from "react-icons/bs";

interface Item {
    isConsentDecree: boolean;
    isOccupiedArea: boolean;
    isDisproportionatelyImpactedCommunity: boolean;
    isOOOOa: boolean;
    isOOOOb: boolean;
}

export interface OdsLdarFlagIconProps {
    item?: Item;
};

export const OdsLdarFlagIcon: React.FC<OdsLdarFlagIconProps> = ({
    item,
}) => {
    return (
        <span className="ods-item-chip ods-ldar-flags cvx-primary-dark-color">
            <OverlayTrigger
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 250, hide: 400 }}
                overlay={<Popover className="ods-tooltip"><Popover.Body>Consent Decree</Popover.Body></Popover>}
            >
                <span>{item?.isConsentDecree ? <BsDiscFill /> : ''}</span>
            </OverlayTrigger>
            <OverlayTrigger
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 250, hide: 400 }}
                overlay={<Popover className="ods-tooltip"><Popover.Body>Occupied Area</Popover.Body></Popover>}
            >
                <span>{item?.isOccupiedArea ? <BsFillHouseFill /> : ''}</span>
            </OverlayTrigger>
            <OverlayTrigger
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 250, hide: 400 }}
                overlay={<Popover className="ods-tooltip"><Popover.Body>Disproportionately Impacted Community</Popover.Body></Popover>}
            >
                <span>{item?.isDisproportionatelyImpactedCommunity ? <BsCloudHazeFill /> : ''}</span>
            </OverlayTrigger>
            <OverlayTrigger
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 250, hide: 400 }}
                overlay={<Popover className="ods-tooltip"><Popover.Body>OOOOa</Popover.Body></Popover>}
            >
                <span>{item?.isOOOOa ? <BsDice4Fill /> : ''}</span>
            </OverlayTrigger>
            <OverlayTrigger
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 250, hide: 400 }}
                overlay={<Popover className="ods-tooltip"><Popover.Body>OOOOb</Popover.Body></Popover>}
            >
                <span>{item?.isOOOOb ? <BsDice2Fill /> : ''}</span>
            </OverlayTrigger>
        </span>
    )
}
