import { BooleanFilter } from "./boolean-filter";
import { IntegerFilter } from "./integer-filter";
import { StringFilter } from "./string-filter";

export class FormDataSourceFilter {
    public formSpaceId?: IntegerFilter;
    public dataSourceId?: IntegerFilter;
    public name?: StringFilter;
    public description?: StringFilter;
    public sqlQuery?: StringFilter;
    public isActive?: BooleanFilter;
}
