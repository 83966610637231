export class ProntoFormsDispatch {
    public id: number = 0;
    public referenceNumber?: number;
    public wellId?: number;
    public apiNumber?: string;
    public wellName?: string;
    public routeName?: string;
    public userName?: string;
    public formId?: number;
    public formName?: string;
    public dispatchBody?: string;
    public dispatchResult?: string;
    public itemState?: string;
    public facilityId?: number;
    public facilityName?: string;
    public lodId?: string;
    public lodName?: string;
    public contextData?: string;
    public nblPnfRequestId?: number;
    public assetNumber?: string;
    public sentDate?: Date;
    public commentText?: string;
    public consentDecree?: string;
    public occupiedArea?: string;
    public assetDescription?: string;
    public assetDescriptionShort?: string;
    public taskId?: string;
    public dueDate?: Date;
    public inspectionType?: string;

    public static mapDispatch(data: any): ProntoFormsDispatch {
        const d = new ProntoFormsDispatch;

        d.id = data.id;
        d.referenceNumber = data.referenceNumber;
        d.wellId = data.wellId;

        d.apiNumber = data.apiNumber;
        d.wellName = data.wellName;
        d.routeName = data.routeName;
        d.userName = data.userName;
        d.formId = data.formId;
        d.formName = data.formName;
        d.dispatchBody = data.dispatchBody;
        d.dispatchResult = (!data.dispatchResult || data.dispatchResult === '') ? undefined : data.dispatchResult;
        d.itemState = data.itemState;
        d.facilityId = data.facilityId;
        d.facilityName = data.facilityName;
        d.lodId = data.lodId;
        d.lodName = data.lodName;
        d.contextData = data.contextData;
        d.nblPnfRequestId = data.nblPnfRequestId;
        d.assetNumber = data.assetNumber;
        d.sentDate = data.sentDate ? new Date(data.sentDate) : undefined;
        d.commentText = data.commentText;
        d.consentDecree = data.consentDecree;
        d.occupiedArea = data.occupiedArea;
        d.assetDescription = data.assetDescription;
        d.assetDescriptionShort = data.assetDescriptionShort;
        d.taskId = data.taskId;
        d.dueDate = data.dueDate ? new Date(data.dueDate) : undefined;
        d.inspectionType = data.inspectionType;

        return d;
    };
};
