export class OrdInspectionRequestStatus {
    public id?: number;
    public name: string = '';

    public static map(data: any): OrdInspectionRequestStatus {
        const m = new OrdInspectionRequestStatus;

        m.id = data.id;
        m.name = data.name;

        return m;
    };


    public static active: number = 1000;
    public static new: number = 1006;
};
