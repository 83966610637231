import { useState } from 'react';
import { Token } from 'react-bootstrap-typeahead';
import * as yup from 'yup';
import { OdsAsyncMultiSelectControl } from '../../components';
import { OdsAsyncMultiSelect } from '../../components/form-editors/async-multi-select/async-multi-select';
import { OdsAsyncSelect } from '../../components/form-editors/async-select/async-select';
import OdsInput from '../../components/form-editors/input/input';
import { OdsStateAsyncSelect } from '../../components/form-editors/state-async-select/state-async-select';
import OdsSwitch from '../../components/form-editors/switch/switch';
import OdsTextArea from '../../components/form-editors/textarea/textarea';
import { useMyself } from '../../hooks/myself-hook';
import { Personnel, PersonnelFilter, PersonnelGroup, PersonnelGroupTypeFilter, StringFilter } from '../../models';
import { Permission } from '../../models/permission';
import { OdsPersonnelApiService } from '../../services';
import OdsBaseAddEditDialog from '../base-add-edit-dialog/base-add-edit.dialog';

export interface OdsEditPersonnelGroupDialogProps {
    service: OdsPersonnelApiService;
    show: boolean;
    personnelGroup: PersonnelGroup | undefined;
    onComplete: (dialogResult: boolean, personnel: PersonnelGroup | undefined) => Promise<any>;
}

export const OdsEditPersonnelGroupDialog: React.FC<OdsEditPersonnelGroupDialogProps> = ({
    service,
    show,
    personnelGroup,
    onComplete,
    ...props
}) => {

    const [canEdit, showState, states] = useMyself(service, Permission.EditPersonnel);

    const [personnel, setPersonnel] = useState<Personnel[]>([]);

    const groupSchema = yup.object().shape({
        name: yup.string().required().max(250)
            .test('duplicate', 'name is already in use', async (value: string | undefined, testContext: any) => {
                if (value) {
                    return await service.ValidatePersonnelGroupNameAsync(value, testContext.parent.state?.id, personnelGroup?.id);
                }
                return true;
            }),
        description: yup.string().nullable(),
        personnelGroupType: yup.object().nullable().required(),
        personnel: yup.array().nullable(),
        isActive: yup.boolean().required(),
    });

    const onSearchPersonnelGroupTypes = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelGroupTypeFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        let data = await service.RetrievePersonnelGroupTypeListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    const onSearchPersonnel = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelFilter();

        filter.fullName = stringFilter;
        filter.fullName.like = filterStr;

        let data = await service.RetrievePersonnelListAsync(top, skip, 'LastName', 'Asc', filter);
        return data;
    };

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={personnelGroup?.id ? 'Edit Personnel Group: ' + personnelGroup.name : 'Add Personnel Group'}
            validationSchema={groupSchema}
            initialValue={personnelGroup ?? new PersonnelGroup()}
            onComplete={onComplete}
        >
            <OdsInput name="name" label="Name" />
            <OdsTextArea name="description" label="Description" />
            <OdsStateAsyncSelect name="state" label="State" service={service} states={states} />
            <OdsAsyncSelect
                name="personnelGroupType"
                label="Group Type"
                labelKey="name"
                onSearch={onSearchPersonnelGroupTypes}
            />
            <OdsAsyncMultiSelect
                name="personnel"
                label="Personnel"
                labelKey="email"
                onSearch={onSearchPersonnel}
                renderMenuItemChildren={(item) => <span>{item.lastName}, {item.firstName} ({item.email})</span>}
                renderToken={(item, { onRemove }, index) => <Token key={index} onRemove={onRemove} option={item}>{item.lastName}, {item.firstName} ({item.email})</Token>}
            />
            <OdsSwitch name="isActive" label="Is Active" />
        </OdsBaseAddEditDialog>
    );
};
