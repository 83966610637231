export class ReportMonth {
    public id: number = 0;
    public name: string = '';

    public static mapReportMonth(data: ReportMonth) : ReportMonth {
        const m = new ReportMonth();

        m.id = data.id;
        m.name = data.name;

        return m;
    };
};