import { FieldHookConfig, useField } from "formik";
import React from "react";
import { Form, Row } from "react-bootstrap";

export interface OdsSwitchProps {
    label: string;
}

const OdsSwitch: React.FC<OdsSwitchProps & FieldHookConfig<boolean>> = ({
    label,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);

    return (
        <Row>
            <Form.Group controlId={field.name}>
                <Form.Label>{label}</Form.Label>
                <Form.Check 
                    name={field.name}
                    type="switch" 
                    placeholder={label} 
                    checked={field.value}
                    onChange={field.onChange} 
                    onBlur={field.onBlur}
                    isInvalid={!!meta.touched && !!meta.error}
                />
                <Form.Control.Feedback type="invalid">
                    {meta.error}
                </Form.Control.Feedback>
            </Form.Group>
        </Row>
    )
};

export default OdsSwitch;
