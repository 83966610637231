export class AvoVocDispatch {
    public inspectionRequestId? : number;
    public inspectionType?: string;
    public frequency?: string;
    public facilityId?: number;
    public facilityName?: string;
    public routeId?: number;
    public routeName?: string;
    public startDate?: Date;
    public sentDate?: Date;
    public dueDate?: Date;
    public completedDate?: Date;
    public inspectionDate?: Date;
    public userName?: string;
    public referenceNumber?: string;
    public completed?: string;
    public completionType?: string;
    public completionUser?: string;

    public static mapDispatch(data: any): AvoVocDispatch {
        const d = new AvoVocDispatch;

        d.inspectionRequestId = data.inspectionRequestId;
        d.inspectionType = data.inspectionType;
        d.frequency = data.frequency;
        d.facilityId = data.facilityId;
        d.facilityName = data.facilityName;
        d.routeId = data.routeId;
        d.routeName = data.routeName;
        d.startDate = data.startDate ? new Date(data.startDate) : undefined;
        d.sentDate = data.sentDate ? new Date(data.sentDate) : undefined;
        d.dueDate = data.dueDate ? new Date(data.dueDate) : undefined;
        d.completedDate = data.completedDate ? new Date(data.completedDate) : undefined;
        d.inspectionDate = data.inspectionDate ? new Date(data.inspectionDate) : undefined;
        d.userName = data.userName;
        d.referenceNumber = data.referenceNumber;
        d.completed = data.completed;
        d.completionType = data.completionType;
        d.completionUser = data.completionUser;

        return d;
    };
};
