import { useEffect, useState } from "react";
import OdsBaseTable from "../../../components/base-table/base-table";
import OdsFormSubmissionFilter from "../../../components/form-submission-filter/form-submission-filter";
import OdsIsActiveColumn from "../../../components/table-columns/is-active-check-column/is-active-check-column";
import OdsShowFormAttributeDataDialog from "../../../dialogs/show-form-attribute-data/show-form-attribute-data.dialog";
import OdsShowFormXmlDialog from "../../../dialogs/show-form-xml/show-form-xml.dialog";
import { EntityView } from "../../../models/entity-view";
import { FormDefinition } from "../../../models/form-definition";
import { FormSubmission } from "../../../models/form-submission";
import { ColumnDefinition, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import CvxContentBlock from "../../../modules/cvx-ui-module/components/content-block/content-block";
import OdsService from "../../../services/ods-service";


export interface OdsFormSubmissionsViewProps {
    odsService: OdsService | undefined;
}


const OdsFormSubmissionsView: React.FC<OdsFormSubmissionsViewProps> = ({
    odsService,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [forms, setForms] = useState<FormDefinition[]>([]);
    const [formSubmissions, setFormSubmissions] = useState<FormSubmission[]>([]);

    const [showXml, setShowXml] = useState<boolean>(false);
    const [showXmlId, setShowXmlId] = useState<number | undefined>();

    const [showAttributeData, setShowAttributeData] = useState<boolean>(false);
    const [showAttributeDataId, setShowAttributeDataId] = useState<string | undefined>();

    const columns: ColumnDefinition<FormSubmission>[] = [
        {
            header: 'PNF Request Id', align: 'center', canSort: true, canFilter: true,
            template: ((i) => i.id), sortFilterValue: ((i) => i.id),
        },
        {
            header: 'Reference Number', align: 'left', canSort: true, canFilter: true,
            template: ((i) => i.referenceNumber), sortFilterValue: ((i) => i.referenceNumber),
        },
        {
            header: 'Server Receive Date', align: 'left', canSort: true, canFilter: true,
            template: ((i) => i.serverReceiveDate?.toLocaleDateString() + ' ' + i.serverReceiveDate?.toLocaleTimeString()), sortFilterValue: ((i) => i.serverReceiveDate?.toLocaleDateString()),
            sortDirection: 'Desc'
        },
        {
            header: 'Form Id', align: 'left', canSort: true, canFilter: true,
            template: ((i) => i.formId), sortFilterValue: ((i) => i.formId),
        },
        {
            header: 'User Name', align: 'left', canSort: true, canFilter: true,
            template: ((i) => i.userName), sortFilterValue: ((i) => i.userName),
        },
        {
            header: 'Form Data', align: 'center',
            template: ((i) => <a href="" onClick={(e) => { onShowXmlData(i.id); e.preventDefault(); }}>XML</a>)
        },
        {
            header: 'Attribute Data', align: 'center',
            template: ((i) => i.referenceNumber ? <a href="" onClick={(e) => { onShowAttributeData(i.referenceNumber); e.preventDefault(); }}>View/Edit</a> : 'No Attribute Data'),
            sortFilterValue: ((i) => i.id),
        },
        {
            header: 'Active', align: 'center', canSort: true, canFilter: true,
            template: ((i) => <OdsIsActiveColumn isActive={i.isActive} />), sortFilterValue: ((i) => i.isActive),
            filterValues: [true]
        },
    ];

    const fetchForms = async () => {
        if (odsService) {
            const allForms = await odsService.RetrieveFormListAsync();
            const dispatchForms: FormDefinition[] = [];

            allForms.forEach(f => {
                if (f.dispatchFormId) {
                    dispatchForms.push(f);
                }
            });

            setForms(dispatchForms);
        }
    };

    const fetchFormSubmissions = async (formId: number | undefined, startDate: Date | undefined, endDate: Date | undefined) => {
        if (odsService) {
            setIsLoading(true);

            let form = forms.find(f => f.id === formId);

            if (form) {
                let formSubmissions = await odsService.RetrieveFormSubmissionsAsync(form.dispatchFormId, startDate, endDate);
                setFormSubmissions(formSubmissions);
            }

            setIsLoading(false);
        }
    }

    const onShowXmlData = (pnfRequestId: number | undefined) => {
        setShowXmlId(pnfRequestId);
        setShowXml(true);
    };

    const onCloseXmlData = async () => {
        setShowXmlId(undefined);
        setShowXml(false);
    };

    const onShowAttributeData = (referenceNumber: string | undefined) => {
        setShowAttributeDataId(referenceNumber);
        setShowAttributeData(true);
    };

    const onCloseAttributeData = async (dialogResult: boolean, referenceNumber: string, updateValues: EntityView[]) => {
        if(odsService && dialogResult && updateValues.length > 0) {
            await odsService.UpdateFormSubmissionEntityDataAsync(referenceNumber, updateValues);
        }

        setShowAttributeDataId(undefined);
        setShowAttributeData(false);
    };

    useEffect(() => {
        fetchForms();
    }, [odsService]);

    return (
        <CvxPage desktop={
            <CvxLayoutCenterColumn>
                <OdsShowFormXmlDialog
                    show={showXml}
                    pnfRequestId={showXmlId}
                    odsService={odsService}
                    onComplete={onCloseXmlData}
                />
                <OdsShowFormAttributeDataDialog
                    show={showAttributeData}
                    referenceNumber={showAttributeDataId}
                    odsService={odsService}
                    onComplete={onCloseAttributeData}
                />
                <OdsFormSubmissionFilter
                    forms={forms}
                    onFetchForms={fetchFormSubmissions}
                />
                <CvxTableContentBlock>
                    <OdsBaseTable
                        heading={<span>Form Submissions</span>}
                        columns={columns}
                        dataSource={formSubmissions}
                        isLoading={isLoading}
                        canAdd={false}
                        canEdit={false}
                        canRemove={false}
                    />
                </CvxTableContentBlock>
            </CvxLayoutCenterColumn>
        } />
    );
};


export default OdsFormSubmissionsView;
