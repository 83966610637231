import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { LdarInspectionRequest } from "../../models";
import { LdarInspectionRequestStatus } from "../../models/ldar-inspection-request-status";
import { OdsLdarApiService } from "../../services/ods-ldar-api-service";

interface Item {
    statusId: number;
    status?: LdarInspectionRequestStatus;
};

export interface OdsLdarStatusChipProps {
    service: OdsLdarApiService;
    inspectionRequest: Item;
};

export const OdsLdarStatusChip: React.FC<OdsLdarStatusChipProps> = ({
    service,
    inspectionRequest
}) => {
    const [data, setData] = useState<LdarInspectionRequestStatus>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        if (inspectionRequest && inspectionRequest.statusId) {
            const data = await service.RetrieveLdarInspectionRequestStatusAsync(inspectionRequest.statusId)
            inspectionRequest.status = data;
            setData(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [inspectionRequest])

    return (
        <span className="ods-item-chip">{isLoading === false ? data?.name : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>}</span>
    )
}
