import React, { useState } from 'react';
import { Form, Modal, Row } from 'react-bootstrap';
import OdsBaseTable from '../../components/base-table/base-table';
import { CvxButton } from '../../modules/cvx-ui-module';

export interface OdsListFilterDialogProps {
    show: boolean;
    columnHeader: string;
    onComplete: (dialogResult: boolean, filterValues: string[]) => void;
}

interface Filter {
}

const OdsListFilterDialog: React.FC<OdsListFilterDialogProps> = ({
    show,
    columnHeader,
    onComplete,
    ...props
}) => {

    const [filterStr, setfilterStr] = useState<string>('');
    const [filterValues, setFilterValues] = useState<string[]>([]);

    const onCancel = () => {
        onComplete(false, []);
    };

    const onApply = () => {
        onComplete(true, filterValues);
    };

    const onClear = () => {
        setFilterValues([]);
    };

    const onChange = (evt: any) => {
        const filter: string = evt.target.value;
        const filterValues: string[] = [];

        (filter ?? '').split(' ').forEach(i => filterValues.push(i));

        setfilterStr('');
        setFilterValues(filterValues);
    };

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' scrollable={true} onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Filter '{columnHeader}'</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Form.Group controlId="filter">
                            <Form.Control placeholder='Paste List' value={filterStr} onChange={(evt) => onChange(evt)}></Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <OdsBaseTable
                                isLoading={false}
                                dataSource={filterValues}
                                heading='Filter Values'
                                columns={[
                                    { header: columnHeader, template: ((i) => i) }
                                ]}
                                overridePageSize={10}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxButton theme='primary' onClick={onClear}>Clear</CvxButton>
                <CvxButton theme='primary' onClick={onApply}>Apply</CvxButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsListFilterDialog;
