import { IPublicClientApplication } from "@azure/msal-browser";
import { ApiError } from "../models/api-error";
import { ErrorResult } from "../models/error-result";

export class ApiService {
    private _msalInstance: IPublicClientApplication | undefined;
    private _scopes: string[];

    private async AquireTokenAsync(): Promise<string | undefined> {
        const request = {
            account: this._msalInstance?.getAllAccounts()[0],
            scopes: this._scopes,
        };

        let accessToken = undefined;

        if (this._msalInstance) {
            await this._msalInstance.acquireTokenSilent(request).then((response) => {
                accessToken = response.accessToken;
            }).catch((e) => {
                this._msalInstance?.acquireTokenRedirect(request);
            });
        }

        return accessToken;
    }

    private delay = (retryCount: number) => new Promise(resolve => setTimeout(resolve, 100 ** retryCount));


    private getWithRetry = async <T>(url: string, numOfRetries: number = 3, retryCount: number = 0, lastError: ErrorResult | undefined = undefined): Promise<T> => {
        try {
            const accessToken = await this.AquireTokenAsync();

            if (!accessToken) {
                throw new Error('credentials have expired. please sign in.');
            }

            const headers = new Headers();
            headers.append('Authorization', 'bearer ' + accessToken);
            headers.append('Content-Type', 'application/json');

            const response = await fetch(url, { method: 'GET', headers: headers });

            if (response.status >= 300) {
                lastError = await response.json();

                if (response.status >= 400 && response.status < 500) {
                    retryCount = numOfRetries;
                }

                throw new ApiError(lastError ?? new ErrorResult());
            }

            return await response.json();

        } catch (e) {

            if (retryCount >= numOfRetries) {
                throw new ApiError(lastError ?? new ErrorResult());
            }

            await this.delay(retryCount);
            return this.getWithRetry(url, numOfRetries, retryCount + 1, lastError);
        }
    }

    private postWithRetry = async <T>(method: string, url: string, body: any, numOfRetries: number = 3, retryCount: number = 0, lastError: ErrorResult | undefined = undefined): Promise<T> => {
        try {
            const accessToken = await this.AquireTokenAsync();

            if (!accessToken) {
                throw new Error('credentials have expired. please sign in.');
            }

            const headers = new Headers();
            headers.append('Authorization', 'bearer ' + accessToken);
            headers.append('Content-Type', 'application/json');

            const response = await fetch(url, { method: method, headers: headers, body: body });

            if (response.status >= 300) {
                lastError = await response.json();

                if (response.status >= 400 && response.status < 500) {
                    retryCount = numOfRetries;
                }

                throw new ApiError(lastError ?? new ErrorResult());
            }

            return await response.json();

        } catch (e) {

            if (retryCount >= numOfRetries) {
                throw new ApiError(lastError ?? new ErrorResult());
            }

            await this.delay(retryCount);
            return this.postWithRetry(method, url, body, numOfRetries, retryCount + 1, lastError);
        }
    }

    private deleteWithRetry = async <T>(url: string, numOfRetries: number = 3, retryCount: number = 0, lastError: ErrorResult | undefined = undefined): Promise<void> => {
        try {
            const accessToken = await this.AquireTokenAsync();

            if (!accessToken) {
                throw new Error('credentials have expired. please sign in.');
            }

            const headers = new Headers();
            headers.append('Authorization', 'bearer ' + accessToken);
            headers.append('Content-Type', 'application/json');

            const response = await fetch(url, { method: 'DELETE', headers: headers });

            if (response.status >= 300) {
                lastError = await response.json();

                if (response.status >= 400 && response.status < 500) {
                    retryCount = numOfRetries;
                }

                throw new ApiError(lastError ?? new ErrorResult());
            }

        } catch (e) {

            if (retryCount >= numOfRetries) {
                throw new ApiError(lastError ?? new ErrorResult());
            }

            await this.delay(retryCount);
            return this.deleteWithRetry(url, numOfRetries, retryCount + 1, lastError);
        }
    }

    private downloadWithRetry = async (url: string, body: any, numOfRetries: number = 3, retryCount: number = 0, lastError: ErrorResult | undefined = undefined): Promise<Blob> => {
        try {
            const accessToken = await this.AquireTokenAsync();

            if (!accessToken) {
                throw new Error('credentials have expired. please sign in.');
            }

            const headers = new Headers();
            headers.append('Authorization', 'bearer ' + accessToken);
            //headers.append('Content-Type', 'text/csv');
            headers.append('Content-Type', 'application/json');

            const response = await fetch(url, { method: 'POST', headers: headers, body: body });

            if (response.status >= 300) {
                lastError = await response.json();

                if (response.status >= 400 && response.status < 500) {
                    retryCount = numOfRetries;
                }

                throw new ApiError(lastError ?? new ErrorResult());
            }

            return await response.blob();

        } catch (e) {

            if (retryCount >= numOfRetries) {
                throw new ApiError(lastError ?? new ErrorResult());
            }

            await this.delay(retryCount);
            return this.downloadWithRetry(url, body, numOfRetries, retryCount + 1, lastError);
        }
    }


    public get = <T>(url: string): Promise<T> => {
        return this.getWithRetry(url);
    };

    public post = <T>(url: string, body: any): Promise<T> => {
        return this.postWithRetry('POST', url, body);
    };

    public put = <T>(url: string, body: any): Promise<T> => {
        return this.postWithRetry('PUT', url, body);
    };

    public delete = <T>(url: string): Promise<void> => {
        return this.deleteWithRetry(url);
    };

    public download = (url: string, body: any): Promise<Blob> => {
        return this.downloadWithRetry(url, body);
    }


    public constructor(msalInstance: IPublicClientApplication, scopes: string[]) {
        this._msalInstance = msalInstance;
        this._scopes = scopes;
    }
}
