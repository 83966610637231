import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { OdsAsyncMultiSelectControl } from "../../../components/async-multi-select/async-multi-select.control";
import { ItemFilter, ListResult } from "../../../models";
import { CvxButton } from "../../../modules/cvx-ui-module";

export interface OdsItemFilterDialogProps {
    show: boolean;
    header: React.ReactNode;
    filter?: ItemFilter;
    onSearch: (top: number, skip: number, filter: string) => Promise<ListResult<string>>;
    onComplete: (dialogResult: boolean, filter?: ItemFilter) => void;
}

export const OdsItemFilterDialog: React.FC<OdsItemFilterDialogProps> = ({
    show,
    header,
    filter,
    onSearch,
    onComplete}) => {

    const [likeValue, setLikeValue] = useState<string>('');
    const [useLikeValue, setUseLikeValue] = useState<boolean>(false);

    const [equalValue, setEqualValue] = useState<string[]>([]);
    const [useEqualValue, setUseEqualValue] = useState<boolean>(false);

    const onLikeValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLikeValue(e.target.value);
        setUseLikeValue(e.target.value !== '' && e.target.value !== undefined);
    }

    const onEqualValueChanged = (selected: string[]) => {
        setEqualValue(selected);
        setUseEqualValue(selected?.length > 0);
    }

    const onCancel = () => {
        onComplete(false, undefined);
    }

    const onClear = () => {
        setLikeValue('');
        setUseLikeValue(false);

        setEqualValue([]);
        setUseEqualValue(false);

        onComplete(true, undefined);
    }

    const onApply = () => {
        if (!useLikeValue && !useEqualValue) {
            onClear();
        } else {
            const filter = new ItemFilter();

            if (useLikeValue) {
                filter.like = likeValue;
            }

            if (useEqualValue) {
                filter.equal = equalValue;
            }

            onComplete(true, filter);
        }
    }

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Filter '{header}'</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useLikeValue} onChange={() => setUseLikeValue(!useLikeValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="likeFilter">
                                <Form.Label>{header} like </Form.Label>
                                <Form.Control type="text" value={likeValue} onChange={onLikeValueChanged}></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Label> </Form.Label>
                            <Form.Check type="switch" checked={useEqualValue} onChange={() => setUseEqualValue(!useEqualValue)}></Form.Check>
                        </Col>
                        <Col>
                            <Form.Group controlId="inFilter">
                                <Form.Label>{header} equals </Form.Label>
                                <OdsAsyncMultiSelectControl
                                    id="equals filter"
                                    onSearch={onSearch}
                                    multiple={true}
                                    selected={equalValue}
                                    onChange={onEqualValueChanged}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxButton theme='primary' onClick={onClear}>Clear</CvxButton>
                <CvxButton theme='primary' onClick={onApply}>Apply</CvxButton>
            </Modal.Footer>
        </Modal>
    );
};
