import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import OdsBaseTable from '../../components/base-table/base-table';
import { Reg7HaulerNewLod } from '../../models/reg7-hauler-new-lod';
import { ColumnDefinition, CvxButton } from '../../modules/cvx-ui-module';
import OdsService from '../../services/ods-service';

export interface OdsAddReg7HaulerLodDialogProps {
    show: boolean;
    odsService: OdsService | undefined;
    reportingMonthId: number;
    columns: ColumnDefinition<Reg7HaulerNewLod>[],
    onComplete: (dialogResult: boolean, newLods: Reg7HaulerNewLod[]) => Promise<any>;
}

const OdsAddReg7HaulerLodDialog: React.FC<OdsAddReg7HaulerLodDialogProps> = ({
    show,
    odsService,
    reportingMonthId,
    columns,
    onComplete,
    ...props
}) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [newLods, setNewLods] = useState<Reg7HaulerNewLod[]>([]);

    const [selectedNewLods, setSelectedNewLods] = useState<Reg7HaulerNewLod[]>([]);

    const fetchNewLods = async () => {
        if (odsService) {
            setIsLoading(true);

            setNewLods(await odsService.RetrieveReg7HaulerNewLodsAsync(reportingMonthId));

            setIsLoading(false);
        }
    };

    const onCancel = () => {
        onComplete(false, []);
    };

    const onApply = () => {
        onComplete(true, selectedNewLods);
    };

    const onSelectionChanged = (selectedItems: Reg7HaulerNewLod[]) => {
        setSelectedNewLods(selectedItems);
    };

    useEffect(() => {
        fetchNewLods();
    }, []);

    return (
        <Modal className="theme-blue" size='xl' show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Add LODs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <OdsBaseTable
                    columns={columns}
                    dataSource={newLods}
                    isLoading={isLoading}
                    overridePageSize={10}
                    onSelectedItemsChanged={onSelectionChanged}
                />
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Cancel</CvxButton>
                <CvxButton theme='primary' disabled={selectedNewLods?.length < 1} onClick={onApply}>Add</CvxButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsAddReg7HaulerLodDialog;
