import { Facility } from "./facility";

export class AvoVocInspectionRequest {
    public id? : number;
    public frequency?: string;
    public inspectionType?: string;
    public facilityId?: number;
    public startDate?: Date;
    public dueDate?: Date;
    public dispatchDate?: Date;
    public inspectionDate?: Date;
    public completionType?: string;
    public dispatchUserIds: number[] = [];
    
    public facility?: Facility;

    public static map(data: any): AvoVocInspectionRequest {
        const m = new AvoVocInspectionRequest;

        m.id = data.id;
        m.frequency = data.frequency;
        m.inspectionType = data.inspectionType;
        m.facilityId = data.facilityId;
        m.startDate = new Date(data.startDate);
        m.dueDate = new Date(data.dueDate);
        m.dispatchDate = new Date(data.dispatchDate);
        m.inspectionDate = new Date(data.inspectionDate);
        m.completionType = data.completionType;
        m.dispatchUserIds = data?.dispatchUserIds?.map((d: any) => parseInt(d)) ?? [];

        return m;
    };
};
