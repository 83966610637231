import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { EntityType } from "../../models/entity-type";
import { CvxButton } from "../../modules/cvx-ui-module";


export interface OdsFormSubmissionFilterProps {
    forms: EntityType[] | undefined;
    onFetchForms: (formId: number | undefined, startDate: Date | undefined, endDate: Date | undefined) => Promise<any>;
}


const OdsFormSubmissionFilter: React.FC<OdsFormSubmissionFilterProps> = ({
    forms,
    onFetchForms,
    ...props
}) => {
    const [formId, setFormId] = useState<number | undefined>(undefined);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [disabled, setDisabled] = useState<boolean>(true);

    const getSubmissions = () => {
        const sgft = `${formId} - ${startDate} - ${endDate}`;
        onFetchForms(formId, startDate, endDate);
    };

    useEffect(() => {
        setDisabled(!(formId && startDate && endDate));
    }, [formId, startDate, endDate]);

    return (
        <div className="cvx-content-block">
            <Container className="ods-form-submission-filter">
                <Row>
                    <Col>
                        <Form.Label>Form</Form.Label>
                        <Form.Select value={formId} onChange={(e) => setFormId(parseInt(e.target.value))}>
                            <option></option>
                            {
                                forms && forms.map((item: any, index: number) => {
                                    return <option key={item.id} value={item.id}>{item.name}</option>
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Start Date</Form.Label>
                        <DatePicker className="form-control"
                            selected={startDate || undefined}
                            onChange={(val: Date) => {
                                setStartDate(val);
                            }}
                        />
                    </Col>
                    <Col>
                        <Form.Label>End Date</Form.Label>
                        <DatePicker className="form-control"
                            selected={endDate || undefined}
                            onChange={(val: Date) => {
                                setEndDate(val);
                            }}
                        />
                    </Col>
                    <Col className="align-self-end">
                        <CvxButton theme="primary" disabled={disabled} onClick={() => getSubmissions()}>Get Submissions</CvxButton>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export default OdsFormSubmissionFilter;
