export class MultiEditField<T> {
    value: T;
    update: boolean

    constructor(value: T, update: boolean = false) {
        this.value = value
        this.update = update
    }
}

export class MultiEditCommentField {
    value: string;
    update: boolean;
    append: boolean;

    constructor(value: string, append: boolean, update: boolean = false) {
        this.value = value
        this.append = append
        this.update = update
    }
}
