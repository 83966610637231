import { useEffect, useState } from "react";
import OdsBaseTable from "../../../components/base-table/base-table";
import OdsReg7HaulerDashboardFilter from "../../../components/reg7-hauler-dashboard-filter/reg7-hauler-dashboard-filter";
import OdsAddReg7HaulerLodDialog from "../../../dialogs/add-reg7-hauler-lod/add-reg7-hauler-lod.dialog";
import OdsConfirmRolloverDialog from "../../../dialogs/confirm-rollover/confirm-rollover.dialog";
import OdsRemoveReg7HaulerLodDialog from "../../../dialogs/remove-reg7-hauler-lod/remove-reg7-hauler-lod.dialog";
import { Reg7HaulerDashboardView } from "../../../models/reg7-hauler-dashboard-view";
import { Reg7HaulerNewLod } from "../../../models/reg7-hauler-new-lod";
import { ReportMonth } from "../../../models/report-month";
import { ColumnDefinition, CvxLayoutCenterColumn, CvxPage } from "../../../modules/cvx-ui-module";
import CvxContentBlock from "../../../modules/cvx-ui-module/components/content-block/content-block";
import { DataToCsvService } from "../../../services/data-to-csv-service";
import { DateHelper } from "../../../services/date-helper";
import OdsService from "../../../services/ods-service";


export interface OdsReg7HaulerDashboardViewProps {
    odsService: OdsService | undefined;
}


const columns: ColumnDefinition<Reg7HaulerDashboardView>[] = [
    {
        header: 'LOD', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.lodId), sortFilterValue: ((i) => i.lodId)
    },
    {
        header: 'LOD Name', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.lodName), sortFilterValue: ((i) => i.lodName),
        tooltip: ((i) => i.lodName)
    },
    {
        header: 'Reference Number', align: 'left', canSort: true, canFilter: true, noWrap: true,
        template: ((i) => i.referenceNumber), sortFilterValue: ((i) => i.referenceNumber)
    },
    {
        header: 'User Name', align: 'left', canSort: true, canFilter: true, noWrap: true,
        template: ((i) => i.userName), sortFilterValue: ((i) => i.userName)
    },
    {
        header: 'Submit Date', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.deviceSubmitDate?.toLocaleDateString()), sortFilterValue: ((i) => i.deviceSubmitDate?.toLocaleDateString())
    },
    {
        header: 'Tank', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.tankName), sortFilterValue: ((i) => i.tankName)
    },
    {
        header: 'Hauler', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.haulerName), sortFilterValue: ((i) => i.haulerName)
    },
    {
        header: 'Driver', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.driverName), sortFilterValue: ((i) => i.driverName)
    },
    {
        header: 'Hauler Pre-Job', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.preJob), sortFilterValue: ((i) => i.preJob)
    },
    {
        header: 'Pre-Job Corrective Actions', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.preJobCorrectiveActions), sortFilterValue: ((i) => i.preJobCorrectiveActions)
    },
    {
        header: 'Connect VRS', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.connectVRS), sortFilterValue: ((i) => i.connectVRS)
    },
    {
        header: 'VRS Corrective Actions', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.vrsCorrectiveActions), sortFilterValue: ((i) => i.vrsCorrectiveActions)
    },
    {
        header: 'Load Equip Functioning Properly', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.loadEquipmentFunctioningCorrectly), sortFilterValue: ((i) => i.loadEquipmentFunctioningCorrectly)
    },
    {
        header: 'Properly Load Product', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.properlyLoadProduct), sortFilterValue: ((i) => i.properlyLoadProduct)
    },
    {
        header: 'Load Product Corrective Actions', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.loadProductCorrectiveActions), sortFilterValue: ((i) => i.loadProductCorrectiveActions)
    },
    {
        header: 'Malfunction Equip List', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.malfunctionEquipmentList), sortFilterValue: ((i) => i.malfunctionEquipmentList)
    },
    {
        header: 'Equip Chevron Owned', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.equipmentChevronOwned), sortFilterValue: ((i) => i.equipmentChevronOwned)
    },
    {
        header: 'Chevron Equip Malfunction', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.chevronEquipmentMalfunction), sortFilterValue: ((i) => i.chevronEquipmentMalfunction)
    },
    {
        header: 'Hauler Equip Malfunction', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.haulerEquipmentMalfunction), sortFilterValue: ((i) => i.lodName)
    },
    {
        header: 'Hauler Positive Observations', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.haulerPositiveObservations), sortFilterValue: ((i) => i.haulerPositiveObservations)
    },
    {
        header: 'Hauler Corrective Actions', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.haulerCorrectiveActions), sortFilterValue: ((i) => i.haulerCorrectiveActions)
    },
    {
        header: 'Comments', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.commentText), sortFilterValue: ((i) => i.commentText)
    },
];


const addLodColumns: ColumnDefinition<Reg7HaulerNewLod>[] = [
    {
        header: 'LOD', align: 'left', canSort: true, canFilter: true,
        template: ((i) => i.lodId), sortFilterValue: ((i) => i.lodId)
    },
    {
        header: 'LOD Name', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.lodName), sortFilterValue: ((i) => i.lodName),
        tooltip: ((i) => i.lodName)
    },
    {
        header: 'Facility Name', align: 'left', canSort: true, canFilter: true, noWrap: true, maxWidth: 200,
        template: ((i) => i.facilityName), sortFilterValue: ((i) => i.facilityName),
        tooltip: ((i) => i.facilityName)
    },
];


const OdsReg7HaulerDashboardView: React.FC<OdsReg7HaulerDashboardViewProps> = ({
    odsService,
    ...props
}) => {
    const [reg7Lods, setReg7Lods] = useState<Reg7HaulerDashboardView[]>([]);
    const [reportMonths, setReportMonths] = useState<ReportMonth[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [selectedReportMonthId, setSelectedReportMonthId] = useState<number>(0);
    const [isLatestMonth, setIsLatestMonth] = useState<boolean>(false);
    const [isCurrentMonth, setIsCurrentMonth] = useState<boolean>(false);

    const [showRollover, setShowRollover] = useState<boolean>(false);
    const [rolloverDate, setRolloverDate] = useState<string>('');

    const [showAddLod, setShowAddLod] = useState<boolean>(false);

    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [deleteLod, setDeleteLod] = useState<Reg7HaulerDashboardView | undefined>(undefined);

    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const currentDate = () => {
        const date = new Date();
        return monthNames[date.getMonth()] + "-" + date.getFullYear();
    };

    const fetchReportMonths = async () => {
        if (odsService) {
            const rm = await odsService.RetrieveReg7HaulerReportMonthsAsync()
            setReportMonths(rm);
            setSelectedReportMonthId(rm[0]?.id ?? 0);
        }
    };

    const fetchPressureData = async () => {
        if (odsService && selectedReportMonthId > 0) {
            setIsLoading(true);
            setReg7Lods(await odsService.RetrieveReg7HaulerDashboardAsync(selectedReportMonthId));
            setIsLoading(false);
        }
    };

    const onSelectMonth = (reportMonth: number) => {
        setSelectedReportMonthId(reportMonth);
    }

    const onRollover = () => {
        if (selectedReportMonthId > 0) {
            const currentMonth = reportMonths.find(r => r.id === selectedReportMonthId);

            if (currentMonth) {
                const date = DateHelper.addMonths(new Date(currentMonth.name), 1);
                const newMonth = monthNames[date.getMonth()] + "-" + date.getFullYear();

                setRolloverDate(newMonth);
                setShowRollover(true);
            }
        }
    }

    const onRolloverComplete = async (dialogResult: boolean) => {
        if (odsService && dialogResult) {
            await odsService.RolloverReg7HaulerDashboardAsync(selectedReportMonthId, rolloverDate, '');;
            await fetchReportMonths();
            fetchPressureData();
        }
        setShowRollover(false);
    }

    const onShowAddLodDialog = (lodData: Reg7HaulerDashboardView | undefined): void => {
        setShowAddLod(true);
    };

    const onAddCompleteDialog = async (dialogResult: boolean, newLods: Reg7HaulerNewLod[]) => {
        if (dialogResult && odsService) {
            await odsService.AddReg7HaulerLodAsync(selectedReportMonthId, newLods);
            fetchPressureData();
        }
        setShowAddLod(false);
    };

    const onShowRemoveLod = (item: Reg7HaulerDashboardView) => {
        setDeleteLod(item);
        setShowDelete(true);
    };

    const onRemoveLod = async (dialogResult: boolean) => {
        if (odsService && dialogResult && deleteLod) {
            await odsService.RemoveReg7HaulerLodAsync(selectedReportMonthId, deleteLod);
            fetchPressureData();
        }

        setShowDelete(false);
    };

    const onExport = () => {
        const a = document.createElement('a');
        a.download = 'reg7hauler.csv';
        a.href = URL.createObjectURL(DataToCsvService.generateCsvData(columns, reg7Lods));
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };

    useEffect(() => {
        fetchReportMonths();
    }, [odsService]);

    useEffect(() => {
        fetchPressureData();
    }, [selectedReportMonthId]);

    useEffect(() => {
        if (reportMonths && selectedReportMonthId) {
            setIsLatestMonth(reportMonths.findIndex(i => i.id === selectedReportMonthId) === 0)
            setIsCurrentMonth(reportMonths.find(i => i.id == selectedReportMonthId)?.name?.toUpperCase() === currentDate());
        } else {
            setIsLatestMonth(false);
            setIsCurrentMonth(false);
        }
    }, [reportMonths, selectedReportMonthId]);

    return (
        <CvxPage desktop={
            <CvxLayoutCenterColumn>
                <OdsConfirmRolloverDialog
                    show={showRollover}
                    rolloverMonth={rolloverDate}
                    onComplete={onRolloverComplete}
                />
                <OdsAddReg7HaulerLodDialog
                    odsService={odsService}
                    show={showAddLod}
                    columns={addLodColumns}
                    reportingMonthId={selectedReportMonthId}
                    onComplete={onAddCompleteDialog}
                />
                <OdsRemoveReg7HaulerLodDialog
                    show={showDelete}
                    lod={deleteLod}
                    onComplete={onRemoveLod}
                />
                <OdsReg7HaulerDashboardFilter
                    isCurrentMonth={isCurrentMonth}
                    isLatestMonth={isLatestMonth}
                    selectedReportMonthId={selectedReportMonthId}
                    reportMonths={reportMonths}
                    onSelectMonth={onSelectMonth}
                    onExport={onExport}
                    onRollover={onRollover}
                    onRefresh={() => fetchPressureData()}
                />
                <CvxContentBlock>
                    <OdsBaseTable
                        heading={<span>Reg 7 Hauler Inspections</span>}
                        columns={columns}
                        dataSource={reg7Lods}
                        isLoading={isLoading}
                        canAdd={isCurrentMonth}
                        canEdit={false}
                        canRemove={isCurrentMonth}
                        onAddEdit={onShowAddLodDialog}
                        onRemove={onShowRemoveLod}
                    />
                </CvxContentBlock>
            </CvxLayoutCenterColumn>
        } />
    );
};


export default OdsReg7HaulerDashboardView;
