import { useEffect, useReducer, useState } from "react";
import { OdsDateColumn, OdsDateColumnHeader, OdsEditItemColumn, OdsIntegerColumn, OdsIntegerColumnHeader, OdsRemoveItemColumn, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsConfirmDeleteDialog, OdsEditLdarHoliday } from "../../../dialogs";
import { usePagedListResult } from "../../../hooks/list-result-hook";
import { useMyself } from "../../../hooks/myself-hook";
import { DateFilter, IntegerFilter, LdarHoliday, LdarHolidayFilter, ListResult, StringFilter } from "../../../models";
import { Permission } from "../../../models/permission";
import { CvxContentBlock, CvxLayoutCenterColumn, CvxPage, PageChangedEvent } from "../../../modules/cvx-ui-module";
import { OdsLdarApiService, ViewHelper } from "../../../services";


export interface OdsLdarHolidaysViewProps {
    service: OdsLdarApiService;
}

export const OdsLdarHolidaysView: React.FC<OdsLdarHolidaysViewProps> = ({
    service,
    ...props
}) => {

    const [canEdit, showState, states] = useMyself(service, Permission.Administrator);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: LdarHolidayFilter) => {
        return service.RetrieveLdarHolidayListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('Date', 'Desc', new LdarHolidayFilter(), onFetchData);

    const [editItem, setEditItem] = useState<LdarHoliday>();
    const [removeItem, setRemoveItem] = useState<LdarHoliday>();


    const onAddItem = () => setEditItem(new LdarHoliday());

    const onEditItem = (item: LdarHoliday) => setEditItem(item);

    const onEditItemCompleted = async (dialogResult: boolean, item?: LdarHoliday) => {
        if (dialogResult && item) {
            if (item.id) {
                await service.UpdateLdarHolidayAsync(item);
            } else {
                await service.CreateLdarHolidayAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    }

    const onRemoveItem = (item: LdarHoliday) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await service.DeleteLdarHolidayAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    }

    const onExport = async () => {
        const blob = await service.ExportLdarHolidayListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`ldar-holidays-${(new Date()).toLocaleDateString()}.csv`, blob)
    }


    const onFilterIdComplete = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); }
    const onFilterNameComplete = (newFilter?: StringFilter) => { filter.name = newFilter; onRefresh(); }
    const onFilterDateComplete = (newFilter?: DateFilter) => { filter.date = newFilter; onRefresh(); }

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveLdarHolidaySearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsEditLdarHoliday service={service} show={!!editItem} ldarHoliday={editItem} onComplete={onEditItemCompleted} />
            <OdsConfirmDeleteDialog show={!!removeItem} text={removeItem?.name} onComplete={onRemoveItemCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onAddItem={onAddItem} onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxContentBlock>
                        <OdsTable heading="LDAR Holidays" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterIdComplete} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Name" header="Name" filter={filter.name} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterNameComplete} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="Date" header="Date" filter={filter.date} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDateComplete} onSearch={onFilterValueSearch} />
                                    <th></th>
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    <OdsEditItemColumn item={item} onEditItem={onEditItem} />
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.id}</OdsIntegerColumn>
                                    <td>{item.name}</td>
                                    <OdsDateColumn>{item.date?.toLocaleDateString()}</OdsDateColumn>
                                    <OdsRemoveItemColumn item={item} onRemoveItem={onRemoveItem} />
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
