import { HtmlHTMLAttributes, PropsWithChildren } from "react";
import { OverlayHoverTrigger } from "../../overlay-hover-trigger/overlay-hover-trigger";

export interface OdsStringColumnProps {
    value?: string;
    align?: 'left-column' | 'center-column' | 'right-column';
    noWrap?: boolean;
    maxWidth?: number;
    tooltip?: React.ReactNode;
}

export const OdsStringColumn: React.FC<PropsWithChildren<OdsStringColumnProps> & HtmlHTMLAttributes<HTMLTableCellElement>> = ({
    value,
    align,
    noWrap,
    maxWidth,
    tooltip,
    ...props
}) => {

    return (
        <>
            {tooltip ?
                <OverlayHoverTrigger
                    placement="bottom"
                    delay={250}
                    component={tooltip}
                >
                    <td className={align} style={{ maxWidth: maxWidth ? `${maxWidth}px` : 'unset', whiteSpace: noWrap ? 'nowrap' : 'unset' }}>{value ?? props.children}</td>
                </OverlayHoverTrigger>
                :
                <td className={align} style={{ maxWidth: maxWidth ? `${maxWidth}px` : 'unset', whiteSpace: noWrap ? 'nowrap' : 'unset' }}>{value ?? props.children}</td>
            }
        </>
    )
}
