import { FieldArray, FieldHookConfig, useField } from "formik";
import React from "react";
import { Form, InputGroup, Row } from "react-bootstrap";
import { RolePermission } from "../../../models/role-permission";
import { ListResult, Role } from "../../../models";
import { CvxButton } from "../../../modules/cvx-ui-module";
import { OdsAsyncSelect } from "../async-select/async-select";
import { UserRole } from "../../../models/user-role";
import OdsSwitch from "../switch/switch";

export interface OdsEditUserRolesProps {
    label: string;
    onSearchRole: (top: number, skip: number, filter: string) => Promise<ListResult<Role>>;
}

export const OdsEditUserRoles: React.FC<OdsEditUserRolesProps & FieldHookConfig<UserRole[]>> = ({
    label,
    onSearchRole,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);

    return (
        <Row>
            <Form.Group controlId={field.name}>
                <Form.Label>{label}</Form.Label>
                <FieldArray
                    name={field.name}
                    render={arrayHelpers =>
                        <div>
                            {field.value.map((item: UserRole, index: number) =>
                                <InputGroup key={index}>
                                    <OdsAsyncSelect
                                        label="Role"
                                        name={`${field.name}.${index}.role`}
                                        labelKey="name"
                                        onSearch={onSearchRole}
                                    />
                                    <OdsSwitch name={`${field.name}.${index}.isAssignable`} label="Is Assignable?" />
                                    <CvxButton onClick={() => arrayHelpers.remove(index)}>-</CvxButton>
                                </InputGroup>
                            )}
                            <CvxButton onClick={() => {
                                arrayHelpers.push(new UserRole())
                            }}>Add User Role</CvxButton>
                        </div>
                    } />
                <Form.Control.Feedback type="invalid">
                    {typeof meta.error === 'string' ? meta.error : ''}
                </Form.Control.Feedback>
            </Form.Group>
        </Row >
    )
};
