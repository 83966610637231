import * as yup from 'yup';
import { OdsAsyncSelect } from '../../components';
import OdsDate from '../../components/form-editors/date/date';
import OdsInput from "../../components/form-editors/input/input";
import OdsTextArea from '../../components/form-editors/textarea/textarea';
import { LdarInspectionRequest, StringFilter } from '../../models';
import { LdarInspectionRequestStatusFilter } from '../../models/ldar-inspection-request-status-filter';
import { OdsLdarApiService } from '../../services/ods-ldar-api-service';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditLdarInspectionRequestDialogProps {
    service: OdsLdarApiService;
    show: boolean;
    ldarInspectionRequest: LdarInspectionRequest | undefined;
    onComplete: (dialogResult: boolean, ldarInspectionRequest: LdarInspectionRequest | undefined) => Promise<any>;
}

export const OdsEditLdarInspectionRequestDialog: React.FC<OdsEditLdarInspectionRequestDialogProps> = ({
    service,
    show,
    ldarInspectionRequest,
    onComplete,
    ...props
}) => {

    const schema = yup.object().shape({
        status: yup.object().nullable().required(),
        startDate: yup.date().nullable(),
        dueDate: yup.date().nullable(),
        commentText: yup.string().nullable(),
    });

    const onSearchStatus = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new LdarInspectionRequestStatusFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        let data = await service.RetrieveLdarInspectionRequestStatusListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={ldarInspectionRequest?.id ? 'Edit Inspection Request: ' + ldarInspectionRequest.id : 'Add Inspection Request'}
            validationSchema={schema}
            initialValue={ldarInspectionRequest ?? new LdarInspectionRequest()}
            onComplete={onComplete}
        >
            <OdsInput name="lod.name" label="LOD" disabled={true} />
            <OdsInput name="type.name" label="Type" disabled={true} />
            <OdsAsyncSelect 
                name="status" 
                label="Status" 
                labelKey="name"
                onSearch={onSearchStatus} 
            />
            <OdsDate name="startDate" label="Start Date" />
            <OdsDate name="dueDate" label="Due Date" />
            <OdsTextArea name="comments" label='Comments' />
        </OdsBaseAddEditDialog>
    );
};
