import { Link } from "react-router-dom";

export interface CvxMenuItemProps {
    label: string;
    href?: string | undefined;
    to?: string | undefined;
    target?: '_self' | '_blank' | '_parent' | '_top';
}

export const CvxMenuItem: React.FC<CvxMenuItemProps> = ({
    label,
    href,
    to,
    target,
    ...props
}) => {
    return (
        to ? <Link to={to} target={target ?? '_self'}>{label}</Link> : <a href={href} target={target ?? '_self'}>{label}</a>
    );
};

export default CvxMenuItem;
