import Editor from "@monaco-editor/react";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";

export interface OdsEditDispatchBodyDialogProps {
    show: boolean;
    dispatchBody: string | undefined;
    onComplete: (dialogResult: boolean, updatedDispatchBody: string) => Promise<void>;
}

const OdsEditDispatchBodyDialog: React.FC<OdsEditDispatchBodyDialogProps> = ({
    show,
    dispatchBody,
    onComplete,
    ...props
}) => {

    const [updateDispatchBody, setUpdatedDispatchBody] = useState<string | undefined>(undefined);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onCancel = async () => {
        if (onComplete) {
            await onComplete(false, '');
        }
    };

    const onSave = async () => {
        if (onComplete) {
            if (updateDispatchBody) {
                setIsSaving(true);
                await onComplete(true, updateDispatchBody);
                setIsSaving(false);
            } else {
                await onComplete(false, '');
            }
        }
    }

    const handleEditorChange = (value: any, event: any) => {
        setUpdatedDispatchBody(value);
    }

    useEffect(() => {
        setUpdatedDispatchBody(undefined);
        setIsSaving(false);
    }, [show]);

    return (
        <Modal className="theme-blue" size="xl" show={show} centered backdrop='static' scrollable={true} onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Edit Dispatch Body</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Editor
                    height="60vh"
                    defaultLanguage="xml"
                    defaultValue={dispatchBody}
                    options={{
                        readOnly: false
                    }}
                    onChange={handleEditorChange}
                />
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxSpinnerButton theme='primary' onClick={onSave} showSpinner={isSaving} disabled={!updateDispatchBody}>Save</CvxSpinnerButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsEditDispatchBodyDialog;
