import { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import OdsTableFilterDialog from "../../dialogs/table-filter-dialog/table-filter.dialog";
import { ColumnDefinition, CvxButton, CvxTable, PageChangedEvent } from "../../modules/cvx-ui-module";
import OdsDeleteColumn from "../table-columns/delete-column/delete-column";
import OdsEditColumn from "../table-columns/edit-column/edit-column";
import OdsIsSelectedColumn from "../table-columns/is-selected-column/is-selected-column";

export interface BaseTableProps<T> {
    isLoading: boolean;
    overridePageSize?: number;
};


export interface OdsBaseTableProps<T> {
    dataSource: T[];
    heading?: JSX.Element | string;
    columns: ColumnDefinition<T>[];
    footer?: JSX.Element | string;
    isLoading: boolean;
    canAdd?: boolean;
    canEdit?: boolean;
    canRemove?: boolean;
    canSelectItem?: boolean;
    onAddEdit?: (item: T | undefined) => void;
    onRemove?: (item: T) => void;
    onFilteredItemsChanged?: (filteredItems: T[]) => void;
    onSelectedItemsChanged?: (selectedItems: T[]) => void;
    onBulkEditColumn?: (column: ColumnDefinition<T>) => void;
    onBulkDeleteItem?: () => void;
}

interface ColumnFilterValue<T> {
    column: ColumnDefinition<T>;
    filteredValues: any[];
}

interface Item {
}

const OdsBaseTable = <T extends Item>({
    dataSource,
    heading,
    columns,
    footer,
    isLoading,
    overridePageSize,
    canSelectItem,
    canAdd,
    canEdit,
    canRemove,
    onAddEdit,
    onRemove,
    onFilteredItemsChanged,
    onSelectedItemsChanged,
    onBulkEditColumn,
    onBulkDeleteItem,
    ...props
}: OdsBaseTableProps<T> & BaseTableProps<T>) => {
    const [filteredDataSource, setFilteredDataSource] = useState<T[]>([]);
    const [pagedDataSource, setPagedDataSource] = useState<T[]>([]);
    const [filteredDataSourceLength, setFilteredDataSourceLength] = useState<number>(0);
    const [updatedColumns, setUpdatedColumns] = useState<ColumnDefinition<T>[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const [sortColumn, setSortColumn] = useState<ColumnDefinition<T> | undefined>();
    const [sortColumnDirection, setSortColumnDirection] = useState<string>();
    const [filters, setFilters] = useState<ColumnFilterValue<T>[]>([]);

    const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
    const [filterColumn, setFilterColumn] = useState<ColumnDefinition<T> | undefined>();
    const [filterValues, setFilterValues] = useState<any[]>([]);

    const [selectedItem, setSelectedItem] = useState<T | undefined>(undefined);
    const [selectedItems, setSelectedItems] = useState<T[]>([]);

    const onPageChanged = (e: PageChangedEvent) => {
        setPageIndex(e.pageIndex);
        setPageSize(e.pageSize);
    };

    const onSortChanged = (column: ColumnDefinition<T>): void => {
        setSortColumn(undefined);

        if (column.sortDirection === 'Asc') {
            column.sortDirection = 'Desc';
        } else if (column.sortDirection === 'Desc') {
            column.sortDirection = 'Asc';
        } else {
            column.sortDirection = 'Asc';
        }

        columns.forEach(c => {
            if (c !== column) {
                c.sortDirection = undefined;
            }
        });

        setSortColumn(columns.find(c => c.sortDirection !== undefined));
        setSortColumnDirection(columns.find(c => c.sortDirection !== undefined)?.sortDirection);
    }

    const onFilterChanged = (column: ColumnDefinition<T>): void => {
        setFilterColumn(column);
        setFilterValues(filters.find(f => f.column === column)?.filteredValues ?? []);

        setShowFilterDialog(true);
    }

    const onFilterComplete = async (dialogResult: boolean, filterValues: any[]): Promise<any> => {
        const f = filters;

        let cf = f.find(f => f.column === filterColumn);
        if (cf) {
            cf.filteredValues = filterValues;
        } else if (filterColumn && filterValues?.length > 0) {
            f.push({ column: filterColumn, filteredValues: filterValues });
        }

        if (filterColumn) {
            filterColumn.filterValues = filterValues;
        }

        setSelectedItem(undefined);
        setSelectedItems([]);
        setPageIndex(0);

        setFilters(f);
        setShowFilterDialog(false);
    }

    const onAddItem = () => {
        if (onAddEdit) {
            onAddEdit(undefined);
        }
    };

    const onEditItem = (item: T) => {
        if (onAddEdit) {
            onAddEdit(item);
        }
    };

    const onRemoveItem = (item: T) => {
        if (onRemove) {
            onRemove(item);
        }
    };

    const onSelectionChanged = (selectedItem: T | undefined, selectedItems: T[]) => {
        setSelectedItem(selectedItem);
        setSelectedItems(selectedItems);

        if (onSelectedItemsChanged) {
            onSelectedItemsChanged(selectedItems);
        }
    }

    useEffect(() => {
        let filtered = dataSource ?? [];

        filters.forEach(f => {
            if (f.filteredValues.length > 0) {
                filtered = filtered.filter(i => f.filteredValues.findIndex(v => {
                    if (v === '-- Blanks --') {
                        return (f.column.sortFilterValue && f.column.sortFilterValue(i) === ''
                            || f.column.sortFilterValue && f.column.sortFilterValue(i) === undefined
                            || f.column.sortFilterValue && f.column.sortFilterValue(i) === null);
                    } else {
                        return f.column.sortFilterValue && f.column.sortFilterValue(i) === v;
                    }
                }) >= 0);
            }
        });

        if (onFilteredItemsChanged) {
            onFilteredItemsChanged(filtered);
        }

        if (sortColumn && sortColumn.sortFilterValue) {
            filtered = filtered.sort((l, r) => {
                if (sortColumn && sortColumn.sortFilterValue) {
                    if (sortColumn.header === 'Server Receive Date') {
                        const datel = new Date(sortColumn.sortFilterValue(l));
                        const dater = new Date(sortColumn.sortFilterValue(r));
                        if (datel > dater) {
                            return sortColumn.sortDirection === 'Asc' ? 1 : -1;
                        }
                        else if (datel < dater) {
                            return sortColumn.sortDirection === 'Desc' ? 1 : -1;
                        }
                    }
                    if (sortColumn.sortFilterValue(l) > sortColumn.sortFilterValue(r)) {
                        return sortColumn.sortDirection === 'Asc' ? 1 : -1;
                    } else if (sortColumn.sortFilterValue(l) < sortColumn.sortFilterValue(r)) {
                        return sortColumn.sortDirection === 'Desc' ? 1 : -1;
                    } else {
                        return 0;
                    }
                }
                return 0;
            });
        }

        setFilteredDataSourceLength(filtered.length);
        setFilteredDataSource(filtered);

        setPagedDataSource(filtered.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize));
    }, [dataSource, pageIndex, pageSize, sortColumn, sortColumnDirection, showFilterDialog, filters]);

    useEffect(() => {
        const updatedColumns: ColumnDefinition<T>[] = [];

        if (canEdit && onAddEdit) {
            updatedColumns.push({ header: '', align: 'center', template: ((i) => <OdsEditColumn item={i} onEdit={(i) => onEditItem(i)} />), sortFilterValue: () => undefined })
        }

        if (canSelectItem) {
            updatedColumns.push({
                header: '', align: 'center', template: ((i) => <OdsIsSelectedColumn isSelected={selectedItems.find(s => s === i) !== undefined} onSelectedChanged={s => {
                    if (s) {
                        selectedItems.push(i)
                    } else {
                        const index = selectedItems.indexOf(i, 0);
                        if (index > -1) {
                            selectedItems.splice(index, 1);
                        }
                    }

                    setSelectedItems(selectedItems);
                    setFilteredDataSource(filteredDataSource);

                    if (onSelectedItemsChanged) {
                        onSelectedItemsChanged(selectedItems);
                    }
                }} />), sortFilterValue: (i) => selectedItems.find(s => s === i) !== undefined
            })
        }

        updatedColumns.push(...columns);

        if (canRemove && onRemove) {
            updatedColumns.push({ header: '', align: 'center', canBulkDelete: true, template: ((i) => <OdsDeleteColumn item={i} onDelete={(i) => onRemoveItem(i)} />), sortFilterValue: () => undefined });
        }

        const filters: ColumnFilterValue<T>[] = [];
        updatedColumns.forEach(c => {
            if (c.sortDirection) {
                setSortColumn(c);
                setSortColumnDirection(c.sortDirection);
            }
            if (c.filterValues && c.filterValues.length > 0) {
                filters.push({ column: c, filteredValues: c.filterValues });
            }
        });
        setFilters(filters);

        setUpdatedColumns(updatedColumns);
    }, [columns, canEdit, canRemove]);

    useEffect(() => {
        if (overridePageSize) {
            setPageSize(overridePageSize);
        }
    }, [overridePageSize]);

    return (
        <>
            <OdsTableFilterDialog show={showFilterDialog} list={filteredDataSource} column={filterColumn} filterValues={filterValues} onComplete={onFilterComplete} />
            <CvxTable
                dataSource={pagedDataSource}
                selectedItems={selectedItems}
                selectedItem={selectedItem}
                heading={
                    <span className="ods-table-header">
                        <span className="ods-table-header-text">{heading}</span>
                        {(canAdd && onAddEdit) ? <span className="ods-add-button"><CvxButton onClick={onAddItem}><BsPlusLg />&nbsp;Add</CvxButton></span> : ''}
                    </span>
                }
                columns={updatedColumns}
                footer={footer}
                isLoading={isLoading}
                supportsPagination={true}
                pageSizeOptions={[5, 10, 25, 100, 500, 5000]}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalCount={filteredDataSourceLength}
                onPageChanged={onPageChanged}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                onSelectionChanged={onSelectionChanged}
                onBulkEditColumn={onBulkEditColumn}
                onBulkDeleteItem={onBulkDeleteItem}
            />
        </>
    );
};

OdsBaseTable.defaultProps = {
    canAdd: true,
    canEdit: true,
    canRemove: true,
};

export default OdsBaseTable;
