import { FieldHookConfig, useField } from "formik";
import React from "react";
import { Form, Row } from "react-bootstrap";
import Editor from "@monaco-editor/react";

export interface OdsMonacoProps {
    label: string;
    language: string;
    height?: number;
}

export const OdsMonaco: React.FC<OdsMonacoProps & FieldHookConfig<string>> = ({
    label,
    language,
    height,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);

    function onChange(value: any, event: any) {
        helpers.setValue(value);
    }

    return (
        <Row>
            <Form.Group controlId={field.name}>
                <Form.Label>{label}</Form.Label>
                <Editor
                    height={height ? `${height}px`: "100px"}
                    defaultLanguage={language}
                    defaultValue={field.value}
                    options={{
                        readOnly: props.disabled
                    }}
                    onChange={onChange}
                />
                <Form.Control.Feedback type="invalid">
                    {meta.error}
                </Form.Control.Feedback>
            </Form.Group>
        </Row>
    )
};
