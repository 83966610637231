import React, { HtmlHTMLAttributes } from "react";
import { BsPlusCircleDotted, BsTrash } from "react-icons/bs";
import { Themes } from "../../../modules/cvx-ui-module";

export interface OdsRemoveItemColumnProps<T> {
    item: T,
    onRemoveItem: (item: T) => void,
}

interface Item {

}

export const OdsRemoveItemColumn = <T extends Item>({
    item,
    onRemoveItem,
    ...props
}: OdsRemoveItemColumnProps<T> & HtmlHTMLAttributes<HTMLTableCellElement>) => {
    return (
        <td {...props} style={{ width: '25px' }}><span onClick={() => { onRemoveItem(item) }}><BsTrash className={Themes.WarningDarkColor} /></span></td>
    )
}
