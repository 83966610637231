import { useState } from "react";
import { OdsBooleanColumn, OdsBooleanColumnHeader, OdsEditItemColumn, OdsIntegerColumn, OdsIntegerColumnHeader, OdsRemoveItemColumn, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsStateLookup } from "../../../components/state-lookup/state-lookup";
import { OdsConfirmDeleteDialog, OdsEditPersonnelDialog } from "../../../dialogs";
import { usePagedListResult } from "../../../hooks/list-result-hook";
import { useMyself } from "../../../hooks/myself-hook";
import { BooleanFilter, IntegerFilter, Personnel, PersonnelFilter, StringFilter } from "../../../models";
import { Permission } from "../../../models/permission";
import { CvxPage, CvxLayoutCenterColumn, CvxContentBlock, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import { OdsPersonnelApiService, ViewHelper } from "../../../services";

export interface OdsPersonnelViewProps {
    service: OdsPersonnelApiService;
}

export const OdsPersonnelView: React.FC<OdsPersonnelViewProps> = ({
    service,
}) => {

    const [canEdit, showState, states] = useMyself(service, Permission.EditPersonnel);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: PersonnelFilter) => {
        return service.RetrievePersonnelListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('LastName', 'Asc', new PersonnelFilter(), onFetchData);

    const [editItem, setEditItem] = useState<Personnel>();
    const [removeItem, setRemoveItem] = useState<Personnel>();


    const onFetchState = async (id: number) => {
        const data = await service.RetrieveStateAsync(id);
        return data;
    }

    const onAddItem = () => setEditItem(new Personnel());

    const onEditItem = (item: Personnel) => setupEditItem(item);

    const setupEditItem = async (item: Personnel) => {
        setEditItem(await ViewHelper.ensureStateId(service, item, states));
    };

    const onEditItemCompleted = async (dialogResult: boolean, item?: Personnel) => {
        if (dialogResult && item) {
            if (item.id) {
                item.stateId = item.state?.id as number;
                await service.UpdatePersonnelAsync(item);
            } else {
                await service.CreatePersonnelAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    }

    const onRemoveItem = (item: Personnel) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await service.DeletePersonnelAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    };

    const onExport = async () => {
        const blob = await service.ExportPersonnelListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`personnel-${(new Date()).toLocaleDateString()}.csv`, blob)
    };


    const onFilterIdComplete = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); };
    const onFilterFirstNameComplete = (newFilter?: StringFilter) => { filter.firstName = newFilter; onRefresh(); };
    const onFilterLastNameComplete = (newFilter?: StringFilter) => { filter.lastName = newFilter; onRefresh(); };
    const onFilterEmailComplete = (newFilter?: StringFilter) => { filter.email = newFilter; onRefresh(); };
    const onFilterAadIdComplete = (newFilter?: StringFilter) => { filter.aadId = newFilter; onRefresh(); };
    const onFilterStateComplete = (newFilter?: StringFilter) => { filter.state = newFilter; onRefresh(); };
    const onFilterIsActiveComplete = (newFilter?: BooleanFilter) => { filter.isActive = newFilter; onRefresh(); };

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrievePersonnelSearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsEditPersonnelDialog service={service} show={!!editItem} personnel={editItem} onComplete={onEditItemCompleted} />
            <OdsConfirmDeleteDialog show={!!removeItem} text={`${removeItem?.firstName} ${removeItem?.lastName}`} onComplete={onRemoveItemCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onAddItem={canEdit ? onAddItem : undefined} onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="Personnel" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    {canEdit ? <th></th> : ''}
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterIdComplete} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="FirstName" header="First&nbsp;Name" filter={filter.firstName} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterFirstNameComplete} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="LastName" header="Last&nbsp;Name" filter={filter.lastName} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterLastNameComplete} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Email" header="Email" filter={filter.email} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterEmailComplete} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="AadId" header="CAI" filter={filter.aadId} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterAadIdComplete} onSearch={onFilterValueSearch} />
                                    {showState ? <OdsStringColumnHeader name="State" header="State" filter={filter.state} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterStateComplete} onSearch={onFilterValueSearch} /> : ''}
                                    <OdsBooleanColumnHeader name="IsActive" header="Is&nbsp;Active" filter={filter.isActive} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterIsActiveComplete} />
                                    {canEdit ? <th></th> : ''}
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    {canEdit ? ((states.filter(d => d === item.stateId).length > 0) ? <OdsEditItemColumn item={item} onEditItem={onEditItem} /> : <td></td>) : ''}
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.id}</OdsIntegerColumn>
                                    <td>{item.firstName}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.email}</td>
                                    <td>{item.aadId}</td>
                                    {showState ? <td><OdsStateLookup item={item} onFetchState={onFetchState} /></td> : ''}
                                    <OdsBooleanColumn value={item.isActive} />
                                    {canEdit ? ((states.filter(d => d === item.stateId).length > 0) ? <OdsRemoveItemColumn item={item} onRemoveItem={onRemoveItem} /> : <td></td>) : ''}
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
