import { IPublicClientApplication } from '@azure/msal-browser';
import { AppResource } from '../models/app-resource';
import { AppRole } from '../models/app-role';
import { AppUser } from '../models/app-user';
import { AvoVocDispatch } from '../models/avo-voc-dispatch';
import { BradenheadDispatch } from '../models/bradenhead-dispatch';
import { BradenheadNewWell } from '../models/bradenhead-new-well';
import { BradenheadPressureData } from '../models/bradenhead-pressure-data';
import { BradenheadWellCategory } from '../models/bradenhead-well-category';
import { CreateBradenheadDispatch } from '../models/create-bradenhead-dispatch';
import { DispatchForm } from '../models/dispatch-form';
import { EntityView } from '../models/entity-view';
import { ErrorResult } from '../models/error-result';
import { FormDefinition } from '../models/form-definition';
import { FormImage } from '../models/form-image';
import { FormSubmission } from '../models/form-submission';
import { FormXmlData } from '../models/form-xml-data';
import { InspectionRequestDashboardItem } from '../models/inspection-request-dashboard-item';
import { LdarCompletedInspections } from '../models/ldar-completed-inspections';
import { OrdCompletedInspections } from '../models/ord-completed-inspections';
import { ProntoFormsDispatch } from '../models/prontoforms-dispatch';
import { Reg7HaulerDashboardView } from '../models/reg7-hauler-dashboard-view';
import { Reg7HaulerNewLod } from '../models/reg7-hauler-new-lod';
import { ReportMonth } from '../models/report-month';
import { UpdateLdarInspectionRequest } from '../models/update-ldar-inspection-request';
import { UpdateOrdInspectionRequest } from '../models/update-ord-inspection-request';

export class ApiError extends Error {
    constructor(error: ErrorResult) {
        super(error.title);
        this.error = error;
    }

    public error: ErrorResult;
};

export class OdsService {

    private _msalInstance: IPublicClientApplication | undefined;
    private _scopes: string[];
    private _accessToken: string | undefined;

    private async AquireTokenAsync() {
        const request = {
            account: this._msalInstance?.getAllAccounts()[0],
            scopes: this._scopes,
        };

        if (this._msalInstance) {
            await this._msalInstance.acquireTokenSilent(request).then((response) => {
                this._accessToken = response.accessToken;
            }).catch((e) => {
                this._msalInstance?.acquireTokenRedirect(request);
            });
        }
    }


    public constructor(msalInstance: IPublicClientApplication, scopes: string[]) {
        this._msalInstance = msalInstance;
        this._scopes = scopes;
    }

    private execute = async (method: string, url: string, body?: any): Promise<any> => {
        if (this._accessToken === undefined) {
            await this.AquireTokenAsync();
        }

        const headers = new Headers();
        headers.append('Authorization', 'bearer ' + this._accessToken);
        headers.append('Content-Type', 'application/json');

        const response = await fetch(url, { method: method, headers: headers, body: body });

        if (response.status != 200) {
            throw new ApiError(await response.json());
        }
    };

    private get = async <T>(url: string): Promise<T> => {
        if (this._accessToken === undefined) {
            await this.AquireTokenAsync();
        }

        const headers = new Headers();
        headers.append('Authorization', 'bearer ' + this._accessToken);
        headers.append('Content-Type', 'application/json');

        const response = await fetch(url, { method: 'GET', headers: headers });

        if (response.status != 200) {
            throw new ApiError(await response.json());
        }

        return await response.json();
    };

    private post = async <T>(url: string, body: any): Promise<T> => {
        if (this._accessToken === undefined) {
            await this.AquireTokenAsync();
        }

        const headers = new Headers();
        headers.append('Authorization', 'bearer ' + this._accessToken);
        headers.append('Content-Type', 'application/json');

        const response = await fetch(url, { method: 'POST', headers: headers, body: body });

        if (response.status != 200) {
            throw new ApiError(await response.json());
        }

        return await response.json();
    };

    private put = async <T>(url: string, body: any): Promise<T> => {
        if (this._accessToken === undefined) {
            await this.AquireTokenAsync();
        }

        const headers = new Headers();
        headers.append('Authorization', 'bearer ' + this._accessToken);
        headers.append('Content-Type', 'application/json');

        const response = await fetch(url, { method: 'PUT', headers: headers, body: body });

        if (response.status != 200) {
            throw new ApiError(await response.json());
        }

        return await response.json();
    };


    public async RetrieveUserPermissionsAsync(): Promise<string[]> {
        const data = await this.get<string[]>('/api/legacy/security/user-permissions');
        return data;
    }


    public async RetrieveBradenheadReportMonthsAsync(): Promise<ReportMonth[]> {
        const data = await this.get<ReportMonth[]>('/api/legacy/bradenhead/reporting-months');
        return data.map((p: ReportMonth) => ReportMonth.mapReportMonth(p));
    }

    public async RetrieveBradenheadWellCategoriesAsync(): Promise<BradenheadWellCategory[]> {
        const data = await this.get<BradenheadWellCategory[]>('/api/legacy/bradenhead/well-categories');
        return data;
    }

    public async RetrieveBradenheadNewWellsAsync(reportMonthId: number): Promise<BradenheadNewWell[]> {
        const data = await this.get<BradenheadNewWell[]>(`/api/legacy/bradenhead/${reportMonthId}/new-wells`);
        return data;
    }

    public async RetrieveBradenheadDashboardAsync(reportMonthId: number): Promise<BradenheadPressureData[]> {
        const data = await this.get<BradenheadPressureData[]>(`/api/legacy/bradenhead/pressure/${reportMonthId}`);
        return data.map((p: BradenheadPressureData) => BradenheadPressureData.mapData(p));
    }

    public async RetrieveBradenheadDispatchesAsync(reportMonthId: number): Promise<BradenheadDispatch[]> {
        const data = await this.get<BradenheadDispatch[]>(`/api/legacy/bradenhead/dispatches/${reportMonthId}`);
        return data.map((p: BradenheadDispatch) => BradenheadDispatch.mapDispatch(p));
    }

    public async AddBradenheadWellDataAsync(reportingMonthId: number, wells: BradenheadNewWell[]): Promise<any> {
        const request = {
            newWells: wells,
        };
        await this.execute('POST', `/api/legacy/bradenhead/${reportingMonthId}/wells`, JSON.stringify(request));
    }

    public async UpdateBradenheadWellDataAsync(reportingMonthId: number, data: BradenheadPressureData): Promise<any> {
        await this.execute('PUT', `/api/legacy/bradenhead/${reportingMonthId}/wells/${data.wellId}`, JSON.stringify(data));
    }

    public async RemoveBradenheadWellDataAsync(reportingMonthId: number, data: BradenheadPressureData): Promise<any> {
        await this.execute('DELETE', `/api/legacy/bradenhead/${reportingMonthId}/wells/${data.wellId}`);
    }

    public async RolloverBradenheadDashboardAsync(reportMonthId: number, newReportingMonthName: string, newReportingMonthDescription: string): Promise<any> {
        const request = {
            ReportMonthName: newReportingMonthName,
            ReportMonthDescription: newReportingMonthDescription,
        };
        await this.execute('POST', `/api/legacy/bradenhead/${reportMonthId}/rollover`, JSON.stringify(request));
    };

    public async DispatchBradenheadPressureRequest(item: CreateBradenheadDispatch): Promise<void> {
        await this.execute('POST', '/api/legacy/bradenhead/dispatch', JSON.stringify(item));
    }

    public async RolloverBradenheadDataAsync(reportingMonthId: number): Promise<void> {
        await this.execute('POST', `/api/legacy/bradenhead/${reportingMonthId}/rollover`);
    }

    public async RemoveBradenheadDispatchAsync(dispatchId: number): Promise<any> {
        await this.execute('DELETE', `/api/legacy/bradenhead/dispatches/${dispatchId}`);
    };


    public async RetrieveFormListAsync(): Promise<FormDefinition[]> {
        const data = await this.get<FormDefinition[]>('/api/legacy/forms/forms');
        return data.map((p: FormDefinition) => FormDefinition.mapDefinition(p));
    }

    public async RetrieveFormAsync(formId: number): Promise<FormDefinition> {
        const data = await this.get<FormDefinition>(`/api/legacy/forms/forms/${formId}`);
        return FormDefinition.mapDefinition(data);
    }

    public async CreateFormAsync(form: FormDefinition): Promise<FormDefinition> {
        const data = await this.post<FormDefinition>(`/api/legacy/forms/forms`, JSON.stringify(form));
        return FormDefinition.mapDefinition(data);
    }

    public async UpdateFormAsync(form: FormDefinition): Promise<FormDefinition> {
        const data = await this.put<FormDefinition>(`/api/legacy/forms/forms/${form.id}`, JSON.stringify(form));
        return FormDefinition.mapDefinition(data);
    }

    public async RetrieveFormSubmissionsAsync(formId: number | undefined, startDate: Date | undefined, endDate: Date | undefined): Promise<FormSubmission[]> {
        const data = await this.get<FormSubmission[]>(`/api/legacy/forms/form-submissions/${formId}?&startDate=${startDate?.toLocaleDateString()}&endDate=${endDate?.toLocaleDateString()}`);
        return data.map((p: FormSubmission) => FormSubmission.mapFormSubmission(p));
    }

    public async RetrieveFormSubmissionXmlDataAsync(pnfRequestId: number): Promise<FormXmlData> {
        const data = await this.get<FormXmlData>(`/api/legacy/forms/form-submissions/xml-data/${pnfRequestId}`);
        return FormXmlData.mapFormData(data);
    }

    public async RetrieveFormSubmissionEntityDataAsync(referenceNumber: string): Promise<EntityView[]> {
        const data = await this.get<EntityView[]>(`/api/legacy/forms/form-submissions/attribute-data/${referenceNumber}`);
        return data.map((p: EntityView) => EntityView.mapEntity(p));
    }

    public async UpdateFormSubmissionEntityDataAsync(referenceNumber: string, updateValues: EntityView[]): Promise<EntityView[]> {
        const data = await this.put<EntityView[]>(`/api/legacy/forms/form-submissions/attribute-data/${referenceNumber}`, JSON.stringify(updateValues));
        return data.map((p: EntityView) => EntityView.mapEntity(p));
    }

    public async RetrieveFormImageAsync(imageGuid: string): Promise<FormImage> {
        const data = await this.get<FormImage>(`/api/legacy/forms/form-image/${imageGuid}`);
        return data;
    }


    public async RetrieveLdarDispatchFormsAsync(): Promise<DispatchForm[]> {
        const data = await this.get<DispatchForm[]>('/api/legacy/ldar/dispatch-forms');
        return data;
    }

    public async RetrieveLdarDashboardAsync(): Promise<InspectionRequestDashboardItem[]> {
        const data = await this.get<InspectionRequestDashboardItem[]>('/api/legacy/ldar/dashboard');
        return data.map((p: InspectionRequestDashboardItem) => InspectionRequestDashboardItem.mapInspectionRequestData(p));
    }

    public async RetrieveLdarCompletedInspectionsAsync(startDate: Date, endDate: Date): Promise<LdarCompletedInspections[]> {
        const data = await this.get<LdarCompletedInspections[]>(`/api/legacy/ldar/inspections?startDate=${startDate?.toLocaleDateString()}&endDate=${endDate?.toLocaleDateString()}`);
        return data.map((p: LdarCompletedInspections) => LdarCompletedInspections.mapData(p));
    }

    public async RemoveLdarInspectionAsync(inspectionRequestId: number) {
        await this.execute('DELETE', `/api/legacy/ldar/inspections/${inspectionRequestId}`);
    }

    public async UpdateLdarInspectionRequestAsync(inspectionRequest: InspectionRequestDashboardItem): Promise<any> {
        const request: UpdateLdarInspectionRequest = {
            inspectionRequestStatus: inspectionRequest.inspectionRequestStatus,
            startDate: inspectionRequest.startDate,
            dueDate: inspectionRequest.dueDate,
            comments: inspectionRequest.commentText,
        };

        await this.execute('PUT', `/api/legacy/ldar/dashboard/${inspectionRequest.inspectionRequestId}`, JSON.stringify(request));
    }

    public async DispatchLdarInspectionRequest(item: InspectionRequestDashboardItem, users: string[]) {
        await this.execute('POST', '/api/legacy/ldar/dispatch', JSON.stringify({
            inspectionRequest: item,
            recipients: users,
        }));
    };

    public async RetrieveLdarDispatchesAsync(): Promise<ProntoFormsDispatch[]> {
        const data = await this.get<ProntoFormsDispatch[]>('/api/legacy/ldar/dispatches');
        return data.map((p: any) => ProntoFormsDispatch.mapDispatch(p));
    }

    public async UpdateLdarDispatchAsync(dispatchId: number, updatedDispatchBody: string): Promise<void> {
        await this.execute('PUT', `/api/legacy/ldar/dispatches/${dispatchId}`, JSON.stringify(updatedDispatchBody));
    };

    public async RemoveLdarDispatchAsync(dispatchId: number): Promise<any> {
        await this.execute('DELETE', `/api/legacy/ldar/dispatches/${dispatchId}`);
    };


    public async RetrieveOrdDispatchFormsAsync(): Promise<DispatchForm[]> {
        //const data = await this.get<DispatchForm[]>('/api/legacy/ord/dispatch-forms');
        return [] //data;
    }

    public async RetrieveOrdDashboardAsync(): Promise<InspectionRequestDashboardItem[]> {
        const data = await this.get<InspectionRequestDashboardItem[]>('/api/legacy/ord/dashboard');
        return data.map((p: InspectionRequestDashboardItem) => InspectionRequestDashboardItem.mapInspectionRequestData(p));
    }

    public async RetrieveOrdCompletedInspectionsAsync(startDate: Date, endDate: Date): Promise<OrdCompletedInspections[]> {
        const data = await this.get<OrdCompletedInspections[]>(`/api/legacy/ord/inspections?startDate=${startDate?.toLocaleDateString()}&endDate=${endDate?.toLocaleDateString()}`);
        return data.map((p: OrdCompletedInspections) => OrdCompletedInspections.mapData(p));
    }

    public async UpdateOrdInspectionRequestAsync(inspectionRequest: InspectionRequestDashboardItem): Promise<any> {
        const request: UpdateOrdInspectionRequest = {
            formName: inspectionRequest.formName,
            assetId: inspectionRequest.assetId,
            assetDescription: inspectionRequest.assetDescription,
            taskId: inspectionRequest.taskId,
            inspectionRequestStatus: inspectionRequest.inspectionRequestStatus,
            startDate: inspectionRequest.startDate,
            dueDate: inspectionRequest.dueDate,
            userDefined1: inspectionRequest.userDefined1,
            userDefined2: inspectionRequest.userDefined2,
            masterId: inspectionRequest.masterId,
            requestId: inspectionRequest.requestId,
            commentText: inspectionRequest.commentText,
        };

        await this.execute('PUT', `/api/legacy/ord/dashboard/${inspectionRequest.inspectionRequestId}`, JSON.stringify(request));
    }

    public async RemoveOrdInspectionAsync(inspectionRequestId: number, comments: string) {
        await this.execute('DELETE', `/api/legacy/ord/inspections/${inspectionRequestId}?comments=${comments}`);
    }

    public async DispatchOrdInspectionRequest(item: InspectionRequestDashboardItem, users: string[]) {
        await this.execute('POST', '/api/legacy/ord/dispatch', JSON.stringify({
            inspectionRequest: item,
            recipients: users,
        }));
    };

    public async RetrieveOrdDispatchesAsync(): Promise<ProntoFormsDispatch[]> {
        const data = await this.get<ProntoFormsDispatch[]>('/api/legacy/ord/dispatches');
        return data.map((p: any) => ProntoFormsDispatch.mapDispatch(p));
    }

    public async CreateOrdInspectionRequestAsync(inspectionRequest: InspectionRequestDashboardItem) {
        await this.execute('POST', '/api/legacy/ord/dashboard', JSON.stringify(inspectionRequest));
    }

    public async RemoveOrdDispatchAsync(dispatchId: number, comments: string): Promise<any> {
        await this.execute('DELETE', `/api/legacy/ord/dispatches/${dispatchId}?comments=${comments}`);
    };

    public async UpdateOrdDispatchAsync(dispatchId: number, updatedDispatchBody: string): Promise<void> {
        await this.execute('PUT', `/api/legacy/ord/dispatches/${dispatchId}`, JSON.stringify(updatedDispatchBody));
    };

    public async RetrieveAvoVocDispatchesAsync(): Promise<AvoVocDispatch[]> {
        const data = await this.get<AvoVocDispatch[]>('/api/legacy/avo-voc-inspections/dispatches');
        return data.map((p: AvoVocDispatch) => AvoVocDispatch.mapDispatch(p));
    }


    public async RetrieveAppResourcesAsync(): Promise<AppResource[]> {
        const data = await this.get<AppResource[]>('/api/legacy/security/app-resources');
        return data.map((r: AppResource) => AppResource.mapResource(r));
    }


    public async RetrieveAppUsersAsync(): Promise<AppUser[]> {
        const data = await this.get<AppUser[]>('/api/legacy/security/app-users');
        return data.map((u: AppUser) => AppUser.mapUser(u));
    }

    public async CreateAppUserAsync(user: AppUser): Promise<AppUser> {
        const data = await this.post<AppUser>('/api/legacy/security/app-users', JSON.stringify(user));
        return AppUser.mapUser(data);
    }

    public async UpdateAppUserAsync(user: AppUser): Promise<AppUser> {
        const data = await this.put<AppUser>(`/api/legacy/security/app-users/${user.id}`, JSON.stringify(user));
        return AppUser.mapUser(data);
    }

    public async RemoveAppUserAsync(user: AppUser): Promise<any> {
        await new Promise((resolve) => setTimeout(resolve, 3000));
    }


    public async RetrieveAppRolesAsync(): Promise<AppRole[]> {
        const data = await this.get<AppRole[]>('/api/legacy/security/app-roles');
        return data.map((r: AppRole) => AppRole.mapRole(r));
    }

    public async CreateAppRoleAsync(role: AppRole): Promise<AppRole> {
        const data = await this.post<AppRole>('/api/legacy/security/app-roles', JSON.stringify(role));
        return AppRole.mapRole(data);
    }

    public async UpdateAppRoleAsync(role: AppRole): Promise<AppRole> {
        const data = await this.put<AppRole>(`/api/legacy/security/app-roles/${role.id}`, JSON.stringify(role));
        return AppRole.mapRole(data);
    }

    public async RemoveAppRoleAsync(role: AppRole): Promise<any> {
        await new Promise((resolve) => setTimeout(resolve, 3000));
    }


    public async RetrieveReg7HaulerReportMonthsAsync(): Promise<ReportMonth[]> {
        const data = await this.get<ReportMonth[]>('/api/legacy/reg7-hauler/reporting-months');
        return data.map((p: ReportMonth) => ReportMonth.mapReportMonth(p));
    }

    public async RetrieveReg7HaulerDashboardAsync(reportMonthId: number): Promise<Reg7HaulerDashboardView[]> {
        const data = await this.get<Reg7HaulerDashboardView[]>(`/api/legacy/reg7-hauler/dashboard/${reportMonthId}`);
        return data.map((p: Reg7HaulerDashboardView) => Reg7HaulerDashboardView.mapDashboard(p));
    }

    public async RolloverReg7HaulerDashboardAsync(reportMonthId: number, newReportingMonthName: string, newReportingMonthDescription: string): Promise<any> {
        const request = {
            ReportMonthName: newReportingMonthName,
            ReportMonthDescription: newReportingMonthDescription,
        };
        await this.execute('POST', `/api/legacy/reg7-hauler/${reportMonthId}/rollover`, JSON.stringify(request));
    };

    public async RetrieveReg7HaulerNewLodsAsync(reportingMonthId: number) {
        const data = await this.get<Reg7HaulerNewLod[]>(`/api/legacy/reg7-hauler/${reportingMonthId}/new-lods`);
        return data;
    }

    public async AddReg7HaulerLodAsync(reportingMonthId: number, lods: Reg7HaulerNewLod[]): Promise<any> {
        const request = {
            newLods: lods,
        };
        await this.execute('POST', `/api/legacy/reg7-hauler/${reportingMonthId}/lods`, JSON.stringify(request));
    }

    public async RemoveReg7HaulerLodAsync(reportingMonthId: number, data: Reg7HaulerDashboardView): Promise<any> {
        await this.execute('DELETE', `/api/legacy/reg7-hauler/${reportingMonthId}/lods/${data.lodId}`);
    }
};

export default OdsService;
