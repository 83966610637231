import { useState } from "react";
import { OdsDateColumn, OdsDateColumnHeader, OdsIntegerColumn, OdsIntegerColumnHeader, OdsItemColumnHeader, OdsPersonnelChip, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsDispatchFormChip } from "../../../components/dispatch-form-chip/dispatch-form-chip";
import { OdsOrdStatusChip } from "../../../components/ord-status-chip/ord-status-chip";
import { OdsOrdTaskChip } from "../../../components/ord-task-chip/ord-task-chip";
import { OdsMultiDeleteColumnHeader } from "../../../components/table-column-headers/multi-delete-column-header/multi-delete-column-header";
import { OdsDateTimeColumn } from "../../../components/table-columns/date-time-column/date-time-column";
import OdsDeleteColumn from "../../../components/table-columns/delete-column/delete-column";
import { OdsConfirmDeleteDialog } from "../../../dialogs";
import { OdsConfirmMultiDeleteDialog } from "../../../dialogs/confirm-multi-delete/confirm-multi-delete.dialog";
import { useMyself, usePagedListResult } from "../../../hooks";
import { DateFilter, IntegerFilter, ItemFilter, StringFilter } from "../../../models";
import { OrdInspectionRequestDispatch } from "../../../models/ord-inspection-request-dispatch";
import { OrdInspectionRequestDispatchFilter } from "../../../models/ord-inspection-request-dispatch-filter";
import { Permission } from "../../../models/permission";
import { CvxContentBlock, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import { OdsOrdApiService, OdsPersonnelApiService, ViewHelper } from "../../../services";


export interface OdsOrdInspectionRequestDispatchesViewProps {
    service: OdsOrdApiService;
    personnelService: OdsPersonnelApiService;
}

export const OdsOrdInspectionRequestDispatchesView: React.FC<OdsOrdInspectionRequestDispatchesViewProps> = ({
    service,
    personnelService
}) => {
    const [canEdit, showState, states] = useMyself(service, Permission.EditORDInspectionRequests);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: OrdInspectionRequestDispatchFilter) => {
        return service.RetrieveOrdInspectionRequestDispatchListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('DispatchDate', 'Desc', new OrdInspectionRequestDispatchFilter(), onFetchData);

    const [removeItem, setRemoveItem] = useState<OrdInspectionRequestDispatch>();
    const [removeItems, setRemoveItems] = useState<OrdInspectionRequestDispatch[]>();

    const onFetchPersonnel = async(item: OrdInspectionRequestDispatch, id: number) =>  {
        let data = await personnelService.RetrievePersonnelAsync(id);
        item.personnel = data;
        return data;
    };


    const onRemoveItem = (item: OrdInspectionRequestDispatch) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await service.DeleteOrdInspectionRequestDispatchAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    };

    const onMultiRemoveItems = () => setRemoveItems(selectedItems);

    const onRemoveItemsCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItems) {
            for(let removeItem of removeItems) {
                await service.DeleteOrdInspectionRequestDispatchAsync(removeItem);
            }
            onRefresh();
        }
        setRemoveItems(undefined);
    };


    const onExport = async () => {
        const blob = await service.ExportOrdInspectionRequestDispatchListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`ord-inspection-dispatches-${(new Date()).toLocaleDateString()}.csv`, blob)
    };

    const onFilterId = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); };
    const onFilterAssetNumber = (newFilter?: StringFilter) => { filter.assetNumber = newFilter; onRefresh(); };
    const onFilterAssetDescription = (newFilter?: StringFilter) => { filter.assetDescription = newFilter; onRefresh(); };
    const onFilterForm = (newFilter?: ItemFilter) => { filter.form = newFilter; onRefresh(); };
    const onFilterTask = (newFilter?: ItemFilter) => { filter.task = newFilter; onRefresh(); };
    const onFilterStatus = (newFilter?: ItemFilter) => { filter.status = newFilter; onRefresh(); };
    const onFilterRoute = (newFilter?: ItemFilter) => { filter.route = newFilter; onRefresh(); };
    const onFilterStartDate = (newFilter?: DateFilter) => { filter.startDate = newFilter; onRefresh(); };
    const onFilterDueDate = (newFilter?: DateFilter) => { filter.dueDate = newFilter; onRefresh(); };
    const onFilterDispatchDate = (newFilter?: DateFilter) => { filter.dispatchDate = newFilter; onRefresh(); };
    const onFilterDispatchNumber = (newFilter?: StringFilter) => { filter.dispatchNumber = newFilter; onRefresh(); };
    const onFilterPersonnel = (newFilter?: ItemFilter) => { filter.personnel = newFilter; onRefresh(); };

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveOrdInspectionRequestDispatchSearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsConfirmDeleteDialog show={!!removeItem} text={`${removeItem?.inspectionRequestId} - ${removeItem?.personnel?.fullName}`} onComplete={onRemoveItemCompleted} />
            <OdsConfirmMultiDeleteDialog show={!!removeItems} onComplete={onRemoveItemsCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="ORD Inspection Dispatches" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged} pageSizeOptions={[5, 10, 25, 100, 500]}
                            tableHeader={
                                <tr>
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="AssetNumber" header="Asset Number" filter={filter.assetNumber} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterAssetNumber} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="AssetDescription" header="Asset Description" filter={filter.assetDescription} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterAssetDescription} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Form" header="Form" filter={filter.form} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterForm} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Task" header="Task" filter={filter.task} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterTask} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Status" header="Status" filter={filter.status} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterStatus} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Route" header="Route" filter={filter.route} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterRoute} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="StartDate" header="Start Date" filter={filter.startDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterStartDate} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="DueDate" header="Due Date" filter={filter.dueDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDueDate} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="DispatchDate" header="Dispatch Date" filter={filter.dispatchDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDispatchDate} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="DispatchNumber" header="Dispatch Number" filter={filter.dispatchNumber} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDispatchNumber} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Personnel" header="Dispatch User" filter={filter.personnel} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterPersonnel} onSearch={onFilterValueSearch} />
                                    {canEdit ? <OdsMultiDeleteColumnHeader selectedItems={selectedItems} onClick={onMultiRemoveItems} /> : ''}
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.inspectionRequestId}</OdsIntegerColumn>
                                    <OdsStringColumn value={item.assetNumber} />
                                    <OdsStringColumn value={item.assetDescription} />
                                    <td><OdsDispatchFormChip item={item} service={service} /></td>
                                    <td><OdsOrdTaskChip inspectionRequest={item} service={service} /></td>
                                    <td><OdsOrdStatusChip inspectionRequest={item} service={service} /></td>
                                    <td>{item.routeId}</td>
                                    <OdsDateColumn value={item.startDate} />
                                    <OdsDateColumn value={item.dueDate} />
                                    <OdsDateTimeColumn value={item.dispatchDate} />
                                    <OdsStringColumn>{item.dispatchNumber}</OdsStringColumn>
                                    <td><OdsPersonnelChip id={item.personnelId} item={item} onFetchPersonnel={onFetchPersonnel} /></td>
                                    {canEdit ? <td><OdsDeleteColumn item={item} onDelete={onRemoveItem} /></td> : ''}
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
