import React from "react";
import { useState } from "react";
import { OdsIntegerColumnHeader, OdsStringColumnHeader, OdsItemColumnHeader, OdsBooleanColumnHeader, OdsPersonnelGroupChip, OdsTableBanner, OdsTable, OdsSelectableTableRow, OdsBooleanColumn, OdsEditItemColumn, OdsIntegerColumn, OdsRemoveItemColumn, OdsSelectItemColumn } from "../../../components";
import { OdsStateLookup } from "../../../components/state-lookup/state-lookup";
import { OdsConfirmDeleteDialog, OdsEditRouteDialog } from "../../../dialogs";
import { usePagedListResult } from "../../../hooks/list-result-hook";
import { useMyself } from "../../../hooks/myself-hook";
import { RouteFilter, PersonnelGroup, IntegerFilter, StringFilter, BooleanFilter, Route } from "../../../models";
import { Permission } from "../../../models/permission";
import { CvxLayoutCenterColumn, CvxPage, CvxContentBlock, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import { OdsPersonnelApiService, ViewHelper } from "../../../services";

export interface OdsRoutesViewProps {
    service: OdsPersonnelApiService;
}

export const OdsRoutesView: React.FC<OdsRoutesViewProps> = ({
    service,
}) => {

    const [canEdit, showState, states] = useMyself(service, Permission.EditPersonnel);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: RouteFilter) => {
        return service.RetrieveRouteListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect] 
        = usePagedListResult('Name', 'Asc', new RouteFilter(), onFetchData);

    const [editItem, setEditItem] = useState<Route>();
    const [removeItem, setRemoveItem] = useState<Route>();

    const [personnelGroups] = useState<Map<number, Promise<PersonnelGroup>>>(new Map());


    const onFetchPersonnelGroup = (id: number) => {
        let data = personnelGroups.get(id);
        if (!data) {
            data = service.RetrievePersonnelGroupAsync(id);
            personnelGroups.set(id, data);
        }
        return data;
    };

    const onFetchState = async (id: number) => {
        const data = await service.RetrieveStateAsync(id);
        return data;
    };

    const onAddItem = () => setEditItem(new Route());

    const onEditItem = (item: Route) => setupEditItem(item);

    const setupEditItem = async (item: Route) => {
        setEditItem(await ViewHelper.ensureStateId(service, item, states));
    };

    const onEditItemCompleted = async (dialogResult: boolean, item?: Route) => {
        if (dialogResult && item) {
            if (item.id) {
                await service.UpdateRouteAsync(item);
            } else {
                await service.CreateRouteAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    };

    const onRemoveItem = (item: Route) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await service.DeleteRouteAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    };

    const onExport = async () => {
        const blob = await service.ExportRouteListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`routes-${(new Date()).toLocaleDateString()}.csv`, blob)
    };

    const onFilterId = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); };
    const onFilterName = (newFilter?: StringFilter) => { filter.name = newFilter; onRefresh(); };
    const onFilterDescription = (newFilter?: StringFilter) => { filter.description = newFilter; onRefresh(); };
    const onFilterEcObjectCode = (newFilter?: StringFilter) => { filter.ecObjectCode = newFilter; onRefresh(); };
    const onFilterInforRouteCode = (newFilter?: StringFilter) => { filter.inforRouteCode = newFilter; onRefresh(); };
    const onFilterPersonnelGroups = (newFilter?: StringFilter) => { filter.personnelGroups = newFilter; onRefresh(); };
    const onFilterState = (newFilter?: StringFilter) => { filter.state = newFilter; onRefresh(); };
    const onFilterIsActive = (newFilter?: BooleanFilter) => { filter.isActive = newFilter; onRefresh(); };

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveRouteSearchListAsync(columnName, top, skip, filter);

    return (
        <>
            <OdsEditRouteDialog service={service} show={!!editItem} route={editItem} onComplete={onEditItemCompleted} />
            <OdsConfirmDeleteDialog show={!!removeItem} text={removeItem?.name} onComplete={onRemoveItemCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onAddItem={canEdit ? onAddItem : undefined} onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="Routes" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    {canEdit ? <th></th> : ''}
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Name" header="Name" filter={filter.name} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterName} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="Description" header="Description" filter={filter.description} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDescription} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="EcObjectCode" header="EC&nbsp;Object&nbsp;Code" filter={filter.ecObjectCode} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterEcObjectCode} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="InforRouteCode" header="Infor&nbsp;Route&nbsp;Code" filter={filter.inforRouteCode} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterInforRouteCode} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="PersonnelGroups" header="Personnel&nbsp;Groups" filter={filter.personnelGroups}
                                        onFilterChanged={onFilterPersonnelGroups} onSearch={onFilterValueSearch} />
                                    {showState ? <OdsStringColumnHeader name="State" header="State" filter={filter.state} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterState} onSearch={onFilterValueSearch} /> : ''}
                                    <OdsBooleanColumnHeader name="IsActive" header="Is&nbsp;Active" filter={filter.isActive}
                                        onFilterChanged={onFilterIsActive} />
                                    {canEdit ? <th></th> : ''}
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    {canEdit ? ((states.filter(d => d === item.stateId).length > 0) ? <OdsEditItemColumn item={item} onEditItem={onEditItem} /> : <td></td>) : ''}
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.id}</OdsIntegerColumn>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td>{item.ecObjectCode}</td>
                                    <td>{item.inforRouteCode}</td>
                                    <td>{item.personnelGroupIds?.map(p => <span><OdsPersonnelGroupChip item={item} id={p} onFetchPersonnelGroup={onFetchPersonnelGroup} />, </span>)}</td>
                                    {showState ? <td><OdsStateLookup item={item} onFetchState={onFetchState} /></td> : ''}
                                    <OdsBooleanColumn value={item.isActive} />
                                    {canEdit ? ((states.filter(d => d === item.stateId).length > 0) ? <OdsRemoveItemColumn item={item} onRemoveItem={onRemoveItem} /> : <td></td>) : ''}
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
}
