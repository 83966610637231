import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Reg7HaulerDashboardView } from "../../models/reg7-hauler-dashboard-view";
import { CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";

export interface OdsRemoveReg7HaulerLodDialogProps {
    show: boolean;
    lod: Reg7HaulerDashboardView | undefined;
    onComplete: (dialogResult: boolean) => Promise<any>;
}

const OdsRemoveReg7HaulerLodDialog: React.FC<OdsRemoveReg7HaulerLodDialogProps> = ({
    show,
    lod,
    onComplete,
    ...props
}) => {
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onCancel = async (): Promise<any> => {
        if (onComplete) {
            await onComplete(false);
        }

        setIsSaving(false);
    };

    const onRemove = async (): Promise<any> => {
        if (onComplete) {
            setIsSaving(true);
            await onComplete(true);
            setIsSaving(false);
        }
    }

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Remove LOD</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>Remove LOD '{lod?.lodName}'?</span>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxSpinnerButton theme='primary' showSpinner={isSaving} onClick={() => onRemove()}>Remove</CvxSpinnerButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsRemoveReg7HaulerLodDialog;
