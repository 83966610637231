import { PersonnelGroup } from "./personnel-group";
import { State } from "./state";

export class Route {
    public id: number = 0;
    public name: string = '';
    public description: string = '';
    public ecObjectCode: string = '';
    public inforRouteCode: string = '';
    public personnelGroupIds: number[] = [];
    public personnelGroups: PersonnelGroup[] = [];
    public stateId: number = 0;
    public isActive: boolean = true;

    public state?: State;

    public static map(data: any): Route {
        const m = new Route;

        m.id = parseInt(data.id);
        m.name = data.name;
        m.description = data.description;
        m.ecObjectCode = data.ecObjectCode;
        m.inforRouteCode = data.inforRouteCode;
        m.personnelGroupIds = data.personnelGroupIds?.map((d: any) => parseInt(d)) ?? [];
        m.stateId = parseInt(data.stateId);
        m.isActive = data.isActive;

        return m;
    };
};
