import { Personnel } from "./personnel";
import { PersonnelGroupType } from "./personnel-group-type";
import { State } from "./state";

export class PersonnelGroup {
    public id: number = 0;
    public name: string = '';
    public description: string = '';
    public personnelGroupTypeId?: number;
    public personnelGroupType?: PersonnelGroupType;
    public personnelIds: number[] = [];
    public personnel: Personnel[] = [];
    public stateId: number = 0;
    public isActive: boolean = true;

    public state?: State;

    public static map(data: any): PersonnelGroup {
        const m = new PersonnelGroup;

        m.id = parseInt(data.id);
        m.name = data.name;
        m.description = data.description;
        m.personnelGroupTypeId = parseInt(data.personnelGroupTypeId);
        m.personnelIds = data.personnelIds?.map((d: any) => parseInt(d)) ?? [];
        m.stateId = parseInt(data.stateId);
        m.isActive = data.isActive;

        return m;
    };
};
