import React, { HtmlHTMLAttributes, MouseEventHandler } from "react";
import { Button } from "react-bootstrap";

export interface CvxButtonProps {
    theme?: 'primary' | 'accent' | 'warning';
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const CvxButton: React.FC<CvxButtonProps & HtmlHTMLAttributes<HTMLButtonElement>> = ({
    theme,
    disabled,
    onClick,
    ...props
}) => {
    return (
        <Button className="p1 gotham-book" variant={theme ?? 'primary'} disabled={disabled} onClick={onClick} {...props}>{props.children}</Button>
    );
};

export default CvxButton;
