import { useEffect, useState } from "react";
import Themes from "../../helpers/themes";
import { MenuItemDefinition, NavigationService } from "../../services/navigation-service";
import CvxSiteMenuItem from "../../subcomponents/site-menu-item/site-menu-item";


export interface CvxSiteNavigationProps {
    navigationService: NavigationService,
}

export const CvxSiteNavigation: React.FC<CvxSiteNavigationProps> = ({
    navigationService,
    ...props
}) => {
    const [siteMenu, setSiteMenu] = useState<MenuItemDefinition[]>([]);
    const [style, setStyle] = useState({});

    useEffect(() => {
        const siteMenuSub = navigationService.siteMenu.subscribe((menu) => {
            setSiteMenu(menu);
            setStyle({ gridTemplateColumns: 'auto repeat(' + menu.length + ', auto) auto' });
        });

        return () => {
            siteMenuSub.unsubscribe();
        }
    }, []);

    return (
        <ul className={`cvx-site-navigation ${Themes.PrimaryDarkBackground} ${Themes.LightColor}`} style={style}>
            {siteMenu.map((link) => <CvxSiteMenuItem key={link.label} menuItem={link} />)}
        </ul>
    );
};

export default CvxSiteNavigation;
