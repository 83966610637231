import Fonts from "../../helpers/fonts";
import FontSizes from "../../helpers/fontsizes";
import Themes from "../../helpers/themes";
import { MenuItemDefinition } from "../../services/navigation-service";
import CvxMenuItem from "../menu-item/menu-item";

export interface CvxSiteMenuItemProps {
    menuItem: MenuItemDefinition,
}

export const CvxSiteMenuItem: React.FC<CvxSiteMenuItemProps> = ({
    menuItem,
    ...props
}) => {
    return (
        <li className={`cvx-site-menu-item ${Fonts.GothamBold} ${FontSizes.H5}`}>
            <CvxMenuItem label={menuItem.label} href={menuItem.href} to={menuItem.to} target={menuItem.target} />
            {menuItem.menuItems ?
                <ul className={Themes.PrimaryMediumBackground}>
                    {menuItem.menuItems?.map((mi) => <CvxSiteMenuItem key={mi.label} menuItem={mi} />)}
                </ul>
                : ''}
        </li>
    );
};

export default CvxSiteMenuItem;
