export class LdarHoliday {
    public id: number = 0;
    public name: string = '';
    public date?: Date;
    public modifiedDate?: Date;

    public static map(data: any) {
        const d = new LdarHoliday();

        d.id = parseInt(data.id);
        d.name = data.name;
        d.date = new Date(data.date);
        d.modifiedDate = new Date(data.modifiedDate);
        
        return d;
    }
}
