import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useReducer, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import OdsBaseTable from "../../components/base-table/base-table";
import { EntityView } from "../../models/entity-view";
import { CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";
import OdsService from "../../services/ods-service";

export interface OdsShowFormAttributeDataDialogProps {
    show: boolean;
    referenceNumber: string | undefined;
    odsService: OdsService | undefined;
    onComplete: (dialogResult: boolean, referenceNumber: string, updatedValues: EntityView[]) => Promise<any>;
}

const OdsShowFormAttributeDataDialog: React.FC<OdsShowFormAttributeDataDialogProps> = ({
    show,
    referenceNumber,
    odsService,
    onComplete,
    ...props
}) => {
    const [formData, setFormData] = useState<EntityView[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const onCancel = async (): Promise<any> => {
        if (onComplete) {
            await onComplete(false, referenceNumber ?? '', []);
        }
    };

    const onSubmit = async (): Promise<any> => {
        if (onComplete) {
            setIsSaving(true);

            const updatedValues: EntityView[] = [];

            formData.forEach(d => {
                if (d.updated === true) {
                    updatedValues.push(d);
                }
            });

            await onComplete(true, referenceNumber ?? '', updatedValues);
            setIsSaving(false);
        }
    };

    const updateValue = (item: EntityView, value: string) => {
        item.attributeValue = value;
        item.updated = true;

        forceUpdate();
    }

    useEffect(() => {
        fetchFormData();
    }, [referenceNumber, odsService]);

    const fetchFormData = async () => {
        setIsLoading(true);
        if (!!odsService && !!referenceNumber) {
            const data = await odsService.RetrieveFormSubmissionEntityDataAsync(referenceNumber);
            setFormData(data);
        } else {
            setFormData([]);
        }
        setIsLoading(false);
    };

    return (
        <Modal className="theme-blue" size="xl" show={show} centered backdrop='static' scrollable={true} onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Form Submission '{referenceNumber}' Data</Modal.Title>
            </Modal.Header>

            <Formik onSubmit={onSubmit} initialValues={formData}>
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors,
                    ...props
                }) => (
                    <>
                        <Modal.Body>
                            <Form noValidate onSubmit={handleSubmit}>
                                <OdsBaseTable columns={[
                                    { header: 'Attribute Name', align: 'left', template: ((i) => i.attributeName) },
                                    { header: 'Sequence', align: 'left', template: ((i) => i.sequence) },
                                    { header: 'Attribute Value', align: 'left', template: ((i) => <Form.Control name={i.attributeValue} type="text" value={i.attributeValue} onChange={e => updateValue(i, e.target.value)} />) },
                                    { header: 'Image?', align: 'center', template: ((i) => '') },
                                    { header: 'Attribute Id', align: 'center', template: ((i) => i.attributeId) },
                                ]} dataSource={formData} isLoading={isLoading} />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <CvxButton theme='accent' onClick={onCancel}>Cancel</CvxButton>
                            <CvxSpinnerButton theme='primary' disabled={!isValid} showSpinner={isSaving} onClick={onSubmit}>Save</CvxSpinnerButton>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default OdsShowFormAttributeDataDialog;
