export class FormDataSource {
    public formSpaceId: number = 0;
    public dataSourceId: number = 0;
    public name: string = '';
    public description: string = '';
    public sqlQuery: string = '';
    public isActive: boolean = true;
    public modifiedDate?: Date;

    public lastPushedDateTime?: Date;
    public lastPushIsSuccess: boolean = false;
    public lastRowsPushed: number = 0;
    public lastPushError: string = '';

    public get id(): string { return `${this.formSpaceId}/${this.dataSourceId}` };

    public static map(data: any) {
        const m = new FormDataSource();

        m.formSpaceId = parseInt(data.formSpaceId);
        m.dataSourceId = parseInt(data.dataSourceId);
        m.name = data.name;
        m.description = data.description;
        m.sqlQuery = data.sqlQuery;
        m.isActive = data.isActive;
        m.modifiedDate = new Date(data.modifiedDate);

        m.lastPushedDateTime = new Date(data.lastPushedDateTime);
        m.lastPushIsSuccess = data.lastPushIsSuccess;
        m.lastRowsPushed = data.lastRowsPushed;
        m.lastPushError = data.lastPushError;

        return m;
    }
}
