import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BradenheadPressureData } from "../../models/bradenhead-pressure-data";
import { CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";

export interface OdsRemoveBradenheadWellDialogProps {
    show: boolean;
    well: BradenheadPressureData | undefined;
    onComplete: (dialogResult: boolean) => Promise<any>;
}

const OdsRemoveBradenheadWellDialog: React.FC<OdsRemoveBradenheadWellDialogProps> = ({
    show,
    well,
    onComplete,
    ...props
}) => {
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onCancel = async (): Promise<any> => {
        if (onComplete) {
            await onComplete(false);
        }

        setIsSaving(false);
    };

    const onRemove = async (): Promise<any> => {
        if (onComplete) {
            setIsSaving(true);
            await onComplete(true);
            setIsSaving(false);
        }
    }

    return (
        <Modal className="theme-blue" show={show} centered backdrop='static' onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Remove well</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>Remove well '{well?.wellName}'?</span>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
                <CvxSpinnerButton theme='primary' showSpinner={isSaving} onClick={() => onRemove()}>Remove</CvxSpinnerButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsRemoveBradenheadWellDialog;
