export class AppUser {
    public id?: number;
    public userName: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public comments: string = '';
    public isActive: boolean = true;
    public roleIds: number[] = [];

    public static mapUser(data: any): AppUser {
        const user = new AppUser();

        user.id = data.id;
        user.userName = data.userName ?? '';
        user.firstName = data.firstName ?? '';
        user.lastName = data.lastName ?? '';
        user.email = data.email ?? '';
        user.comments = data.comments ?? '';
        user.isActive = data.isActive ?? false;
        user.roleIds = data.roleIds ?? [];

        return user;
    };
};

