import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FormXmlData } from "../../models/form-xml-data";
import { CvxButton } from "../../modules/cvx-ui-module";
import OdsService from "../../services/ods-service";
import XMLViewer from 'react-xml-viewer';
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";

export interface OdsShowFormXmlDialogProps {
    show: boolean;
    pnfRequestId: number | undefined;
    odsService: OdsService | undefined;
    onComplete: (dialogResult: boolean) => Promise<any>;
}

const OdsShowFormXmlDialog: React.FC<OdsShowFormXmlDialogProps> = ({
    show,
    pnfRequestId,
    odsService,
    onComplete,
    ...props
}) => {
    const [xmlData, setXmlData] = useState<FormXmlData | undefined>(undefined);

    const onCancel = async (): Promise<any> => {
        if (onComplete) {
            await onComplete(false);
        }
    };

    useEffect(() => {
        fetchXmlData();
    }, [pnfRequestId, odsService]);

    const fetchXmlData = async () => {
        if (!!odsService && !!pnfRequestId) {
            const xmlData = await odsService.RetrieveFormSubmissionXmlDataAsync(pnfRequestId);
            setXmlData(xmlData);
        } else {
            setXmlData(undefined);
        }
    };

    return (
        <Modal className="theme-blue" size="xl" show={show} centered backdrop='static' scrollable={true} onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Form Submission '{pnfRequestId}' XML</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {xmlData !== undefined
                    ?
                    // <XMLViewer xml={xmlData?.xml} collapsible={true} indentSize={5} />
                    <Editor
                        height="60vh"
                        defaultLanguage="xml"
                        defaultValue={xmlData.xml}
                        options={{
                            readOnly: true
                        }}
                    />
                    :
                    <Spinner animation="grow" />
                }
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Close</CvxButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsShowFormXmlDialog;
