import React from "react";

export interface CvxFooterProps {
    footer: JSX.Element | string;
}

export const CvxFooter: React.FC<CvxFooterProps> = ({
    footer,
    ...props
}) => {
    return (
        <div className="cvx-footer cvx-primary-dark-color">{footer}</div>
    );
};

export default CvxFooter;
