import { FieldHookConfig, useField } from "formik";
import React, { } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { StringPropertyNames, TokenProps } from "react-bootstrap-typeahead";
import { MultiEditField } from "../../../models";
import { ListResult } from "../../../models/list-result";
import { OdsAsyncMultiSelectControl } from "../../async-multi-select/async-multi-select.control";

export interface OdsMultiAsyncSelectProps<T> {
    label: string;
    labelKey?: T extends object ? StringPropertyNames<T> | ((option: T) => string) : never;
    onSearch: (top: number, skip: number, filter: string) => Promise<ListResult<T>>;
    renderMenuItemChildren?: (item: T) => JSX.Element;
    renderToken?: (item: T, props: TokenProps, index: number) => React.ReactNode;
}

interface Item {
};

export const OdsMultiAsyncSelect = <T extends Item>({
    label,
    labelKey,
    onSearch,
    renderMenuItemChildren,
    renderToken,
    ...props
}: OdsMultiAsyncSelectProps<T> & FieldHookConfig<MultiEditField<T>>) => {
    const [field, meta, helpers] = useField(props);

    const doValueChanged = (selected: T[]) => {
        helpers.setValue(new MultiEditField(selected[0], true), true);
    }

    const doUpdateChanged = (update: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(new MultiEditField(field.value?.value, !(field.value?.update ?? true)), true);
    }

    const doTouched = (show: boolean) => {
        if (!show) {
            helpers.setTouched(true, true);
        }
    }

    return (
        <Row>
            <Col md="auto">
                <Form.Group controlId={`${field.name}Update`}>
                    <Form.Label>Update&nbsp;&nbsp;</Form.Label>
                    <Form.Check
                        name={`${field.name}Update`}
                        type="switch"
                        placeholder={`Update ${label}`}
                        checked={field.value?.update ?? false}
                        onChange={doUpdateChanged}
                        onBlur={field.onBlur}
                        isInvalid={!!meta.touched && !!meta.error}
                    />
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId={field.name}>
                    <Form.Label>{label}</Form.Label>
                    <OdsAsyncMultiSelectControl
                        id={field.name}
                        labelKey={labelKey}
                        placeholder={label}
                        multiple={false}
                        selected={field.value?.value ? [field.value.value] : []}
                        onSearch={onSearch}
                        onChange={doValueChanged}
                        onMenuToggle={doTouched}
                        onBlur={field.onBlur}
                        isInvalid={!!meta.touched && !!meta.error}
                        renderMenuItemChildren={renderMenuItemChildren}
                        renderToken={renderToken}
                    />
                    <Form.Control.Feedback type="invalid" style={{ display: (!!meta.touched && !!meta.error) ? 'block' : '' }}>
                        {meta.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    )
};
