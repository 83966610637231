export class LayoutManager {
    private _layoutMode: LayoutMode = LayoutMode.desktop;

    public get LayoutMode(): LayoutMode {
        return this._layoutMode;
    }
};

export enum LayoutMode {
    none = 0,
    mobile,
    tablet,
    desktop,
};
