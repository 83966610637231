import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { ReportMonth } from "../../models/report-month";

export interface OdsBradenheadDispatchFilterProps {
    reportMonths: ReportMonth[];
    selectedReportMonthId: number;
    onSelectMonth: (reportingMonthId: number) => void;
}

const OdsBradenheadDispatchFilter: React.FC<OdsBradenheadDispatchFilterProps> = ({
    reportMonths,
    selectedReportMonthId,
    onSelectMonth,
    ...props
}) => {
    return (
        <div className="cvx-content-block">
            <Container className="ods-bradenhead-dashboard-filter">
                <Row>
                    <Col>
                        <Form.Label>Month</Form.Label>
                        <Form.Select value={selectedReportMonthId} onChange={(e) => onSelectMonth(parseInt(e.target.value))}>
                            {
                                reportMonths && reportMonths.map((item: any) => {
                                    return <option key={item.id} value={item.id}>{item.name}</option>
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
            </Container>
        </div>
    );
};

export default OdsBradenheadDispatchFilter;
