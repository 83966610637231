export abstract class Themes {
    public static LightColor: string = 'cvx-light-color';
    public static LightDark: string = 'cvx-dark-color';

    public static PrimaryDarkColor: string = 'cvx-primary-dark-color';
    public static PrimaryMediumColor: string = 'cvx-primary-medium-color';
    public static PrimaryLightColor: string = 'cvx-primary-light-color';

    public static PrimaryDarkBackground: string = 'cvx-primary-dark-background';
    public static PrimaryMediumBackground: string = 'cvx-primary-medium-background';
    public static PrimaryLightBackground: string = 'cvx-primary-light-background';

    public static AccentDarkColor: string = 'cvx-accent-dark-color';
    public static AccentMediumColor: string = 'cvx-accent-medium-color';
    public static AccentLightColor: string = 'cvx-accent-light-color';

    public static AccentDarkBackground: string = 'cvx-accent-dark-background';
    public static AccentMediumBackground: string = 'cvx-accent-medium-background';
    public static AccentLightBackground: string = 'cvx-accent-light-background';

    public static WarningDarkColor: string = 'cvx-warning-dark-color';
    public static WarningMediumColor: string = 'cvx-warning-medium-color';
    public static WarningLightColor: string = 'cvx-warning-light-color';

    public static WarningDarkBackground: string = 'cvx-warning-dark-background';
    public static WarningMediumBackground: string = 'cvx-warning-medium-background';
    public static WarningLightBackground: string = 'cvx-warning-light-background';
};

export default Themes;
