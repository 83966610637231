export class PersonnelGroupType {
    public id: number = 0;
    public name: string = '';
    public description: string = '';
    public isActive: boolean = true;

    public static map(data: any): PersonnelGroupType {
        const type = new PersonnelGroupType;

        type.id = data.id;
        type.name = data.name;
        type.description = data.description;
        type.isActive = data.isActive;

        return type;
    };
};
