import React, {  } from "react";
import { Spinner } from "react-bootstrap";
import { ListResult } from "../../models";
import { CvxHeading, CvxPagination, PageChangedEvent } from "../../modules/cvx-ui-module";

export interface OdsTableProps<T> {
    heading: string;
    data: ListResult<T>;
    isLoading: boolean;
    tableHeader: React.ReactNode;
    tableRow: (item: T) => React.ReactNode;
    pageSize: number;
    pageIndex: number;
    pageSizeOptions?: number[];
    onPageChanged: (e: PageChangedEvent) => void;
};

interface Item {

};

export const OdsTable = <T extends Item>({
    heading,
    data,
    isLoading,
    tableHeader,
    tableRow,
    pageSize,
    pageIndex,
    pageSizeOptions,
    onPageChanged,
    ...props
}: OdsTableProps<T>) => {
    return (
        <div className="cvx-table">
            <CvxHeading heading={<span>{heading}</span>} />
            <div className="table-scroll">
                <table>
                    <thead>
                        {tableHeader}
                    </thead>
                    <tbody>
                        {isLoading
                            ? <tr className="loading">
                                <td colSpan={7} style={{ height: `${Math.min(pageSize, 20) * 38}px` }}>
                                    <Spinner animation="border" role="status" variant="primary" style={{ width: '75px', height: '75px' }}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </td>
                            </tr>
                            : data.items.map((item) => tableRow(item)
                            )}
                    </tbody>
                </table>
            </div>
            <CvxPagination totalCount={data.totalCount} pageIndex={pageIndex} pageSize={pageSize} pageSizeOptions={pageSizeOptions ?? [5, 10, 25, 100]} onPageChanged={onPageChanged} />
        </div>
    )
};
