export class FormSubmission {
    public id?: number;
    public referenceNumber?: string;
    public formId?: string;
    public status?: string;
    public formVersionId?: string;
    public userName?: string;
    public serverReceiveDate?: Date;
    public isActive: boolean = false;

    public static mapFormSubmission(data: any): FormSubmission {
        const f = new FormSubmission();

        f.id = data.id;
        f.referenceNumber = data.referenceNumber;
        f.formId = data.formId;
        f.status = data.status;
        f.formVersionId = data.formVersionId;
        f.userName = data.userName;
        f.serverReceiveDate = data.serverReceiveDate ? new Date(data.serverReceiveDate) : undefined;
        f.isActive = data.isActive;

        return f;
    };
};
