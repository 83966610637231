import { HtmlHTMLAttributes } from "react";
import { BsCheckCircleFill, BsXCircle } from "react-icons/bs";

export interface OdsSuccessColumnProps {
    value: boolean
}

export const OdsSuccessColumn: React.FC<OdsSuccessColumnProps & HtmlHTMLAttributes<HTMLTableCellElement>> = ({
    value,
    ...props
}) => {
    return (
        <td {...props} className="center-column" style={{color: (value ? "green": "red")}}>{(value ? <BsCheckCircleFill /> : <BsXCircle />)}</td>
    )
}
