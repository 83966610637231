import { BooleanFilter } from "./boolean-filter";
import { IntegerFilter } from "./integer-filter";
import { ItemFilter } from "./item-filter";
import { StringFilter } from "./string-filter";

export class PersonnelGroupFilter {
    public id?: IntegerFilter;
    public name?: StringFilter;
    public description?: StringFilter;
    public personnelGroupType?: ItemFilter;
    public personnel?: ItemFilter;
    public state?: StringFilter;
    public isActive?: BooleanFilter;
}
