export class LdarInspectionRequestStatus {
    public id: number = 0;
    public name: string = '';

    public static map(data: any): LdarInspectionRequestStatus {
        const m = new LdarInspectionRequestStatus;

        m.id = data.id;
        m.name = data.name;

        return m;
    };
};
