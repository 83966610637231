import { FieldHookConfig, useField } from "formik";
import { Form, Row } from "react-bootstrap";
import { StringPropertyNames, TokenProps } from "react-bootstrap-typeahead";
import { ListResult } from "../../../models/list-result";
import { OdsAsyncMultiSelectControl } from "../../async-multi-select/async-multi-select.control";

export interface OdsAsyncMultiSelectProps<T> {
    label: string;
    labelKey?: T extends object ? StringPropertyNames<T> | ((option: T) => string) : never;
    onSearch: (top: number, skip: number, filter: string) => Promise<ListResult<T>>;
    renderMenuItemChildren?: (item: T) => JSX.Element;
    renderToken? : (item: T, props: TokenProps, index: number) => React.ReactNode;
}

interface Item {
};

export const OdsAsyncMultiSelect = <T extends Item>({
    label,
    labelKey,
    onSearch,
    renderMenuItemChildren,
    renderToken,
    ...props
}: OdsAsyncMultiSelectProps<T> & FieldHookConfig<T[]>) => {
    const [field, meta, helpers] = useField(props);

    const doChanged = (selected: T[]) => {
        helpers.setValue(selected, true);
    }

    const doTouched = (show: boolean) => {
        if(!show) {
            helpers.setTouched(true, true);
        }
    }

    return (
        <Row>
            <Form.Group controlId={field.name}>
                <Form.Label>{label}</Form.Label>
                <OdsAsyncMultiSelectControl
                    id={field.name}
                    labelKey={labelKey}
                    placeholder={label}
                    multiple={true}
                    selected={field.value}
                    onSearch={onSearch}
                    onChange={doChanged}
                    onMenuToggle={doTouched}
                    onBlur={field.onBlur}
                    isInvalid={!!meta.touched && !!meta.error}
                    renderMenuItemChildren={renderMenuItemChildren}
                    renderToken={renderToken}
                />
                <Form.Control.Feedback type="invalid" style={{ display: (!!meta.touched && !!meta.error) ? 'block' : '' }}>
                    {meta.error}
                </Form.Control.Feedback>
            </Form.Group>
        </Row>
    )
};
