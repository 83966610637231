import { DispatchForm } from "./dispatch-form";
import { OrdInspectionRequestStatus } from "./ord-inspection-request-status";
import { OrdInspectionRequestTask } from "./ord-inspection-request-task";
import { Personnel } from "./personnel";
import { Lod } from "./lod";

export class OrdInspectionRequestDispatch {
    public id: number = 0;
    public inspectionRequestId: number = 0;
    public assetNumber: string = '';
    public assetDescription: string = '';
    public formId: number = 0;
    public startDate?: Date;
    public dueDate?: Date;
    public dispatchDate?: Date;
    public dispatchNumber: string = '';
    public personnelId: number = 0;
    public taskId: number = 0;
    public statusId: number = 0;
    public modifiedDate?: Date;
    public routeId?: string;
    public personnel?: Personnel;
    public status?: OrdInspectionRequestStatus;
    public task?: OrdInspectionRequestTask;
    public form?: DispatchForm;

    public static map(data: any) {
        const m = new OrdInspectionRequestDispatch();

        m.id = parseInt(data.id);
        m.inspectionRequestId = parseInt(data.inspectionRequestId);
        m.formId = parseInt(data.formId);
        m.taskId = parseInt(data.taskId);
        m.assetNumber = data.assetNumber;
        m.assetDescription = data.assetDescription;
        m.personnelId = parseInt(data.personnelId);
        m.startDate = new Date(data.startDate);
        m.routeId = data.routeId;
        m.dueDate = new Date(data.dueDate);
        m.dispatchDate = new Date(data.dispatchDate);
        m.dispatchNumber = data.dispatchNumber;
        m.statusId = parseInt(data.statusId);
        m.modifiedDate = new Date(data.modifiedDate);
        
        return m;
    }
}
