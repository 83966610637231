import { LdarInspectionRequestStatus } from "./ldar-inspection-request-status";
import { LdarInspectionRequestType } from "./ldar-inspection-request-type";
import { Lod } from "./lod";
import { State } from "./state";

export class LdarInspectionRequest {
    public id: number = 0;
    public lodId: string = '';
    public typeId: number = 0;
    public stateId: number = 0;
    public startDate?: Date;
    public dueDate?: Date;
    public dispatchDate?: Date;
    public dispatchUserIds: number[] = [];
    public formSubmissionDate?: Date;
    public formSubmissionNumber?: string;
    public comments: string = '';
    public statusId: number = 0;
    public isConsentDecree: boolean = false;
    public isOccupiedArea: boolean = false;
    public isDisproportionatelyImpactedCommunity: boolean = false;
    public isOOOOa: boolean = false;
    public isOOOOb: boolean = false;
    public workOrderNumber?: string;
    public sourcePersonnelId: number = 0;
    public modifiedDate?: Date;

    public lod?: Lod;
    public state?: State;
    public status?: LdarInspectionRequestStatus;
    public type?: LdarInspectionRequestType;

    public static map(data: any) {
        const m = new LdarInspectionRequest();

        m.id = parseInt(data.id);
        m.lodId = data.lodId;
        m.typeId = parseInt(data.typeId);
        m.stateId = parseInt(data.stateId);
        m.startDate = new Date(data.startDate);
        m.dueDate = new Date(data.dueDate);
        m.dispatchDate = new Date(data.dispatchDate);
        m.dispatchUserIds = data?.dispatchUserIds?.map((d: any) => parseInt(d)) ?? [];
        m.formSubmissionDate = new Date(data.formSubmissionDate);
        m.formSubmissionNumber = data.formSubmissionNumber;
        m.comments = data.comments;
        m.statusId = parseInt(data.statusId);
        m.isConsentDecree = data.isConsentDecree;
        m.isOccupiedArea = data.isOccupiedArea;
        m.isDisproportionatelyImpactedCommunity = data.isDisproportionatelyImpactedCommunity;
        m.isOOOOa = data.isOOOOa;
        m.isOOOOb = data.isOOOOb;
        m.workOrderNumber = data.workOrderNumber;
        m.sourcePersonnelId = parseInt(data.sourcePersonnelId);
        m.modifiedDate = new Date(data.modifiedDate);
        
        return m;
    }
}
