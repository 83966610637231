import { UserRole } from "./user-role";

export class Myself {
    public id: number = 0;
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public isActive: boolean = true;

    public roles: UserRole[] = [];
    public permissions: { permission: string, stateId: number }[] = [];

    public static map(data: any): Myself {
        const m = new Myself;

        m.id = parseInt(data.id);
        m.firstName = data.firstName;
        m.lastName = data.lastName;
        m.email = data.email;
        m.isActive = data.isActive;

        m.roles = data.roles?.map((d: any) => UserRole.map(d)) ?? [];
        m.permissions = data.permissions ?? [];

        return m;
    };
}
