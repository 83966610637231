import { useEffect, useReducer, useState } from "react";
import { Form } from "react-bootstrap";
import { BsChevronDown, BsChevronRight, BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FormDefinition } from "../../../models/form-definition";
import { CvxButton, CvxHeading, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock, Themes } from "../../../modules/cvx-ui-module";
import CvxContentBlock from "../../../modules/cvx-ui-module/components/content-block/content-block";
import OdsService from "../../../services/ods-service";


export interface OdsFormManagementViewProps {
    odsService: OdsService | undefined;
}


const OdsFormManagementView: React.FC<OdsFormManagementViewProps> = ({
    odsService,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [forms, setForms] = useState<FormDefinition[]>([]);

    const navigate = useNavigate();

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const fetchForms = async () => {
        if (odsService) {
            setIsLoading(true);

            const forms = await odsService.RetrieveFormListAsync();

            const hierForms: FormDefinition[] = [];

            forms.forEach(f => {
                if (f.parentId === 0) {
                    hierForms.push(f);
                } else {
                    const parent = forms.find(p => p.id === f.parentId);

                    if (parent) {
                        parent.children.push(f);
                    }
                }
            });

            setForms(hierForms);
            setIsLoading(false);
        }
    };

    const drawFormChildren = (children: FormDefinition[], indent: number): React.ReactNode => {
        return children.map(f => {
            return (
                <>
                    <tr>
                        <td className="center-column" style={{ maxWidth: '50px' }}>{f.id}</td>
                        <td style={{ paddingLeft: `${indent}px` }}>
                            {drawAttributePrefix(f)}
                            {!f.dispatchFormId ?
                                <span className={`${Themes.PrimaryDarkColor} ods-add-icon`} onClick={() => onAddItem(f)}><BsPlusLg /></span>
                                : <span style={{ paddingLeft: '26px' }}></span>}
                            <a href={`/forms/management/${f.id}`}>{f.name}</a>
                        </td>
                        <td>{f.description}</td>
                        <td className="center-column">{f.sortOrder}</td>
                        <td className="center-column">{f.dispatchFormId}</td>
                        <td className="center-column">{f.dispatchFormId ? <Form.Check type="switch" checked={f.retrieveFormSubmissions} disabled={true} /> : ''}</td>
                        <td className="center-column">{(f.dispatchFormId && f.submissionLastReceiveDateTimeUtc) ? f.submissionLastReceiveDateTimeUtc.toLocaleDateString() + ' ' + f.submissionLastReceiveDateTimeUtc.toLocaleTimeString() : ''}</td>
                    </tr>
                    {f.isCollapsed ? '' : drawFormChildren(f.children ?? [], indent + 24)}
                </>);
        });
    };

    const drawAttributePrefix = (form: FormDefinition) => {
        if (form.children.length == 0) {
            return <span style={{ paddingLeft: '20px' }}></span>;
        } else if (form.isCollapsed) {
            return <span className="ods-expand-icon" onClick={() => updateCollapsed(form, false)}><BsChevronRight /></span>;
        } else {
            return <span className="ods-expand-icon" onClick={() => updateCollapsed(form, true)}><BsChevronDown /></span>;
        }
    }

    const updateCollapsed = (form: FormDefinition, isCollapsed: boolean) => {
        form.isCollapsed = isCollapsed;
        forceUpdate();
    }

    const onAddItem = (parent: FormDefinition | undefined) => {
        if(parent) {
            navigate('../forms/management/new?parent=' + parent.id);
        } else {
            navigate('../forms/management/new');
        }
    }

    useEffect(() => {
        fetchForms();
    }, [odsService]);

    return (
        <CvxPage desktop={
            <CvxLayoutCenterColumn>
                <CvxTableContentBlock>
                    <div className="cvx-table ods-form-management">
                        <CvxHeading heading={
                            <span className="ods-table-header">
                                <span className="ods-table-header-text">Forms</span>
                                <span className="ods-add-button"><CvxButton onClick={() => onAddItem(undefined)}><BsPlusLg />&nbsp;Add</CvxButton></span>
                            </span>
                            }
                        />
                        <div className="table-scroll">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="center-column">Id</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th className="center-column">Priority</th>
                                        <th className="center-column">Form Id</th>
                                        <th className="center-column">Retreive Submissions</th>
                                        <th className="center-column">Last Submission Received</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {forms ? drawFormChildren(forms, 0) : ''}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CvxTableContentBlock>
            </CvxLayoutCenterColumn >
        } />
    );
};


export default OdsFormManagementView;
