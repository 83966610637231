import { useEffect, useReducer, useState } from 'react';
import * as yup from 'yup';
import { OdsAsyncMultiSelect, OdsAsyncSelect } from '../../components';
import OdsInput from '../../components/form-editors/input/input';
import OdsSwitch from '../../components/form-editors/switch/switch';
import OdsTextArea from '../../components/form-editors/textarea/textarea';
import { useMyself } from '../../hooks/myself-hook';
import { BooleanFilter, IntegerFilter, PersonnelGroupFilter, Route, StringFilter } from '../../models';
import { Permission } from '../../models/permission';
import { StateFilter } from '../../models/state-filter';
import { OdsPersonnelApiService } from '../../services';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditRouteDialogProps {
    service: OdsPersonnelApiService;
    show: boolean;
    route: Route | undefined;
    onComplete: (dialogResult: boolean, route: Route | undefined) => Promise<any>;
}

export const OdsEditRouteDialog: React.FC<OdsEditRouteDialogProps> = ({
    service,
    show,
    route,
    onComplete,
    ...props
}) => {

    const [canEdit, showState, states] = useMyself(service, Permission.EditPersonnel);

    const routeSchema = yup.object().shape({
        name: yup.string().required().max(250)
            .test('duplicate', 'name is already in use', async (value: string | undefined, testContext: any) => {
                if (value) {
                    return await service.ValidateRouteNameAsync(value, testContext.parent.state?.id, route?.id);
                }
                return true;
            }),
        description: yup.string().nullable(),
        ecObjectCode: yup.string().nullable().max(250),
        inforRouteCode: yup.string().nullable().max(250),
        personnelGroups: yup.array().nullable(),
        state: yup.object().required(),
        isActive: yup.boolean().required(),
    });

    const onSearchPersonnelGroups = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelGroupFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        //here is where you add the active value to the filter
        //only want to search on active
        const boolFilter = new BooleanFilter();
        filter.isActive = boolFilter;
        filter.isActive.equal = true;

        let data = await service.RetrievePersonnelGroupListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    const onSearchState = async (top: number, skip: number, filterStr: string) => {
        const filter = new StateFilter();
        const idFilter = new IntegerFilter();

        filter.id = idFilter;
        filter.id.equal = states;

        let data = await service.RetrieveStateListAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };


    return (
        <OdsBaseAddEditDialog
            show={show}
            title={route?.id ? 'Edit Personnel Route: ' + route.name : 'Add Personnel Route'}
            validationSchema={routeSchema}
            initialValue={route ?? new Route()}
            onComplete={onComplete}
        >
            <OdsInput name="name" label="Name" />
            <OdsTextArea name="description" label="Description" />
            <OdsInput name="ecObjectCode" label="EC Object Code" />
            <OdsInput name="inforRouteCode" label="Infor Route Code" />
            <OdsAsyncMultiSelect
                name="personnelGroups"
                label="Personnel Groups"
                labelKey="name"
                onSearch={onSearchPersonnelGroups}
                renderMenuItemChildren={(item) => <span>{item.name}</span>}
            />
            <OdsAsyncSelect
                name="state"
                label="State"
                labelKey="name"
                onSearch={onSearchState}
            />
            <OdsSwitch name="isActive" label="Is Active" />
        </OdsBaseAddEditDialog>
    );
};
