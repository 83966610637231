import { IntegerFilter } from "./integer-filter";
import { StringFilter } from "./string-filter";
import { DateFilter } from "./date-filter";
import { BooleanFilter } from "./boolean-filter";
import { ItemFilter } from "./item-filter";

export class OrdInspectionRequestFilter {
    public id?: IntegerFilter;
    public assetNumber?: StringFilter;
    public assetDescription?: StringFilter;
    public form?: StringFilter;
    public comments?: StringFilter;
    public sourceSubmissionNumber?: StringFilter;
    public userDefined1?: StringFilter;
    public userDefined2?: StringFilter;
    public status?: ItemFilter;
    public route?: ItemFilter;
    public task?: ItemFilter;
    public masterId?: StringFilter;
    public requestId?: StringFilter;
    public startDate?: DateFilter;
    public dueDate?: DateFilter;
    public dispatchDate?: DateFilter;
    public formSubmissionNumber?: StringFilter;
    public formSubmissionDate?: DateFilter;
    public dispatchUser?: StringFilter;
    public isCompleted?: BooleanFilter;
    public isDispatched?: BooleanFilter;
}
