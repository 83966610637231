export abstract class Fonts {
    public static GothamBook: string = 'gotham-book';

    public static GothamNarrowBook: string = 'gotham-narrow-book';

    public static GothamNarrowBold: string = 'gotham-narrow-bold';

    public static GothamBold: string = 'gotham-bold';

    public static GothamBookMedium: string = 'gotham-book-medium';
}

export default Fonts;
