import * as yup from 'yup';
import OdsInput from "../../components/form-editors/input/input";
import OdsSelect from '../../components/form-editors/select/select';
import { BradenheadPressureData } from '../../models/bradenhead-pressure-data';
import { BradenheadWellCategory } from '../../models/bradenhead-well-category';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditBradenheadWellDialogProps {
    show: boolean;
    well: BradenheadPressureData | undefined;
    categories: BradenheadWellCategory[];
    onComplete: (dialogResult: boolean, inspection: BradenheadPressureData | undefined) => Promise<any>;
}

const OdsEditBradenheadWellDialog: React.FC<OdsEditBradenheadWellDialogProps> = ({
    show,
    well,
    categories,
    onComplete,
    ...props
}) => {

    const schema = yup.object().shape({
        wellName: yup.string().nullable(),
        wellCategoryId: yup.object().nullable(),
    });

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={well ? 'Edit Well: ' + well.wellName : 'Add Well'}
            validationSchema={schema}
            initialValue={well ?? new BradenheadPressureData()}
            onComplete={onComplete}
        >
            <OdsInput name="wellName" label="Well" disabled={true} />
            <OdsSelect name="wellCategoryId" label="Category" items={categories} />
        </OdsBaseAddEditDialog>
    );
};

export default OdsEditBradenheadWellDialog;
