import { HtmlHTMLAttributes } from "react";

export interface OdsDateColumnProps {
    value?: Date
}

export const OdsDateColumn: React.FC<OdsDateColumnProps & HtmlHTMLAttributes<HTMLTableCellElement>> = ({
    value,
    ...props
}) => {
    return (
        <td {...props} className="center-column">{value ? (value > new Date('9000-1-1') ? '' : value.toLocaleDateString()): props.children}</td>
    )
}
