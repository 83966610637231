import { Lod } from "./lod";
import { Personnel } from "./personnel";

export class LdarInspectionRequestDispatch {
    public id: number = 0;
    public inspectionRequestId: number = 0;
    public lodId: string = '';
    public typeId: number = 0;
    public stateId: number = 0;
    public startDate?: Date;
    public dueDate?: Date;
    public dispatchDate?: Date;
    public dispatchNumber: string = '';
    public personnelId: number = 0;
    public statusId: number = 0;
    public isConsentDecree: boolean = false;
    public isOccupiedArea: boolean = false;
    public isDisproportionatelyImpactedCommunity: boolean = false;
    public isOOOOa: boolean = false;
    public isOOOOb: boolean = false;
    public modifiedDate?: Date;

    public lod?: Lod;
    public personnel?: Personnel;

    public static map(data: any) {
        const m = new LdarInspectionRequestDispatch();

        m.id = parseInt(data.id);
        m.inspectionRequestId = parseInt(data.inspectionRequestId);
        m.lodId = data.lodId;
        m.typeId = parseInt(data.typeId);
        m.stateId = parseInt(data.stateId);
        m.personnelId = parseInt(data.personnelId);
        m.startDate = new Date(data.startDate);
        m.dueDate = new Date(data.dueDate);
        m.dispatchDate = new Date(data.dispatchDate);
        m.dispatchNumber = data.dispatchNumber;
        m.statusId = parseInt(data.statusId);
        m.isConsentDecree = data.isConsentDecree;
        m.isOccupiedArea = data.isOccupiedArea;
        m.isDisproportionatelyImpactedCommunity = data.isDisproportionatelyImpactedCommunity;
        m.isOOOOa = data.isOOOOa;
        m.isOOOOb = data.isOOOOb;
        m.modifiedDate = new Date(data.modifiedDate);
        
        return m;
    }
}
