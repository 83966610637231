const uid = () =>
    String(
        Date.now().toString(32) +
        Math.random().toString(16)
    ).replace(/\./g, '');

export class FormAttribute {
    public id: number = 0;
    public parentId: number = 0;
    public name: string = '';
    public description: string = '';
    public tag: string = '';
    public xPath: string = '';
    public isImage: boolean = false;
    public isRepeating: boolean = false;
    public isActive: boolean = true;
    public isDeleted: boolean = false;

    public children: FormAttribute[] = [];

    
    public key: string = uid();
    public isCollapsed: boolean = false;


    public constructor() {
        this.key = uid();
    }

    
    public static mapDefinition(data: any): FormAttribute {
        const type = new FormAttribute;

        type.id = data.id;
        type.parentId = data.parentId ?? 0;
        type.name = data.name;
        type.description = data.description;
        type.tag = data.tag;
        type.xPath = data.xPath;
        type.isImage = data.isImage;
        type.isRepeating = data.isRepeating;
        type.isActive = data.isActive;
        type.isDeleted = data.isDeleted;

        type.children = (data.children ?? []).map((d: FormAttribute) => FormAttribute.mapDefinition(d))

        return type;
    };
}
