import { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { OdsAsyncMultiSelectControl, OdsTable } from "../../components";
import OdsBaseTable from "../../components/base-table/base-table";
import { BooleanFilter, PersonnelFilter, PersonnelGroupFilter, PersonnelGroupTypeFilter, StringFilter } from "../../models";
import { ApiError } from "../../models/api-error";
import { Personnel } from "../../models/personnel";
import { PersonnelGroup } from "../../models/personnel-group";
import { PersonnelGroupType } from "../../models/personnel-group-type";
import { Route } from "../../models/route";
import { RouteFilter } from "../../models/route-filter";
import { ColumnDefinition, CvxButton, CvxSpinnerButton } from "../../modules/cvx-ui-module";
import { OdsPersonnelApiService } from "../../services";

export interface OdsAutoDispatchAllDialogProps {
    service: OdsPersonnelApiService;
    show: boolean;
    inspections: any[];
    columns?: ColumnDefinition<any>[];
    onComplete: (dialogResult: boolean) => void;
    onAutoDispatchItem: (item: any, users: string[]) => Promise<any>;
}

const OdsAutoDispatchDialog: React.FC<OdsAutoDispatchAllDialogProps> = ({
    service,
    show,
    inspections,
    columns,
    onComplete,
    onAutoDispatchItem,
    ...props
}) => {
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [populating, setPopulating] = useState<boolean>(false);

    const [selectedGroupTypesLO, setSelectedGroupTypesLO] = useState<PersonnelGroupType[]>([]);
    const [selectedPersonnelLO, setSelectedPersonnelLO] = useState<Personnel[]>([]);
    const [selectedPersonnelGroupsLO, setSelectedPersonnelGroupsLO] = useState<PersonnelGroup[]>([]);

    const [selectedGroupTypesARO, setSelectedGroupTypesARO] = useState<PersonnelGroupType[]>([]);
    const [selectedPersonnelARO, setSelectedPersonnelARO] = useState<Personnel[]>([]);
    const [selectedPersonnelGroupsARO, setSelectedPersonnelGroupsARO] = useState<PersonnelGroup[]>([]);

    const [selectedGroupTypesProblemWell, setSelectedGroupTypesProblemWell] = useState<PersonnelGroupType[]>([]);
    const [selectedPersonnelProblemWell, setSelectedPersonnelProblemWell] = useState<Personnel[]>([]);
    const [selectedPersonnelGroupsProblemWell, setSelectedPersonnelGroupsProblemWell] = useState<PersonnelGroup[]>([]);

    const [personnel, setPersonnel] = useState<Map<number, Promise<Personnel>>>(new Map());
    const [personnelGroups, setPersonnelGroups] = useState<Map<number, Promise<PersonnelGroup>>>(new Map());
    const [routes, setRoutes] = useState<Map<string, Promise<Route | undefined>>>(new Map());

    const [totalDispatches, setTotalDispatches] = useState<number>(0);

    const onSubmit = async (): Promise<any> => {
        setIsSaving(true);

        const inspec: any[] = [];

        let hasDispatchErrors: boolean = false;

        inspections.forEach((i: any) => inspec.push(i));

        for (let i = 0; i < inspec.length; i++) {
            try {
                if (inspec[i].dispatchUserList.length > 0) {
                    await onAutoDispatchItem(inspec[i], inspec[i].dispatchUserList);
                    const index = inspections.indexOf(inspec);
                    if (index > -1) {
                        inspections.splice(index, 1);
                    }
                }
            } catch (error: any) {
                hasDispatchErrors = true;

                if (error instanceof ApiError) {
                    inspec[i].error = error.error.detail;
                } else if (error instanceof Error) {
                    inspec[i].error = error.message;
                } else {
                    inspec[i].error = error.toString();
                }
            }
        }

        setIsSaving(false);

        if (hasDispatchErrors == false && onComplete) {
            onComplete(true);
        }
    };

    const onCancel = () => {
        if (onComplete) {
            onComplete(false);
        }
    };

    const onSearchPersonnelGroupTypes = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelGroupTypeFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        //here is where you add the active value to the filter
        //only want to search on active
        const boolFilter = new BooleanFilter();
        filter.isActive = boolFilter;
        filter.isActive.equal = true;


        return await service.RetrievePersonnelGroupTypeListAsync(top, skip, 'Name', 'Asc', filter);
    };

    const onSearchPersonnelGroups = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelGroupFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        //here is where you add the active value to the filter
        //only want to search on active
        const boolFilter = new BooleanFilter();
        filter.isActive = boolFilter;
        filter.isActive.equal = true;


        return await service.RetrievePersonnelGroupListAsync(top, skip, 'Name', 'Asc', filter);
    };

    const onSearchPersonnel = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new PersonnelFilter();

        filter.fullName = stringFilter;
        filter.fullName.like = filterStr;

        //here is where you add the active value to the filter
        //only want to search on active
        const boolFilter = new BooleanFilter();
        filter.isActive = boolFilter;
        filter.isActive.equal = true;


        return await service.RetrievePersonnelListAsync(top, skip, 'LastName', 'Asc', filter);
    };


    const fetchPersonnel = (id: number) => {
        let data = personnel.get(id);
        if (!data) {
            data = service.RetrievePersonnelAsync(id);
            personnel.set(id, data);
        }
        return data;
    }

    const fetchPersonnelGroup = (id: number) => {
        let data = personnelGroups.get(id);
        if (!data) {
            data = service.RetrievePersonnelGroupAsync(id);
            personnelGroups.set(id, data);
        }
        return data;
    }

    const fetchPersonnelGroupType = (id: number) => {
        let data = service.RetrievePersonnelGroupTypeAsync(id);
        return data;
    }

    const fetchRoute = (routeName: string) => {
        let data = routes.get(routeName);
        if (!data) {
            const filter = new RouteFilter();
            filter.name = new StringFilter();
            filter.name.equal = [routeName];

            data = (async () => (await service.RetrieveRouteListAsync(1, 0, 'Name', 'Asc', filter)).items[0])();
            routes.set(routeName, data);
        }
        return data;
    }

    const onAssignDispatchesProblemWell = async () => {
        setPopulating(true);


        if (inspections) {
            inspections.filter(p => p.wellCategory?.includes('Problem Well')).forEach(i => i.dispatchUserList = []);
            inspections.filter(p => p.wellCategory?.includes('Problem Well')).forEach(i => i.dispatchUsers = '');

            for (let selectedGroupType of selectedGroupTypesProblemWell) {
                for (let inspection of inspections.filter(p => p.wellCategory?.includes('Problem Well'))) {
                    const route = await fetchRoute(inspection.route);

                    if (route) {
                        for (let personnelGroupId of route.personnelGroupIds) {
                            const personnelGroup = await fetchPersonnelGroup(personnelGroupId);

                            if (personnelGroup.personnelGroupTypeId === selectedGroupType.id) {
                                for (let id of personnelGroup.personnelIds) {
                                    const personnel = await fetchPersonnel(id);
                                    if (personnel.email) {
                                        inspection.dispatchUserList.push(personnel.email);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            for (let g of selectedPersonnelGroupsProblemWell) {
                for (let id of g.personnelIds) {
                    const personnel = await fetchPersonnel(id);
                    inspections.filter(p => p.wellCategory?.includes('Problem Well')).forEach(i => {
                        if (personnel.email) {
                            i.dispatchUserList.push(personnel.email);
                        }
                    });
                }
            }

            for (let personnel of selectedPersonnelProblemWell) {
                inspections.filter(p => p.wellCategory?.includes('Problem Well')).forEach(i => {
                    if (personnel.email) {
                        i.dispatchUserList.push(personnel.email);
                    }
                });
            }

            let totalDispatches = 0;

            inspections.filter(p => p.wellCategory?.includes('Problem Well')).forEach(i => {
                i.dispatchUserList = i.dispatchUserList.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
                totalDispatches += i.dispatchUserList.length;
                i.dispatchUsers = i.dispatchUserList.join("; ")
            });

            setTotalDispatches(totalDispatches);
        }
        setPopulating(false);
    }

    const onAssignDispatchesARO = async () => {
        setPopulating(true);


        if (inspections) {
            inspections.filter(p => p.wellCategory?.includes('ARO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC')).forEach(i => i.dispatchUserList = []);
            inspections.filter(p => p.wellCategory?.includes('ARO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC')).forEach(i => i.dispatchUsers = '');

            for (let selectedGroupType of selectedGroupTypesARO) {
                for (let inspection of inspections.filter(p => p.wellCategory?.includes('ARO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC'))) {
                    const route = await fetchRoute(inspection.route);

                    if (route) {
                        for (let personnelGroupId of route.personnelGroupIds) {
                            const personnelGroup = await fetchPersonnelGroup(personnelGroupId);

                            if (personnelGroup.personnelGroupTypeId === selectedGroupType.id) {
                                for (let id of personnelGroup.personnelIds) {
                                    const personnel = await fetchPersonnel(id);
                                    if (personnel.email) {
                                        inspection.dispatchUserList.push(personnel.email);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            for (let g of selectedPersonnelGroupsARO) {
                for (let id of g.personnelIds) {
                    const personnel = await fetchPersonnel(id);
                    inspections.filter(p => p.wellCategory?.includes('ARO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC')).forEach(i => {
                        if (personnel.email) {
                            i.dispatchUserList.push(personnel.email);
                        }
                    });
                }
            }

            for (let personnel of selectedPersonnelARO) {
                inspections.filter(p => p.wellCategory?.includes('ARO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC')).forEach(i => {
                    if (personnel.email) {
                        i.dispatchUserList.push(personnel.email);
                    }
                });
            }

            let totalDispatches = 0;

            inspections.filter(p => p.wellCategory?.includes('ARO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC')).forEach(i => {
                i.dispatchUserList = i.dispatchUserList.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
                totalDispatches += i.dispatchUserList.length;
                i.dispatchUsers = i.dispatchUserList.join("; ")
            });

            setTotalDispatches(totalDispatches);
        }
        setPopulating(false);
    }

    const setDispatchDefaults = async () => {
        try
        {
            const defaultLOGroup = await fetchPersonnelGroupType(76087);
            setSelectedGroupTypesLO(Array(defaultLOGroup));
            let personnelIds: number[] = [55651, 49158, 49157, 203421];
            let personnel: Personnel[] = [];

            for (let id of personnelIds) {
                personnel.push(await fetchPersonnel(id));
            }

            setSelectedPersonnelARO(personnel.slice(0, 3));
            setSelectedPersonnelProblemWell(personnel);
        }
        catch
        {
            setSelectedGroupTypesLO([]);
            setSelectedPersonnelARO([]);
            setSelectedPersonnelProblemWell([]);
        }
    }

    const onAssignDispatchesLO = async () => {
        setPopulating(true);


        if (inspections) {
            inspections.filter(p => p.wellCategory?.includes('LO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC')).forEach(i => i.dispatchUserList = []);
            inspections.filter(p => p.wellCategory?.includes('LO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC')).forEach(i => i.dispatchUsers = '');

            for (let selectedGroupType of selectedGroupTypesLO) {
                for (let inspection of inspections.filter(p => p.wellCategory?.includes('LO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC'))) {
                    const route = await fetchRoute(inspection.route);

                    if (route) {
                        for (let personnelGroupId of route.personnelGroupIds) {
                            const personnelGroup = await fetchPersonnelGroup(personnelGroupId);

                            if (personnelGroup.personnelGroupTypeId === selectedGroupType.id) {
                                for (let id of personnelGroup.personnelIds) {
                                    const personnel = await fetchPersonnel(id);
                                    if (personnel.email) {
                                        inspection.dispatchUserList.push(personnel.email);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            for (let g of selectedPersonnelGroupsLO) {
                for (let id of g.personnelIds) {
                    const personnel = await fetchPersonnel(id);
                    inspections.filter(p => p.wellCategory?.includes('LO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC')).forEach(i => {
                        if (personnel.email) {
                            i.dispatchUserList.push(personnel.email);
                        }
                    });
                }
            }

            for (let personnel of selectedPersonnelLO) {
                inspections.filter(p => p.wellCategory?.includes('LO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC')).forEach(i => {
                    if (personnel.email) {
                        i.dispatchUserList.push(personnel.email);
                    }
                });
            }

            let totalDispatches = 0;

            inspections.filter(p => p.wellCategory?.includes('LO') && !p.wellCategory.includes('Problem Well') && !p.wellCategory.includes('AOC')).forEach(i => {
                i.dispatchUserList = i.dispatchUserList.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
                totalDispatches += i.dispatchUserList.length;
                i.dispatchUsers = i.dispatchUserList.join("; ")
            });

            setTotalDispatches(totalDispatches);
        }
        setPopulating(false);
    }

    const doChangedLO = (item: PersonnelGroupType[]) => {
        setSelectedGroupTypesLO(item);
    }

    const doChangedARO = (item: Personnel[]) => {
        setSelectedPersonnelARO(item);
    }

    const doChangedProblemWell = (item: Personnel[]) => {
        setSelectedPersonnelProblemWell(item);
    }

    useEffect(() => {
        onAssignDispatchesLO();
    }, [selectedGroupTypesLO, selectedPersonnelLO, selectedPersonnelGroupsLO]);

    useEffect(() => {
        onAssignDispatchesARO();
    }, [selectedGroupTypesARO, selectedPersonnelARO, selectedPersonnelGroupsARO]);

    useEffect(() => {
        onAssignDispatchesProblemWell();
    }, [selectedGroupTypesProblemWell, selectedPersonnelProblemWell, selectedPersonnelGroupsProblemWell]);

    useEffect(() => {
        setTotalDispatches(0);
        setSelectedGroupTypesLO([]);
        setSelectedPersonnelLO([]);
        setSelectedPersonnelGroupsLO([]);
        setSelectedGroupTypesARO([]);
        setSelectedGroupTypesProblemWell([]);
        setSelectedPersonnelGroupsProblemWell([]);
        setDispatchDefaults();
    }, [show]);

    return (
        <Modal className="theme-blue" size="xl" show={show} centered backdrop="static" onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Dispatch All Inspections</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            
                            <Form.Label>Personnel Group Type LO</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelGroupTypeLO"
                                labelKey="name"
                                placeholder="Personnel Group Type"
                                multiple={true}
                                onSearch={onSearchPersonnelGroupTypes}
                                onChange={doChangedLO}
                                selected={selectedGroupTypesLO}
                                
                            />
                        </Col>
                        <Col>
                            <Form.Label>Personnel LO</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelLO"
                                labelKey="fullName"
                                placeholder="Personnel"
                                multiple={true}
                                onSearch={onSearchPersonnel}
                                onChange={(item) => setSelectedPersonnelLO(item)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Personnel Group LO</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelGroupsLO"
                                labelKey="name"
                                placeholder="Personnel Groups"
                                multiple={true}
                                onSearch={onSearchPersonnelGroups}
                                onChange={(item) => setSelectedPersonnelGroupsLO(item)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <Form.Label>Personnel Group Type ARO</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelGroupTypeARO"
                                labelKey="name"
                                placeholder="Personnel Group Type"
                                multiple={true}
                                onSearch={onSearchPersonnelGroupTypes}
                                onChange={(item) => setSelectedGroupTypesARO(item)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Personnel ARO</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelARO"
                                labelKey="fullName"
                                placeholder="Personnel"
                                multiple={true}
                                onSearch={onSearchPersonnel}
                                onChange={doChangedARO}
                                selected={selectedPersonnelARO}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Personnel Group ARO</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelGroupsARO"
                                labelKey="name"
                                placeholder="Personnel Groups"
                                multiple={true}
                                onSearch={onSearchPersonnelGroups}
                                onChange={(item) => setSelectedPersonnelGroupsARO(item)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <Form.Label>Personnel Group Type Problem Well</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelGroupTypeProblemWell"
                                labelKey="name"
                                placeholder="Personnel Group Type"
                                multiple={true}
                                onSearch={onSearchPersonnelGroupTypes}
                                onChange={(item) => setSelectedGroupTypesProblemWell(item)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Personnel Problem Well</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelProblemWell"
                                labelKey="fullName"
                                placeholder="Personnel"
                                multiple={true}
                                onSearch={onSearchPersonnel}
                                onChange={doChangedProblemWell}
                                selected={selectedPersonnelProblemWell}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Personnel Group Problem Well</Form.Label>
                            <OdsAsyncMultiSelectControl
                                id="personnelGroupsProblemWell"
                                labelKey="name"
                                placeholder="Personnel Groups"
                                multiple={true}
                                onSearch={onSearchPersonnelGroups}
                                onChange={(item) => setSelectedPersonnelGroupsProblemWell(item)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginTop: '20px' }}>
                            <OdsBaseTable heading="Inspections" columns={columns ?? []} dataSource={inspections} isLoading={false} overridePageSize={10} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CvxButton theme='accent' onClick={onCancel}>Cancel</CvxButton>
                <CvxSpinnerButton theme='primary' disabled={populating} showSpinner={isSaving} onClick={onSubmit}>Dispatch</CvxSpinnerButton>
            </Modal.Footer>
        </Modal>
    );
};

export default OdsAutoDispatchDialog;
